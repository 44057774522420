@use 'base/variables' as var;
@use 'base/mixins' as mix;

.radio-list-v2 {
}

//Depends
.radio-list-v2 {

    .input-radio-v3, .input-radio-v2, .input-radio-v1, .input-radio-v4 {

        margin-top: 6rem;

        &:first-child {
            margin-top: 0;
        }

        span {
            font-family: var.$font-header;
            font-size: 2rem;
            line-height: 2.6rem;
            color: var.$color-black-tone4;
        }
    }
}