@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-tab-v1 {
    &__sticky {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: var.$container-width;
        z-index: 10;

        @include mix.breakpoint(var.$tablet-width) {
            padding: 0 var.$container-padding-x-md;
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 0 var.$container-padding-x-lg;
        }

        .product-tab-v1__wrapper {
            border-bottom-left-radius: 0.8rem;
            border-bottom-right-radius: 0.8rem;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            @include mix.breakpoint(var.$tablet-width) {
                padding: 0 calc(var.$container-padding-x-md / 2 );
            }

            @include mix.breakpoint(var.$desktop-width) {
                padding: 0 calc(var.$container-padding-x-lg / 2);
            }
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        gap: 3.6rem;
        background: var.$color-white;
        z-index: 100;
    }

    &__tabs {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        margin-top: 1.2rem;
    }

    &__title {
        font-family: var.$font-text;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 2.2rem;
        letter-spacing: 0em;
        color: var.$color-black-tone4;
    }

    &__highlight {
        font-family: var.$font-text;
        color: var.$color-main;
        text-decoration: underline;
    }

    &__shop {
        margin-left: auto;
    }

    &__price {
        display: flex;
        align-items: center;
        justify-items: center;
    }

    &__price {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
    }

    &__installment-price-from {
        margin: 0.8rem auto;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 2.4rem;
        font-family: var.$font-text;
        color: var.$color-black;
        text-align: center;
        .product-tab-v1__highlight {
            text-decoration: none;
            color: var.$color-black;
        }
    }

    &__image    {
        max-height: 11rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        display: none;
        &__sticky {
            display: none;
        }
    }
}

/** DEPENDS CODE */
.product-tab-v1 {
    &__tabs {
        .link-v1 {
            font-family: var.$font-text;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.7rem;
            color: var.$color-black-tone4;
            text-decoration: underline;
            @include mix.breakpoint(var.$tablet-h-width) {
                 font-size: 1.4rem;
            }
        }

        .badge-v1 .badge-v1__wrap .badge-v1__label {
            font-size: 1.4rem;
        }
    }

    &__price {
        margin: 0.8rem auto;

        .sale-price-v1 {
            font-size: 2.1rem;
            line-height: 2.5rem;
        }

        .old-price-v1 {
            font-size: 1.4rem;
            line-height: 1.7rem;
            color: var.$color-main;
        }

    }

    &__shop {
        margin-left: auto;

        .button-v1 {
            padding: 1rem 0;
            border-radius: 0.4rem;
            gap: 1rem;
            @include mix.breakpoint(var.$desktop-width) {
                padding: 1rem 13.3rem;
            }
        }
    }

    .size-selector-v1 {
        display: inline-block;
    }

    &__image {
        img {
            max-height: 11rem;
        }
    }

}

/** DEPENDS CODE */