@use 'base/variables' as var;
@use 'base/mixins' as mix;

.default {
    @include mix.breakpoint(var.$tablet-width) {
        display: flex;
        align-items: flex-start;
        padding-left: 2rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        padding-left: 6rem;
    }

    &__wrap {
        @include mix.breakpoint(var.$tablet-width) {
            order: -1;
            margin-right: 2rem;
            flex-grow: 1;
            max-width: 62rem;
            width: 43.2rem;

        }
        @include mix.breakpoint(var.$desktop-max-width) {
            max-width: 80rem;
        }
    }

    &__important {
        border: 2px solid var.$color-main;
        border-radius: .6rem;
        padding: 2rem;
        margin-bottom: 3.8rem;

        @include mix.breakpoint(var.$tablet-width) {
            position: sticky;
            top: 5rem;
            width: 27.5rem;
            max-width: 40rem;
            flex-grow: 1;
            max-width: 40rem;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 41.3rem;
            flex-grow: 0;
            max-width: none;
            padding: 5rem;
            padding-right: 4.4rem;
            padding-top: 4rem;
        }

        .default {
            &__text {
                color: #474747;
            }
        }
    }

    &__important-title {
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
        max-width: 27rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
            max-width: none;
        }
    }

    &__text {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 150%;
        color: #7C7C7C;
        &:last-child {
            margin-bottom: 0;
        }
    }


    &__section {
        margin-bottom: 5rem;
        &:last-child {
            margin-bottom: 0;
        }
        &._border {
            padding-top: 5rem;
            border-top: 1px solid #D6D6D6
        }

        &._grey {
            padding: 2rem 3rem;
            background: #F5F7FA;
            border-radius: 4px;
            .default {
                &__title {
                    margin-bottom: 1.5rem;
                    max-width: 21rem;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    font-weight: 700;
                    @include mix.breakpoint(var.$tablet-width) {
                        max-width: initial;
                    }
                }
                &__text {
                    color: #474747;
                }
            }
        }
    }

    &__title {
        margin: 0;
        margin-bottom: 3rem;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 600;
    }

    &__subtitle {
        margin-top: 4rem;
        margin-bottom: 1.5rem;
        color: #474747;
        font-weight: 500;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__ordered-item {
        position: relative;
        padding-left: 2rem;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        &::before {
            content: attr(data-marker);
            position: absolute;
            left: 0;
            top: 0;
            font-weight: 700;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0;
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__link {
        color: var.$color-main;
        text-decoration: none;
        // &[href^="mailto"] {
        //     border-bottom: 1px solid currentColor;
        // }
    }

    &__mark {
        font-size: 1.2rem;
        line-height: 150%;
        color: #A3A3A3;
    }

    ._mt-0 {
        margin-top: 0;
    }
}
