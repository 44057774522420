@use 'base/variables' as var;
@use 'base/mixins' as mix;

.high-rating {
    margin-bottom: 6.4rem;
    .title {
        margin-bottom: 1.5rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 10rem;
        .title {
            font-size: 2.8rem;
            line-height: 1.2;
            max-width: 33rem;
        }
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14rem;
        .title {
            margin-bottom: 2.5rem;
        }
    }
    @include mix.breakpoint(var.$desktop-laptop-width) {
        .title {
            font-size: 4rem;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 10.5rem;
        .title {
            margin-bottom: 2rem;
        }
    }

    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }
    }

    &__wrap {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 23rem;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            width: 32.5rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 41.3rem;
        }
    }

    &__main,
    &__best-price,
    &__certificate {
        background-color: #F5F7FA;
        border-radius: 4px;
        padding: 2rem 1.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
        }
    }

    &__main {
        margin-bottom: 1.5rem;
        min-height: 32rem;

        background-image: url("/img/maskot-pleased-mobile.svg");
        background-repeat: no-repeat;
        background-size: 29.3rem auto;
        background-position: bottom right;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 3rem;
            width: 47.8rem;
            min-height: 41.6rem;
            margin-bottom: 0;
            margin-right: 2rem;
            flex-grow: 1;

            background-image: url("/img/maskot-pleased-tablet.svg");
            background-position: bottom right 2.4rem;
            // background-size: 29.3rem auto;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            min-height: 39rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 84.7rem;

            background-image: url("/img/maskot-pleased-desktop.svg");
            background-size: 32.7rem auto;
            background-position: bottom right 6rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            width: 95rem;
            flex-grow: 0;
            min-height: 41.4rem;
            background-size: 35.4rem auto;
            background-position: bottom right 7rem;
        }
    }

    &__text {
        color: #818DA2;
        font-size: 1.3rem;
        line-height: 1.3;
        margin: 0;
        max-width: 30rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
            max-width: 22rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            max-width: 33.2rem;
        }
    }

    &__best-price {
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 2rem;
            flex-grow: 1;
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 3.5rem 3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 4.5rem 6rem;
        }
    }

    &__certificate {
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 2rem;
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 3.5rem 3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 4.5rem 6rem;
        }
    }
}
