@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
.search-form {
    flex-grow: 1;
    position: relative;
    @include mix.breakpoint(var.$desktop-test-width) {
        flex-grow: 0;
        width: 42.6rem;
        // order: 3;
    }

    &._focused {
        .search-dropdown {
            display: block;
            opacity: 1;
            visibility: visible;
            max-height: 100rem;
            overflow: initial;
        }

        .search-form {
            &__input {
                border-bottom-color: transparent;
                border-radius: 4px 4px 0 0;
            }
        }
        .search-form__close-btn{
            display: block;
        }
    }

    @include mix.breakpoint(var.$tablet-width) {

        &._header {
            .search-card {
                &__buttons {
                    margin-left: 2rem;
                }

                &__top-wrap {
                    display: block;
                }

                &__rating {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    &__input {
        width: 100%;
        padding: 1.2rem 4.5rem 1.2rem 4.5rem;
        background-color: #f9f9f9;
        border: 1px solid #ebf0f5;
        border-radius: 4px;
        font-size: 1.5rem;
        line-height: 1.9rem;
        outline: none;
    }

    &__input-manager {
        width: 100%;
        padding: 1.2rem 4.5rem 1.2rem 4.5rem;
        background-color: #f9f9f9;
        border: 1px solid #ebf0f5;
        border-radius: 4px;
        font-size: 1.5rem;
        line-height: 1.9rem;
        outline: none;
    }

    &__btn {
        position: absolute;
        top: 50%;
        left: 2rem;
        background-color: transparent;
        border: 0;
        padding: 0;
        transform: translateY(-50%);
        line-height: 0;
    }
    &__close-btn {
        position: absolute;
        top: 50%;
        right: 2rem;
        background-color: transparent;
        border: 0;
        padding: 0;
        transform: translateY(-50%);
        line-height: 0;
        stroke: #7b75c9;
        display: none;
    }

    &__wrap {
        // background-color: #ebf0f5;
        padding: 1.5rem 0;

        @include mix.breakpoint(var.$tablet-width) {
            width: calc(50% - 1rem);
            margin-left: 0.5rem;
            padding: 0;

            .container {
                padding: 0;
            }
        }
    }

    &__input-wrap {
        position: relative;
    }
}
/* critical:end */
