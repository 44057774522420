@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popover {
  &._sdek {
    background: #fff;
    border-radius: 6px;
    padding: 8px;
    width: 200px;
    min-height: 30px;
    box-shadow: 0 2px 8px rgba(18, 17, 36, 0.38), 0 4px 32px rgba(18, 17, 36, 0.08);
    font-size: 14px;
    position: relative;

    .close {
      position: absolute;
      right: 8px;
      top: 4px;
      font-size: 13px;
      display: flex;
      align-items: center;
      background: #fff;
      color: var.$color-main;
      text-decoration: none;

      span {
        font-size: 22px;
      }
    }

    .popover-inner {

      ._title {
        margin-bottom: 8px;
        font-weight: normal;
        font-size: 13px;
      }

      ._content {
        color: #a19c9c;
        font-size: 12px;

        p {
          margin-bottom: 8px;
          font-size: 1.2rem;
        }

        @media (max-width: var.$tablet-width - 1) {
          padding-top: 44px;
          position: relative;

          ._actions {
            position: absolute;
            top: 0;
            left: 0;
          }

        }

      }

    }

    ymaps {
      max-width: 100%;
      height: auto !important;
      max-height: 350px;
    }

    .btn {
      padding: 1.1rem 2rem;
      margin-top: 0.6rem;
    }

  }
}

._delivery {
  ._content {
    padding-top: 0 !important;
  }
}

.ymaps-2-1-79-balloon__content {
  ._title {
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 13px;
  }

  ._content {
    color: #a19c9c;
    font-size: 12px;

    p {
      margin-bottom: 8px;
      font-size: 1.2rem;
    }

    @media (max-width: var.$tablet-width - 1) {
      padding-top: 44px;
      position: relative;

      ._actions {
        position: absolute;
        top: 0;
        left: 0;
      }

    }

  }
}
