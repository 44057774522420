@use 'base/variables' as var;
@use 'base/mixins' as mix;

.author-page-v1 {
    p {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 1.6rem 0;
    }
    li {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
}
//dependencies
.author-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .author__last-articles {

    }

    .author__position {
        margin-bottom: 1.4em;
    }
}