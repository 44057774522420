@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-add-button-v1 {
    display: flex;
    justify-content: end;
    width: 100%;
}

/** DEPENDS CODE */
.review-add-button-v1{

}
/** DEPENDS CODE */