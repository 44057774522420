@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart {
    margin-bottom: 7.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 4rem;
        .title {
            margin-bottom: 5.5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 4.7rem;
    }

    @include mix.breakpoint(var.$desktop-max-width) {
        .title {
            margin-bottom: 6rem;
        }
    }
}

.pay-container {

  &._wait, &._again {
    display: block;
    text-align: center;
    padding: 3.2rem 0;
    @include mix.breakpoint(var.$tablet-width) {
      padding: 6.4rem 0;
    }

  }
}

.dolyame_ws{
  width: 100%;
  display: flex;
  opacity: 1 !important;
  &__block{
    width: 22%;
    margin-right: 2%;
    background-color: var.$color-third;
    text-align: center;
    color: var.$color-text;
    border-radius: 0.5rem;

  }
  &__week1{
    background-color: var.$color-main;
    color: White;
  }
  &__date{
    font-size: 1em;
    margin: 1rem 0 0 0 !important;
  }
  &__sum{
    font-size: 1.2em;
    margin: 0 0 1rem 0!important;
  }
}
