@use 'base/variables' as var;
@use 'base/mixins' as mix;

.promotion-grid-v2 {
    &__title-place {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 3.5rem;
    }

    &__list-place {
        grid-template-columns: repeat(3, 1fr);
        -moz-column-gap: 3rem;
        column-gap: 3rem;
        row-gap: 4rem;
        margin-top: 3.6rem;
        margin-bottom: 12rem;
        display: grid;
    }

    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width - 1) {
        &__list-place {
            grid-template-columns: repeat(2, 1fr);
            -moz-column-gap: 2rem;
            column-gap: 2rem;
            row-gap: 3rem;
            margin-top: 3rem;
            margin-bottom: 6rem;
        }
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width - 1) {
        &__list-place {
            grid-template-columns: 1fr;
            -moz-column-gap: 0;
            column-gap: 0;
            row-gap: 2.4rem;
            margin-top: 2rem;
            margin-bottom: 2.4rem;
        }
    }
}

/** \DEPENDS CODE */
.promotion-grid-v2 {
    .sale-more__img {
        margin-bottom: 0.5rem;
    }

    .sale-more__title {
        text-align: center;
        color: var.$color-text;
        line-height: 1.2;
        font-weight: 500;
        font-size: 1.8rem;
        text-decoration: none;
        margin-top: 2rem;
    }

    .sale-more__text {
        color: #545a65;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.4;
        text-align: center;
        margin: 2.7rem 0 1.5rem 0;
    }

    .title-v1 {
        font-size: 2rem;
        line-height: 1.1;
        letter-spacing: 0.015em;
        margin-top: 0;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4rem;
            margin-bottom: 6rem;
        }
    }
}