@use 'base/variables' as var;
@use 'base/mixins' as mix;

.select-v2 { 

    &__element {
        width: 100%;
        max-width: 100%;
    }

    // -moz-appearance: none;
    // -webkit-appearance: none;
    // appearance: none;
    // display: block;
    // border: 1px solid var.$color-border-v2;
    // border-radius: 0.5rem;
    // font-size: 1.4rem; 
    // font-weight: 400; 
    // line-height: 1.8rem; 
    // width: 100%; 
    // max-width: 100%; 
    // padding: 1rem 4.5rem 1rem 1.8rem;
    // background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUw3IDdMMTMgMSIgc3Ryb2tlPSIjM0MzQzcwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo='); 
    // background-repeat: no-repeat;
    // background-position: right 1.6rem top 1.4rem;
    // background-size: 1.4rem 0.8rem; 
    // background-color: #fff;


    // &::-ms-expand {
    //     display: none;
    // }

    // &:focus {
    //     border-color: var.$color-main; 
    //     outline: none; 
    // }

    // & option {
    //     font-weight:normal;
    // }

    // *[dir="rtl"] &, :root:lang(ar) &, :root:lang(iw) & { 
    //     background-position: left 1.6rem top 1.4rem;
    //     padding: 1rem 1.8rem 1rem 4.5rem;
    // }

}

/** DEPENDS CODE */
.select-v2 {

}
/** DEPENDS CODE */

.select-v2 {
    
    .select2 {
    
    }

    .select2-selection__clear {
        height: 90% !important;
        margin-right: 2.7rem !important;
        font-size: 2rem !important;
        font-weight: 100 !important;
        color: var.$color-main !important;
        padding-top: 0.2rem !important;
    }
    
    .select2-container--default .select2-selection--single {
        border: 0.1rem solid rgba(var.$color-text, 0.3);
        border-radius: 0.4rem;
    }

    .select2-container .select2-selection--single {
        height: 4.2rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        font-family: var.$font-text;
        font-size: 1.4rem;
        font-weight: 400;
        color: var.$color-black-tone4;
        line-height: 1.8rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__placeholder {
        color: var.$color-black-tone4;
    }

    .select2-container .select2-selection--single {
        height: 3.4rem;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: normal !important;
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        padding: 0 3.5rem 0 1rem;
        display: grid;
        height: 100%;
        align-content: center;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border: 0;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        width: 1.3rem;
        height: 100%;
        position: absolute;
        right: 1rem;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuMTIxMDlMNi41IDcuMTIxMDlMMTIgMS4xMjEwOSIgc3Ryb2tlPSIjN0I3NUM5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==)
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
        -webkit-animation: select2-corner-spin 0.5s linear;
        -moz-animation: select2-corner-spin 0.5s linear;
        animation: select2-corner-spin 0.5s linear;
        animation-fill-mode: forwards;
    }

}

@-moz-keyframes select2-corner-spin { 
    100% { -moz-transform: rotate(180deg); } 
}
@-webkit-keyframes select2-corner-spin { 
    100% { -webkit-transform: rotate(180deg); } 
}
@keyframes select2-corner-spin { 
    100% { 
        -webkit-transform: rotate(180deg); 
        transform: rotate(180deg); 
    } 
}

/** it's code no depends in select-2
    because select2 plugin use dropdown
    as popup-window in body-element on page 
*/
.select-v2__dropdown-icon-wrap {
    position: absolute;
    top: 1rem;
    left: 1.6rem;
    height: 1.2rem;
    width: 1.2rem;
}

.select2-search .icon-v1 {
    height: 100%;
    width: 100%;
}

.select2-dropdown {
    border: 1px solid rgba(var.$color-black-tone4, 0.3);
    position: relative;
}

.select2-results {
}

.select2-search {
}

.select2-dropdown--above .select2-results {
    // padding: 1rem 0 0 0;
    // padding-bottom: 3.7rem;
    // margin-right: 1rem;
}

.select2-dropdown--above .select2-search {
}

.select2-dropdown--below .select2-results {
    // padding: 1rem 0 0 0;
    // margin-bottom: 3.7rem;
    // margin-right: 1rem;
}

.select2-dropdown--below .select2-search {
    grid-area: "result";
}

// .select2-search:not(.select2-search--hide) {
//     padding: 1rem 0 0 0;
//     margin-top: 3.7rem;
//     // margin-right: 1rem;
// }

.select2-results__option {
    font-weight: 400;
    font-size: 1.2rem;
    color: var.$color-black-tone4;
    font-family: var.$font-text; 
    line-height: 1.522rem;
}

.select2-results__options::-webkit-scrollbar {
    width: 0.4rem;  
    background: linear-gradient(
        to right,
        var.$color-white 30%,
        rgba(var.$color-black-tone4, 0.3) 30% 70%,
        var.$color-white 70%
    );
}

.select2-results__options::-webkit-scrollbar-track { 
    border-radius: 0.4rem; 
}

.select2-results__options::-webkit-scrollbar-thumb {
    background: var.$color-black-tone4;
    border-radius: 0.4rem;
    height: 0rem;
}

.select2-search--dropdown {
    padding: 0;
}

.select2-container--default .select2-search--dropdown {
    width: 100%;
    // position: absolute;
    bottom: 0;
    background-color: var.$color-white;
    // padding: 0.5rem 1.2rem 1rem 1rem;
    padding: 0.5rem;
    border-radius: 0px 0px 0.5rem 0.5rem;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    color: var.$color-main-tone7;
    font-weight: 400;
    font-size: 1.2rem;
    width: 100%;
    height: 1.8rem;
    bottom: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    border: 1px solid rgba(var.$color-black-tone4, 0.3);
    border-radius: 0.4rem;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: var.$color-black-tone4;
    color: var.$color-white;
}

.select2-container--default .select2-results__option--selected {
    background-color: var.$color-third;
}

.select2-results__option.select2-results__option--highlighted {
    background-color: var.$color-black-tone4;
}

.select2-results .select2-results__option,
.select2-results .select2-results__group
{
    padding: 0 !important;
    // padding-bottom: 0.5rem !important;
    // padding-left: 1rem !important;
}

// .select2-container--default .select2-results__option .select2-results__option {
// }

.select2-results .select2-results__option[role="option"],
.select2-results .select2-results__group
{
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-left: 1rem !important; 
}