@use 'base/variables' as var;
@use 'base/mixins' as mix;

.rating-star-v3 {
    svg {
        fill: var.$color-black-tone4;
        height: 1.1rem;
        width: 1rem;
    }
}

/** DEPENDS CODE */
.rating-star-v3 {

    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: min-content min-content;
    gap: 0.4rem;

    .badge-v1 {
        margin-right: 0;
        margin-bottom: 0;
        height: auto;
        &__wrap {
            margin: 0;
        }
    }

    .badge-v1.badge-v1_content-type-label {
        .badge-v1_filling-type-contained {
            background: var.$color-white;
        }
        .badge-v1__label {
            white-space: nowrap;
            font-family: var.$font-text;
            color:  var.$color-black-tone4;
            font-size: 1rem;
            font-weight: 400;
            line-height: 120%;
            padding: 0rem;

        }

    }
    .icon-v1 {
        fill: var.$color-black-tone4
    }

}
/** DEPENDS CODE */