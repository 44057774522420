@use 'base/variables' as var;
@use 'base/mixins' as mix;

.factory {
    /*background: #F5F7FA;*/
    background: var.$color-third;
    border-radius: 4px;
    padding: 2rem 1.5rem;
    margin-bottom: 1.5rem;

    .card-detail__subtitle {
        width: 100%;
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        display: flex;
        align-items: center;
        padding: 2rem 2rem;
        margin-bottom: 2.3rem;
        .card-detail__subtitle {
            width: auto;
            min-width: 10rem;
            margin-right: 2rem;
            margin-bottom: 1rem;
            order: 1;
        }
    }

    @include mix.breakpoint(var.$tablet-h-width) {
        .card-detail__subtitle {
            margin-right: 5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        display: block;
        margin-bottom: 2.5rem;
        .card-detail__subtitle {
            width: 100%;
            min-width: initial;
            margin-right: 0;
            margin-bottom: 0.8rem;
            order: initial;
        }
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
            max-width: 32rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: none;
            margin-bottom: 0.5rem;
        }
    }

    &__logo-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            min-width: 10rem;
            margin-right: 2rem;
            order: 3;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-right: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            min-width: initial;
            order: initial;
            margin-right: 1.5rem;
        }
    }


    &__logo-img {
        display: block;
        width: 5rem;
        margin-right: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 7rem;
            margin-right: 0;
        }
    }

    &__name {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        letter-spacing: -0.03em;
        color: rgba(60, 60, 112, 0.4);
        @include mix.breakpoint(var.$tablet-width) {
            order: 4;
        }
        @include mix.breakpoint(var.$desktop-width) {
            order: initial;
        }
    }

    &__link {
        text-decoration: none;
        color: var.$color-main;
        font-size: 1.4rem;
        line-height: 1;
        margin-left: auto;
        @include mix.breakpoint(var.$tablet-width) {
            order: 2;
            margin-left: 0;
            margin-bottom: 1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            order: initial;
            margin-left: auto;
            margin-bottom: 0;
        }
    }

    &__bottom {
        display: flex;
        margin-right: -3rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 31rem;
            flex-shrink: 0;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 45rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: auto;
            margin-left: 0;
        }
    }

    &__data-wrap {
        margin-right: 3rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-right: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 3rem;
        }
    }



    &__data-value {
        display: block;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #383838;
    }

    &__data-name {
        color: rgba(60, 60, 112, 0.4);
        font-size: 1.3rem;
        line-height: 1.6rem;
    }
}
