@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider-v6 {

    &__wrap {
        display: grid;
        grid-auto-flow: row;

        .slick-initialized .slider-v6__item.slick-slide {
            display: grid;
        }
    }

    &__track {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
    }


    @include mix.breakpoint(var.$tablet-h-width) {
    }

    &__container {
        position: relative;
    }

    @include mix.breakpoint(var.$tablet-width) {
        .container {
            padding: 0 2rem;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        .container {
            padding: 0 8rem;
        }
    }

    &__item {
        display: flex;
        padding: 1.6rem 0.4rem;
        flex-direction: column;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        text-decoration: none;
    }

    &__item-image-place {
        display: flex !important;
        width: 12.9rem;
        height: 7.7rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-position: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        mix-blend-mode: darken;
        margin: 0 auto;
    }

    &__item-title {
        margin-top: 0.4rem;
        color: var.$color-black-tone4;
        text-align: center;
        font-family: var.$font-header;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-self: stretch;
        max-width: 14.5rem;
        text-decoration: none;
    }

    &__item-title:hover {
        text-decoration: underline;
    }
}

//Deps
.slider-v6 {
    .slick-slide {
        border-radius: 8px;
        background: var.$bgcolor-light-tone1;
         margin-right: 0.8rem;
    }

    .title-v2 {
        color: var.$color-black-tone4;
        font-family: var.$font-header;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.8rem; /* 116.667% */
        margin-bottom: 2.4rem;
    }

    @media only screen and (min-width: 1024px) {

    }
}