@use 'base/variables' as var;
@use 'base/mixins' as mix;

.promotion-slider-v1 {

    display: grid;
    grid-template-columns: min-content auto;
    column-gap: 3rem;

    &__tracker-wrap {
        overflow: hidden;
        display: grid;
        grid-auto-flow: column;
        column-gap: 3rem;
    }

}

/** DEPENDS CODE */
.promotion-slider-v1 {

    .slick-track {
        overflow: hidden;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, minmax(21.5rem, 21.5rem));
        column-gap: 3rem;
    }

}
/** DEPENDS CODE */
