@use 'base/variables' as var;
@use 'base/mixins' as mix;

.character-list-v2 {

    table {
        width: 100%;
    }

    table tr, table th {
        border-top: 0.1rem solid var.$color-border;
    }

    table tr td {
        color: var.$color-text;
        font-size: 3rem;
        text-align: left;
        padding: 2.6rem 0;
        width: 50%;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 1.6rem;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 1.2rem;
        }

    }

    table tr td:nth-child(2) {
        color: var.$color-main;
        text-align: right;
    }

}