@use 'base/variables' as var;
@use 'base/mixins' as mix;

.additional-good {
    // border-top: 2px solid #EBF0F5;
    // padding-top: 2rem;
    // margin-bottom: 4rem;

    // .card-detail__subtitle {
    //     font-size: 1.4rem;
    //     margin-bottom: 1.7rem;
    // }

    // @include mix.breakpoint(var.$tablet-width) {
    //     width: 40rem;
    //     margin-left: auto;
    //     border-top: 0;
    //     margin-bottom: 0;
    //     padding-top: 0;
    //     .card-detail__subtitle {
    //         margin-bottom: 2rem;
    //     }
    // }

    // @include mix.breakpoint(var.$desktop-width) {
    //     width: auto;
    //     padding-top: 3rem;
    //     border-top: 1px solid #EBF0F5;
    //     margin-bottom: 3rem;
    // }
    &__list {
        display: none;
        // &._active {
        //     display: block;
        // }
    }



    &__item {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__label {
        position: relative;
        display: flex;
        padding-left: 3rem;
        color: #808DA1;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        transition: all .3s;

        @media(hover:hover) {
            &:hover {
                color: #383838;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.5rem;
            line-height: 1.9rem;
        }
    }

    &__radio {
        position: absolute;
        left: 0;
        top: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid var.$color-text;
        border-radius: 50%;
    }

    &__input:checked {
        + .additional-good__radio::after {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;

            width: 1rem;
            height: 1rem;
            background-color: var.$color-main;
            border-radius: 50%;
        }

        ~ span {
            color: #383838;
        }
    }

    &__price {
        margin-left: auto;
        padding-left: 1rem;
        flex-shrink: 0;
    }
}
