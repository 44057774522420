@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sitemap-page-v1 {

    margin-bottom: 13.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 11rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14rem;
    }
    @include mix.breakpoint(var.$desktop-lg-width) {
        margin-bottom: 15rem;
    }


    &__root {
        font-weight: 600;
        font-size: 2rem;


    }

    &__wrap {
        // padding-left: 1.5rem;
        margin-bottom: 5rem;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
        }

    }

    &__left ul {
        padding-left: 1.5rem;
        margin-bottom: 3rem;
        margin-top: 1.3rem;
        @include mix.breakpoint(var.$desktop-width) {
            width: 60rem;
            margin: 0;
            margin-right: 15.6rem;

        }
    }

    &__right {
        padding-left: 1.5rem;
    }
}

/** DEPENDS CODE */

.sitemap-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .title-v1, .title-v2 {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 5rem;
        }
    }

    a {
        text-decoration: none;
        color: var.$color-main;
        // border-bottom: 1px solid var.$color-main;
        font-size: 1.5rem;
        line-height: 2.2;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
        }
    }
}

/** \DEPENDS CODE */