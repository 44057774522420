@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sale-label {
    background-color: var.$color-text;
    border-radius: 4px;
    min-height: 2.8rem;
    color: var.$color-white;
    font-family: "Affect";
    font-size: 1.4rem;
    line-height: 1;
    font-weight: 700;

    padding: 0.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        font-size: 1.6rem;
        padding-top: 0.7rem;
        padding-bottom: 0.9rem;
        padding-left: 0.6rem;
        padding-right: 0.7rem;

    }
}
