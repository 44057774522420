.product-composition-v1 {

    @media only screen and (min-width: 768px) {
        .details__left {
            width: calc(50% - 1rem);
            margin-right: 2rem;
        }
    }
    @media only screen and (min-width: 1025px) {
        .details__left {
            margin-right: 2.2rem;
            width: calc(100% - 47.5rem);
        }
    }
    @media only screen and (min-width: 1280px) {
        .details__left {
            margin-right: 3.6rem;
            width: calc(100% - 49.6rem);
        }
    }
    @media only screen and (min-width: 1441px) {
        .details__left {
            margin-right: 11.6rem;
            width: calc(100% - 57.6rem);
        }
    }

    .details-layers__wrapper {
        min-height: 62rem;
        float: left;
    }


     .layers__wrapper {
        position: relative;
        z-index: 0;
        width: 85rem;
        // margin: 0 auto;
    }

    @media only screen and (min-width: 1025px) {
        .layers__wrapper {
            width: 52rem;
        }
    }
    @media only screen and (min-width: 1280px) {
        .layers__wrapper {
            width: 70rem;
        }
    }
    @media only screen and (min-width: 1441px) {
        .layers__wrapper {
            width: 85rem;
        }
    }

    .layers {
        position: relative;
        z-index: 0;
        padding-top: 16rem;
        margin: 0 auto;
        width: 100%;
    }

    @media only screen and (min-width: 1025px) {
        .layers {
            padding-top: 10rem;
        }
    }
    @media only screen and (min-width: 1280px) {
        .layers {
            padding-top: 13rem;
        }
    }
    @media only screen and (min-width: 1441px) {
        .layers {
            padding-top: 16rem;
        }
    }


    .layers__image {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-top: -14rem;
        top: auto !important;
        transition: all .2s ease-out;
        // pointer-events: none;
        min-height: 20rem;
        width: 100%;
        cursor: pointer;
        transform: scale(1);
    }

    .layers__image._active {
        transform: scale(1.05);
    }

    .layers__image > svg {
        max-width: 100%;
        height: auto;
    }

    .layers__image._disabled {
        opacity: .5;
    }

    @media only screen and (min-width: 1025px) {
        .layers__image {
            min-height: 14rem;
            margin-top: -9.6rem;
        }
    }
    @media only screen and (min-width: 1280px) {
        .layers__image {
            min-height: 18rem;
            margin-top: -13rem;
        }
    }
    @media only screen and (min-width: 1441px) {
        .layers__image {
            min-height: 20rem;
            margin-top: -14rem;
        }
    }


    .layers__labels {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        width: -moz-max-content;
        width: max-content;
        position: absolute;
        height: -moz-max-content;
        height: max-content;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 2rem;
    }

    @media only screen and (min-width: 1025px) {
        .layers__labels {
            gap: .4rem;
        }
    }
    @media only screen and (min-width: 1280px) {
        .layers__labels {
            gap: 1.4rem;
        }
    }
    @media only screen and (min-width: 1441px) {
        .layers__labels {
            gap: 1.6rem;
        }
    }

    .layers__settings {
        display: flex;
        gap: 1.6rem;
        transition: all .2s ease-out;
    }

    .layers__settings._active {
        transform: scale(1.3);
    }

    .layers__label {
        position: relative;
        color: #ebf0f5;
        width: 4.4rem;
        height: 4.4rem;
        background: #7b75c9;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        line-height: 4.4rem;
        border-radius: 50%;
        cursor: pointer;
    }

    @media only screen and (min-width: 1025px) {
        .layers__label {
            width: 3.6rem;
            height: 3.6rem;
            line-height: 3.6rem;
            font-size: 1.2rem;
        }
    }
    @media only screen and (min-width: 1280px) {
        .layers__label {
            width: 4rem;
            height: 4rem;
            line-height: 4rem;
            font-size: 1.4rem;
        }
    }
    @media only screen and (min-width: 1441px) {
        .layers__label {
            width: 4.4rem;
            height: 4.4rem;
            line-height: 4.4rem;
            font-size: 1.6rem;
        }
    }

    .tooltip-templates {
        display: none;
    }

    .tooltip__container._big {
        padding: 1.5rem 2rem;
    }

    .tooltip__container {
        padding: 1.2rem;
        max-width: 33.9rem;
        font-family: EuclidCircularA, Arial, sans-serif;
        color: #3c3c70;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}

//deps
.product-composition-v1 {
    &__tooltip_description {
        .js-tooltip {
            display: inline;
        }
    }
}

.product-composition-v1__tooltip_description {
    display: inline;
}