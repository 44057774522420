@use 'base/variables' as var;
@use 'base/mixins' as mix;

.detail-footer {
    background: #ebf0f5;
    box-shadow: 0 -6.54px 10px rgba(0, 0, 0, 0.05);
    @include mix.breakpoint(var.$tablet-width) {
        box-shadow: none;
    }

    &._sticky {
        position: sticky;
        z-index: 100;
        bottom: var(--footer-height);
    }

    &__inner {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        padding: 1.2rem 0;
        @include mix.breakpoint(var.$desktop-width) {
            padding: 2.3rem 0;
        }
    }

    &__price-wrap {
        order: 1;
        margin-right: auto;
        flex-shrink: 0;
        @include mix.breakpoint(var.$desktop-width) {
            order: 2;
            margin-right: 3rem;
        }
    }

    &__credit-container {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            order: 3;
            display: block;
            margin-right: auto;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 6rem;
            margin-left: 0;
        }
    }

    &__credit-title,
    &__month-price {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.9rem;
    }

    &__month-price {
        color: var.$color-main;
        display: flex;
        align-items: center;

        .js-month-pay {
          color: var.$color-main;
          text-decoration: none;
        }

    }

    .info-tooltip {
        width: 1.5rem;
        height: 1.5rem;
        color: inherit;
        margin-left: 1rem;

        svg {
            fill: currentColor;
        }
    }

    &__old {
        font-size: 1.4rem;
        line-height: 1.8rem;
        position: relative;
        display: inline-block;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
            background-color: var.$color-text;
        }
    }

    &__current {
        display: block;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 700;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 3.5rem;
            line-height: 4.4rem;
        }
    }

    &__buy-btn {
        width: 100%;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 14rem;
            margin-right: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 21.6rem;
        }
    }

    &__select {
        order: 2;
        margin: 0 1rem;
        position: static;
        flex-grow: 1;
        @include mix.breakpoint(var.$tablet-width) {
            position: relative;
            order: 1;
            width: 21rem;
            margin-right: 3rem;
            margin-left: 0;
            flex-grow: 0;
        }
    }

    .select__dropdown {
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 265px;
            left: 0;
        }
    }

    .select__option-content {
        display: flex;
        justify-content: space-between;
    }

    &__btn-wrap {
        order: 3;
        width: 14rem;
        margin-left: auto;
        @include mix.breakpoint(var.$desktop-sm-width) {
            display: flex;
            width: auto;
            margin-left: 0;
            margin-right: 3rem;
        }
    }

    &__buy-one.btn {
        display: none;
        @include mix.breakpoint(var.$desktop-sm-width) {
            background-color: #fff;
            display: block;
            width: 21.6rem;
        }
    }

    &__indicators-list {
        display: none;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            order: 5;
            margin-right: -3rem;
        }
    }

    &__indicators-link {
        &._active  {
            .detail-footer__indicators-icon._like {
                fill: #f46b5f;
            }
            .detail-footer__indicators-icon._compare {
                opacity: 1;
            }
        }

        @media(hover:hover) {
            &:hover {
                .detail-footer__indicators-icon._like {
                    fill: #f46b5f;
                }
                .detail-footer__indicators-icon._compare {
                    opacity: 1;
                    stroke-width: 3px;
                }
            }
        }
    }

    &__indicators-item {
        margin-right: 3rem;
    }

    &__indicators-icon._like {
        width: 1.8rem;
        height: 1.5rem;
        stroke: #f46b5f;

        stroke-width: 2px;
        fill: transparent;
        transition: all .3s;
    }

    &__indicators-icon._compare {
        width: 1.8rem;
        height: 1.6rem;
        stroke: var.$color-main;
        opacity: 0.5;
        transition: all .3s;
        // stroke-width: 2px;
        // fill: none;
    }

    .select__dropdown {
        opacity: 1;
        pointer-events: initial;
        max-height: initial;
        overflow: initial;
        @media all and (max-width: 425px) {
            width: 80% !important;
            left:0;
        }
    }
}
