@use 'base/variables' as var;
@use 'base/mixins' as mix;

.installment-payment-v1 {
    display: flex;
    flex-direction: column;

    &__info {
        display: flex;
        align-items: center;
        border: 0.1rem solid var.$color-gray-tone-14;
        padding: 1.2rem;
        border-radius: 0.5rem;
        gap: 1rem;
    }

    &__chevron {
        margin-left: auto;

        svg {

            fill: var.$color-gray-tone-14;
            color: var.$color-gray-tone-14;
        }
    }

    &__info-label {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 2.4rem;
        letter-spacing: 0px;
        text-align: left;
        font-family: var.$font-text;
    }

    &__badge {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: var.$color-black;
        padding: 0.6rem 0.6rem;
        border-radius: 0.4rem;
        height: 2rem;
    }
}

// Depends code
.installment-payment-v1 {
    &__chevron .icon-v1 {
        fill: var.$color-gray-tone-14;
        color: var.$color-gray-tone-14;
    }


}

// \Depends code