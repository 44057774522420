@use 'base/variables' as var;

.add-to-compare-v1 {
    &:focus {
        outline: none;
    }

    svg {
        transition: all .3s;
        stroke: var.$color-main;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.5;
    }

    &[data-add-to-compare-v1-is-in-compare="1"] {
        svg {
            transition: all .3s;
            stroke: var.$color-text;
            fill: var.$color-text;
            stroke-width: 2.5;
        }
    }

}

//deps
.add-to-compare-v1 {

}