@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v6 {

    position: relative;

    margin-bottom: 5rem;

    // inner geometry and styled (bordered/etc)
    padding: 1.5rem;
    border: 0.1rem solid rgba(var.$color-text, 0.3);
    border-radius: 0.6rem;

    // change gap for tablet
    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        column-gap: 1.5rem;
    }

    // change gap for tablet
    @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
        column-gap: 1rem;
    }

    &__vm, &__rm {
        margin-top: 1rem;
        font-size: 1.3rem;
    }

    &__desc-wrap {
        margin-bottom: 1rem;
    }

}


/** DEPENDS CODE */

.product-card-v6:has(+.product-card-v7-as-cart-gift-v1) {
    margin-bottom: 0;
    border-radius: 0.6rem 0.6rem 0 0;
}

.product-card-v6 + .product-card-v7-as-cart-gift-v1 {
    background-color: var.$bgcolor-light-tone1;
    border-top: 0;
    border-radius: 0 0 0.6rem 0.6rem;
}

.product-card-v6 {
    
    &__info-column {

        // common grid and set area's for feature grid's
        display: grid;
        column-gap: 4rem;

        > .product-card-v6__gallery-wrap {
            grid-area: gallery;
        }
        > .link-v1 {
            grid-area: title;
        }
        > .product-card-v6__articul.p-v1 {
            grid-area: articul;
            margin: 0;
            margin-bottom: 1rem;
        }
        > .product-card-v6__count.p-v1 {
            grid-area: count-changer;
            margin-bottom: 1rem;
        }
        > .count-changer-v1 {
            grid-area: count-changer;
            margin-bottom: 1rem;
        }
        > .text-changer-v1 {
            grid-area: count-changer;
            margin-bottom: 1rem;
        }
        > .delivery-info-v1 {
            grid-area: delivery;
        }
        > .product-card-v6__price-block {
            grid-area: prices;
            justify-self: right;

            // grid for mobile
            @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
                justify-self: left;
            }
        }
        > .product-card-v6__action-favorite {
            grid-area: action-favorite;
            align-self: start;
        }
        > .product-card-v6__action-remove {
            grid-area: action-remove;
            align-self: end;

            // unsivible badge-list on mobile
            @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
                align-self: start;
            }
        }

        // grid for mobile
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            grid-template-columns: calc(55% - 4rem) calc(45% - 1.8rem) 1.8rem;
            grid-template-rows: calc(55% - 6rem) 15% 15% 15%;
            row-gap: 2rem;
            align-items: center;
            column-gap: 2rem;
            grid-template-areas: 
                "gallery title action-favorite"
                "gallery articul articul"
                "prices count-changer action-remove"
                "delivery delivery delivery";
        }
            
        // unsivible badge-list on mobile
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            > .product-card-v6__gallery-wrap > .badge-list-v1 {
                display: none;
            }
        }

        // grid for tablet + desktop
        @include mix.breakpoint(var.$tablet-width) {
            grid-template-columns: calc(40% - 5.8rem) calc(40% - 8rem) 20% 1.8rem;
            align-items: center;
            grid-template-areas: 
                "gallery title title action-favorite"
                "gallery articul articul articul"
                "gallery count-changer count-changer count-changer"
                "gallery delivery prices action-remove";
        }

        // position badge list in gallery-wrap
        > .product-card-v6__gallery-wrap {
            position: relative;
        }

        > .product-card-v6__gallery-wrap > .badge-list-v1 {
            position: absolute;
            top: 0rem;
            left: 0rem;
        }
        
        // every block stylization's

        // title
        > .link-v1 {
            font-family: var.$font-text;
            font-weight: 400;
            font-size: 1.6rem;
            @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
                font-size: 1.4rem;
            }
        }

        // articul
        > .p-v1 {
            font-family: var.$font-text;
            font-weight: 400;
            font-size: 1.2rem;
        }
        // articul:label
        > .p-v1 span:first-child {
            color: var.$color-gray;
        }
        // articul:value
        > .p-v1 span:last-child {
            color: var.$color-main;
        }

        > .delivery-info-v1 {
            align-self: center;
        }

        > .delivery-info-v1 .delivery-info-v1__date {
                padding-bottom: 0.5rem;
        }

        
        // delivery
        // all styles in self-stylesheet

        // prices
        > .product-card-v6__price-block .old-price-v1 {
            font-size: 1.2rem;
            padding-bottom: 0.5rem;
        }
        > .product-card-v6__price-block .sale-price-v1 {
            font-size: 2.1rem;
        }

        // count-changer
        
        // badge-listand badge's
        .product-card-v6__gallery-wrap > .badge-list-v1 {

        }
        .product-card-v6__gallery-wrap > .badge-list-v1 .badge-v1 {
            height: 2.4rem;
            display: inline-flex;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }
        .product-card-v6__gallery-wrap > .badge-list-v1 .badge-v1__wrap {
            margin: auto 1rem;
        }
        .product-card-v6__gallery-wrap > .badge-list-v1 .badge-v1__wrap > div {
            display: flex;
        }
        .product-card-v6__gallery-wrap > .badge-list-v1 .badge-v1__label {
            font-size: 1.2rem;
            line-height: 1;
        }

    }

    &__action-column {
        display: grid;
        align-content: space-between;
        justify-content: center;
    }

    .link-with-icon-v1 {
        cursor: pointer;
    }

    &__gallery-wrap {
        width: 100%;
        height: 100%;
    }

    .textile-switcher-v1__option-image {
        position: relative;
        width: 6rem;
        height: 6rem;
        border: 0.1rem solid var.$color-white;
        border-radius: 0.6rem;
    }

    .textile-switcher-v1__option-image::before,
    .textile-switcher-v1__option-image img {
        width: 5rem;
        height: 5rem;
        top: 0.4rem;
        left: 0.4rem;
    }

    .textile-switcher-v1__option-block {
        cursor: default !important;
    }

}
/** \DEPENDS CODE */
