@use 'base/variables' as var;
@use 'base/mixins' as mix;

.character-list-v1 {

    display: flex;
    flex-direction: row;
    column-gap: 6rem;

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        column-gap: 1rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
        justify-content: center;
    }

}

//depends code
.character-list-v1 {

}