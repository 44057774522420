@use 'base/variables' as var;
@use 'base/mixins' as mix;

.check-box-list-v1 {
    &__title-wrapper {
        display: flex;
        align-items: center;
    }

    &__option-wrapper {
    }
}

//Depends
.check-box-list-v1 {
    &__title-wrapper {
        .image-v1 {
            margin-right: 0.8rem;
        }
    }

    .title-v2 {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-family: var.$font-text;
        font-weight: 600;
        color: var.$color-main-tone7;
    }

    .input-checkbox-v1, .input-radio-v2, .input-radio-v1  {
        margin-top: 1.2rem;
    }
}