@use 'base/variables' as var;
@use 'base/mixins' as mix;

.protip-skin-default--scheme-pro.protip-container {
    color: var.$color-text;
    background: var.$color-gray-tone3;
    border: 0.1rem solid var.$color-main;
    font-family: var.$font-special;
    font-size: 1.2rem;
    line-height: 1.8rem;
}

.protip-skin-default--scheme-pro[data-pt-position="right-top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="right"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="right-bottom"] .protip-arrow {
  border-right-color: var.$color-main;
}
.protip-skin-default--scheme-pro[data-pt-position="top-left"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="top"] .protip-arrow,
.protip-skin-default--scheme-pro[data-pt-position="top-right"] .protip-arrow {
  border-top-color: var.$color-main;
}