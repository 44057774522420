@use 'base/variables' as var;
@use 'base/mixins' as mix;

.goods {
    margin-bottom: 8.8rem;

    &__top {
        margin-bottom: 2rem;

        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row-reverse;
    }

    &__reset-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .4rem;
        width: 100%;
        @include mix.breakpoint(var.$tablet-width) {
            // margin-right: auto;
            margin-left: auto;
            margin-bottom: 0;
            width: auto;
        }
        // @include mix.breakpoint(var.$desktop-width) {
        //     margin-bottom: 0;
        // }
    }

    &__reset-link {
        line-height: 1.2;
        color: var.$color-main;
        position: relative;
        text-decoration: none;
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var.$color-main;
            display: none;
        }


        &._disable {
          // opacity: 0.6;
        }

    }

    &__coincidence {
        font-weight: 600;
        margin-right: 3rem;
        background: transparent;
        transition: all .3s;

        @include mix.breakpoint(var.$tablet-width) {
          padding: 1.5rem 2rem;
        }

        &._pulse-animated {
          text-align: center;
          border-radius: 4px;
          background-color: #7b75c9;
          color: #fff;
        }

    }
    /* critical:start */
    &__sort {
        display: flex;
        flex-wrap: wrap;
        // margin-top: -1.3rem;
        width: calc(100% + 1.3rem);
        margin-right: -1.3rem;
        align-items: flex-start;
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        // position: sticky;
        // top: var(--header-height);


        // @include mix.breakpoint(var.$tablet-width) {
        //     justify-content: space-between;
        // }


        .select {
            flex-grow: 1;
            // margin-right: 1.3rem;
            @include mix.breakpoint(var.$mobile-width) {
                width: calc(70% / 2 - 1.3rem);
                margin-right: 1.3rem;
            }

            &__face {
                width: auto;
            }
            // @include mix.breakpoint(var.$mobile-width) {
            //     margin-right: 1.3rem;
            // }
            // margin-top: 1.3rem;
        }
        .select__face {
            padding: 1.5rem 3rem 1.5rem 1.2rem;
            font-size: 1.4rem;
            line-height: 1.2;
        }



        @include mix.breakpoint(var.$tablet-width) {
            position: static;
            width: auto;
            .select {
                //width: 17.6rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {

            .select {
                width: 21.4rem;
            }
        }
    }
    /* critical:end */

    &__sort-select {
        margin-top: 0;

        flex-grow: 1;
        margin-bottom: 0.5rem;
        @include mix.breakpoint(var.$mobile-width) {
            margin-bottom: 0;
        }

        @include mix.breakpoint(var.$tablet-width) {
            flex-grow: 0;
        }

    }

    &__delivery {
        // width: 15.8rem;
        margin-right: 1.3rem;
        // margin-top: 1.3rem;
        background-color: #F5F7FA;
        color: var.$color-text;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 400;
        // test
        display: none;

        @media(hover:hover) {
            &:hover {
                background-color: var.$color-main;
                color: #fff;
            }
        }
        &._active {
            background-color: var.$color-main;
            color: #fff;
        }

        @include mix.breakpoint(var.$tablet-width) {
            width: 15.8rem;
            padding: 1.5rem .5rem;
            font-size: 1.4rem;
            line-height: 120%;
            display: block;
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 19.2rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            // font-size: 1.4rem;
            // line-height: 120%;
        }
    }

    &__filter-link {
        margin-right: 1.3rem;
        font-size: 1.4rem;
        line-height: 1.2;
        font-weight: 400;
        transition: none;
        flex-grow: 1;
        .button-preloader {
            max-width: 4rem;
            margin: auto;
            display: none;
        }
        ._filter-opened {
            display: none;
        }
        @include mix.breakpoint(var.$mobile-width) {
            flex-grow: 0;
           // width: 15.8rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }

        &.preloader{
            //padding: 0.15rem 0!important;
            .button-preloader{
                display:block;
            }
            ._filter-opened{
                //display: none !important;
            }
            ._filter-closed{
                display: none;
            }
        }
    }

    &__view {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-left: 1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 5rem;
        }
    }

    &__view-square,
    &__view-line {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
        background-color: #F5F7FA;
        border-radius: 4px;
        border: 0;
        padding: 0;
        outline: none;

    }

    &__view-line {
        margin-right: 1rem;
        &._active svg {
            fill: var.$color-main;
            stroke: var.$color-main;
        }
    }
    &__view-square {
        &._active svg {
            fill: var.$color-main;
        }
    }

    &__square-icon {
        width: 1.8rem;
        height: 1.8rem;
        fill: #C4C4C4;
    }

    &__line-icon {
        width: 2.2rem;
        height: 2rem;
        fill: #C4C4C4;
        stroke: #C4C4C4;
    }

    &__list {
        margin-top: -1rem;
        margin-left: auto;
        margin-right: auto;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            margin-top: -1.6rem;
            margin-right: -2rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            margin-top: -2.6rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            // margin-top: -2rem;
        }
    }

    &__item {
        margin-top: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 2 - 2rem);
            margin-top: 2rem;
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width - 1) {
            width: calc(100% / 3 - 2rem);
            margin-top: 3rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            width: calc(100% / 4 - 2rem);
            margin-top: 4rem;
        }
    }


}
