@use 'base/variables' as var;
@use 'base/mixins' as mix;

.pagination-v2 {
    .page-link {
        border: none;
    }

    .active {
        .page-link {
            background: none;
        }
    }

    @include mix.breakpoint(var.$tablet-width) {
        .page-link {
            width: 5.4rem;
            height: 5.4rem;
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

//Dependence Code
.pagination-v2 {

    &__item_next, &__item_prev {
        a:hover {
            .icon-v1 {
                stroke: #fff;
            }
        }

        .icon-v1 {
            width: .7rem;
            height: 1.4rem;
            stroke: var.$color-main;
        }
    }
}

.product-grid-v1__pagination {
    grid-column: 1 / -1;
}