@use 'base/variables' as var;
@use 'base/mixins' as mix;

.advantages-v1 {
    @media only screen and (min-width: 768px) {
        .advantages__item {
            width: 30.5rem;
            height: 11.5rem !important;
            margin: 0 1rem;
            padding: 1rem 2.3rem;
        }
        .slick-slide {
            padding: 1rem 2.3rem;
        }
    }
}

//DEPENDS CODE
.advantages-v1 {

}