@use 'base/variables' as var;
@use 'base/mixins' as mix;

.favorite-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.4rem;
    height: 3.4rem;
    line-height: 1;
    @media (hover: hover) {
        &:hover .favorite-btn__icon {
            stroke: #F46B5F;
            fill: #F46B5F;
        }
    }

    &__icon {
        transition: all .3s;
        width: 2.2rem;
        height: 1.9rem;
        fill: #E3E3E3;
        stroke: #E3E3E3;
        transition: all .3s;
    }
}

._favorite {
    .favorite-btn__icon {
        stroke: #F46B5F;
        fill: #F46B5F;
    }
}
