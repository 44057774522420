@use 'base/variables' as var;
@use 'base/mixins' as mix;

.about {
    margin-bottom: 5rem;

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6.5rem;
        .title {
            margin-bottom: 2.8rem;
        }
    }
    @include mix.breakpoint(var.$desktop-lg-width) {
        margin-bottom: 5.5rem;
        .title {
            margin-bottom: 4rem;
        }
    }
    &__inner {
        min-height: 22.8rem;
        padding: 2rem 1.5rem;
        background-color: var.$color-main;
        background-image: url("/img/mister-green-happy_mobile.svg");
        background-size: 8rem auto;
        background-repeat: no-repeat;
        background-position: bottom -1px right 1.2rem;
        @include mix.breakpoint(var.$mobile-width) {
            background-size: 10.2rem 8.4rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            min-height: 20.3rem;
            padding: 3rem 2rem;
            padding-top: 2.5rem;
            background-size: 20.4rem 17.3rem;
            background-position: bottom -5px right 4.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            min-height: initial;
            background-image: url("/img/mister-green-happy_desktop.svg");
            background-size: 17.6rem 18.1rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            background-position: bottom -1px left 97.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
        }
    }

    &__text {
        color: #fff;
        font-size: 1.3rem;
        line-height: 1.3;
        margin: 0;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 38.5rem;
            font-size: 1.4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 43.5rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            max-width: 66rem;
        }
    }
}
