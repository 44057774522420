@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-block-grid-v1 {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 3rem;
    row-gap: 3rem;

    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
        row-gap: 2rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }

}

/** DEPENDS CODE */
.info-block-grid-v1 {
    
    .box-v1 {
        padding: 4rem;
    }

    .info-block-v1 {
    }

}
/** DEPENDS CODE */