@use 'base/variables' as var;
@use 'base/mixins' as mix;

.footer-menu-v1 {


}

/** DEPENDS CODE */
.footer-menu-v1 {
    .site-footer__nav-link {
        &:hover {
            /* ставлю импортант - нет времени разбиратсья с перекрытием */
           color: var.$color-white !important;
        }
    }
}
/** DEPENDS CODE */