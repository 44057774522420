@use 'base/variables' as var;
@use 'base/mixins' as mix;

.showroom-address-v1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.6rem;

    &__phone-wrap {
        text-align: center;
        font-weight: 700;
        order: 1;
        flex-grow: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            text-align: left;
        }
    }

    &__phone {
        font-size: 2rem;
        margin-top: 0.8rem;
        font-weight: 700;
        font-family: var.$font-header;
        text-decoration: underline;
        display: block;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 3rem;
            font-weight: 700;
            margin-top: 0.8rem;
        }
    }

    &__phone:hover {
        color: var.$color-text;
    }

    /*  &__address-more-btn {
          background-color: var.$color-border;
          color: var.$color-text;
          font-size: 1.6rem;
          line-height: 2rem;
          padding: 1.7rem 2rem;
          text-decoration: none;
      }*/

    &__label-wrap {
        width: 4rem;
        order: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            order: 0;
        }
    }

    &__info {
        order: 1;
        max-width: 30rem;
    }


    &__schedule {
        order: 1;
        font-size: 2rem;
        width: 40rem;
        font-weight: 700;
        font-family: var.$font-header;
    }


    &__content {
        @media only screen and (min-width: var.$tablet-width) {
            flex-direction: row;
        }
        * {line-height: 1.5;}
        flex-grow: 1;
        display: flex;
        flex-direction: column;

    }

    &__address {
        margin-top: 0;
        margin-bottom: 0.8rem;
        font-size: 1.8rem;
        font-weight: 700;
        font-family: var.$font-header;
        line-height: 1.5;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-weight: 400;
        }
    }

    &__to-map-btn {
        font-size: 1.4rem;
        color: var.$color-main-tone7;
        text-decoration: underline;
    }
}

/** DEPENDS CODE */
.showroom-address-v1 {
    .icon-v1 {
        width: 4.3rem;
        height: 4rem;
        margin-right: 1.6rem;
    }
}
/** DEPENDS CODE */