@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-card-v2 {

    background-color: var.$color-white;
    border-radius: 0.6rem;
    padding: 4rem 3rem;

    &__head {
        white-space: nowrap;
        display: grid;
        grid-template-columns: min-content min-content;
        grid-template-rows: min-content min-content min-content;
        grid-template-areas: 
            "avatar name"
            "avatar date"
            "rating rating";
    }

    &__letter {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-right: .8rem;
        background: var.$color-second;
        border: 1px solid var.$color-main;
        color: var.$color-main;
        text-align: center;
        line-height: 2.6rem;
        font-weight: 700;
    }

    &__head-avatar {
        grid-area: avatar;
        width: 4rem;
        height: 4rem;

        img {
            border-radius: 10rem;
        }
    }

    &__head-name {
        grid-area: name;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        color: var.$color-main;
    }

    &__head-date {
        grid-area: date;
        padding-left: 1rem;
        color: var.$color-gray;
        font-size: 1.2rem;
    }

    &__head-rating {
        grid-area: rating;
        display: inline-block;
        padding: 4px 12px;
        border: 1px dashed var.$color-main;
        border-radius: 4px;
        text-align: center;
        margin-bottom: .6rem;
        margin-top: 1rem;
    }

    &__from {
        color: var.$color-main;
        font-size: 1.4rem;
        font-weight: 700;
    }

    &__body {
        font-size: 1.4rem;
        line-height: 1.5;
        font-family: var.$font-text;
        color: var.$color-text;
    }
}

/** DEPENDS CODE */
.review-card-v2 {
    .rating-star-v1 {
        height: 2.4rem;
        text-align: center;
        display: block;
    }
}

/** DEPENDS CODE */