@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-info-v1 {

    font-family: var.$font-text;
    font-weight: 400;
    font-size: 1.2rem;

    &__label {
        color: var.$color-gray;
    }

    &__value {
        color: var.$color-main;
    }

}

/** DEPENDS CODE */
/** \DEPENDS CODE */
