@use 'base/variables' as var;
@use 'base/mixins' as mix;

.select-v1 { 

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: block;
    border: 1px solid var.$color-border-v2;
    border-radius: 0.5rem;
    font-size: 1.4rem; 
    font-weight: 400; 
    line-height: 1.8rem; 
    width: 100%; 
    max-width: 100%; 
    padding: 1rem 4.5rem 1rem 1.8rem;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMUw3IDdMMTMgMSIgc3Ryb2tlPSIjM0MzQzcwIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo='); 
    background-repeat: no-repeat;
    background-position: right 1.6rem top 1.4rem;
    background-size: 1.4rem 0.8rem; 
    background-color: #fff;


    &::-ms-expand {
        display: none;
    }

    &:focus {
        border-color: var.$color-main; 
        outline: none; 
    }

    & option {
        font-weight:normal;
    }

    *[dir="rtl"] &, :root:lang(ar) &, :root:lang(iw) & { 
        background-position: left 1.6rem top 1.4rem;
        padding: 1rem 1.8rem 1rem 4.5rem;
    }

}