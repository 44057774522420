@use 'base/variables' as var;
@use 'base/mixins' as mix;

.category {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-top: -1rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-right: -1.5rem;
        margin-top: -1.5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-right: -2.2rem;
        margin-top: -2.2rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-right: -2.5rem;
        margin-top: -2.5rem;
    }

    .link {
        display: block;
        margin-top: 1rem;
        margin-right: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 1.5rem;
            margin-top: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2.2rem;
            margin-top: 2.2rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 2.5rem;
            margin-top: 2.5rem;
        }

        &__icon {
            transition: none;
        }
    }
}
