@use 'base/variables' as var;
@use 'base/mixins' as mix;

.infoblock {

  &._wide {
    width: 100% !important;
    max-width: 100% !important;
  }

  &._no-border {
    border: none !important;
  }

  &._more-btn {
    margin: 4rem auto 0 auto !important;

    .more-products-btn {
      max-width: 55rem !important;
      display: flex;
      border: 1px solid #ebf0f5;
      margin: auto;
      transition: background-color .3s, color .3s;

      .button-preloader {
        max-width: 4rem;
        margin: auto;
        display: none;
      }

      &._load {
        padding: 0.5rem;
        .button-preloader {
          display: block;
        }
        .button-text {
          display: none;
        }
      }
    }
  }

  &__content {


    .ourbenefits {
      .benefit-headers, .benefit-texts {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 4px;
        padding: 1rem;

        & > div {
          flex: 1;
          text-align: center;
        }
      }
      .benefit-headers {
        background: var.$color-second;
        font-size: 16px;
        font-weight: bold;
      }
      .benefit-space {
        height: 0.4rem;
      }
      .benefit-texts {
        background: var.$color-main;
        color: #fff;
        font-size: 13px;
      }
    }

  }

  &__link {
    display: block;
    text-decoration: none;
  }

}
