@use 'base/variables' as var;
@use 'base/mixins' as mix;

.modal {
    position: relative;
    background: #FFFFFF;
    border-radius: 1.6rem;
    width: calc(100% - 3rem);
    max-width: 68.2rem;
    margin: 0 auto;
    padding: 6.5rem 2rem;
    padding-bottom: 7rem;
    background-repeat: no-repeat;
    background-position: bottom right 3.6rem;
    &._success {
        background-image: url("/img/mister-green.svg");
        background-size: 6.4rem auto;
    }

    &._fail {
        background-image: url("/img/mister-purple-sad.svg");
        background-size: 6.4rem auto;
        .modal__text {
            color: var.$color-text;
        }
    }
    .title {
        font-size: 2.4rem;
        line-height: 1.2;
        margin-bottom: 1.5rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        padding: 5rem 4rem;
        background-size: 8.8rem auto;
        background-position: bottom right 6.3rem;
        border-radius: .6rem;
        .title {
            font-size: 4rem;
            line-height: 1.1;
            margin-bottom: 2rem;
        }
    }

    &__text {
        margin: 0;
        margin-bottom: 3.5rem;
        color: var.$color-main;
        font-size: 1.6rem;
        line-height: 1.5;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 5rem;
        }
    }

    &__btn-ok {
        min-width: 16.9rem;
        font-size: 1.6rem;
        line-height: 2rem;
    }

    &__pre {
      font-size: 1.3rem;
      margin-bottom: 1rem;
    }
}
