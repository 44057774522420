@use 'base/variables' as var;
@use 'base/mixins' as mix;

.detail-delivery {
    /*background: #F5F7FA;*/
    background: var.$color-third;
    border-radius: 4px;
    padding: 1.5rem;
    margin-bottom: 3rem;
    @include mix.breakpoint(var.$tablet-width) {
        padding: 2rem;
        display: flex;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        padding: 1.5rem;
        margin-bottom: 3rem;
        display: block;
    }

    &__wrap {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            &:not(:last-child) {
                margin-bottom: 0;
            }
            display: block;
            &._city {
                margin-right: auto;
            }
            &._nearest {
                width: 38rem;
            }
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            &._nearest {
                width: auto;
                clear: both;
                flex-grow: 1;
                margin-top: 3rem;
            }

        }
        @include mix.breakpoint(var.$desktop-width) {
            &._city {
                min-width: 14rem;
                margin-right: 5rem;
            }

            &._nearest {
                width: auto;
                flex-grow: 1;
            }
        }
    }

    &__subtitle {
        display: block;
        width: 10.3rem;
        margin: 0;
        padding-right: 0.5rem;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: var.$color-text;
        flex-shrink: 0;
        text-decoration: none;
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            font-size: 1.6rem;
            line-height: 2rem;
            margin-bottom: 1rem;
        }

        &.delivery-date-subtitle {
          display: flex;
          align-items: center;
        }
    }

    &__select {
        line-height: 1.5rem;
        position: relative;
        color: #383838;
        font-size: 1.2rem;
        padding: 0;
        margin-bottom: 0;
        // padding-right: 3rem;
        background-color: transparent;
        // .form__input {
        //     width: 100%;
        // }
        align-items: center;
        flex-wrap: wrap;
        cursor: pointer;
        border-bottom: 1px dotted var.$color-main;

        &._open {
            .delivery-form__icon {
                transform: rotateX(180deg);
            }

            .select__dropdown {
                opacity: 1;
                pointer-events: initial;
                max-height: initial;
                overflow: initial;
            }

            .form {
                &__input {
                    &::placeholder {
                        color: rgba(#383838, 0.5);
                    }
                }
            }

            .detail-delivery__input-wrap {
                background-color: #fff;
                //border-color: var.$color-text;
            }
        }

        // выпилено
        // &._dropdown-hidden {
        //     .select__dropdown {
        //         display: none;
        //     }
        //     .delivery-form__icon {
        //         transform: none;
        //     }
        // }


        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }

        .select__dropdown {
            min-width: 13rem;
        }

        .select__option-item._hidden {
            display: none;
        }

        .delivery-form__icon {
            stroke: var.$color-text;
            @include mix.breakpoint(var.$tablet-width) {
                top: calc(50% - 0.5rem);
            }
            // margin-left: -25%;
        }

        .form {
            &__input {
                font-size: 1.6rem;
                line-height: 1;
                color: #383838;
                padding: 0;
                padding-right: 2.5rem;
                // костыль чтобы форма не зумилась
                // transform: scale(0.75);
                // transform-origin: left;
                &::placeholder {
                    color: currentColor;
                }
                border: none;
                background-color: transparent;

                @include mix.breakpoint(var.$tablet-width) {
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }


    }

    &__input-wrap {
        position: relative;
        width: 18rem;
        transform: scale(0.75);
        transform-origin: left;
        padding: 0.5rem;
        margin-left: -0.5rem;
        /*border: 1px solid #F5F7FA;*/
        @include mix.breakpoint(var.$tablet-width) {
            transform: none;
            margin-top: -0.5rem;
        }

    }

    &__date {
        margin: 0;
        color: #383838;

        font-size: 1.2rem;
        line-height: 1.5rem;
        align-self: flex-end;

        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
    &__freeCost{}

    &__popup {
        @include mix.breakpoint(var.$desktop-width) {
            width: 80rem;
        }
        // font-size: 1.4rem;
        // line-height: 1.1;
        ul {
            padding: revert;
            list-style: revert;
            margin: revert;
        }

        @include mix.breakpoint(var.$tablet-width) {
          .mfp-close {
            right: calc(50% - 38rem) !important;
          }
        }
    }

}
