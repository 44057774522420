@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-block-v1 {

    border-bottom: 0.1rem solid rgba(60,60,112,0.3);
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    
}

.receipt-block-v1:first-child {
    padding-top: 0;
}

.receipt-block-v1:last-child {
    padding-bottom: 0;
}


/** DEPENDS CODE */

.receipt-block-v1 {

    
}

/** \DEPENDS CODE */
