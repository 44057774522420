@use 'base/variables' as var;

.remove-from-compare-v1 {
    &:focus {
        outline: none;
    }

    svg {
        transition: all .3s;
        stroke: var.$color-gray-tone8;
        fill: var.$color-gray-tone8;
        fill: none
    }


    &:hover {
        svg {
            stroke: var.$color-black-tone4;
            fill: var.$color-black-tone4;
        }
    }
}

//deps
.remove-from-compare-v1 {

}