@use 'base/variables' as var;
@use 'base/mixins' as mix;

.installment-payment-modal-v1 {
    position: relative;
    padding: 3.2rem 2.4rem;

    &__close {
        top: 1.2rem;
        right: 1.2rem;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2.4rem;
        background: rgba(var.$color-black, 0.04);
        cursor: pointer;
    }

    &__image {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.8rem;
        background: var.$color-gray-tone-17;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    &__description {
        font-family: "Factor A", var.$font-text, sans-serif;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: rgba(var.$color-black, 0.6);
    }

    &__label {
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: var.$color-black;
    }

    &__buns {
        display: flex;
        gap: 1.2rem;
        margin-top: 2rem;
    }

    &__buns-item {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        flex-grow: 1;
    }

    &__steps {
        display: flex;
        gap: 0.6rem;
        background: #F6F7F8;
        padding: 1.6rem;
        border-radius: 1.2rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__step {
        flex-grow: 1;
    }

    &__date {
        font-family: var.$font-text;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: rgba(var.$color-black, 0.4);
    }

    &__price {
        font-family: var.$font-text;
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: rgba(var.$color-black, 0.4);
        margin-top: 0.4rem;
    }

    &__line {
        width: 100%;
        height: 0.6rem;
        border-radius: 0.6rem;
        background: rgba(var.$color-black, 0.12);
        margin-top: 1.2rem;
    }

    &__step-active {
        .installment-payment-modal-v1__date {
            color: var.$color-black;
        }

        .installment-payment-modal-v1__price {
            color: var.$color-black;
        }

        .installment-payment-modal-v1__line {
            background: #428BF9;
        }
    }

    &__btn-buy {
        background: var.$color-black;
        color: var.$color-white;
        padding: 1.6rem 2.4rem;
        gap: 0.8rem;
        border-radius: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &__more {
        font-family: "SF Pro Display", var.$font-header, sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        text-align: center;
        color: rgba(var.$color-black, 0.4);
        margin-top: 2rem;
        a {
            color:  #1771E6;
        }
    }
}

// Depends code installment-payment-modal-v1
.installment-payment-modal-v1 {

    .title-v2 {
        font-family: "SF Pro Display", var.$font-header, sans-serif;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 3.2rem;
        background: var.$color-white;
        margin-top: 0;
        margin-bottom: 0.8rem;
    }

    &__image {
        svg {
            width: 2.133rem;
            height: 2.133rem;
        }
    }
}

// \Depends code