@use 'base/variables' as var;
@use 'base/mixins' as mix;

.additional-product-list-v1 {

    display: grid;
    grid-auto-flow: row;
    row-gap: 1.5rem;

    &__wrap {
        
    }

    &__checkbox {

    }

    &__product {

        margin-top: 1.5rem;   

    }

    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        grid-auto-flow: column;
        column-gap: 1.5rem;
    }

}

/** DEPENDS CODE */
.additional-product-list-v1 {

}
/** \DEPENDS CODE */
