@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-row-v8 {
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/** DEPENDS CODE */
.receipt-row-v8 {

    &__value {

        font-family: var.$font-text;
        font-weight: 700;
        color: var.$color-text;

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 2.1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 3rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 3rem;
        }
    }

    &__subvalue {

        font-family: var.$font-text;
        font-weight: 700;
        text-align: right;
        color: var.$color-text;
        white-space: nowrap;

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 2.1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 3rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 3rem;
        }
    }
    
}

/** \DEPENDS CODE */
