@use 'base/variables' as var;
@use 'base/mixins' as mix;

.interior-view-card-v1 {

    // common
    display: grid;
    background: #ffffff;
    min-height: 50rem;
    @include mix.breakpoint(var.$tablet-h-width) {
        grid-template-columns: 58% 42%;
        grid-template-areas: "slider content";
        height: 76vh;
        width: 70vw;
    }
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
        grid-auto-flow: row;
        grid-template-rows: 47vh 47vh;
        grid-template-areas: 
            "slider"
            "content";
        width: 90vw;
    }    

    &__slider-wrap {
        grid-area: slider;
        overflow: hidden;
        position: relative;
    }

    &__list-place {
        width: 100%;
        height: 100%;
    }

    &__content-wrap {
        grid-area: content;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 3rem;
        display: grid;
        grid-template-rows: min-content auto min-content;
    }

    &__title-place {
    }

    &__product-grid-place {

    }

    &__social-place {
        text-align: center;
        margin-top: 2rem;
        align-items: center;
        display: grid;
        grid-auto-columns: min-content;
        @include mix.breakpoint(var.$tablet-width) {
            grid-auto-flow: column;
        }
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            justify-content: center;
        }
    }

    &__social-label {
        white-space: nowrap;
        font-size: 1.2rem;
        color: var.$color-main-tone7;
        line-height: 1.2;
        @include mix.breakpointRange (var.$zero-width, (var.$tablet-width - 1)) {
            margin-bottom: 1.2rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 2rem;
        }
    }

    &__social-wrap {

    }

    &__product-grid-title {
        font-size: 1.6rem;
        line-height: 1.2;
        color: var.$color-main-tone7;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    &__product-grid-wrap {

    }

    &__title-place {
        font-weight: 500;
        color: var.$color-black-tone4;
        line-height: 1.2;
        font-size: 3.2rem;
        padding-bottom: 4rem;
        border-bottom: 0.1rem solid var.$color-border;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 2rem;
            padding-bottom: 1.4rem;
            text-align: center;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            padding-bottom: 3rem;
            font-size: 2.6rem;
        }
    }

    &__navigator-place {
        display: none;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: block;
        }
    }

    &__prev {
        cursor: pointer;
        position: absolute;
        left: 3%;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__next {
        cursor: pointer;
        position: absolute;
        right: 3%;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__dots-bar-place {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-top: 1.4rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 1rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 1.2rem;
        }
        width: 100%;
    }

    &__items {
        width: 100%;
        height: 100%;
    }

    &__item {
        position: relative;
        width: 100%;
        height: 100%;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        }
    }

    &__bg-place {
        width: 100%;
        height: 100%;
        position: absolute;
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    // TODO[dependСss]
    // depend: button-link-v1
    &__content-wrap .button-link-v1 {
        margin-top: 1.5rem;
    }
    // \depend: button-link-v1

    // depend: slick overwrite
    &__dots-bar-place .slick-dots {
        max-width: inherit;
        margin: 0;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            // TODO[fixme]: strange centering (check on mobile)
            margin-left: 1.2rem;
            // TODO[\fixme]: strange centering (check on mobile)
        }
    }

    &__dots-bar-place .slick-dots li:nth-child(1) {
        margin-left: 0;
    }
    &__dots-bar-place .slick-dots li {
        margin: 0;
        line-height: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-left: 1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-left: 1.2rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-left: 1.2rem;
        }
    }
    &__dots-bar-place .slick-dots li button {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 1rem;
            height: 1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            width: 1.4rem;
            height: 1.4rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 2rem;
            height: 2rem;
        }
    }
    // \depend: slick overwrite
    // \TODO[dependСss]
}