@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-together-v1 {

    display: grid;
    grid-template-columns: min-content min-content min-content;
    align-items: center;
    column-gap: 3rem;
    margin-bottom: 6rem;

    &__switcher-card {
        display: grid;
        grid-template-columns: min-content min-content min-content;
        align-items: center;
        column-gap: 3rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        display: grid;
        grid-template-columns: min-content;
        grid-template-rows: min-content auto min-content;
        align-items: center;
        column-gap: 3rem;
        row-gap: 3rem;
        margin-bottom: 3rem;
    }

    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        display: grid;
        grid-template-columns: min-content auto;
        grid-template-rows: min-content min-content;
        align-items: center;
        column-gap: 3rem;
        row-gap: 3rem;
        margin-bottom: 4rem;
    }

}

/** DEPENDS CODE */

.product-together-v1 {
    .product-card-v2 {
        max-width: 33.9rem;
    }
}

/** \DEPENDS CODE */
