@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-stage-v2 {
    position: relative;
    display: none;
    margin: 0 auto;
    z-index: 0;

    &__header {
        font-weight: 500;
        line-height: 1.1;
        text-align: center;
        white-space: nowrap;
        margin-top: 0;

    }

    &__row {
        display: flex;
        justify-content: center;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        &__column {
            width: 100%;
        }
        &__column + &__column
        {
            margin-left: 0rem;
        }
        &__row {
            flex-direction: column;
            max-width: 27rem;
            margin: 0 auto;
            width: 100%;
        }
        &__header {
            font-size: 1.8rem;
            margin-bottom: 1.2rem;
            padding-top: 1rem;
        }
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        &__column {
            width: 27rem;
        }
        &__column + &__column
        {
            margin-left: 2rem;
        }
        &__row {
            flex-direction: row;
            width: 100%;

        }
        &__header {
            font-size: 2.4rem;
            margin-bottom: 4rem;
            padding-top: 0;
        }
    }
    @include mix.breakpoint(var.$desktop-notebook-width) {
        & {
            width: 94rem;


        }
        &__header {
            font-size: 4.4rem;
            margin-bottom: 4rem;
            padding-top: 0;
        }

        &__row {
            flex-direction: row;
            width: 100%;
        }

        &__column {
            width: 43rem;
        }

        &__column + &__column
        {
            margin-left: 8rem;
        }
    }
}

//deps
.questionnaire-stage-v2 {

}
