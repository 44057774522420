@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider-v2 {

    &__wrap {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: 13.6rem;
        .slick-initialized .slider-v2__item.slick-slide {
            display: grid;
            margin-right: 4rem;
        }
    }

    &__track {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        
        &_is-prev-visible {
            padding-left: 7rem;
        }
        &_is-prev-visible .slider-v2__prev {
            display: block;
        }
        &_is-next-visible {
            padding-right: 7rem;
        }
        &_is-next-visible .slider-v2__next {
            display: block;
        }
        
    }

    &__prev {
        display: none;
        position: absolute;
        left: 0px;
        width: 5rem;
        height: 5rem;
        top: calc(50% - 2.5rem);
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            top: calc(50% - 2rem);
            width: 4rem;
            height: 4rem;
        }
        cursor: pointer;
    }

    &__next {
        display: none;
        position: absolute;
        right: 0px;
        width: 5rem;
        height: 5rem;
        top: calc(50% - 2.5rem);
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            top: calc(50% - 2rem);
            width: 4rem;
            height: 4rem;
        }
        cursor: pointer;
    }

    &__list-place {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 4rem;
        overflow: hidden;
    }

    &__item {
        height: 13.6rem;
        max-height: 13.6rem;
        width: auto !important;
        display: grid !important;
        grid-template-areas: 
            "image-place"
            "title-place";
        grid-template-rows: 10.8rem 1.8rem;
        row-gap: 1rem;
        text-decoration: none;
    }

    &__item-image-place {
        display: grid;
        grid-area: image-place;
    }

    &__item-image {
        place-self: center;
        max-height: none;
        max-width: none;
    }

    &__item-title-place {
        grid-area: title-place;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }

    &__item-icon {
        margin-right: 1rem;
    }

    &__item-title {
        text-decoration: none;
        white-space: nowrap;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 400;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.3rem;
        }
    }

    &__item:hover &__item-title {
        color: var.$color-main;
    }

    // TODO[dependСss]
    // depend: slick overwrite
    &__list-place .slick-slide {
        margin-right: 2rem;
    }
    // \depend: slick overwrite
    // \TODO[dependСss]
}