@use 'base/variables' as var;
@use 'base/mixins' as mix;

.action-group-v1 {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(0, min-content));
    align-content: end;
}

/** DEPENDS CODE */
.action-group-v1 {

    > .button-v1 {
        margin-right: 1rem;
        display: inline-flex;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            margin-right: 2rem;
        }

    }

    > .button-v1:last-child {
        margin-right: 0;
    }

}
/** DEPENDS CODE */