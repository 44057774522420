@use 'base/variables' as var;
@use 'base/mixins' as mix;

.products {
    overflow: hidden;
    .title {
        margin-bottom: 5.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6.5rem;
        }
    }
    &__list {
        margin-top: -1.5rem;
        @include mix.breakpoint(var.$mobile-2lg-width) {
            display: flex;
            flex-wrap: wrap;
            margin-right: -3rem;
            margin-top: -2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -2rem;
        }
    }
    &__item {
        background-color: #EBF0F5;
        border-radius: 4px;
        overflow: hidden;

        margin-top: 1.5rem;
        @include mix.breakpoint(var.$mobile-2lg-width) {
            width: calc(100% / 2 - 3rem);
            margin-top: 2rem;
            margin-right: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 3 - 2rem);
            margin-right: 2rem;
            // height: 16.7rem;
            // align-items: stretch;
        }
    }

    &__link {
        display: flex;
        color: var.$color-text;
        height: 12.3rem;
        text-decoration: none;
        align-items: center;
        @include mix.breakpoint(var.$desktop-width) {
            align-items: stretch;
            height: 16.7rem;
        }

        img  {
            // flex-shrink: 0;
            height: 100%;
            border-radius: 4rem 0.3rem 0.3rem 0;
            @include mix.breakpoint(var.$desktop-width) {
                border-radius: 60px 4px 4px 0px;
            }
        }

    }

    &__text-wrap {
        // flex-grow: 1;
        margin-right: auto;
        padding: 1.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            flex-direction: column;
            padding: 2.5rem 2rem;
            padding-right: 0.5rem;
        }
    }

    &__title {
        font-family: "Affect";
        font-size: 1.6rem;
        line-height: 1.1;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2.3rem;
            margin-bottom: auto;
        }
    }

    &__text{
        text-decoration: none;
        color: var.$color-text2;
        font-size: 1.2rem;
        line-height: 1.5rem;
        display: flex;
        align-items: center;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }

    &__link-icon {
        width: 5px;
        height: 8px;
        stroke: currentColor;
        margin-left: 5px;
        @include mix.breakpoint(var.$desktop-width) {
            width: 7px;
            height: 11px;
            margin-left: 11px;
        }
    }

}

.product-item-small{
    height: 100%;
}
