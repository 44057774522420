@use 'base/variables' as var;
@use 'base/mixins' as mix;

.recursive-list-v1 {

    &__level-1 {
        padding-left: 1.5rem;

        a {
            line-height: 2.2;
            font-weight: 600;
            margin-bottom: 1.3rem;
        }
    }

    &__level-2 {
        padding-left: 2rem;
        margin: 1rem 0;
    }

    &__level-3 {
        margin: 1rem 0;
        padding-left: 2rem;
        a {
            font-size: 1.4rem;
            font-weight: 400;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 2rem;
            }
        }
    }

    &__level-4 {
        margin: 1rem 0;
        padding-left: 2rem;
    }
}


/** DEPENDS CODE */
.recursive-list-v1 {


    
}

/** \DEPENDS CODE */
