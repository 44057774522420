@use 'base/variables' as var;
@use 'base/mixins' as mix;

.pagination-v1 {
    &__content {
        margin-top: 6.7rem;
        display: flex;
        overflow: hidden;
    }
    &__item {
        margin-right: 1.5rem;
    }

    &__link {
        width: 5.4rem;
        height: 5.4rem;
        font-size: 2rem;
        line-height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: var.$color-gray-tone6;
    }

    &__item_active > &__link {
        font-weight: 600;
        color: var.$color-black-tone4;
    }

    &__item_next, &__item_prev {
        a {
            background: var.$color-gray-tone3;
            border-radius: 4px;
            border: 0;
            padding: 0;
            align-items: center;
            justify-content: center;
            width: 3.6rem;
            height: 3.6rem;
            outline: none;
            text-decoration: none;
            display: flex;
            color: var.$color-gray-tone6;
            font-size: 1.7rem;
            line-height: 2.2rem;
        }

        a:hover {
            background-color: var.$color-main;
        }

        @include mix.breakpoint(var.$mobile-width) {
            a {
                width: 4.6rem;
                height: 4.6rem;
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            a {
                width: 5.4rem;
                height: 5.4rem;
                font-size: 2rem;
                line-height: 2.5rem;
            }
        }
    }
}

//Dependence Code
.pagination-v1 {
    &__item_next, &__item_prev {
        a:hover {
            .icon-v1 {stroke: #fff;}
        }

        .icon-v1{
            width: .7rem;
            height: 1.4rem;
            stroke: var.$color-main;
        }
    }

}