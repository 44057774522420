@use 'base/variables' as var;
@use 'base/mixins' as mix;

.policy {

    &__content {
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 73.1rem;
        }
    }
    margin-bottom: 6rem;
    .title {
        max-width: 24rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        // padding-top: 3rem;
        .title {
            max-width: 50rem;
        }
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 5.5rem;
        .title {
            max-width: initial;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 4.5rem;
    }
    h3 {
        margin: 0;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 5rem;
            max-width: 51.5rem;
        }
    }

    p {
        margin: 0;
        margin-bottom: 1.8rem;

        font-size: 1.6rem;
        line-height: 2rem;
        color: #818DA1;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 1.6rem;
        }
    }

    ul {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            &:not(:last-child) {
                margin-bottom: 1.8rem;
            }
        }
    }

    li {
        text-indent: .8rem;
        color: #818DA2;
        font-size: 1.6rem;
        line-height: 2rem;
        position: relative;
        &::before {
            content: '\00B7';
            position: absolute;
            left: -10px;
            top: -1px;
            font-size: 3rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
}
