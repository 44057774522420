@use 'base/variables' as var;
@use 'base/mixins' as mix;

.filter-applied-tags-v1 {

    &__wrap {
        display: grid;
        grid-auto-flow: row;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 2rem;
        overflow: hidden;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            row-gap: 0.8rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            row-gap: 1.2rem;
        }
    }

    &__reset {
        align-self: center;
        font-weight: 500;
        line-height: 1.2;
        color: var.$color-main;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.2rem;
            text-decoration: none;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 1.4rem;
            text-decoration: none;
            padding-bottom: 0.2rem;
            border-bottom: 1px solid var.$color-main;
        }
    }

    &__reset:hover {
        @include mix.breakpoint(var.$tablet-h-width) {
            border-bottom: 0;
            padding-bottom: 0.3rem;
        }
    }

    &__item {
        cursor: pointer;
        border: 1px solid var.$color-main;
        font-weight: 400;
        line-height: 1.2;
        border-radius: 0.5rem;
        color: var.$color-main-tone6;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.3rem;
            padding: 0.7rem 1rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 1.4rem;
            padding: 1.3rem 1.5rem;
        }
        font-size: 1.4rem;
        display: grid;
        grid-auto-flow: column;
        grid-template-areas: "text cancel";
        grid-template-columns: min-content min-content;
        column-gap: 1rem;
        align-items: center;
    }

    &__text {
        grid-area: text;
    }

    &__cancel {
        grid-area: cancel;
        width: 1.1rem;
        height: 1.1rem;
    }

    &__cancel-icon {
        width: 1.1rem;
        height: 1.1rem;
        stroke: var.$color-main;
    }

    // TODO[dependСss]
    // depend: slick overwrite
    // \depend: slick overwrite
    // \TODO[dependСss]
}