@use 'base/variables' as var;
@use 'base/mixins' as mix;

.search-view-page-v1 {



    
}

/** DEPENDS CODE */

.search-view-page-v1 {
    .category-tile-v1 {
        margin-bottom: 8rem;
    }
    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }
}

/** \DEPENDS CODE */