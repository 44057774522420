@use 'base/variables' as var;
@use 'base/mixins' as mix;

.interior-popup-v1 {

    position: relative;
    margin: 0 auto;
    width: 144rem;
    height: 83rem;
    overflow: hidden;
    padding: 0;
    display: flex;

    @include mix.breakpointRange(var.$zero-width, (560px - 1)) {
        width: calc(100% - 3.2rem);
        height: auto;
        margin: 5rem 1.6rem;
        display: block;
    }

    @include mix.breakpointRange(560px, (1150px - 1)) {
        width: 55.3rem;
        height: auto;
        margin: 5rem auto;
        display: block;
    }

    @include mix.breakpointRange(1150px, (var.$desktop-notebook-width - 1)) {
        width: calc(100% - 3.2rem);
        height: auto;
        margin: 5rem 1.6rem;
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        width: 144rem;
        height: 83rem;
    }

    &__close {
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        top: 3.5rem;
        right: 3.5rem;
        cursor: pointer;
    }

    &__close-icon {
        stroke: var.$color-main;
    }

    // TODO[dependСss]
    & .interior-view-card-v1 {
        width: 100%;
        overflow: hidden;
        padding: 0;
    }
    // \TODO[dependСss]

    &__images {
        width: 83rem;
        height: 83rem;

        @include mix.breakpointRange(var.$zero-width, (560px - 1)) {
            width: 100%;
            height: auto;
            margin: 0;
        }
        @include mix.breakpointRange(560px, (1150px - 1)) {
            width: 55.3rem;
            height: auto;
        }
        @include mix.breakpointRange(1150px, (var.$desktop-laptop-width - 1))  {
            width: 67rem;
            height: 67rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 83rem;
            height: 83rem;
        }
    }


    &__content {
        padding: 4rem 3rem 3rem 3rem;
        background: #fff;
        display: inline-block;
        width: calc(100% - 83rem);

        @include mix.breakpointRange(var.$zero-width, (560px - 1)) {
            width: 100%;
            display: block;
            height: auto;
        }
        @include mix.breakpointRange(560px, (1150px - 1)) {
            width: 55.3rem;
            display: block;
            height: auto;
        }
        @include mix.breakpointRange(1150px, (var.$desktop-laptop-width - 1)) {
            width: calc(100% - 67rem);
            height: 67rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: calc(100% - 83rem);
            height: 83rem;
        }
    }

    &__social {
        display: flex;
        position: absolute;
        bottom: 3rem;

        @include mix.breakpointRange(var.$zero-width, (560px - 1)) {
            display: block;
            position: relative;
            text-align: center;
        }
    }

    &__social-text {
        font-size: 1.2rem;
        font-weight: normal;
        color: #545A65;
        line-height: 3rem;
        margin-right: 2rem;
    }

    &__social .social-v2__list {
        @include mix.breakpointRange(var.$zero-width, (560px - 1)) {
            display: flex;
            justify-content: center;
        }
    }
}