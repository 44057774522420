@use 'base/variables' as var;
@use 'base/mixins' as mix;

.rating-star-v1 {

}

/** DEPENDS CODE */
.rating-star-v1 {

    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: min-content min-content;

    .badge-v1 {

        margin-right: 1rem;

        &__wrap {
            margin: 0;
        }

    }

    .badge-v1.badge-v1_content-type-label {

        .badge-v1__label {

            font-family: var.$font-text;
            font-size: 1rem;
            font-weight: 600;
            line-height: 120%;
            padding: 0.5rem;

        }

    }

}
/** DEPENDS CODE */