@use 'base/variables' as var;
@use 'base/mixins' as mix;

.select-dropdown-v1 {
    &__container {
        max-width: 20rem;
        position: relative;
    }

    &__item:hover:not(&__item-active) {
        background-color: #ebf0f5;
        cursor: pointer;
    }

    &__item {
        a {
            width: 100%;
            border: 0;
            background-color: transparent;
            color: #3c3c70;
            border-left-color: currentcolor;
            text-decoration: none;
            display: inline-block;
            text-align: center;
            border-bottom: 1px solid #ebf0f5;
            border-bottom-color: rgb(235, 240, 245);
            font-size: 1.5rem;
            line-height: 1.9rem;
            padding: 1.3rem 1.5rem;
            outline: none;
        }
    }

    &__item-active {
        background-color: #7b75c9;
        border-color: #7b75c9;
        a {
            color: #fff;
        }
    }

    &__dropdown-container {
        opacity: 1;
        pointer-events: auto;
        max-height: none;
        overflow: initial;
        position: absolute;
        top: calc(100% + 5px);
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background-color: #fff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.25);
        border-radius: 4px;
        z-index: 50;
        pointer-events: auto;

        div {
            overflow: hidden !important;
            overflow-anchor: none;
            -ms-overflow-style: none;
            touch-action: auto;
            -ms-touch-action: auto;
            position: relative;
            max-height: 30rem;
        }
    }

    &__dropdown {
        opacity: 1;
        pointer-events: auto;
        max-height: none;
        overflow: initial;
        position: absolute;
        top: calc(100% + 5px);
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background-color: #fff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.25);
        border-radius: 4px;
        z-index: 50;
    }

    &__open {

    }

    &__viewport {
        background-color: #ebf0f5;
        width: 100%;
        position: relative;
        cursor: pointer;
        color: #3c3c70;
        font-size: 1.5rem;
        line-height: 1.9rem;
        outline: none;
        border: 0;
        border-radius: 4px;
        padding: 1.4rem 1.8rem 1.4rem 1.5rem;
        text-align: left;
        transition: all .3s;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    select {
        display: none;
    }
}

//Dependencies
.select-dropdown-v1
{

}