@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(120%);
    will-change: transform;
    // transition: transform .3s;
    background-color: #fff;
    padding: 0 1.5rem;
    height: 100%;
    overflow: scroll;
    z-index: 200;
    display: flex;
    flex-direction: column;

    @include mix.breakpoint(var.$tablet-width) {
        position: absolute;
        height: auto;
        transition: all .1s;
        overflow: auto;
        cursor: default;
        overflow: initial;
        transform: none;
        opacity: 0;
        pointer-events: none;
        left: initial;
        top: calc(100%);
        right: 0;
        // right: 3rem;
        width: 64rem;
        padding: 20px;
        padding-right: 10px;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    }
    @include mix.breakpoint(var.$desktop-width) {
        // right: -1rem;
    }

    &__back-wrap {
        text-align: right;
    }

    &__back {
        padding: 2rem 0;
        font-size: 1.6rem;
        line-height: 1.2;
        color: var.$color-main;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        text-decoration: none;
    }

    &__back-icon {
        stroke: currentColor;
        margin-left: 1rem;
        width: 1.1rem;
        height: 1.1rem;
    }

    &__scroll-container {
        position: relative;
        @include mix.breakpoint(var.$tablet-width) {
            &::after,
            &::before {
                opacity: 0;
            }
        }
    }

    &__scroll-inner{
        height: calc(var(--vh) * 100 - 5.9rem - 12.5rem - var(--footer-height));
        // height: 55vh;
        overflow: scroll;
        position: relative;
        @include mix.breakpoint(var.$tablet-width) {
            height: auto;
            max-height: var(--cart-popup-scroll-height);
            overflow: initial;
        }
    }


    &__footer {
        padding-top: 3rem;
        // padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: auto;
        padding-bottom: calc(var(--footer-height) + 1rem);
        @include mix.breakpoint(var.$tablet-width) {
            padding-right: 4rem;
            padding-bottom: 1rem;
        }
    }

    &__order-btn {
        width: 12.5rem;
        margin-bottom: 0.6rem;
        font-weight: 400;
        @include mix.breakpoint(var.$mobile-width) {
            width: 16rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            margin-bottom: 0;
            padding: .9rem 2rem;
        }
    }

    &__sum {
        width: 20rem;
        padding-left: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: center;
        }
    }

    &__sum-text {
        margin: 0;
        margin-bottom: 1.5rem;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.2;
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 3rem;
            margin-bottom: 0;
        }
    }

    &__price-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 2rem;
            order: 4;
        }
    }

    &__price-old {
        display: inline-block;
        position: relative;
        margin: 0;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #A7A7A7;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var.$color-text;
        }
    }

    &__price {
        margin: 0;
        font-weight: 700;
        line-height: 2.7rem;
        min-width: 13rem;
        font-size: 1.9rem;
        @include mix.breakpoint(var.$mobile-width) {
            font-size: 2.1rem;
        }
    }

    &__list {
        @include mix.breakpoint(var.$tablet-width) {
            padding-right: 2rem;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5rem;
        padding: 1rem;
        padding-right: 4rem;
        padding-bottom: 2rem;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        &:last-child {
            margin-bottom: 0;
        }
        &._remove-animation {
            animation: .5s dissapear ease-out;
        }
        &._gift {
            background-color: #F9F9F9;
        }

        @include mix.breakpoint(var.$tablet-width) {
            padding: 2rem;
            margin-bottom: 1rem;
        }
    }

    &__img-wrap {
        width: 10rem;
        flex-shrink: 0;
        margin-right: 1.3rem;
        img {
            display: block;
            width: 100%;
        }

        @include mix.breakpoint(var.$tablet-width) {
            width: 9rem;
            margin-right: 3rem;
            margin-top: -0.5rem;
        }
    }

    &__content-wrap {
        flex-grow: 1;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &__product-name {
        margin: 0;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.3;
        @include mix.breakpoint(var.$tablet-width) {
            margin: 0;
            margin-bottom: 1rem;
            width: 100%;
            padding-right: 2.5rem;
            order: 1;
        }
        a {
          text-decoration: none;
          color: #3c3c70;
        }
    }

    &__count {
        position: relative;
        width: 12rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 10.8rem;
            // margin-right: 3rem;
            order: 2;
            margin-top: 1rem;
            margin-right: auto;
        }
    }

    &__input {
        font-size: 1.5rem;
        line-height: 1.9rem;
        padding-top: 1.3rem;
        padding-bottom: 1.4rem;
        // padding-left: 2.5rem;
        text-align: center;
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        width: 100%;
        outline: none;
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 0.8rem;
            padding-bottom: 0.9rem;
        }
    }

    &__change-btn {
        position: absolute;
        top: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 0;
        background-color: #fff;
        width: 3.4rem;
        height: 4.6rem;
        color: var.$color-main;
        outline: none;
        &._increase {
            right: 1px;
        }

        &._decrease {
            left: 1px;
        }

        @include mix.breakpoint(var.$tablet-width) {
            height: 3.4rem;
        }
    }

    &__math-icon {
        stroke: currentColor;
        width: 1.4rem;
        height: 1.4rem;
    }

    &__delete-btn {
        width: 3.4rem;
        height: 3.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 0;
        background-color: transparent;
        position: absolute;
        top: 1.2rem;
        right: 1rem;
        outline: none;
        @media(hover:hover) {
            &:hover {
                .cart-popup__delete-icon {
                    fill: var.$color-main;
                }
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            right: 0.5rem;
        }
    }

    &__delete-icon {
        fill: #E3E3E3;
        width: 1.6rem;
        height: 1.6rem;
        transition: all .3s;
    }

    &__sale {
        position: absolute;
        left: 1rem;
        top: 1rem;
        min-width: 4.3rem;
        padding: 0.5rem;
        background-color: var.$color-text;
        border-radius: 4px;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #fff;
        font-family: "Affect";
        @include mix.breakpoint(var.$tablet-width) {
            position: static;
            min-width: 5rem;
            font-size: 1.6rem;
            line-height: 2.1rem;
            padding: .6rem;
            order: 3;
            margin-left: auto;
            margin-top: 0.5rem;
        }

        &._discountcode {
          left: 5.2rem;
          margin-left: .4rem;
          background: #f46b5f;
        }
    }

    &__gift {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 5rem;
        order: 3;
        margin-left: auto;
        margin-top: 0.5rem;
    }

    &__gift-icon {
        width: 4rem;
        height: 4rem;
    }



    // &__gift {

    // }

    ._scroll {
        @include mix.breakpoint(var.$tablet-width) {
            &::before,
            &::after {
                height: 10rem;
                // opacity: 0.5;
            }
        }
    }

    .compare__empty {
      text-align: center;
    }

}

._scroll {
    &::after,
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 5rem;
        pointer-events: none;
        transition: all .1s;
        z-index: 1;
        opacity: 0.8;
    }

    &::after {
        bottom: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &::before {
        top: 0;
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        opacity: 0;
    }

    &._down-position {
        &::after {
            opacity: 0;
        }
        &::before {
            opacity: 1;
        }
    }

    .select__dropdown-inner.ps--active-y {
        margin-right: 1.5rem;
    }
}
