@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
.filter__inner{
  display: none;
  @include mix.breakpoint(var.$tablet-width) {
    display: block;
  }
}
/* critical:end */

.filter {
  position: fixed;
  top: 0;
  left: 0;
  /*height: 100%;*/
  overflow: hidden;
  transform: translateY(-100%);
  transition: transform .3s;
  z-index: 100;
  width: 100%;
  margin: 0;

  @include mix.breakpoint(var.$tablet-width) {
    overflow: visible;
    position: static;
    transform: none;
  }

  @include mix.breakpoint(var.$desktop-width) {
    margin-bottom: 9.8rem;
  }

  &._only-mobile {
    @include mix.breakpoint(var.$tablet-width) {
      display: none;
    }
  }

  &._sticked {
    @include mix.breakpoint(var.$desktop-max-width) {
      .filter {
        &__inner {
          transition-delay: .1s;
          padding-top: 2rem;
          padding-bottom: 3rem;
        }

        &__list-container {
          transition-delay: .1s;

          /*opacity: 0;
          max-height: 0;
          overflow: hidden;
          margin: 0;*/
        }
      }
    }
  }

  &._active {
    transform: none;
  }
  &__form._preloader{
    @media only screen and (max-width: var.$tablet-width){
      .button-preloader {
        display: block !important;
      }

    }
  }

  &__form._expanded {
    .filter__show-btn svg {
      transform: rotateX(180deg);
    }

    .form__input-item._extra {
      display: block;
    }
  }

  &__inner {
    background-color: var.$color-main;
    border-radius: 4px;
    //padding: 4rem 1.4rem;
    padding: 1.4rem 1.4rem;
    padding-bottom: 70px;
    color: var.$color-white;
    transition: all .3s;
    @include mix.breakpoint(var.$mobile-width) {
      background-image: url("/img/mister-purple.svg");
      background-repeat: no-repeat;
      background-position: top right 2.5rem;
      background-size: 7.6rem 8.2rem;
      transition: none;

    }
    // overflow: hidden;
    @include mix.breakpoint(var.$tablet-width) {
      padding: 4rem 3rem;
      background-size: 9.5rem 10.3rem;
      background-position: top right 13.5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
      background-size: 15.1rem 16.2rem;
      background-position: top right 23.4rem;
      padding-bottom: 6rem;
      padding-top: 4.8rem;
    }
    @include mix.breakpoint(var.$desktop-lg-width) {
      background-position: top right 20rem;
    }
    @media(min-width: 1550px) {
      padding: 5rem 6rem;
      padding-bottom: 6rem;

    }

    @include mix.breakpoint(var.$desktop-max-width) {
      padding-top: 2rem;
      padding-bottom: 3rem;
    }

  }

  &__btn-wrap {
    @media only screen and (max-width: var.$tablet-width - 1) {
      position: fixed;
      bottom: 6rem;
      background-color: WHITE;
      width: 100%;
      left: 0;
      height: 6.7rem;
      padding: 1rem;
    }

    &._outer {
      display: none;
      @include mix.breakpoint(var.$desktop-notebook-width) {
        display: flex;
      }
    }
    &._inner {
      @include mix.breakpoint(var.$desktop-notebook-width) {
        display: none;
      }
    }

    @include mix.breakpoint(var.$tablet-width) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 26rem;
      margin-left: auto;
      margin-right: 1rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
      width: 28rem;
      flex-grow: 0;
    }
    @include mix.breakpoint(var.$desktop-notebook-width) {
      width: 25.5rem;
      flex-shrink: 0;
      margin-left: 1rem;
      margin-right: 0;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
      width: 30.7rem;
      margin-left: 2rem;
      // display: flex;
      // width: 25.5rem;
    }
    // &._inner {
    //     display: block;
    //     @include mix.breakpoint(var.$tablet-width) {
    //         display: flex;
    //     }

    //     @include mix.breakpoint(var.$desktop-notebook-width) {
    //         display: none;
    //     }
    // }



  }

  &._mobile-filter {
    .filter__submit-btn {
      margin-top: 0;
    }
    // .filter__btn-wrap  {
    //     @media only screen and (max-width: var.$tablet-width) {
    //       position: static !important;
    //       background-color: transparent;
    //       padding: 0;
    //     }
    // }
  }

  .title {
    max-width: 20rem;
    margin-bottom: 2.8rem;
    @include mix.breakpoint(var.$tablet-width) {
      max-width: 30rem;
      margin-bottom: 4.4rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
      max-width: 50rem;
      margin-bottom: 4.5rem;
    }
  }

  &__list-container {
    display: flex;
    margin-bottom: 2rem;
    transition: all .3s;
    @include mix.breakpoint(var.$tablet-width) {
      /* margin-bottom: 3rem;*/
    }
    /* @include mix.breakpoint(var.$desktop-width) {
         margin-bottom: 4rem;
     }*/
  }

  &__list-btn {
    background: inherit;
    padding: 0 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    display: none;
    z-index: 1;
    @include mix.breakpoint(var.$desktop-width) {
      display: flex;
    }


    .filter__list-nav-icon {
      stroke: #fff;
      width: .8rem;
      height: 2.2rem;
    }
    // svg {
    //   background: #fff;
    // }
  }

  &__list-wrap {
    width: calc(100% + 2.8rem);
    overflow-x: scroll;
    margin-left: 0;
    // margin-bottom: 2rem;
    max-height: 10rem;
    transition: all .3s;
    @include mix.breakpoint(var.$tablet-width) {
      width: calc(100% + 6rem);
      margin-left: 0;
      // margin-bottom: 3rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
      // margin-bottom: 4rem;
      width: 100%;
    }
  }

  &__category-list {
    display: flex;

    .slick-track {
      margin-left: 1.4rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
      .slick-track {
        margin-left: 3rem;
      }
    }
  }

  &__category-item {
    flex-shrink: 0;
    margin-right: 1.4rem;
  }

  &__category-label {
    display: block;
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
    border: 2px solid #a29de9;
    border-radius: 18px;
    transition: 0.3s all;
    @include mix.breakpoint(var.$tablet-width) {
      padding-top: 0.4rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
      &:hover {
        background-color: #fff;
        color: var.$color-main;
        border-color: #fff;
      }
    }
  }

  &__close-icon {
    display: none;
    fill: var.$color-main;
  }


  &__checkbox:checked + .filter__category-label {
    background: var.$color-white;
    border-color: var.$color-white;
    color: var.$color-main;
    @include mix.breakpoint(var.$tablet-width) {
      padding-right: 3rem;
      position: relative;

      .filter__close-icon {
        position: absolute;
        display: block;
        top: 50%;
        right: 1rem;
        transform: translatey(-50%);
        width: 1.3rem;
        height: 1.3rem;
        pointer-events: none;
        stroke: var.$color-main;
      }
    }
  }

  &__input-list {
    @include mix.breakpoint(var.$tablet-width) {
      display: flex;
      flex-wrap: wrap;
      margin-right: -1rem;
      margin-top: -3rem;

      .form__input-item {
        margin-right: 1rem;
        margin-bottom: 0;

        &._size {
          width: calc(100% / 5 - 1rem);
        }

        &._cost,
        &._producer,
        &._head,
        &._head-height {
          width: calc((100% / 5) * 2 - 1rem);
        }

        &._basis,
        &._rigidity-first,
        &._lifting-device {
          width: 28%;
        }
        &._basis,
        &._lifting-device {
          flex-grow: 2;
        }

        &._rigidity-first {
          flex-grow: 1;
        }
      }

      .form__btn-wrap {
        // margin-right: 1rem;
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-end;
        // margin-left: auto;
        // width: 26rem;
        // @include mix.breakpoint(var.$desktop-width) {
        //     width: 28rem;
        //     flex-grow: 0;
        // }
        // @include mix.breakpoint(var.$desktop-notebook-width) {
        //     width: 25.5rem;
        // }
      }

      // .filter__submit-btn {
      //     width: 12.3rem;
      // }


    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
      flex-grow: 1;
      .form__input-item {
        flex-grow: 1;
        &._size {
          width: 12rem;
        }

        &._head-height {
          width: 18.2rem;
        }

        &._rigidity-first,
        &._rigidity-second {
          width: 16rem;
          flex-grow: 0;

          .select__dropdown {
            width: calc(100% + 4rem);
          }
        }
        &._lifting-device {
          width: 16.4rem;
        }
        &._head {
          width: 15.3rem;
        }

        &._cost {
          width: 19rem;
          flex-grow: 0;
        }
        &._producer {
          width: 15.3rem;
        }
        &._basis {
          width: 15.3rem;
        }

        // &._pressure {
        //     margin-right: 0;
        // }

      }

      // .form__btn-wrap {
      //     margin-right: 2.2rem;
      // }
    }

    @include mix.breakpoint(var.$desktop-max-width) {
      justify-content: space-between;
      margin-right: -2rem;

      // .form__btn-wrap {
      //     width: auto;
      //     flex-grow: 1;
      // }

      // .filter__submit-btn {
      //     flex-grow: 1;
      // }

      .filter__show-btn {
        max-width: 13.2rem;
      }

      .form__input-item {
        margin-right: 2rem;

        &._size {
          width: 12rem;
        }

        &._cost {
          width: 19rem;
        }

        &._producer, &._basis, &._rigidity-first  {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
      }

    }
  }

  &__submit-btn {
    width: 100%;
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
    @include mix.breakpoint(var.$tablet-width) {
      width: 12rem;
      display:none;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
      width: 17.6rem;
    }
  }

  &__show-btn {
    display: none;
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
    margin-top: 0.5rem;
    @media(hover:hover) {
      &:hover {
        background-color: #fff;
        color: #7b75c9;
      }
    }
    @include mix.breakpoint(var.$tablet-width) {
      // width: 13.5rem;
      // .filter__show-btn {
      display: block;
      width: calc(15rem + 12rem);
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      height: 4.7rem;
      // }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
      width: calc(15rem + 17.6rem);
    }
    @include mix.breakpoint(var.$desktop-width) {
      padding-right: 0;
    }
    @include mix.breakpoint(var.$desktop-notebook-width) {
      margin-top: 0;
    }
    &._border{
      border-color: #fff;
    }

    svg {
      width: 1rem;
      height: 1rem;
      stroke: currentColor;
      margin-left: 0.6rem;
      transition: transform 0.5s;
    }

    &._expanded {
      svg {
        transform: rotateX(180deg);
      }
    }
  }

  &__selects {
    @include mix.breakpoint(var.$desktop-notebook-width) {
      display: flex;
    }
  }

  .form {
    &__checkbox:checked + .form__checkbox-label {
      &::before {
        background: var.$color-main;
        border-color: var.$color-text;
      }

      &::after {
        display: block;
      }
    }

    &__input {
      padding: 0;
    }

    &__input-item {
      margin-bottom: 2rem;
      @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 0;
        margin-top: 3rem;
      }
    }

    &__input-item._extra {
      display: block;
      @include mix.breakpoint(var.$tablet-width) {
        display: none;
        order: 2;
        width: calc(100% / 3 - 1rem);
      }
      @include mix.breakpoint(var.$desktop-sm-width) {
        width: calc(100% / 3 - 1rem);
      }

      @include mix.breakpoint(var.$desktop-max-width) {
        width: calc(100% / 3 - 2rem);
        margin-right: 2rem;
      }
    }

    &__input-item._material .js-select-dropdown {
      padding-right: 0;
    }

    &__input-item._basis,
    &__input-item._material,
    &__input-item._producer {
      @include mix.breakpoint(var.$tablet-width) {
        .select__dropdown {
          width: calc(100% + 2rem);
        }

        .select__face span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        .select__option-content {
          display: flex;
        }

        .info-tooltip {
          margin-left: 5px;
          margin-top: -5px;
        }
      }
      @include mix.breakpoint(var.$desktop-width) {
        .select__dropdown {
          width: 100%;
        }
      }
    }




    &__input._disabled {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 200, 0.2);
      }

      &._grey::after {
        background: rgba(255, 255, 255, 0.6);
      }
    }

    &__input {
      position: relative;
      border: 0;

      input._text {
        width: 100%;
        background-color: #fff;
        color: var.$color-text;
        font-size: 1.5rem;
        line-height: 1.9rem;
        border: 0;
        border-radius: 4px;
        text-align: left;
        padding: 1.4rem 1.5rem;
        outline: none;
      }
    }

    &__double {
      display: flex;

      .form__input {
        width: calc(100% / 2 - 1rem);

        &:first-child {
          margin-right: 2rem;
        }

        &:nth-child(2) {
          &::after {
            content: '';
            position: absolute;
            right: 100%;
            top: 50%;
            width: 2rem;
            height: 1px;
            transform: translateY(-50%);
            background-color: #fff;
          }
        }

        @include mix.breakpoint(var.$tablet-width) {
          width: calc(100% / 2 - .5rem);
          &:first-child {
            margin-right: 1rem;
          }
          &:nth-child(2) {
            &::after {
              width: 1rem;
            }
          }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
          width: calc(100% / 2 - 1rem);

          &:first-child {
            margin-right: 2rem;
          }

          &:nth-child(2) {
            &::after {
              width: 2rem;
            }
          }
        }
      }
    }

    &__label {
      display: flex;
      font-family: "Affect";
      font-size: 1.3rem;
      line-height: 1;
      text-transform: uppercase;
      color: #bcb8ee;
      margin-bottom: 1.5rem;
      @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 1.8rem;
      }

      span {
        margin-right: 5px;
      }

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__label-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    margin-right: -1.5rem;
    transition: opacity .3s;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    &._visible {
      opacity: 1;
      visibility: visible;
      max-height: initial;
      overflow: initial;
    }
  }

  &__label-item {
    position: relative;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    padding: .6rem 1.9rem;
    padding-right: 3.3rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    font-weight: 500;
    color: var.$color-main;
    border: none;
    outline: none;
    border-radius: 1.8rem;
    background-color: #fff;
    .filter__close-icon {
      position: absolute;
      top: 50%;
      right: 1.3rem;
      transform: translateY(-35%);
      display: block;
      width: 1rem;
      height: 1rem;
      stroke: currentColor;
    }
  }

  // perfect-scrollbar
  .ps__rail-y {
    opacity: 1 !important;
    width: 8px !important;
    background-color: #ebf0f5;
    border-radius: 8px !important;
  }

  .ps__thumb-y {
    background-color: var.$color-main !important;
    width: 8px !important;
    right: initial !important;
    border-radius: 8px !important;
  }


  &-krovati {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {

        &.form__input-item-42 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-76, &.form__input-item-77 {
          width: 28%;
          flex-grow: 2;
        }

      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-42, &.form__input-item-76, &.form__input-item-77 {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
        &._producer {
          width: calc(100% / 3 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-42, &.form__input-item-76, &.form__input-item-77 {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
        &._producer {
          width: calc(100% / 3 - 2rem);
        }
      }
    }
  }


  &-namatrasniki {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {
        &.form__input-item-20 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-24, &.form__input-item-72 {
          width: 28%;
          flex-grow: 2;
        }
        &.form__input-item-73, &.form__input-item-74, &.form__input-item-75, &._producer {
          width: calc(100% / 2 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-sm-width) {
        &.form__input-item-73, &.form__input-item-74, &.form__input-item-75, &._producer {
          width: calc(100% / 4 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-20, &.form__input-item-24, &.form__input-item-72 {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-20, &.form__input-item-24, &.form__input-item-72 {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
        &.form__input-item-73, &.form__input-item-74, &.form__input-item-75, &._producer {
          width: calc(100% / 4 - 2rem);
        }
      }
    }
  }



  &-osnovaniya {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {
        &.form__input-item-80 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-81, &.form__input-item-82 {
          width: 28%;
          flex-grow: 2;
        }
      }
      @include mix.breakpoint(var.$desktop-sm-width) {
        &.form__input-item-86, &.form__input-item-87, &.form__input-item-88, &._producer {
          width: calc(100% / 4 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-80, &.form__input-item-81, &.form__input-item-82 {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-80, &.form__input-item-81, &.form__input-item-82 {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
        &.form__input-item-86, &.form__input-item-87, &.form__input-item-88, &._producer {
          width: calc(100% / 4 - 2rem);
        }
      }
    }
  }


  &-podushki {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {

        &._cost, &.form__input-item-15 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-92, &._producer {
          width: 28%;
          flex-grow: 2;
        }
        &.form__input-item-19 {
          width: calc(30% - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-15, &.form__input-item-92, &._producer {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
        &.form__input-item-19 {
          width: calc(25% - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-15, &.form__input-item-92, &._producer {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
      }
    }
  }


  &-postelnoe_belye {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {
        &.form__input-item-31 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-32, &.form__input-item-33 {
          width: 28%;
          flex-grow: 2;
        }
      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-31, &.form__input-item-32, &.form__input-item-33 {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-31, &.form__input-item-32, &.form__input-item-33 {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
      }
    }
  }

  &-odeyala {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {
        &.form__input-item-56 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-57, &.form__input-item-58 {
          width: 28%;
          flex-grow: 2;
        }
      }
      @include mix.breakpoint(var.$desktop-sm-width) {
        &.form__input-item-63, &._producer {
          width: calc(100% / 2 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-56, &.form__input-item-57, &.form__input-item-58 {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-56, &.form__input-item-57, &.form__input-item-58 {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
      }
    }
  }

  &-pledy {
    .form__input-item {
      @include mix.breakpoint(var.$tablet-width) {
        &.form__input-item-69 {
          width: calc((100% / 5) * 2 - 1rem);
        }
        &.form__input-item-70, &.form__input-item-71 {
          width: 28%;
          flex-grow: 2;
        }
      }
      @include mix.breakpoint(var.$desktop-notebook-width) {
        &.form__input-item-69, &.form__input-item-70, &.form__input-item-71 {
          width: calc((100% - 32rem - 19rem - 2rem) / 3 - 1rem);
        }
      }
      @include mix.breakpoint(var.$desktop-max-width) {
        &.form__input-item-69, &.form__input-item-70, &.form__input-item-71 {
          width: calc((100% - 12rem - 19rem - 4rem) / 3 - 2rem);
        }
      }
    }
  }


}
