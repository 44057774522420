@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider-v7 {
}

//Deps
.slider-v7 {
    .title-v2 {
        color: var.$color-black-tone4;
        font-family: var.$font-header;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.8rem; /* 116.667% */
        margin-bottom: 2.4rem;
    }
}