@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popular-size {
    margin-bottom: 3rem;
    .card-detail__subtitle {
        margin-bottom: 2.3rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        // overflow: hidden;
        .card-detail__subtitle {
            display: none;
            margin-bottom: 3rem;
        }
    }
    @include mix.breakpoint(var.$desktop-sm-width) {
        // margin-bottom: 3rem;
        .card-detail__subtitle {
            margin-bottom: 2rem;
        }
    }
    @include mix.breakpoint(var.$desktop-width) {
        .card-detail__subtitle {
            display: block;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -1.5rem;
        margin-top: -1.5rem;
        margin-bottom: 2rem;

        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
                margin-right: -1.25rem;
                margin-left: -1.25rem;
                margin-top: -2.5rem;
            }
        @include mix.breakpoint(var.$desktop-width) {
            // width: 100%;
            display: flex;
            margin-right: -1.4rem;
            margin-left: 0;
            margin-top: -1.4rem;
            margin-bottom: 2.5rem;
        }
    }

    &__item {
        width: calc(100% / 3 - 1.5rem);
        margin-right: 1.5rem;
        margin-top: 1.5rem;
        // @include mix.breakpoint(var.$tablet-width) {
        //     width: calc(100% / 6 - 2.5rem);
        //     margin-right: 1.25rem;
        //     margin-left: 1.25rem;
        //     margin-top: 2.5rem;
        //     &:nth-child(n+7) {
        //         display: none;
        //     }
        // }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(100% / 4 - 2.5rem);
            margin-right: 1.25rem;
            margin-left: 1.25rem;
            &:nth-child(n+7) {
                display: block;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 1.4rem);
            margin-right: 1.4rem;
            margin-left: 0;
            margin-top: 1.3rem;
            &:nth-child(n+7) {
                display: block;
            }
        }
    }

    &__btn {
        display: block;
        border-radius: 4px;
        border: 1px solid #EBF0F5;
        padding: 1rem 1.2rem;
        text-decoration: none;
        transition: all .3s;
        @media(hover:hover) {
            &:hover,
            &:focus {
                background-color: var.$color-text;
                .popular-size__value {
                    color: #fff;
                }
                .popular-size__price {
                    color: #A0A0D0;
                }
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 1.3rem 1.7rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
        }

    }

    &__btn._active {
        background-color: var.$color-text;
        .popular-size__value {
            color: #fff;
        }
        .popular-size__price {
            color: #A0A0D0;
        }
    }

    &__value {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        letter-spacing: -0.03em;
        display: block;
        color: var.$color-text;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.5rem;
            line-height: 1.9rem;
            margin-bottom: 2px;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0;
        }
    }

    &__x {
        font-size: 0.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1rem;
        }
    }

    &__price {
        display: block;
        font-size: 11px;
        line-height: 14px;
        color: #8D8D8D;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }

    &__btn-container {
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: auto;
            // margin-bottom: 2rem;
        }
        /*@include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }*/
    }

    &__select {
        width: 100%;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            // width: 35rem;
            // flex-grow: 1;
            // margin-right: 3.7rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            /*width: 30.2rem;*/
            // width: 50%;
            /*margin-right: 1rem;*/
            margin-bottom: 0;
            flex-grow: 0;
        }
    }
    &__select-title{
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 1.4rem;
    }



    &__link-wrap {
        //text-align: center;
        text-align:center;
        @include mix.breakpoint(var.$mobile-2lg-width) {
            text-align: left;
        }
        @include mix.breakpoint(var.$tablet-width) {
            //text-align: center;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            /*width: auto;*/
            // width: 50%;
            margin-left: auto;
            flex-grow: 0;
            margin-top: 1.3rem;
        }
    }

    &__order-size {
        text-decoration: none;
        color: var.$color-main;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        display: inline-flex;
        align-items: center;

        svg {
            stroke: var.$color-main;
            width: 8px;
            height: 10px;
            margin-left: 5px;
        }

        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    .select {
        &__face {
            font-size: 1.4rem;
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.5rem;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                font-weight: 500;
                padding-left: 4rem;
            }
            @include mix.breakpoint(var.$desktop-sm-width) {
                padding-left: 1.5rem;
                padding-top: 1rem;
                padding-bottom: 1.2rem;
            }
        }
    }
}
