@use 'base/variables' as var;
@use 'base/mixins' as mix;

.step-v1 {

    width: 100%;
    height: 3.5rem;
    text-align: center;
    border-radius: 0.6rem;
    display: grid;

    &_filling-type-contained {
        border: 0.6rem;
        color: var.$color-text;
    }

    &_filling-type-outlined {
        border: 0.6rem;
        color: var.$color-text;
    }

    &__title, &__subtitle {
        width: 100%;
        text-align: center;
        font-family: var.$font-text;
        font-weight: 600;
        font-size: 1rem;
        white-space: nowrap;
    }

    &__title {
        align-self: end;
    }

    &__subtitle {
        margin-top: 0.5rem;
    }

    &_text-color-type-white {
        color: var.$color-white;
    }
}
    
.step-v1_filling-type-contained.step-v1_filling-color-type-gray {
    background-color: var.$color-third;
}

.step-v1_filling-type-contained.step-v1_filling-color-type-text {
    background-color: var.$color-text;
}

.step-v1_filling-type-contained.step-v1_filling-color-type-secondary {
    background-color: var.$color-second-tone4;
}

/** DEPENDS CODE */
.step-v1 {
}
/** \DEPENDS CODE */
