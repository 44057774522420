@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v1 {
    margin-top: 2.1rem;
    display: grid;

    /*
        временное решение чтобы баннеры не сжимались когда 0 товаров. Нужно будет исправить  если будет приводить
        к проблемам, но по идее не должно.
    */
    width: 100%;

    @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        grid-template-areas: 
            "title"
            "left"
            "right";
    }
    @include mix.breakpoint(var.$desktop-sm-width) {
        grid-template-rows: auto auto;
        grid-template-columns: calc(68% - 3rem) 32%;
        column-gap: 3rem;
        grid-template-areas: 
            "title title"
            "left right";
    }

    &__rating-block {
        display: flex;
        align-items: center;
        gap: 3rem;
    }

    &__rating-info {

        margin-top: 1rem;
        gap: 1rem;
        align-items: center;
        white-space: nowrap;
        display: grid;
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            grid-template-columns: 100%;
            grid-template-rows: min-content min-content min-content;
            grid-template-areas: 
                "articul"
                "rating-block"
                "icon-wrap";

            .p-v1 {
                grid-area: articul;
                justify-self: start !important;
                /* WTF?! */
                margin-bottom: 1.5rem !important;
                /* \WTF?! */
            }

            .product-card-v1__rating-block {
                grid-area: rating-block;
            }

            .product-card-v1__icon-wrap {
                grid-area: icon-wrap;
            }

        }

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            display: none;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            width: 100%;
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 62%;
            gap: 3rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            grid-template-columns: min-content min-content auto;
        }

    }

    &__real-modifier-switcher {
        width: 100%;
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            grid-auto-flow: row;
            grid-template-columns: 100% !important;
            grid-template-rows: min-content min-content;

            .link-v1 {
                justify-self: end;
            }

        }
    }

    &__textile-switcher {
        margin-top: 1rem;
    }

    &__icon-wrap {
        display: grid;
        grid-template-columns: repeat(2, min-content);
        gap: 1.5rem;
    }

    &__left {
        grid-area: left;
    }

    &__right {
        grid-area: right;
    }

    &__price-block {
        margin-left: 5.5rem;
        min-width: 0.1rem; /* crutch */
        min-height: 6rem;
        /*display: grid;
        grid-template-columns: min-content min-content;
        grid-template-rows: min-content min-content;
        column-gap: 2rem;
        row-gap: 2rem;*/
    }

    &__stock-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.4rem;
        align-items: center;
    }

    &__right {


        .product-card-v1__one-tablet-row {

            @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {

                display: grid;
                grid-template-rows: min-content min-content;
                grid-template-columns: 50% 50%;
                grid-template-areas: 
                    "price-block real-modifier-switcher"
                    "stock real-modifier-switcher";

                .product-card-v1__price-block {
                    grid-area: price-block;
                }

                .stock-v2 {
                    grid-area: stock;
                }

                .product-card-v1__real-modifier-switcher {
                    grid-area: real-modifier-switcher;
                    margin-top: 0;
                    display: grid;
                    grid-template-columns: 100%;
                    grid-template-rows: min-content min-content;
                    justify-items: end;

                    .form-group-select-v1 {
                        min-width: 24.5rem;
                    }

                }

            }
        }

    }

    &__right &__two-tablet-row {

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {

            display: grid;
            margin-top: 3rem;
            grid-template-rows: min-content;
            grid-template-columns: calc(50% - 2rem) 50%;
            grid-template-areas: 
                "credit delivery"
                "dolyame entice";
            column-gap: 2rem;
            row-gap: 1rem;

            .info-block-v1 {
                height: 100%;
            }

            .product-card-v1__buy-via-credit {
                margin-top: 0;
                grid-area: credit;
            }

            .product-card-v1__buy-via-dolyame {

                margin-top: 0;
                grid-area: dolyame;

                .stepper-v1__head .stepper-v1__head-desc {
                    font-size: 1rem;
                }

                .stepper-v1__track {
                    gap: 1rem;
                }

                .step-v1 {
                    width: 100%;
                }

            }

            .product-card-v1__delivery {
                margin-top: 0;
                grid-area: delivery;
                font-size: 1.2rem;
            }

            .product-card-v1__entice-block {
                margin-top: 0;
                grid-area: entice;
                font-size: 1.1rem;
            }

        }

    }

    &__price-block-wrapper {
        margin-top: 6rem;
        display: flex;
        justify-content: space-between;
    }

    &__price-discount-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}


/** DEPENDS CODE */
.product-card-v1 {

    &__icon-wrap {

    }
    &__price-block {

        .old-price-v1 {
            display: flex;
            justify-self: start;
            align-self: center;
            white-space: nowrap;
            align-items: center;
        }

        .sale-price-v1 {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            white-space: nowrap;
            line-height: 4.5rem;
            margin-top: 0.4rem;
        }

        .saving-money-v1 {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            white-space: nowrap;
            line-height: 2.3rem;
        }

        .badge-v1 {
            margin-left: 0.4rem;
            height: 3.4rem;
        }

    }

    &__real-modifier-switcher {


        .real-modifier-switcher-v1 {

        }

        .link-v1 {
            display: block;
            align-self: end;
            font-family: var.$font-text;
            font-weight: 400;
            font-size: 1.4rem;
            margin-top: 0.8rem;
            line-height: 1.8rem;
            text-decoration: underline;
            color: var.$color-text;
        }

        .select2 {
            width: 100% !important;
        }

    }

    &__left &__gallery {

        position: relative;
        margin-top: 2rem;

        .badge-list-v1 {
            display: inline-flex;
            position: absolute;
            top: 1rem;
            left: 0; //3rem;
            height: 3.4rem;
            z-index: 9;
            @include mix.breakpoint(var.$desktop-sm-width) {
                // left: 15.6rem;
                left: 0;
            }

        }

        .gallery-v1 {
            margin-top: 1rem;
        }
    }

    > .header-v1 {
        grid-area: title;
        line-height: 4.8rem;
        margin-bottom: 1.5rem;
    }

    .link-with-icon-v1 {
        font-size: 1.2rem;
    }

    .popular-info-v1 {
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        gap: 0.8rem
    }

    .popular-info-v1__info {
        display: flex;
        align-items: center;
        padding-left: 0rem;
    }


    .p-v1 {
        font-size: 1.2rem;
        margin: 0;
        justify-self: end;
    }

    .character-list-v1 {
        margin-top: 2rem;
    }

    &__right .stock-v2 {

    }

    &__right &__real-modifier-switcher {
    }

    &__right &__additional-product-group {
        margin-top: 2.2rem;
    }

    &__right .action-group-v1 {

        margin-top: 3rem;

        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {

            grid-template-columns: repeat(auto-fit, minmax(0, 50%)) !important;

        }

    }

    &__right &__buy-via-credit {
        margin-top: 3rem;
        font-weight: 400;
        font-size: 1.6rem;
    }

    &__right &__buy-via-dolyame {
        margin-top: 1rem;
    }

    &__right &__delivery {
        margin-top: 1rem;
        font-weight: 400;
        font-size: 1.4rem;
    }

    &__right &__entice-block {
        margin-top: 1rem;
        font-weight: 400;
        font-size: 1.4rem;
    }

    .radio-list-v1 {
        margin-top: 2.4rem;
    }

    .installment-payment-v1, .delivery-info-v2 {
        margin-top: 2.4rem;
    }

    .delivery-info-v2 {
        margin-bottom: 6rem;
    }

    .gallery-v4 {
        margin-bottom: 8rem;
    }

    .sale-price-v1__no-price {
        margin-top: 6rem;
        line-height: auto;
        font-size: 2rem;
        color: var.$color-gray;
    }

    .textile-switcher-v1 {
        margin-bottom: 2.4rem;
    }
}

/** \DEPENDS CODE */