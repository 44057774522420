@use 'base/variables' as var;
@use 'base/mixins' as mix;

.interior-products-v1 {

    &__wrap {

    }

    &__title-place {
        @include mix.breakpointRange(var.$zero-width, (var.$mobile-2lg-width - 1)) {
            margin-top: 1rem;
            margin-bottom: 3.5rem;
        }

        @include mix.breakpointRange(var.$mobile-2lg-width, (var.$tablet-h-width - 1)) {
            margin-top: 0rem;
            margin-bottom: 3.5rem;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 1rem;
        }

        & .title {
            font-family: "EuclidCircularA";
            font-weight: normal;
            position: relative;
            margin-bottom: 7rem;

            &::after {
                position: absolute;
                content: '';
                display: block;
                height: 1px;
                width: 100%;
                background: #EBF0F5;
                left: 0;
                bottom: -3.5rem;
            }
        }
    }

    &__list-place {

        // display: grid;

        & .slick-list {
            padding-top: 2rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
            margin-left: -2rem;
        }

        & .card {
            float: left;
            width: 20rem;
            background: #fff;
            margin-left: 0;
            margin-top: 0;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                margin-right: 2rem;
                width: 18rem;
            }

            @include mix.breakpoint(var.$tablet-width) {
                margin-right: 3rem;
                width: 20rem;
            }

            &__content {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &__compare-icon {
                width: 1.4rem;
                height: 1.4rem;
            }

            &__like-icon {
                width: 1.4rem;
                height: 1.4rem;
            }

            &__sale { 
                height: 2.1rem;
                font-size: 0.9rem !important;
                line-height: 1.1rem !important;
                margin-right: 0.4rem;
                margin-bottom: 0rem;
                &.delivery-icon {
                    font-size: 0.6rem !important;
                    line-height: 0.8rem !important;
                }
            }

            &__stat {
                display: flex;
                flex-direction: row;
                margin: 0.8rem 0;
            }

            &__reviews {
                justify-content: center;
            }

            &__review-number {
                color: #7B75C9;
                font-size: 0.9rem;
                line-height: 1.1rem;
                margin-right: 0.4rem;
                border-right: 0.1rem solid #7B75C9;
                border-left: 0.1rem solid #7B75C9;
                padding: 0 0.4rem;
            }

            &__order-number {
                color: #7B75C9;
                font-size: 0.9rem;
                line-height: 1.1rem;
            }

            &__title {
                font-size: 1.2rem;
                line-height: 1.4rem;
            }

            &__name {
                min-height: 2.8rem;
            }

            &__footer {
                padding-top: 1.4rem;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &__price-wrap {
                flex-direction: column;
                min-height: 2.8rem;
                justify-content: center;
            }

            &__price-title {
                &._absolute {
                    display: none !important;
                }
            }

            &__price-old {
                font-size: 1rem;
                line-height: 1.2rem;
                width: fit-content;
                align-self: end;
            }

            &__price {
                font-size: 1.4rem;
                line-height: 1.6rem;
                white-space: nowrap;
            }

            &__buy-btn {
                font-size: 1rem;
                line-height: 1.2rem;
                padding-top: 1rem;
                padding-bottom: 1.1rem;
                font-weight: 500;
            }

            &__recommend {
                font-size: 1rem;
                line-height: 1.1rem;
                height: 2.1rem;
            }

            &__props-item {
                font-size: 1.1rem;
                line-height: 1.1;
                margin-bottom: 0.4rem;
            }

            &__props-name {
                width: 6.4rem;
            }

            &__colors {
                padding: 0 1rem;
                height: 3rem;
                display: none;
            }

            &__color {
                height: 3rem;
                width: 3rem;
            }

            &__counter {
                margin-top: 0.3rem;
                width: 2.4rem;
                height: 2.4rem;
                line-height: 2.2rem;
                font-size: 0.9rem;
            }

            &__row {
                flex-direction: column;
                justify-content: space-between;
                min-height: 4.8rem;
                display: none;
            }

            &__rating {
                font-size: 0.9rem;
                line-height: 1.1rem;
                color: #7B75C9;
            }

            &__rate-count {
                display: none;
            }

            &__star-icon {
                width: 0.8rem;
                height: 0.8rem;
                fill: #7B75C9;
                margin-right: 0.2rem;
            }

            &__prop_14 svg {
                display: block;
            }

            &__offer {
                margin-top: 0rem;
                padding-left: 1.2rem;
                margin-bottom: 0.8rem;
                font-size: 1.2rem;
                display: none;
                
                &::before {
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }

            &__status {
                line-height: 3.3rem;
                margin-top: 1.2rem;
                font-size: 1.4rem;
            }

            &__img-wrap {
                margin-bottom: 0;
            }

            & .slick-dots li {
                @media(max-width: (var.$tablet-width - 1)) {
                    opacity: 0;
                }
            }
        }

        & .card:last-child {
            margin-right: 0;
        }

        & .card__link {
            padding: 0;
        }

        & .card__recommend {
            left: 0;
        }

    }

    &__prev {
        display: none !important;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: grid !important;
        }
        cursor: pointer;
        position: absolute;
        width: 5rem;
        height: 5rem;
        z-index: 11;

        top: -4.8rem;
        left: auto;
        right: 6.5rem;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {

        }
        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-laptop-width - 1)) {
            top: -6.8rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            top: -4.8rem;
        }
    }

    &__next {
        display: none !important;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: grid !important;
        }
        cursor: pointer;
        position: absolute;
        width: 5rem;
        height: 5rem;
        z-index: 11;
        top: -4.8rem;
        right: 0;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-laptop-width - 1)) {
            top: -6.8rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            top: -4.8rem;
        }
    }

    &__track {
        position: relative;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-bottom: 6rem;
            margin-top: 2.4rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-laptop-width - 1)) {
            margin-bottom: 6rem;
            margin-top: 3rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-bottom: 10rem;
            margin-top: 1.4rem;
        }
    }

    // TODO[dependСss]
    // depend: widget-type-in-hyphen-case

    // \depend: widget-type-in-hyphen-case

    // \TODO[dependСss]

}