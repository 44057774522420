@use 'base/variables' as var;
@use 'base/mixins' as mix;

.footer-menu-v2 {
    background-color: var.$color-gray-tone3;
    border-top: 1px solid var.$color-gray-tone8;
    padding-bottom: 4.9rem;
    margin-top: 5rem;

}

/** DEPENDS CODE */
.footer-menu-v2 {
    .site-footer__nav-link {
        &:hover {
            /* ставлю импортант - нет времени разбиратсья с перекрытием */
           color: var.$color-white !important;
        }
    }
}
/** DEPENDS CODE */