@use 'base/variables' as var;
@use 'base/mixins' as mix;

.mattress {
    & .filter__submit-btn {
        display: block;
    }

    &-category {
        width: 16rem;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        font-size: 2.1rem;

        @include mix.breakpointRange(var.$zero-width, (var.$mobile-2lg-width - 1)) {
            width: calc(50% - 2rem);
            margin-bottom: 2rem;
            font-size: 1.8rem;
        }

        @include mix.breakpointRange(var.$mobile-2lg-width, (var.$tablet-h-width - 1)) {
            width: calc(33.3% - 2rem);
            margin-bottom: 2rem;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            width: 16rem;
            margin-bottom: 0;
        }

        &__section {
            margin-top: 10rem;
            margin-bottom: 10rem;

            @include mix.breakpointRange(var.$zero-width, (var.$desktop-lg-width - 1)) {
                margin-top: 6rem;
                margin-bottom: 6rem;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                margin-top: 10rem;
                margin-bottom: 10rem;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: normal;
            justify-content: space-between;
        }

        &__image {
            margin-bottom: 1.2rem;
            align-self: center;
            width: 16rem;

        }

        &__title {
            font-family: EuclidCircularA,Arial,sans-serif;
            margin-top: 0;
            text-align: center;
            color: var.$color-text;
        }
    }

    &-banner {
        position: relative;
        width: calc(50% - 1.5rem);
        margin-bottom: 3rem;
        border-radius: 4rem 0 0 0;
        height: 300px;
        background: #D8E2EC;
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: bottom right;

        @include mix.breakpointRange(var.$zero-width, (var.$mobile-2lg-width - 1)) {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
            height: 240px;
            border-radius: 2rem 0 0 0;
            background-size: 153px 181px;
        }

        @include mix.breakpointRange(var.$mobile-2lg-width, (var.$tablet-width - 1)) {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
            height: 222px;
            border-radius: 2rem 0 0 0;
            background-size: 153px 181px;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-width - 1)) {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
            height: 180px;
            border-radius: 2rem 0 0 0;
            background-size: 153px 181px;
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: calc(50% - 1.5rem);
            margin-bottom: 3rem;
            height: 300px;
            border-radius: 4rem 0 0 0;
            background-size: 401px 300px;
        }

        &__section {
            margin-top: 10rem;
            margin-bottom: 10rem;

            @include mix.breakpointRange(var.$zero-width, (var.$desktop-lg-width - 1)) {
                margin-top: 6rem;
                margin-bottom: 6rem;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                margin-top: 10rem;
                margin-bottom: 10rem;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        &__text-wrap {
            position: relative;
            padding: 4rem;
            width: 26rem;
            z-index: 1;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                padding: 2rem;
                width: 100%;
            }

            @include mix.breakpoint(var.$tablet-width) {
                padding: 4rem;
                width: 26rem;
            }
        }

        &__title {
            font-family: EuclidCircularA,Arial,sans-serif;
            text-transform: uppercase;
            font-size: 3rem;
            line-height: 3.6rem;
            font-weight: 700;
            margin-top: 0;
            margin-bottom: 1rem;
            z-index: 1;

            @include mix.breakpointRange(var.$zero-width, (var.$mobile-2lg-width - 1)) {
                font-size: 1.3rem;
                line-height: normal;
            }

            @include mix.breakpointRange(var.$mobile-2lg-width, (var.$desktop-width - 1)) {
                font-size: 2rem;
                line-height: normal;
            }

            @include mix.breakpoint(var.$desktop-width) {
                font-size: 3rem;
                line-height: 3.6rem;
            }
        }

        &__image {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 0;
            max-width: none;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                width: auto;
                height: 180px;
                max-width: 100%;
            }

            @include mix.breakpoint(var.$tablet-width) {
                width: auto;
                height: 100%;
            }
        }

        // &_item-1 {
        //     @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
        //         background-image: url(/img/mattress/ban_1_mobile.png);
        //     }

        //     @include mix.breakpoint(var.$desktop-width) {
        //         background-image: url(/img/mattress/ban_1.png);
        //     }
        // }

        // &_item-2 {
        //     @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
        //         background-image: url(/img/mattress/ban_2_mobile.png);
        //     }

        //     @include mix.breakpoint(var.$desktop-width) {
        //         background-image: url(/img/mattress/ban_2.png);
        //     }
        // }

        // &_item-3 {
        //     @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
        //         background-image: url(/img/mattress/ban_3_mobile.png);
        //     }

        //     @include mix.breakpoint(var.$desktop-width) {
        //         background-image: url(/img/mattress/ban_3.png);
        //     }
        // }

        // &_item-4 {
        //     @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
        //         background-image: url(/img/mattress/ban_4_mobile.png);
        //     }

        //     @include mix.breakpoint(var.$desktop-width) {
        //         background-image: url(/img/mattress/ban_4.png);
        //     }
        // }
    }

    &-size {
        width: calc(50% - 1.5rem);
        margin-bottom: 3rem;
        text-align: center;
        font-family: Affect;
        font-size: 3rem;
        font-weight: 700;
        line-height: 10rem;
        background: #EBF0F5;
        border-radius: 1rem;
        text-decoration: none;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-lg-width - 1)) {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
            font-size: 2rem;
            line-height: 6rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            width: calc(50% - 1.5rem);
            margin-bottom: 3rem;
            font-size: 3rem;
            line-height: 10rem;
        }

        &__section {
            margin-top: 10rem;
            margin-bottom: 10rem;

            @include mix.breakpointRange(var.$zero-width, (var.$desktop-lg-width - 1)) {
                margin-top: 6rem;
                margin-bottom: 6rem;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                margin-top: 10rem;
                margin-bottom: 10rem;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }

    &-item {
        width: calc(25% - 1.5rem);
        margin-bottom: 3rem;
        text-align: left;
        font-family: Affect;
        font-size: 3rem;
        font-weight: 700;
        line-height: 10rem;
        background: #EBF0F5;
        border-radius: 1rem;
        text-decoration: none;
        padding-left: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: flex;
        flex-direction: row;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
            flex-direction: column;
            padding-right: 0;
            padding-left: 0;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-lg-width - 1)) {
            width: calc(50% - 1rem);
            margin-bottom: 2rem;
            flex-direction: row;
            padding-right: 2rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            width: calc(25% - 2.25rem);
            margin-bottom: 3rem;
            flex-direction: row;
            padding-right: 0;
        }

        &__section {
            margin-top: 10rem;
            margin-bottom: 10rem;

            @include mix.breakpointRange(var.$zero-width, (var.$desktop-lg-width - 1)) {
                margin-top: 6rem;
                margin-bottom: 6rem;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                margin-top: 10rem;
                margin-bottom: 10rem;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                align-items: normal;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                align-items: center;
            }
        }

        &__title {
            text-transform: uppercase;
            font-size: 3rem;
            line-height: 3rem;
            font-weight: 700;
            align-items: center;
            display: flex;
            z-index: 1;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                font-size: 2rem;
                line-height: 2rem;
                display: block;
                margin-top: 1.2rem;
                text-align: center;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-lg-width - 1)) {
                font-size: 2rem;
                line-height: 2rem;
                display: flex;
                margin-top: 0;
                text-align: left;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                font-size: 3rem;
                line-height: 3rem;
                display: flex;
                margin-top: 0;
                text-align: left;
            }
        }

        &__image {
            margin-right: 2rem;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                width: 6rem;
                margin-right: 0;
                align-self: center;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-lg-width - 1)) {
                width: 10rem;
                margin-right: 2rem;
                align-self: start;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                width: 10rem;
                margin-right: 2rem;
                align-self: start;
            }
        }
    }

    &-price {
        position: relative;
        width: calc(33% - 1.5rem);
        margin-bottom: 3rem;
        overflow: hidden;
        border-radius: 4rem 0 0 0;
        height: 30rem;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 100%;
            margin-bottom: 2rem;
            border-radius: 2rem 0 0 0;
        }

        @include mix.breakpoint(var.$tablet-width) {
            width: calc(33.3% - 2rem);
            margin-bottom: 3rem;
            border-radius: 4rem 0 0 0;
        }

        &__section {
            margin-top: 10rem;
            margin-bottom: 10rem;

            @include mix.breakpointRange(var.$zero-width, (var.$desktop-lg-width - 1)) {
                margin-top: 6rem;
                margin-bottom: 6rem;
            }

            @include mix.breakpoint(var.$desktop-lg-width) {
                margin-top: 10rem;
                margin-bottom: 10rem;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        &__title {
            display: block;
            position: absolute;
            font-family: EuclidCircularA,Arial,sans-serif;
            z-index: 1;
            height: 4.8rem;
            padding: 0 2rem 0 3rem;
            background: #7C75C9;
            font-size: 2.6rem;
            line-height: 4.8rem;
            font-weight: 500;
            color: #fff;
            left: 0;
            top: 0;
        }

        &__limit {
            display: block;
            position: absolute;
            font-family: EuclidCircularA,Arial,sans-serif;
            z-index: 1;
            background: #BEE6A8;
            padding: 1.2rem;
            color: #000;
            font-size: 1.4rem;
            line-height: 1.4rem;
            bottom: 0;
            right: 0;
            font-weight: 500;
        }

        &__image {
            height: 100%;
            min-width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__show-btn {
        width: 320px;
        display: block;
        background-color: #f5f7fa;
        border-radius: 4px;
        color: #3c3c70;
        text-align: center;
        text-decoration: none;
        padding: 1.5rem;
        font-size: 1.6rem;
        transition: all .3s;
        margin: 0 auto;

        @include mix.breakpointRange(var.$zero-width, (var.$mobile-2lg-width - 1)) {
            margin-top: 5rem;
            margin-bottom: 6rem;
            width: 100%;
        }

        @include mix.breakpointRange(var.$mobile-2lg-width, (var.$desktop-lg-width - 1)) {
            margin-top: 5rem;
            margin-bottom: 6rem;
            width: 320px;
        }

        &:hover {
            background-color: #7b75c9;
            color: #fff;
        }

        &-desktop-text {
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                display: none;
            }

            @include mix.breakpoint(var.$tablet-width) {
                display: inline-block;
            }
        }

        &-mobile-text {
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                display: inline-block;
            }

            @include mix.breakpoint(var.$tablet-width) {
                display: none;
            }
        }
    }
}