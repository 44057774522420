@use 'base/variables' as var;
@use 'base/mixins' as mix;

.link-v1 {
    
    // color: var.$color-gray-tone4;
    text-decoration: none;

    &_text-color-type-gray {
        color: var.$color-gray-tone4;
    }

    &_text-color-type-gray:hover {
        color: var.$color-gray-tone6;
    }

    &_text-color-type-main {
        color: var.$color-main;
    }

    &_text-color-type-main:hover {
        color: var.$color-main-tone8;
    }

    &_text-color-type-secondary {
        color: var.$color-second;
    }

    &_text-color-type-secondary:hover {
        color: var.$color-second-tone6;
    }

    &_decoration-type-none {
        text-decoration: none;
    }

    &_decoration-type-underline {
        text-decoration: underline;
    }

}

.link-v1:hover {
    color: inherit;
    text-decoration: none;
}

.link-v1_active.link-v1_text-color-type-gray {
    color: var.$color-gray-tone6;
}

.link-v1_active.link-v1_text-color-type-main {
    color: var.$color-main-tone8;
}

.link-v1_active.link-v1_text-color-type-secondary {
    color: var.$color-second-tone6;
}