@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-block-grid-v2 {

    display: grid;
    row-gap: 6rem;

    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5.1rem;
        row-gap: 3rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        row-gap: 0.7rem;
    }

    &__element {

        padding-top: 2.1rem;
        align-items: start;
        border-top: 0.2rem solid rgba(var.$color-text, 0.3);
        display: grid;
        grid-template-columns: 40% 30% 30%;
        grid-auto-flow: column;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
            padding-top: 1rem;
        }
    
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
            padding-top: 0.6rem;
        }

    }

    &__subelement {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            margin-bottom: 1rem;
        }
    }

    &__subelement:nth-child(1) {

        font-size: 2.8rem;
        font-weight: 600;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 1.4rem;
            padding-bottom: 1rem;
        }
    
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 1.4rem;
            padding-bottom: 1rem;
        }

    }

    &__subelement:nth-child(2) {

        font-size: 2.4rem;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 1.2rem;
            padding-bottom: 3rem;
        }
    
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 1.2rem;
            padding-bottom: 3rem;
        }

    }

    &__subelement:nth-child(3) {

        font-size: 2.4rem;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 1.2rem;
        }
    
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 1.2rem;
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            justify-self: end;
        }

    }

    &__subelement:last-child {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            margin-bottom: 2.4rem;
        }
    }

}

/** DEPENDS CODE */
.info-block-grid-v2 {
    
    &__subelement .header-v1 > * {
        vertical-align: top;
    }

    &__subelement:nth-child(1) .header-v1.header-v1_lvl-type-4 {

        font-size: 2.8rem;
        font-weight: 600;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 1.4rem;
        }
    
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 1.4rem;
        }

    }

    &__subelement:nth-child(1) .header-v1 .icon-v1 {

        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            width: 1.8rem !important;
            height: 2.1rem !important;
        }
        
    }

}
/** DEPENDS CODE */