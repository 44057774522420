@use 'base/variables' as var;
@use 'base/mixins' as mix;

._alert {

    font-size: 1.2rem !important;

    &._alert-error {
        color: #f46b5f !important;
    }

}