@use 'base/variables' as var;
@use 'base/mixins' as mix;

.progress-v1 {
    
}

.progress-v1.progress {

    background-color: var.$color-gray-tone1;

    .progress-bar {
        background-color: var.$color-rating-tone6;
    }

}