@use 'base/variables' as var;
@use 'base/mixins' as mix;

.promotion-view-page-v1 {
    &__offer {
        padding: 1rem;
        background: var.$color-second;
        border-radius: 0.4rem;
        margin-bottom: 5rem;
        p {
            margin: 0 !important;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
    }
}

//deps
.promotion-view-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .text-v2 h5 {
        margin: 2.2rem auto;
    }
}