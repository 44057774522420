@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
.form {
    &__input-item {
        position: relative;
        margin-bottom: 1.6rem;

        &._required {
            &::after {
                content: '*';
                position: absolute;
                bottom: 100%;
                /*left: 0;*/

                left: 1rem;
                top: 0.2rem;

                transform: translateY(30%);
                color: #f46b5f;
            }
        }

        &._comment {
            margin-bottom: 3rem;

            textarea {
                height: 11rem;
                display: block;
            }
        }

        &._attach {
            margin-bottom: 3rem;
        }

        &._open {
            .delivery-form__icon {
                transform: rotateX(180deg);
            }
            .delivery-form__icon-btn {
                pointer-events: initial;
            }

            .select__dropdown {
                opacity: 1;
                pointer-events: initial;
                max-height: initial;
                overflow: initial;
            }
        }

        // выпилено
        // &._dropdown-hidden {
        //     .select__dropdown {
        //         display: none;
        //     }
        //     .delivery-form__icon {
        //         transform: none;
        //     }
        // }

        @include mix.breakpoint(var.$tablet-width) {
            &._phone {
                width: 20.5rem;
                flex-grow: 1;
                margin-right: 1.5rem;
            }

            &._email {
                width: 42.6rem;
                flex-grow: 1;
            }

            &._comment {
                margin-bottom: 3rem;
            }

            &._attach {
                display: flex;
                margin-bottom: 1.5rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            &._comment {
                margin-bottom: 1.8rem;
            }

            &._email {
                width: 29.5rem;
            }

            &._attach {
                margin-bottom: 0;
            }
        }

    }

    &__input {
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 1.2rem;
        padding-bottom: 1.4rem;

        font-size: 1.6rem;
        line-height: 2rem;

        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ebf0f5;
        outline: none;
        @include mix.placeholder {
            color: #818da2;
        }

        &._error {
            background-color: #fefbfb;
            border-color: #eebeba !important;
        }

        &._dark {
            background-color: var.$color-main;
            border-color: #fff;
            color: #fff;
            @include mix.placeholder {
                color: #fff;
            }

            &._error {
                border-color: #eebeba !important;
            }
        }

        &[data-dropdown-on-input][disabled] {
            cursor: pointer;
        }

        &._disabled {
          background: #dfdfdf;
        }
    }

    &__input-contener {
        position: relative;
    }

    &__input-disabled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        &._hidden {
            display: none;
        }
    }

    &__input-title {
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        line-height: 1.1;
    }

    &__row {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }
    }

    &__label-radio {
        position: relative;
        .form__label-text {
            padding-left: 3rem;
            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 1.4rem;
                height: 1.4rem;
                border-radius: 50%;
            }

            &::before {
                transform: translateY(-50%);
                border: 1px solid var.$color-main;
            }

            &::after {
                display: none;
                background-color: var.$color-main;
                transform: translateY(-50%) scale(0.6);
            }
        }
        input:checked + .form__label-text {
            &::after {
                display: block;
            }
        }
    }

    &__file-name {
        margin: 0;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1.1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 2rem;
        }
    }

    &__rating-text {
        margin: 0;
        margin-bottom: 2rem;
        color: #818da1;
        font-size: 1.6rem;
        line-height: 2rem;
    }

    &__rating-list {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        max-width: 37.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            justify-content: flex-start;
        }
    }

    &__rating-label {
        @include mix.breakpoint(var.$tablet-width) {
            padding: 0 0.5rem;

            &:last-child {
                padding-left: 0;
            }

            &:first-child {
                padding-right: 0;
            }
        }
        @media(hover:hover) {
            &:hover .form__rating-icon,
            &:hover ~ .form__rating-label .form__rating-icon {
                fill: var.$color-main;
            }
        }
    }

    &__rating-icon {
        display: block;
        width: 4rem;
        height: 4rem;
        stroke: var.$color-main;
        fill: transparent;

        @include mix.breakpoint(var.$tablet-width) {
            width: 2rem;
            height: 2rem;
        }
    }

    input[type="radio"]:checked ~ .form__rating-label .form__rating-icon {
        fill: var.$color-main;
    }

    &__attached-list {
        display: flex;
        margin-right: -1rem;
    }

    &__attached-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
        border: 1px solid #ebf0f5;
        border-radius: 4px;
        margin-right: 1rem;

        &._interactive {
            background-image: url("/img/mountains-dark.svg");
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__file-remove {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 0;
        background-color: transparent;
        border-radius: 50%;
        background: #EBF0F5;
        width: 2rem;
        height: 2rem;
        outline: none;
    }

    &__remove-icon {
        width: 1.2rem;
        height: 1.2rem;
        stroke-width: 2px;
        stroke: #fff;
    }

    &__attach-btn {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 2.5rem;
        font-weight: 400;
    }

    &__private-text {
        margin: 0 0.3rem;
        color: #898989;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: center;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 41.5rem;
            margin: 0 auto;
        }

        a {
            color: #4d4d4d;
            text-decoration: none;
            border-bottom: 1px solid currentColor;
        }
    }

    &__checkbox-label {
        position: relative;
        padding-left: 2.5rem;

        .form__label-text {
            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 1.4rem;
                height: 1.4rem;
            }

            &::before {
                background: #f9f9f9;
                border: 1px solid #ebf0f5;
                border-radius: 2px;
            }

            &::after {
                display: none;
                background-image: url("..//img/checkbox.svg");
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }


    input[type="checkbox"]:checked + .form__label-text {
        &::after {
            display: block;
        }
        &::before {
            background-color: var.$color-main;
            border-color: var.$color-text;
        }
    }

    &__count {
        position: relative;
        width: 12rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 10.8rem;
            margin-right: 3rem;
            margin-top: 1rem;
        }
    }

    .form__input {
        font-size: 1.6rem;
        line-height: 1.9rem;
        padding-top: 1.3rem;
        padding-bottom: 1.4rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        // text-align: center;
        border: 1px solid #e7e7e7;
        border-radius: 4px;
        width: 100%;
        outline: none;
        &:focus {
            outline: none;
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 0.8rem;
            padding-bottom: 0.9rem;
            font-size: 1.5rem;
        }
    }

    &__change-btn {
        position: absolute;
        top: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 0;
        background-color: #fff;
        width: 3.4rem;
        height: 4.6rem;
        color: var.$color-main;
        outline: none;

        @include mix.breakpoint(var.$tablet-width) {
            height: 3.4rem;
        }

        &._increase {
            right: 1px;
        }

        &._decrease {
            left: 1px;
        }
    }

    &__math-icon {
        stroke: currentColor;
        width: 1.4rem;
        height: 1.4rem;
    }

    &__select-btn {
        border: 0;
        padding: 0;
        background-color: transparent;
    }
}
/* critical:end */
