@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info {

    &.section {
        width: 100%;
        // max-width: 100%;
    }

    margin-bottom: 7rem;
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 3.5rem;
        // margin-bottom: 7.5rem;

    }
    &__inner {
        // @include mix.breakpoint(var.$desktop-width) {
        //     padding-left: 3rem;
        //     padding-right: 10rem;
        // }
        // @include mix.breakpoint(var.$desktop-lg-width) {
        //     padding-left: 0;
        //     padding-right: 0;
        // }
    }
    &__text {
        color: #9F9F9F;
        font-size: 1.4rem;
        line-height: 1.5;
        margin-top: 0;
        margin-bottom: 4rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 3rem;
        }
        ul{
            list-style: disc;
            padding-left: 2rem;
        }
    }
    &__text-wrap {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 6rem;
        }
    }

    .title {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4rem;
        }
    }
}
