.cert-page-v1 {

}

/** DEPENDS CODE */

.cert-page-v1 {
      .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

}

/** \DEPENDS CODE */