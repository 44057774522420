@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
svg {
   /* width: 1px;
    height: 1px;*/
}

.btn {
    display: inline-flex;
    padding: 1.5rem 1.5rem;

    text-decoration: none;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: center;

    border-radius: 4px;
    background-color: var.$color-main;
    color: var.$color-white;
    border: 0;
    outline: none;
    transition: all .3s;
    cursor: pointer;
    
    &._text-white {
        color: #fff;
    }

    &._white {
        background-color: var.$color-white;
        color: var.$color-text;
        border: 1px solid var.$color-white;
    }

    &._grey {
        /*background-color: #F5F7FA;*/
        background-color: var.$color-third;
        color: var.$color-text;
    }

    &._green {
        background-color: var.$color-second;
        color: var.$color-text;
    }

    &._border {
       border: 1px solid var.$color-white;
       padding: 1.3rem 1.5rem;
    }

    &._dashed {
        border: 2px dashed #fff;
    }

    &._white._dashed {
        border-color: var.$color-main;
    }

    &._white._border {
        border-color: currentColor;
    }

    &._full {
        width: 100%;
    }

    @media(hover:hover) {
        &:hover {
            background-color: var.$color-text;
            color: #fff !important;
        }

        &._border:hover {
            background-color: #fff;
            color: var.$color-main !important;
        }

        &._grey:hover {
            background-color: var.$color-main;
            color: #fff !important;
        }

        &._green:hover {
            color: #fff !important;
        }

        &._white:hover {
            background: #9591D4;
            // background: rgba(255, 255, 255, 0.2);
            color: var.$color-white !important;
            border-color: #fff;
        }
    }

    @include mix.breakpoint(var.$tablet-width) {
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 1.7rem 2rem;
    }

    &[disabled] {
      background-color: var.$color-main;
      color: var.$color-white;
      opacity: 0.7;
      cursor: default;

      &:hover {
        background-color: var.$color-main;
        color: var.$color-white !important;
      }
    }
}
/* critical:end */
