@use 'base/variables' as var;
@use 'base/mixins' as mix;

.wysiwyg {
    font-size: 1.4rem;
    line-height: 1.5;
    color: #383838;
    p {

        margin-bottom: 1.5rem;
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 3rem;
        }
    }

    &__hidden {
        display: none;
        margin-top: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 2rem;
        }
    }

    ul{
        list-style: disc;
        margin-left: 2rem;
        color: inherit;
    }
}
