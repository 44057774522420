@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-list {
    &__item {
        position: relative;
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        &._removed {
            .remove-shim {
                visibility: visible;
                opacity: 1;
            }
        }

        &._favorite {
            .remove-shim .favorite-btn__icon{
                fill: var.$color-main;
                stroke: var.$color-main;
            }
        }
    }

    &__item-wrap {
        border: 1px solid #ebf0f5;
        border-radius: 0.4rem;
        margin-bottom: 1rem;

        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 0;
        }
    }

    &__row {
        position: relative;
        display: flex;
        align-items: flex-start;
        // margin-bottom: 1rem;
        padding: 2rem 1rem;
        padding-top: 1.6rem;
        padding-left: 1.6rem;
        padding-right: 3.5rem;
        @include mix.breakpoint(var.$mobile-width) {
            padding-right: 4.5rem;

        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 2rem 1rem;
            padding-bottom: 0;
            padding-right: 4rem;
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            padding: 0.5rem;
            padding-right: 4rem;
            margin-right: auto;
            margin-bottom: 0;
            position: static;
        }
    }

    &__img-wrap {
        position: relative;
        width: 12.4rem;
        flex-shrink: 0;
        margin-right: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 18.5rem;
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 19rem;
        }
    }

    &__img {
        width: 100%;
        max-width: none;
        display: block;
    }

    &__content {
        @include mix.breakpoint(var.$tablet-width) {
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            display: flex;
            align-items: center;
            flex-grow: 1;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        @include mix.breakpoint(var.$desktop-laptop-width) {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }

    &__text-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-direction: column;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            display: block;
            margin-right: auto;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            // width: 48rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 0;
        }
    }

    &__sale-wrap {
        position: absolute;
        top: 0;
        left: 0;
        @include mix.breakpoint(var.$tablet-h-width) {
            top: 1rem;
            left: 0.5rem;
        }

        .sale-label {
          margin-right: 4px;
          padding-top: 0.3rem;
          padding-bottom: 0.5rem;

          &-promo {
            background: #F46B5F;
          }
        }
    }

    &__price {
        order: -1;
        // margin-bottom: 1.5rem;
        // @include mix.breakpoint(var.$tablet-width) {
        //     margin-bottom: 0;
        // }
        @include mix.breakpoint(var.$tablet-width) {
            order: initial;
        }
    }

    &__title {
        margin: 0;
        margin-bottom: .5rem;
        font-weight: 400;
        line-height: 1.3;
        font-size: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0.7rem;
        }
        a {
          text-decoration: none;
          color: #3c3c70;
        }
    }

    &__set-number {
        margin: 0;
        margin-bottom: .5rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #87919c;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.2rem;
            margin-bottom: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0.5rem;
        }
    }

    &__build {
        display: inline-block;
        margin-bottom: 1.5rem;
        color: #87919c;
        font-size: 1.2rem;
        line-height: 1.1;
        @include mix.breakpoint(var.$tablet-width) {
            order: 4;
            margin-bottom: 2.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 1.8rem;
            order: initial;
        }

        ._bold {
            font-weight: 500;
        }
    }

    &__select {
        margin-bottom: 1rem;
        border: 1px solid #e7e7e7;
        border-radius: 4px;

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
            width: 15.3rem;
            margin-right: 2rem;
        }

        .select__face {
            padding-top: 1.3rem;
            padding-bottom: 1.3rem;
        }
    }

    &__favorite {
        position: absolute;
        top: .8rem;
        right: .5rem;
        @include mix.breakpoint(var.$mobile-width) {
            right: .8rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            top: 1rem;
            right: 1rem;
        }
    }

    &__remove {
        position: absolute;
        bottom: .8rem;
        outline: none;
        right: .5rem;
        @media(hover:hover) {
            &:hover {
                .remove-btn__icon {
                    fill: var.$color-main;
                }
            }
        }
        @include mix.breakpoint(var.$mobile-width) {
            right: .8rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            // bottom: 0;
            // top: .5rem;
            // transform: translateY(50%);
            right: 1rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            // top: initial;
            bottom: 1rem;

        }
    }

    &__delivery {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 1.6rem;
        border-top: 1px solid #E6E6E6;
        margin-right: 1.6rem;
        margin-left: 1.6rem;
        padding-left: 1rem;
        @include mix.breakpoint(var.$mobile-width) {
            padding-left: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 0;
            margin-right: 0;
            border: 0;
            padding-top: 0;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            flex-direction: row;
            align-items: center;
            margin-right: -2rem;
        }
    }

    &__delivery-text {
        // display: flex;
        // align-items: center;
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        line-height: 1.1;
        color: #818da1;
        // padding-left: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            // padding-left: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0.5rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-bottom: 0;
            margin-right: 2rem;
            width: auto;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__delivery-title {
        width: 65%;
        text-align: right;
        margin-right: 1rem;
        @include mix.breakpoint(var.$mobile-width) {
            width: 72%;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
        }
    }

    &__delivery-value {
        width: 35%;
        @include mix.breakpoint(var.$mobile-width) {
            width: 28%;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
        }
    }

    &__highlight {
        color: var.$color-text;
    }

    &__hint {
        margin-left: 0.5rem;

        svg {
            color: var.$color-text;
        }
    }

    &__input-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 1.6rem;
        padding-right: 4rem;
        margin-bottom: 2.2rem;

        @include mix.breakpoint(var.$mobile-width) {
            padding-right: 6.3rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
            justify-content: flex-start;
            padding-left: 21.5rem;
            padding-bottom: 2rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            flex-shrink: 0;
            padding: 0;
            position: static;
            flex-grow: 1;
            max-width: 40.5rem;
            margin-left: 2rem;
            flex-shrink: 1;
        }
        @include mix.breakpoint(var.$desktop-width) {
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-left: 2rem;
            max-width: 50.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-left: 1.5rem;
            flex-shrink: 0;
        }
    }

    &__gift {
        padding: 1.2rem 1rem;
        padding-right: 4rem;
        display: flex;
        align-items: center;
        background-color: #f9f9f9;
        border-radius: 4px;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 0;
            padding-left: 2.6rem;
            padding-right: 4rem;
        }
    }

    &__gift-wrap {
        width: 9.2rem;
        flex-shrink: 0;
        margin-right: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 10.8rem;
        }
    }

    &__gift-name {
        margin: 0;
        line-height: 1.3;
        @include mix.breakpoint(var.$tablet-width) {
            width: 18.5rem;
            margin-right: 3.7rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            width: auto;
            max-width: 50rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 48rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-right: 2rem;
        }
    }

    &__gift-count {
        margin: 0;
        margin-right: 4.3rem;
        font-size: 1.5rem;
        line-height: 1.9rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 24rem;
            margin-left: auto;
            text-align: center;
        }
    }

    &__gift-icon {
        width: 4rem;
        height: 4rem;
        margin-right: 4.4rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-right: 3.4rem;
        }
    }

    &__gift-price {
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 15rem;
            margin-left: auto;
            margin-right: 5rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: auto;
            margin-left: 2rem;
            margin-right: 0;
        }
        @include mix.breakpoint(var.$desktop-notebook-width) {
            margin-right: 5rem;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 22rem;
        }
    }

    .form__count {
        width: 9rem;
        @include mix.breakpoint(var.$mobile-width) {
            width: 10.7rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin: 0;
            margin-right: 2rem;
            width: 12rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-right: 6rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            margin-right: 6rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 8rem;
        }

        .form__input {
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            text-align: center;
        }
    }

    &__desktop-row {
        @include mix.breakpoint(var.$tablet-h-width) {
            display: flex;
            position: relative;
        }
    }

    .form__change-btn {
        height: 4.3rem;
    }

    .select {
        &__dropdown-inner {
            height: 25rem;
        }
    }
}
