@use 'base/variables' as var;
@use 'base/mixins' as mix;

.buy-block-v1 {
    display: flex;
    flex-direction: column;

    &__action-buttons {
        .icon-button {
            cursor: pointer;
        }

        svg {
            transition: all 0.3s;
        }

        .icon-button[data-add-to-wishlist-v1-is-in-wishlist="1"] svg{
            stroke: var.$color-text;
            fill: var.$color-text;
        }

        .icon-button[data-add-to-wishlist-v1-is-in-wishlist="0"] svg {
            fill: none;
        }

        .icon-button[data-add-to-compare-v1-is-in-compare="0"] svg {
            stroke-width: 1.5;
        }

        .icon-button[data-add-to-compare-v1-is-in-compare="1"] svg {
            transition: all 0.3s;
            stroke: var.$color-text;
            fill: var.$color-text;
            stroke-width: 2.5;
        }
    }

    &__buy-container-row {
        margin: 0.8rem 0;
        display: flex;
    }

    &__equal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.2rem;
        padding-right: 1.2rem;
    }

    &__column {
        flex: 1;

    }

    .payment-info,
    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }


    .payment-button {
        background-color: white;
        color: black;
        border: 1px solid #ccc;
        border-radius: 20px;
    }

    .icon-button {
        width: 4.8rem;
        height: 4.8rem;
        padding: 1.4rem;
        border-radius: 0.6rem;
        gap: 0.4px;
        background: var.$color-gray-tone-13;
        color: var.$color-text;
    }

    &__buy-one-click {
        font-family: var.$font-text;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;
        text-align: center;
        text-decoration: underline;
    }
}

// Depends code
.buy-block-v1 {
    .share-v1 {
        width: 4.8rem;
        height: 4.8rem;
        padding: 1.4rem;
        border-radius: 0.6rem;
        gap: 0.4px;
        background: var.$color-gray-tone-13;
        color: var.$color-text;;
    }
}

// \Depends code