@use 'base/variables' as var;
@use 'base/mixins' as mix;

.certificate {
    margin-bottom: 2.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 6rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14rem;
    }
    &__inner {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
        }
    }

    &__content {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            // margin-bottom: 3.5rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            width: 52rem;
            padding-top: 8rem;
        }
    }
    &__title {
        margin: 0;
        margin-bottom: 4rem;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 3.5rem;
        }
    }

    &__text {
        margin: 0;
        margin-bottom: 2.3rem;
        font-size: 1.8rem;
        line-height: 1.3;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__wrap {
        &._security {
            margin-bottom: 6rem;
        }

        &._list {
            margin-bottom: 3rem;
            .certificate__title {
                margin-bottom: 3rem;
            }
        }

        &._img {
            width: 100vw;
            margin-left: -1.5rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            &._security {
                width: 33rem;
                margin-right: 5.4rem;
                flex-grow: 1;
                margin-bottom: 3.5rem;
            }

            &._list {
                width: 34rem;
                flex-grow: 1;
                margin-bottom: 3.5rem;
            }

            &._img {
                width: 100%;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            &._security {
                width: 100%;
                margin-right: 0;
                margin-bottom: 9rem;
                .certificate__title {
                    max-width: 45rem;
                }
            }

            &._list {
                width: 42rem;
                margin-bottom: 0;
                .certificate__title {
                    max-width: 34rem;
                }
            }

            &._img {
                width: 72rem;
                margin-right: -5.5rem;
                margin-left: auto;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            &._img {
                margin-right: 0;
            }
        }
    }

    &__img {
        display: block;
        margin: 0 auto;
    }

    &__list {
        padding-left: 1.7rem;
    }

    &__item {
        position: relative;
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
        line-height: 1.3;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 2rem;
        }
    }

    &__item-num {
        position: absolute;
        left: -1.8rem;
        top: 0;
        font-weight: 500;
        line-height: 1.5;
    }
}

.certificate-subtitle {
  font-size: 1.6rem !important;
  @include mix.breakpoint(var.$tablet-width) {
      font-size: 2.4rem !important;
  }
  @include mix.breakpoint(var.$desktop-width) {
      font-size: 3.2rem !important;
  }
}
