@use 'base/variables' as var;
@use 'base/mixins' as mix;

.articles {
    &__top-wrap {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6.4rem;
        }

        .title {
            margin-bottom: 0;
        }

    }

    &__show-all {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
            margin-left: 2rem;
        }
    }

    &__list {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            margin-right: -2rem;
        }
    }

    &__item {
        margin: 0 0.75rem;
        width: 27.5rem;
        display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            margin: 0 1rem;
            width: 30.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin: 0;
            margin-right: 2rem;
            width: calc(100% / 4 - 2rem);
        }
    }

    &__link {
        text-decoration: none;
        color: var.$color-text;
        outline: none;

    }

    &__img {
        border-radius: 4px;
        display: block;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2rem;
            // max-width: none;
        }
    }

    &__category {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #818da1;
        margin: 0;
        margin-bottom: 0.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: 1rem;
        }
    }

    &__title {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        margin: 0;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            max-width: 23rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 25rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    }
}
