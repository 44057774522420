@use 'base/variables' as var;
@use 'base/mixins' as mix;

.pickup {
    margin-bottom: 4rem;
    .title {
        max-width: 50%;
        padding-right: 1rem;
        margin: 0;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6rem;
        }
    }

    &__select {
        width: 50%;
        .select__dropdown {
            width: calc(100vw - 3rem)!important;
            right: 0;
            left: initial;
        }

        .select__option-content {
            text-align: left;
        }
    }

    .select__face {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__map {
        height: 34rem;
        // display: none;
        @include mix.breakpoint(var.$tablet-width) {
            height: 38.5rem;
            width: 43.3rem;
            flex-grow: 1;
            margin-right: 2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 84.7rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 95.2rem;
        }
    }
    &__contact-map {
        // display: none;
        height:30rem;

        @include mix.breakpoint(var.$tablet-width) {
            width: 100%;
            flex-grow: 1;
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 100%;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 100%;
        }
    }

    &__contacts {
        position: relative;
        @include mix.breakpoint(var.$tablet-width) {
            max-height: 38.5rem;
            width: 27.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 42rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 46.7rem;
        }
    }

    &__content {
        border: 1px solid #EBF0F5;
        border-radius: 2px;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            border: 0;
        }
    }

    .map-data {
        display: flex;

        &__item {
            margin-right: 2rem;
        }
    }

    &__contacts-list {
        // display: flex;
        // flex-wrap: wrap;
        // padding: 2rem 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            // padding: 0;
            display: none;
            margin-top: 1.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 2.6rem;
        }
    }

    &__contacts-item {
        // margin-right: 2rem;
        // @include mix.breakpoint(var.$tablet-width) {
        //     margin-right: 0;
        // }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 3.8rem;
        }

    }

    &__address-list {
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: .8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2rem;
        }


    }

    &__address-item {
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2rem;
            border: 1px solid #EBF0F5;
            border-radius: 2px;
            padding: 2rem;
            padding-bottom: 3rem;
            padding-right: 4rem;
            position: relative;
            cursor: pointer;
            line-height: 1.2;
            &._active {
                .pickup__contacts-list {
                    display: block;
                }

                .select__icon {
                    transform: rotateX(180deg);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 2rem;
            &._active {
                .pickup__contacts-list {
                    display: flex;
                }
            }
        }
    }

    &__contacts-title {
        display: block;
        color: #A5A5A5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        // margin-bottom: 0.6rem;
    }

    &__contacts-value {
        font-size: 1.5rem;
        line-height: 1.9rem;
    }

    &__address {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        display: block;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            max-width: 26rem;
        }
    }


}
