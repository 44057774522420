@use 'base/variables' as var;
@use 'base/mixins' as mix;

.about-second {
    .title {
        margin-bottom: 1.5rem;
        max-width: 30rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5.5rem;
        .title {
            max-width: 33rem;

        }
    }
    @include mix.breakpoint(var.$desktop-sm-width) {
        .title {
            max-width: 35rem;
            margin-bottom: 2.5rem;
        }
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14.5rem;
        .title {
            max-width: 51.5rem;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 10rem;
    }

    &__inner {
        background-color: var.$color-main;
        border-radius: 2px;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            border-radius: 4px;
        }
    }

    &__content {
        padding: 2rem 1.5rem;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-direction: column;
            padding: 4rem 3rem;
            padding-right: 2.6rem;
            margin-bottom: 0;
            max-width: 54rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 70rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            padding-right: 5.2rem;
            max-width: none;

        }
    }

    &__text {
        color: #fff;
        font-size: 1.3rem;
        line-height: 1.3;
        margin-bottom: 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 1.5;
            margin-bottom: 2.5rem;
        }
    }

    &__btn {
        width: 16rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: auto;
            width: 17rem;
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    &__img-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            width: 34rem;
            flex-shrink: 0;
            margin-left: auto;
            align-self: flex-end;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 52rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 71rem;
        }
    }

    &__img {
        display: block;
        width: 100%;
        border-radius: 120px 4px 0 0;
        @include mix.breakpoint(var.$desktop-width) {
            border-radius: 175px 4px 0 0;
        }
    }
}
