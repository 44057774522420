@use 'base/variables' as var;

.modal-select-location-v1 {
    display: none;
    position: absolute;
    padding: 2rem;
    background-color: #fff;
    top: 5.2rem;
    box-shadow: 0 0 8px 0 #b59d9d;
    z-index: 12;

    &__city-select {
        font-family: var.$font-header;
        margin: 1rem 0;
        font-weight: 700;
    }

    &__city-list {
        a {
            text-decoration: none;
            padding: .5rem 0;
            font-size: 1.6rem;
            line-height: 1.5;
        }
        a.active {
            text-decoration: underline;
        }
    }

    &__city-other {
        margin-top: 2rem;
        text-decoration: underline;
        cursor: pointer;
    }
}

//deps
.modal-select-location-v1 {

}