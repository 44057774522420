@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tag-list-v13 {

    display: grid;
    grid-auto-flow: column;
    gap: 1.8rem;
    overflow: hidden;

    .slick-initialized &__item.slick-slide {
        display: grid;
        margin-right: 2rem;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-right: 0.8rem;
        }
    }

    &__item {
        &:hover, &_is-active {
            background-color: var.$color-main;
            color: var.$color-border;
            cursor: pointer;
        }
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.2;
        padding: 1.5rem 2.6rem;
        border-radius: 3rem;
        background-color: var.$color-main-tone2;
        display: block;
        text-decoration: none;
        white-space: nowrap;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.1rem 1.6rem;
        }

        &-select {
            position: relative;
            padding-right: 4.4rem;
            cursor: pointer;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                padding-right: 4rem;
            }

            &::after {
                position: absolute;
                content: '';
                width: 1.4rem;
                height: .8rem;
                right: 1.8rem;
                top: 0;
                bottom: 0;
                margin: auto;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxbDYgNiA2LTYiIHN0cm9rZT0iIzNDM0M3MCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=");

                @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                    right: 1.4rem;
                }
            }
        }
    }

    &__content {
        display: flex;
    }

    &__popup {

        position: relative;
        margin: 0 auto;
        width: 36rem;
        overflow: hidden;
        background: #fff;
        padding: 2.4rem;
        padding-top: 1.2rem;

        &__close {
            position: absolute;
            width: 1.6rem;
            height: 1.6rem;
            top: 3.5rem;
            right: 3.5rem;
            cursor: pointer;
        }

        &__close-icon {
            stroke: var.$color-main;
        }

        & .button-link-v1 {
            margin-top: 2.4rem;
        }

        & .catalog-filtered-grid__filter-block_is-open {
            padding-bottom: 0;
        }

        & .catalog-filtered-grid__filter-head-wrap {
            padding-top: 0.5rem;
        }

        & .catalog-filtered-grid__filter-head-title {
            text-align: center;
        }

        & .catalog-filtered-grid__filter-options {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            width: 100%;
        }

        & .catalog-filtered-grid__filter-runner-row {
            width: calc(100% - 1.6rem);
            margin: 0 auto;
            margin-top: 1rem;
            margin-bottom: 4rem;
        }

        & .top-filter__title {
            margin-top: 1rem;
            font-size: 1.4rem;
            font-weight: 600;
            color: #3C3C70;
            width: 100%;
        }

        & .top-filter__label {
            display: flex;
            height: 3.8rem;
            width: calc(50% - 0.4rem);
            flex-basis: calc(50% - 0.4rem);
            border-radius: 2rem;
            border: 0.1rem solid #7B75C9;
            font-size: 1.2rem;
            text-align: center;
            margin-bottom: 0.6rem;
            line-height: 1;
            justify-content: center;
            align-items: center;

            &_color {
                justify-content: start;
                padding-left: 1.1rem;
            }

            &_checked {
                background: #7B75C9;

                & .checkbox-v1__label {
                    color: #fff !important;
                }
            }

            & input,
            & .checkbox-v1__tooltip {
                display: none;
            }

            & .checkbox-v1__wrap {
                display: block;
            }

            & .checkbox-v1__label {
                width: 100%;
                color: #3C3C70;
                text-align: center;
                line-height: 1;
                white-space: normal;
                padding: 0;
                justify-content: center;
                align-items: center;
            }

            & .card-detail__colorbox {
                padding-bottom: 0;
                margin-right: 0.4rem;
                border-bottom: 0;
            }

            & .card-detail__colorbox div {
                width: 1.6rem;
                height: 1.6rem;
            }
        }

        & ._size {
            overflow-y: scroll;
        }
    }
}

// deps
.tag-list-v13 {
    .tag-list-item, .tag-list-item-v10 {
        margin-right: 1.8rem;
    }

    &__popup {
        .catalog-filtered-grid__filter-active-wrap, .catalog-filtered-grid__filter-content-wrap {
            display: block;
        }
        .tooltip-v1, .select2, .catalog-filtered-grid__filter-head-corner, .filter-applied-tags-v1__list {
            display: none;
        }
        // стили для оконо с типом чекбокс
        &.type-checkbox {
            .js-top-filter__label {display: none;}
            .checkbox-v1__label {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .catalog-filtered-grid__filter-option {
            display: flex;
            height: 3.8rem;
            width: calc(50% - .4rem);
            flex-basis: calc(50% - 0.4rem);
            border-radius: 2rem;
            border: .1rem solid #7b75c9;
            font-size: 1.2rem;
            text-align: center;
            margin-bottom: .6rem;
            line-height: 1;
            justify-content: center;
            align-items: center;
            padding-bottom: 0;
            cursor: pointer;
            overflow: hidden;
        }

        .catalog-filtered-grid__filter-option .checkbox-v1__wrap {
            max-width: 90%;
            overflow: hidden;
        }

        .top-filter__label_color {
            justify-content: start;
            padding-left: 1.1rem;
            .checkbox-v1__colorbox  {
                border: none;
            }
        }
    }

    &__popup .button-link-v1.js-apply-top-filter:hover {
        color: var.$color-white !important;
    }
}

// \deps
