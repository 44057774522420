@use 'base/variables' as var;
@use 'base/mixins' as mix;

.social-v2 {

    &__list {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fill, 3rem);
        column-gap: 2.4rem;
    }

    &__item {
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem;
    }
    
    &__item svg {
        display: grid;
        justify-self: center;
        align-self: center;
    }

    &__link {
        text-decoration: none;
        display: grid;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    &__item_wu {
        background-color: #58B547;
    }

    &__item_tg {
        background-color: #51A2DF;
    }

    &__item_vk {
        background-color: #5B80B7;
    }

    &__item_ok {
        background-color: #FB8615;
    }

    &__item_yt {
        background-color: #D62F09;
    }

    &__separator {
        transform: translateY(-3px);
    }


}
