@use 'base/variables' as var;
@use 'base/mixins' as mix;

.call {
    margin-bottom: 8rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 9rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.7rem;
    }

    &._alt {
        .call {
            &__inner {
                background-image: url("/img/maskot-call-mobile.svg");
                background-position: bottom -1px right 1.5rem;
                background-size: 8rem auto;
                @include mix.breakpoint(var.$mobile-width) {
                    background-size: 10.8rem 11.3rem ;
                    background-position: bottom -1px right 2.9rem;
                }
                @include mix.breakpoint(var.$tablet-width) {
                    background-size: 24.8rem 26rem;
                    background-position: bottom -1px right 2rem;
                    padding-right: 30rem;
                }
                @include mix.breakpoint(var.$desktop-width) {
                    background-size: 26.2rem 27.5rem;
                    background-position: bottom -2px right 11.5rem;
                }
            }

            &__title {
                @include mix.breakpoint( var.$tablet-width) {
                    margin-bottom: 2rem;
                    max-width: 41rem;
                }
                @include mix.breakpoint(var.$desktop-width) {
                    max-width: none;
                    margin-bottom: 3rem;
                }
            }

            &__desc {
                @include mix.breakpoint(var.$tablet-width) {
                    margin-bottom: 5rem;
                    max-width: 65rem;
                }
            }
        }
    }
    &__inner {
        background-color: var.$color-main;
        border-radius: 2px;
        color: var.$color-white;
        padding: 2rem 1.5rem;
        background-image: url("/img/mister-green.svg");
        background-repeat: no-repeat;
        background-position: bottom -1px right 1.5rem;
        background-size: 8rem auto;
        @include mix.breakpoint(var.$mobile-width) {
            background-size: 10rem 11rem;
            background-position: bottom -1px right 3.5rem;

        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 3rem;
            background-size: 17rem 18.7rem;
            background-position: bottom -2px right 5rem;
            background-image: url("/img/mister-green-tablet.svg");
        }
        @include mix.breakpoint(var.$desktop-width) {
            background-size: 25rem 27.3rem;
            background-position: bottom -2px right 10rem;
            padding-top: 3.7rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            background-position: bottom -2px right 29rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            background-position: bottom -2px right 20rem;
            padding-left: 6rem;
            padding-top: 4.5rem;
            padding-bottom: 4.5rem;
            padding-right: 6rem;
        }
    }

    &__publicoffer{
        margin-top: 1rem;

        .form__private-text{
            color: White;
            text-align:left;
            a{
                color: WHite;
            }
            max-width: none;
        }
    }

    &__title {
        margin-bottom: 1.5rem;
        // max-width: 20rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3.4rem;
            max-width: 65rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 1.5rem;
            max-width: 50rem;
            line-height: 1.2;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            max-width: 72.6rem;
            margin-bottom: 3.5rem;
        }
    }

    &__desc {
        font-size: 1.3rem;
        line-height: 1.3;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 1.5;
            max-width: 47rem;
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4rem;
            max-width: 48.5rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-bottom: 5rem;
        }
    }

    &__form {
        &._input-shown {
            .call__link {
                display: none;
            }

            .call__phone-input {
                display: block;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 38rem;
        }
    }

    &__link {
        color: var.$color-white;
        text-decoration: none;
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-weight: 700;
        margin-bottom: 2.5rem;
        margin-top: 1rem;
        display: inline-block;
        max-width: 16.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            order: 2;
            font-size: 2.3rem;
            line-height: 2.9rem;
            max-width: none;
            margin-top: 0;

            margin-bottom: 0;
        }
    }

    &__order {
        width: 16.3rem;
        display: block;
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding: 1.3rem 2rem;

        @include mix.breakpoint(var.$tablet-width) {
            order: 2;
            font-size: 1.6rem;
            line-height: 2rem;
            width: 17.1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 1.4rem;
            padding-bottom: 1.4rem;
        }
    }

    &__phone-input {
        padding: 1.2rem 1.3rem;
        padding-bottom: 1.3rem;
        background-color: #fff;
        border-radius: 4px;
        font-size: 1.5rem;
        line-height: 1.9rem;
        color: #818DA2;
        width: 16.3rem;
        border: 1px solid transparent;
        outline: 0;
        margin-bottom: 1.2rem;
        display: none;
        &._error {
            background-color: #FEFBFB;
            border-color:  #EEBEBA;
        }

        @include mix.breakpoint(var.$tablet-width) {
            order: 2;
            margin-bottom: 0;
            padding-top: 1.2rem;
            padding-bottom: 1.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 1.3rem;
            padding-bottom: 1.4rem;
        }
    }
}

