@use 'base/variables' as var;
@use 'base/mixins' as mix;

.feedback {
    margin-bottom: 4rem;
    .title {
        max-width: 20.4rem;
        margin-bottom: 4rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5rem;
        .title {
            max-width: 53.5rem;
            margin-bottom: 1.5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 10rem;
        .title {
            max-width: 72rem;
            margin-bottom: 3rem;
        }
    }

    &__inner {
        background-color: var.$color-main;
        border-radius: 4px;
        padding: 2rem 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem 2rem;
            padding-bottom: 4rem;
            background-image: url("/img/mister-green.svg");
            background-repeat: no-repeat;
            background-size: 23.2rem auto;
            background-position: bottom -1.5rem right 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            background-size: 29rem auto;
            background-position: bottom -1rem right 4rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            background-size: 32.8rem auto;
            background-position: bottom -1rem right 8.3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            padding-bottom: 3.5rem;
            background-position: bottom -3rem right 11.7rem;
            background-size: 35.6rem auto;
        }
    }

    &__desc {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
            max-width: 38.5rem;
            margin: 0;
            font-size: 1.4rem;
            line-height: 1.3;
            color: #fff;
            margin-bottom: 4.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 58.6rem;
            font-size: 1.6rem;
            line-height: 1.5;
            margin-bottom: 4rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 3.5rem;
        }
    }

    &__form {
        @include mix.breakpoint(var.$desktop-width) {
            width: 73.3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 77.2rem;
        }
    }

    // усилить
    &__form .feedback__text-inputs {
        .form__input {
            margin-bottom: 1.8rem;
            padding-left: 1.3rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            // margin-right: -2rem;
            margin-bottom: 2rem;
            .form__input {
                margin-bottom: 0;
                margin-right: 2rem;
                // width: calc(100% / 3 - 2rem);
                padding-left: 1.5rem;

                &._name,
                &._phone {
                    width: 20.8rem;
                    margin-right: 1.8rem;
                }
                &._email {
                    width: 23.4rem;
                    // flex-grow: 1;
                    margin-right: 0;
                }
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            .form__input {
                padding-left: 2rem;
                flex-grow: 1;
                &._name,
                &._phone {
                    width: 20.8rem;
                }
                &._email {
                    width: 28rem;
                }
            }
        }

    }

    &__bottom {
        @include mix.breakpoint(var.$tablet-width) {
            width: 43.5rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 68.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 100%;
            display: flex;
        }
    }

    &__btn-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            width: 20.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 28.2rem;
        }
    }

    &__submit-btn {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.9rem;
        padding-top: 1.2rem;
        padding-bottom: 1.3rem;
        margin-bottom: 1.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 1.2rem;
            padding-bottom: 1.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            width: 15.4rem;
            margin-bottom: 2rem;
        }
    }

    &__form-text {
        margin: 0;
        color: #EBF0F5;
        font-size: 1rem;
        line-height: 1.3;
        font-weight: 300;

        a {
            font-weight: 500;
            color: inherit;
            text-decoration: none;
            border-bottom: 1px solid currentColor;
        }
    }

    .form__input._comment {
        height: 17rem;
        margin-bottom: 3.5rem;
        padding-left: 1.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            height: 8rem;
            margin-bottom: 1rem;
            padding-left: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 43.4rem;
            height: 9.2rem;
            margin-bottom: 0;
            margin-right: 2rem;
            padding-left: 2rem;
            font-size: 1.5rem;
            line-height: 1.9rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 46rem;
        }

    }
}
