@use 'base/variables' as var;
@use 'base/mixins' as mix;

.stock-v2 {

    > * {
        display: inline;
        vertical-align: middle;
    }

}