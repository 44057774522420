@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-page-contact-v1 {


}

/** DEPENDS CODE */
.text-page-contact-v1 {
    .tag-list-v14 {
        margin-bottom: 6rem;
    }

}