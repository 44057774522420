@use 'base/variables' as var;
@use 'base/mixins' as mix;

.character-element-v1 {

    display: grid;
    grid-auto-flow: row;
    grid-template-rows: min-content min-content;

    &__value-row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: minmax(1.8rem, max-content) min-content;
        font-weight: 400;
    }

    &__value {
          font-weight: 400;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        font-size: 1rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$mobile-lg-width) {
        font-size: 1rem;
    }

    @include mix.breakpointRange(var.$mobile-lg-width, var.$mobile-2lg-width) {
        font-size: 1.2rem;
    }

    @include mix.breakpointRange(var.$mobile-2lg-width, var.$tablet-width) {
        margin-right: 1rem;
        font-size: 1.4rem;
    }

    &__value-row {

        vertical-align: top;
        gap: 0.8rem;

        > div {
            display: inline-flex;
            vertical-align: top;
        }

        margin-top: 1rem;

    }

    &__label-row {
        font-weight: 400;
        color: var.$color-gray;
        font-family: var.$font-text;
    }

    &__icon {

    }

}

.character-element-v1:last-child {
    margin-right: 0;
}