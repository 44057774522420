@use 'base/variables' as var;
@use 'base/mixins' as mix;

.brand-section {
    margin-bottom: 5rem;

    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: grid;
            grid-template-columns: 23rem auto;
            column-gap: 2rem;
            grid-template-areas: "img desc"
                                 "img info"
                                 "coll coll";
        }
        @include mix.breakpoint(var.$desktop-width) {
            grid-template-columns: max-content auto 19.8rem;
            column-gap: initial;
            grid-template-areas: "img desc info"
                                 "img coll info"
                                 "img coll info";
        }
        // @include mix.breakpoint(var.$desktop-max-width) {
        //     grid-template-columns: 29rem 79.5rem auto;
        // }
        &._withoutImage {
            grid-template-columns: auto;
            grid-auto-rows: auto min-content;
        }

        &._withVideo {
          grid-template-columns: max-content auto 28rem;
        }

        &._withoutImage._withVideo {
          grid-template-columns: max-content 32rem;
        }
    }

    &__img-wrap {
        width: 16.6rem;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            grid-area: img;
            width: 23rem;
            margin: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 29rem;
            margin-right: 3.6rem;
        }
    }

    &__img {
        display: block;
        width: 100%;
    }

    &__desc,
    &__info-value {
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: #818DA1;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2.1rem;
        }
    }

    &__video {
      margin-bottom: 2rem;
      @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 0;
      }
    }

    &__info-title,
    &__collection-title {
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    &__desc {
        /*margin-bottom: 2rem;*/
        @include mix.breakpoint(var.$tablet-width) {
            /*margin-bottom: 3rem;*/
            /*margin-top: 0.7rem;*/
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 12rem;
        }
    }

    &__info {
        display: flex;
        margin: 0 -1rem;
        margin-bottom: 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            grid-area: info;
            margin-bottom: 4.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin: 0;
            margin-top: 1.2rem;

        }
    }

    &__info-item {
        width: calc(100%/2 - 2rem);
        margin: 0 1rem;
        @include mix.breakpoint(var.$desktop-width) {
            width: auto;
            margin: 0;
            margin-bottom: 2rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__info-title {
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 1.5rem;
        }
    }

    &__collection {
        // display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            grid-area: coll;
            display: flex;
            flex-wrap: wrap;
            &._open {
                .brand-section__collection-list {
                    max-height: initial;
                }
                ._all-text {
                    display: none;
                }

                ._hide-text {
                    display: inline;
                }
            }
        }
        /*@include mix.breakpoint(var.$desktop-width) {
            margin-right: 12rem;
            margin-left: 3.6rem;
        }*/
    }

    &__collection-title {
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2.5rem;
            width: 100%;
        }
    }

    &__collection-line {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: flex-start;
        }
        // @include mix.breakpoint(var.$desktop-width) {
        //     margin-right: 16rem;
        // }
    }

    &__collection-list {
        width: 100vw;
        margin-left: -1.5rem;
        display: none;
        .slick-track {
            margin: 0 1.5rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-left: 0;
            margin-right: -1rem;
            margin-top: -1rem;
            overflow: hidden;
            max-height: 4.7rem;
            transition: max-height .3s;
        }
    }

    &__collection-item {
        margin-right: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 1rem;
        }
    }

    &__collection-link._grey {
        display: inline-block;
        padding: .9rem 1.5rem;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: 400;
        outline: none;
        @include mix.breakpoint(var.$tablet-width) {
            padding: .9rem 1.5rem;
        }
    }

    &__show-more {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: inline-block;
            margin-top: 0.7rem;
            flex-shrink: 0;
            font-size: 1.6rem;
            line-height: 1.2;
            color: #C4C4C4;
            text-decoration: none;
            border-bottom: 1px dashed #C4C4C4;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 1.2rem;
        }

        ._hide-text {
            display: none;
        }
    }
}
