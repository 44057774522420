@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popup-v1 {
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1041;

    &__bg {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1042;
        overflow: hidden;
        position: fixed;
        background: #0b0b0b;
        opacity: 0.8;
    }

    &__container {
        z-index: 1043;
        position: relative;
        overflow: hidden scroll;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}