@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-short,
.delivery-fully {
    h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.3rem;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }


    p {
        color: #818DA2;
        font-size: 1.6rem;
        line-height: 2rem;
        margin: 0;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: 1.8rem;
        }
    }

    p.delivery__text {
        margin-bottom: 0rem;
    }

    a {
        color: var.$color-main;
        text-decoration: none;
        font-size: 1.6rem;
        line-height: 2rem;
        // border-bottom: 1px solid currentColor;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }

    ul {
        padding-left: .8rem;
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            &:not(:last-child) {
                margin-bottom: 1.8rem;
            }
        }

        &._bold-dots li::before {
            font-weight: 700;
            color: var.$color-text;
        }

    }

    li {
        color: #818DA2;
        font-size: 1.6rem;
        line-height: 2rem;
        position: relative;
        &::before {
            content: '\00B7';
            position: absolute;
            left: -7px;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }

    b {
        font-weight: 700;
        color: var.$color-text;
    }

}

.delivery-fully {
    margin-bottom: 10rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
    }
    ul {
        padding-left: 2.3rem;
    }

    img {
        margin-bottom: 5rem;
        display: block;
    }

    h3 {
        margin-top: 3rem;
    }

    &__logo {
        max-width: 24.4rem;
    }
}

.delivery {
  &__nav {

    flex-direction: row;
    margin-bottom: 3rem;

    /*a {
      color: var.$color-text;
      text-decoration: none;
      font-weight: normal;
      font-size: 1.8rem !important;
      line-height: 2.3rem !important;
      @include mix.breakpoint(var.$tablet-width) {
          font-size: 2rem !important;
          line-height: 2.5rem !important;
      }

      &:first-child {
        margin-right: 4rem;
      }

    }*/
  }
}
