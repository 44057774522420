@use 'base/variables' as var;
@use 'base/mixins' as mix;

.header-wrap {

    background-repeat:no-repeat; 
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        background-image:
            // info-bar
            linear-gradient(to right, rgb(249, 249, 249), rgb(249, 249, 249)),
            // search-bar
            linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)),
            // search-border-bottom
            linear-gradient(to right, rgb(235, 240, 245), rgb(235, 240, 245));
        background-position:
            // info-bar
            0 0,
            // search-bar
            0 3.8rem,
            // search-border-bottom (3.8 + 5.1 (5.2 - 0.1))
            0 8.9rem;
        background-size:  
            // info-bar
            100% 3.8rem,
            // search-bar
            100% 5.1rem,
            // search-border-bottom
            100% 0.1rem;
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        background-image:
            // info-bar
            linear-gradient(to right, rgb(249, 249, 249), rgb(249, 249, 249)),
            // search-bar
            linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)),
            // search-border-bottom
            linear-gradient(to right, rgb(235, 240, 245), rgb(235, 240, 245));
        background-position:
            // info-bar
            0 0,
            // search-bar
            0 3.8rem,
            // search-border-bottom (3.8 + 6.3 (6.4 - 1))
            0 10.1rem;
        background-size:  
            // info-bar
            100% 3.8rem,
            // search-bar
            100% 6.3rem,
            // search-border-bottom
            100% 0.1rem;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        background-image:
            // info-bar
            linear-gradient(to right, rgb(249, 249, 249), rgb(249, 249, 249)),
            // search-bar
            linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)),
            // menu-border-top
            linear-gradient(to right, rgb(235, 240, 245), rgb(235, 240, 245)),
            // menu-bar
            linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)),
            // menu-border-bottom
            linear-gradient(to right, rgb(235, 240, 245), rgb(235, 240, 245));
        background-position:
            // info-bar
            0 0,
            // search-bar
            0 5.2rem,
            // menu-border-top (5.2 + 10)
            0 15.2rem,
            // menu-bar (5.2 + 10 + 0.1)
            0 15.3rem,
            // menu-border-bottom (5.2 + 10 + 0.1 + 6.9 (7.1 - 0.2 as borders, 
            //      because in normalize set boxing-sizing: border-box 
            //      for all elements (*, and pseudo))
            0 22.2rem;
        background-size:  
            // info-bar
            100% 5.2rem,
            // search-bar
            100% 10rem,
            // menu-border-top
            100% 0.1rem,
            // menu-bar
            100% 6.9rem,
            //menu-border-bottom
            100% 0.1rem;
    }

    &__content {

        display: grid;
        margin-left: auto;
        margin-right: auto;
        max-width: var.$container-width;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            padding: 0 var.$container-padding-x-md;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 0 var.$container-padding-x-lg;
        }
    
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            grid-template-areas: 
                "ib-content"
                "sb-logo"
                "mb-menu";
            grid-template-columns: 100%;
            grid-template-rows: 
                3.8rem
                5.2rem
                // place for table-mobile-menu
                0vh;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-template-areas: 
                "ib-content ib-content ib-content"
                "sb-logo sb-logo sb-action"
                "mb-menu mb-menu mb-menu";
            grid-template-columns: repeat(10, 10%);
            grid-template-rows: 
                3.8rem
                6.4rem
                // place for table-mobile-menu
                0vh;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-template-areas: 
                "ib-content ib-content ib-menu"
                "sb-logo sb-search sb-action"
                "mb-menu mb-menu mb-menu"
                "mb-submenu mb-submenu mb-submenu";
            grid-template-columns: repeat(10, 10%);
            grid-template-rows: 
                5.2rem
                10rem
                7.1rem
                0rem;
        }

    }


}