@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v7 {

    margin-bottom: 5rem;

    display: grid;
    grid-template-columns: 7% 15% calc(54% - 12rem) calc(24% - 2.8rem) 2.8rem;
    border: 0.1rem solid rgba(60,60,112,0.3);
    border-radius: 0.6rem;
    padding: 1.5rem;    
    column-gap: 3rem;
    align-items: center;
    justify-items: center;

    // position price-block right side
    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        grid-template-columns: 8% 15% calc(55% - 9rem) 22%;
    }

    // grid for mobile
    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        grid-template-columns: 10% 25% calc(65% - 4rem);
        column-gap: 2rem;
    }

    &__vm, &__rm {
        margin-top: 1rem;
        font-size: 1.3rem;
    }

}


/** DEPENDS CODE */
.product-card-v7 {

    &__badge-column {

        // badge-listand badge's
        > .badge-list-v1 {

        }
        > .badge-list-v1 .badge-v1 {
            height: 3.4rem;
            
            // change size badge for tablet
            @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
                height: 3.4rem;
            }
        }

        // change size icon for tablet
        > .badge-list-v1 .badge-v1__icon .icon-v1 {
            @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
                width: 2.4rem;
                height: 2.4rem;
            }
        }

        // change position badge for tablet
        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            justify-self: center;
        }


        > .badge-list-v1 .badge-v1__wrap {
            margin: auto 1rem;
        }
        > .badge-list-v1 .badge-v1__wrap > div {
            display: flex;
        }
        > .badge-list-v1 .badge-v1__label {
            font-size: 1.2rem;
            line-height: 1;
        }

    }

    &__image-column {

        .image-v1 {
            width: 10.3rem;
            height: 6.4rem;
        }

    }
    &__title-column {

        .link-v1 {
            font-family: var.$font-text;
            font-weight: 400;
            font-size: 1.6rem;
        }

    }
    &__price-column {

        justify-self: end;

        .old-price-v1 {
            font-size: 1.2rem;
            text-align: end;
        }
        .sale-price-v1 {
            font-size: 2.1rem;
        }

    // grid for mobile
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: none;
    }

    }
    &__action-column {

    }
    
}

/** \DEPENDS CODE */
