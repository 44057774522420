@use 'base/variables' as var;
@use 'base/mixins' as mix;

.agreement-v1 {
    
    font-weight: 400;
    font-size: 1rem;
    color: var.$color-gray; 
    text-align: center;
    padding-top: 2rem;   

}


/** DEPENDS CODE */

.agreement-v1 {
    
}

/** \DEPENDS CODE */
