@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-final-v1 {

    padding: 6rem 6.5rem;
    background: var.$color-main;
    color: var.$color-white;
    width: 100% !important;
    margin: 0 0 -3rem;
    position: relative;

    &__background {
        position: absolute;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        text-align: right;
        height: 100%;
    }

    &__title {
        margin-bottom: 3.5rem;
        position: relative;
        z-index: 1;
        color: var.$color-white;
        line-height: 1.1;
        font-family: var.$font-header;
    }

    &__btn:hover {
        background: var.$color-main-tone9;
        color: var.$color-white;
        border-color: var.$color-white;
    }

    &__btn {
        background-color: var.$color-white;
        color: var.$color-text;
        border: 1px solid var.$color-white;
        font-size: 3.6rem;
        padding: 3.2rem;
        line-height: 2rem;
        border-radius: 0.8rem;
        font-weight: 500;
        font-family: var.$font-text;
        cursor: pointer;
        transition: all .3s;
        position: relative;
        z-index: 1;
    }

    @include mix.breakpoint(var.$tablet-width) {

    }
    @include mix.breakpointRange(var.$zero-width, var.$custom-v1-width) {
        &__background {
            height: auto;
            width: 100%;
        }
    }
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        & {
            padding: 7rem 1.4rem;
        }
        &__title {
            width: 28rem !important;
            font-size: 2.4rem;
            text-align: left !important;
        }
        &__btn {
            width: 16rem;
            font-size: 1.4rem;
            padding: 1.6rem;
        }
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        & {
            padding: 7.7rem 5.5rem;
        }
        &__title {
            width: 57rem;
            font-size: 4rem;
        }
        &__btn {
            width: 55rem;
            font-size: 2.4rem;
            padding: 1.4rem;
        }
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        & {
            padding: 6rem 6.5rem;
        }
        &__title {
            width: 67rem;
            font-size: 4.8rem;
        }
        &__btn {
            width: 53rem;
            font-size: 3.6rem;
            padding: 3.2rem;
        }
    }

}

//deps
.questionnaire-final-v1 {

}
