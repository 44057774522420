@use 'base/variables' as var;
@use 'base/mixins' as mix;

.warranty {
    margin-bottom: 10.5rem;
    .title {
        margin-bottom: 3rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        .title {
            margin-bottom: 5.5rem;
        }
    }

    &__producer {
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-bottom: 5rem;
        }

    }

    &__title {
        margin: 0;
        margin-bottom: 2.5rem;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.5rem;

        @include mix.breakpoint(var.$tablet-width) {
            max-width: 39.5rem;
        }
    }

    &__producer-wrap {

        width: 100% !important;

        padding-top: 2.8rem;
        &._select {
            padding-bottom: 4rem;
            margin-bottom: 1.5rem;
        }

        &._desc {
            padding-bottom: 3rem;

            p {
                margin: 0;
                font-size: 1.4rem;
                line-height: 1.8rem;
                color: #fff;
                margin-bottom: 1.8rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            &._select {
                width: 48rem;
                flex-grow: 1;
                margin-right: 2rem;
                margin-bottom: 0;
            }

            &._desc {
                width: 23rem;
                flex-grow: 1;
                padding-right: 1.5rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 3rem;
            &._select {
                width: 73.6rem;
                min-height: 19.1rem;
                background-image: url("/img/bow-star.svg");
                background-repeat: no-repeat;
                background-position: top 3.6rem right 7.4rem;
                background-size: 13rem auto;
            }

            &._desc {
                width: 52.2rem;
                padding-right: 3rem;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding-left: 3rem;
            &._select {
                width: 83.2rem;
                background-position: top 3.6rem right 14rem;
            }

            &._desc {
                width: 58.8rem;
            }
        }

    }

    &__img {
        display: block;
        width: 15rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4.5rem;
        }
    }

    &__desc {
        // margin: 0;
        // font-size: 1.4rem;
        // line-height: 1.8rem;
        // color: #fff;
        // margin-bottom: 1.8rem;

        // &:last-child {
        //     margin-bottom: 0;
        // }
    }

    .form__input-item {
        position: relative;
        margin-bottom: 0;
        @include mix.breakpoint(var.$mobile-width) {
            width: 27.5rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0;
        }
    }

    .form__input {
        width: 100%;
        padding-top: 1.3rem;
        padding-bottom: 1.4rem;
        padding-left: 2rem;

        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #E7E7E7;
        border-radius: 4px;
        outline: none;
        &._error {
            background-color: #fefbfb;
            border-color: #eebeba;
        }
    }

    .select {
        &__option-content {
            text-align: left;
        }
    }

    &__more {
        h3 {
            margin-top: 3rem;
            margin-bottom: 1.5rem;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.5rem;
            &:first-child {
                margin-top: 0;
            }
        }

        p {
            margin: 0;
            margin-bottom: 1.8rem;
            color: #9F9F9F;
            font-size: 1.4rem;
            line-height: 1.5;
            &:last-child {
                margin-bottom: 0;
            }
        }
        ul{
            font-size: 1.4rem;
            list-style: disc;
            margin-left: 2rem;
            color: #9F9F9F;
            margin-bottom:2rem;
        }

        .certificate__item {
            margin-bottom: 0;
        }
    }

    &__more-wrap {
        margin-top: 3.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            padding-left: 4rem;
            margin-top: 5.5rem;
            margin-bottom: 5.5rem;
        }
    }
}
