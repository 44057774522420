@use 'base/variables' as var;
@use 'base/mixins' as mix;

.form-group-select-v1 {

    &__label {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.6rem;
        color: var.$color-gray;
        margin-bottom: 1rem;
    }

    &__select {

    }
}

/** DEPENDS CODE */
/** DEPENDS CODE */