@use 'base/variables' as var;
@use 'base/mixins' as mix;

.send-request {
    margin-bottom: 8rem;

    .title {
        color: #fff;
        margin-bottom: 3rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 9rem;
        .title {
            margin-bottom: 5rem;
            max-width: 47rem;
        }
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.4rem;
        .title {
            margin-bottom: 5.5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-lg-width) {
        margin-bottom: 14rem;
    }

    &__inner {
        background-color: var.$color-main;
        border-radius: 4px;
        padding: 4rem 3rem;
        padding-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 5rem 4rem;
            background-image: url("/img/mister-green-down.svg");
            background-repeat: no-repeat;
            background-position: top -1px right 8rem;
            background-size: 12.5rem 13.5rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            background-position: top -1px right 4rem;
            background-size: 25.2rem 27.1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            background-position: top -1px right 21rem;
            background-size: 25.2rem 27.1rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            padding: 5rem 6rem;
            background-position: top -1px right 28rem;
            background-size: 29.7rem 32rem;
        }
    }

    &__form {
        max-width: 64rem;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: flex-end;
            max-width: none;
        }
    }

    &__fields-wrap {
        @include mix.breakpoint(var.$desktop-width) {
            width: 51.6rem;
            margin-right: 3.2rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-right: 2rem;
        }
    }

    &__bottom-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: center;
        }
    }

    &__btn {
        border: 1px solid #fff;
        width: 100%;
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        line-height: 2rem;
        padding-bottom: 1.2rem!important;
        padding-top: 1.2rem!important;
        @include mix.breakpoint(var.$tablet-width) {
            width: 20.5rem;
            // margin-right: 2.6rem;
            flex-shrink: 0;
            margin-bottom: 0;
            order: 2;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            order: initial;
            margin-right: 2.6rem;
            width: 20rem;
        }
    }

    &__confirm-text {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #ADA8EA;
        text-align: center;
        margin: 0;
        a {
            color: #D8D5FF;
            text-decoration: none;
            border-bottom: 1px solid #D8D5FF;
        }
        @include mix.breakpoint(var.$tablet-width) {
            text-align: left;
            margin-right: 2.8rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 0;
            max-width: 41.4rem;
        }
    }

    &__attached-list {
        display: flex;
        margin-right: -1rem;
    }

    &__attached-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.6rem;
        height: 4.6rem;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        margin-right: 1rem;
        &._interactive {
            background-image: url("/img/mountains-icon.svg");
            background-repeat: no-repeat;
            background-position: center center;
            cursor: pointer;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__attach-btn {
        width: 100%;
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 1.1rem .5rem!important;
        font-weight: 400;
        @include mix.breakpoint(var.$tablet-width) {
            width: 34rem;
            // width: 42.6rem;
            margin-bottom: 0;
            flex-shrink: 0;
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 32.5rem;
            margin-right: 2rem;
            // width: 34.2rem;
        }
    }

    &__attach-wrap {
        align-items: center;
    }
}

/** Depends code */
.send-request {

    .form__attached-list .form__attached-item {
        background-color: #fff !important;
    }

    .form-group.field-contactform-phone {
        @include mix.breakpoint(var.$tablet-width) {
            width: 20.5rem;
            margin-right: 1.5rem;
        }
    }

    .field-b2brequestv1form-email {
        flex-grow: 1;
    }

    .form__input-item textarea {
        height: 11rem;
    }

}
/** Depends code */