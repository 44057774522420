@use 'base/variables' as var;
@use 'base/mixins' as mix;

.callback-button{
    display: none;
    @include mix.breakpoint(var.$tablet-width) {
        font-size: 1.2rem;
        text-align: left;
        cursor: pointer;
        displaY:block;
    }

    @include mix.breakpoint(var.$desktop-width) {
        font-size:  1.4rem;
    }

}
.callback__popup {
    width: 70rem !important;
    @include mix.breakpoint(var.$desktop-width) {
        width: 80rem !important;
    }
    .container{
        padding: 0 !important;
    }
    .call{
        margin: 0 !important;
    }
    .call__inner{
        background-size: 16rem 17.5rem !important;
        @include mix.breakpoint(var.$desktop-width) {
            background-position: bottom -1px right 2rem !important;
        }
    }
    .call__title{
        font-size: 3rem !important;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4rem !important;
        }

    }
}

