@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-block-v1 {

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: [icon] min-content [content] auto;

    &_text-color-type-text {
        color: var.$color-text;
    }
    
    &_text-color-type-gray {
        color: var.$color-gray;
    }

    &__icon {
        grid-area: icon;
        padding: 1.4rem 1rem 1rem 1rem;
        align-self: center;
        justify-self: center;
        background-color: var.$color-second-tone4;
        border-radius: 0.6rem;
        margin-right: 2rem;
    }

    &__content {
        grid-area: content;
        line-height: 1.775rem;
    }
    
}

/** DEPENDS CODE */
.info-block-v1 {
    
    &__content {
        
        p {
            margin-bottom: 1.5rem;
        }
        
        p:last-child {
            margin-bottom: 0;
        }
    }

}
/** DEPENDS CODE */