@use 'base/variables' as var;
@use 'base/mixins' as mix;

.distrib-advantages {
    overflow: hidden;
    margin-bottom: 9rem;

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14rem;
    }

    .title {
        margin-bottom: 5rem;
        max-width: 27rem;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 61rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 78.5rem;
            margin-bottom: 7rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin-top: -6rem;
        @include mix.breakpoint(var.$tablet-width) {
            flex-direction: row;
            margin-top: -9rem;
            margin-right: -2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -3rem;
        }
    }

    &__item {
        margin-top: 6rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 2 - 2rem);
            margin-right: 2rem;
            margin-top: 9rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 3rem);
            margin-right: 3rem;
        }
    }

    &__icon {
        margin-bottom: 1.5rem;
        &._sale {
            width: 6.3rem;
            height: 6.1rem;
        }
        &._thumb-up {
            width: 9rem;
            height: 5.4rem;
            margin-bottom: 2px;
        }
        &._bow-star {
            width: 6.3rem;
            height: 6.1rem;
            margin-top: -1rem;
            margin-bottom: 1rem;
        }
        &._transport {
            width: 10.8rem;
            height: 5.8rem;
            margin-left: -2rem;
            margin-bottom: 4px;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2.5rem;
            &._sale {
                width: 8.9rem;
                height: 8.5rem;
            }
            &._thumb-up {
                width: 12.5rem;
                height: 8.5rem;
                margin-top: 1rem;
                margin-bottom: 1.3rem;
            }
            &._bow-star {
                width: 8.8rem;
                height: 8.5rem;
                margin-top: 0;
                margin-bottom: 2.2rem;
            }
            &._transport {
                width: 15rem;
                height: 8.5rem;
                margin-left: -3rem;
                margin-top: 1rem;
                margin-bottom: 1.3rem;
            }
        }
    }

    &__title {
        line-height: 2rem;
        font-weight: 500;
        margin-bottom: 2rem;
        margin-top: 0;
        max-width: 20.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
            max-width: 25rem;
        }
    }

    &__text {
        margin: 0;
        font-size: 1.4rem;
        line-height: 1.3;
        color: #6B6B6B;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 29.6rem;
        }
    }
}
