@use 'base/variables' as var;
@use 'base/mixins' as mix;

.payee {
    overflow: hidden;
    margin-bottom: 4rem;
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6rem;
        .title {
            margin-bottom: 6.5rem;
        }
    }
    &__list {
        width: 100vw;
        margin-left: -1.5rem;
        display: flex;

        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            margin-left: 0;
            flex-wrap: wrap;
            margin-right: -1.5rem;
            margin-top: -1.5rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -2rem;
            margin-top: -2rem;
        }
    }
    &__item {
        background: #F5F7FA;
        border-radius: 2px;
        margin-right: 1.5rem;
        padding: 1rem 1.5rem;
        padding-right: 2rem;
        outline: none;
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 3 - 1.5rem);
            margin-top: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(100% / 4 - 1.5rem);
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 2rem);
            padding: 1.6rem 2rem;
            margin-right: 2rem;
            margin-top: 2rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: calc(100% / 5 - 2rem);
        }
    }
    &__item.address {
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(100% - 1.5rem) !important;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% - 2rem) !important;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: calc(40% - 2rem) !important;
        }
    }
    &__subtitle {
        margin: 0;
        margin-bottom: 0.6rem;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #A5A5A5;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 2rem;

        }
    }

    &__value {
        margin: 0;
        font-size: 1.5rem;
        line-height: 1.9rem;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

    .slick-track {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}
