@use 'base/variables' as var;
@use 'base/mixins' as mix;

.pagination-summary-v1 {
    font-family: var.$font-text;
    font-weight: 600;
    font-size: 1.5rem;
}

/** DEPENDS CODE */
.pagination-summary-v1 {
}
/** DEPENDS CODE */