@use 'base/variables' as var;
@use 'base/mixins' as mix;

.answers {
    &__top-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4rem;
        .title {
            margin-bottom: 0;
            padding-right: 56px;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 5.8rem;
        }
    }

    &__btn {
        width: 13rem;
        margin-left: 1rem;
        flex-shrink: 0;
        @include mix.breakpoint(var.$mobile-width) {
            width: 16.3rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: 17.2rem;
        }
    }

    &__item {
        border-bottom:  1px solid #E3E3E3;
        // transition: all .5s;
        &:first-of-type {
            border-top: 1px solid #E3E3E3;
        }

        &._active {
            .answers__title {
                background-color: var.$color-text;
                color: var.$color-white;
            }
            .answers__text-wrap {
                height: var(--calc-height);
                // max-height: 50rem;
                // transition: max-height 5s cubic-bezier(0, 1, 1, 1);
                // @include mix.breakpoint(var.$desktop-width) {
                //     max-height: 30rem;
                // }
            }

            .answers__item-icon {
                transform: translateY(-50%) rotateX(180deg);
                stroke: #fff;
            }
        }
    }

    &__item-icon {
        width: 2rem;
        height: 1rem;
        stroke: var.$color-main;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
        transition: all .3s;
        @include mix.breakpoint(var.$tablet-width) {
            right: 2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            right: 3rem;
        }
    }

    &__title {
        border: 0;
        padding: 0;
        background-color: transparent;
        font-size: 1.4rem;
        line-height: 1.5;
        position: relative;
        padding: 2rem 1.5rem;
        padding-right: 5.7rem;
        outline: none;
        text-align: left;
        width: 100%;
        // transition: padding .5s;
        // transition-delay: .1s;


        @include mix.breakpoint(var.$tablet-width) {
            padding: 2rem;
            padding-right: 10.5rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            padding: 3.5rem 2.5rem;
            padding-right: 6rem;
        }
    }

    &__text {
        // overflow: hidden;
        // transition: height 500ms ease;
        // display: none;
        margin: 1.5rem;
        // margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 1.5;
        &:last-child {
            margin-bottom: 2rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.5;
            margin: 2rem;
            margin-right: 6.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 1.3;
            margin: 2.5rem;
            margin-right: 6.3rem;
            &:last-child {
                margin-bottom: 3.5rem;
            }
        }
    }

    &__text-wrap {
        overflow: hidden;
        height: 0;
        transition: .3s height;
        // transition: max-height .5s cubic-bezier(0, 0, 0, 1);
        background-color: #EBF0F5;
    }

    &__popup {
        .form .form__input-item._comment {
            margin-bottom: 4rem;
        }
    }

    &__more {
      text-align: center;
      margin-top: 3rem;

      .btn {
        position: relative;
        width: 100%;
        @include mix.breakpoint(var.$desktop-width) {
          max-width: 46rem;
        }

        &._load {
          ._text {
           visibility: hidden;
          }
          .button-preloader {
            display: block;
          }
        }
      }
    }
}
