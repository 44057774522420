@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-row-v2 {
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr;
    justify-content: space-between;
    align-items: center;
}


/** DEPENDS CODE */
.receipt-row-v2 {
    
    &__value {
        font-family: var.$font-text;
        font-weight: 700;
        color: var.$color-main;
        font-size: 1.8rem;
    }

    &__subvalue {

        font-family: var.$font-text;
        font-weight: 700;
        text-align: right;
        color: var.$color-main;
        white-space: nowrap;

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-size: 2.1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-size: 3rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 3rem;
        }
    }

}

/** \DEPENDS CODE */
