@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-short {
    margin-bottom: 5rem;
    .title {
        margin-bottom: 2.5rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 6rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 8rem;
        .title {
            margin-bottom: 4rem;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 3.5rem;
        .title {
            margin-bottom: 5.5rem;
        }
    }
    &__list {
        @include mix.breakpoint(var.$tablet-width) {
            display: grid;
            grid-template-areas: "del    pay"
                                 "pickup pay";
                                //  "back   pay";
            margin-top: -2.5rem;
            // margin-right: -3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 0;
            justify-content: space-between;
            grid-template-areas: "del    pay"
                                 "pickup pay";
            column-gap: 5rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            column-gap: 20rem;
        }
    }

    &__wrap {
        margin-top: 3rem;
        &:first-child {
            margin-top: 0;
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 2.5rem;
            margin-right: 3rem;

            &:first-child {
                margin-top: 2.5rem;
            }

            &._delivery {
                grid-area: del;
            }
            &._pickup {
                grid-area: pickup;
            }
            &._back {
                grid-area: back;
            }
            &._pay {
                grid-area: pay;
                min-width: 35vw;
                margin-right: 0;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            max-width: none;
            margin-right: 0;
            // max-width: 30.5rem;
            // margin-right: 8rem;
            &._pickup {
                margin-top: 0;
            }
            &._pay {
                min-width: 30vw;
            }
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            // margin-right: 16rem;
        }
    }
}
