@use 'base/variables' as var;
@use 'base/mixins' as mix;

.thin-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(129, 141, 161, 0.5) #fff;
    &::-webkit-scrollbar {
        width: 0.4rem;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(129, 141, 161, 0.5);
        border-radius: 10px;
    }
}