@use 'base/variables' as var;
@use 'base/mixins' as mix;

.gallery-v3 {

    position: relative;

    &__page-controller {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }
    &__overlay {
        cursor: pointer;
        flex-grow: 1;
    }

    &__track_dots {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, minmax(0, auto));
        column-gap: 0.2rem;
        width: 100%;
        margin: auto;
        position: absolute;
        bottom: -0.8rem;
        z-index: 2;
        height: 0.4rem;
    }

    &__track-element {
        height: 0.4rem;
        width: 0.4rem;
        background: var.$color-gray-tone10;
        border-radius: 1.2rem;
        cursor: pointer;
    }

    &__track-element:hover {
        background: var.$color-gray-tone9;
    }

    &__track-element_active {
        background: var.$color-text;
        background: var.$color-gray-tone9;
    }

    &__current-image {

    }

    &__slide_wrapper img{
        margin: auto;
    }
}

/** DEPENDS CODE */
.gallery-v3 {
    .slick-track {
        display: flex;
    }

    .slick-track .slick-slide {
        // width: 100%;
        flex-shrink: 0;
        flex-grow: 1;
    }

    .badge-list-v1 {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
    }

    .badge-list-v1 .badge-v1 {
        position: absolute;
    }

    .badge-list-v1 .badge-v1.badge-v1_position-type-bottom-left {
        left: 0;
        bottom: 0;
    }

    .badge-list-v1 .badge-v1.badge-v1_position-type-bottom-right {
        right: 0;
        bottom: 0;
    }

    .badge-list-v1 .badge-v1.badge-v1_position-type-top-left {
        left: 0;
        top: 0;
    }

    .badge-list-v1 .badge-v1.badge-v1_position-type-top-right {
        right: 0;
        top: 0;
    }
}

/** \DEPENDS CODE */
