@use 'base/variables' as var;
@use 'base/mixins' as mix;

.box-v1 {
    padding: 1rem 2rem;
    border-radius: 0.6rem;
}


.box-v1_filling-type-contained.box-v1_filling-color-type-gray {
    background-color: var.$color-third;
}

.box-v1_filling-type-outlined {
    border: 0.1rem solid rgba(var.$color-black-tone4, 0.3);
}