.compare-page-v1 {
    .compare__inner {
        margin-bottom: 6rem;
    }


}

//deps
.compare-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        margin-bottom: 3.5rem;
    }

    .title-v2 {
        margin-bottom: 6rem;
    }

    .title-v1 {
        font-size: 4rem;
    }

    .product-grid-v1 {
        margin-bottom: 14rem;
    }
}