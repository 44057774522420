@use 'base/variables' as var;
@use 'base/mixins' as mix;

.showroom-v2 {

    border-radius: 8rem 0 0 0;
    background: #fff;
    height: 100%;

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        border-radius: 6rem 0 0 0;
    }

    &::after {
        background: #fff;
        position: absolute;
        bottom: 0;
        content: '';
        display: block;
        height: 6rem;
        width: 100%;
        z-index: -1;

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-lg-width - 1)) {
            width: 41rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            width: 46rem;
        }
    }

    &__wrapper {
        display: block;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            flex-direction: row;
            flex-wrap: nowrap;
            width: max-content;
        }

        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin-left: 41rem;
            margin-bottom: 8rem;
        }
    }

    &__city {
        display: none;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        width: 4.4rem;
        height: 4.4rem;
        margin-right: 0.8rem;
    }

    &__images {
        height: 25.3rem;
        border-radius: 0.6rem;
        // overflow: hidden;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            display: block;
        }
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 4rem;
        padding-bottom: 2.6rem;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            padding-right: 0;
            padding-left: 0;
        }

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-sm-width - 1)) {
            padding-top: 2rem;
        }
    }

    &__info {
        position: relative;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 100%;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 2.4rem;
        line-height: 3rem;
        color: #3C3C70;
        margin-bottom: 4rem;
        order: 1;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            font-size: 2rem;
            line-height: 2.4rem;
            margin-bottom: 1.6rem;
        }
    }

    &__contacts {
        order: 2;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            min-height: 6rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            min-height: 8.6rem;
        }
    }


    &__address {
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: #3C3C70;
        margin-bottom: 0.8rem;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            font-size: 1.4rem;
            line-height: 2.6rem;
        }

    }

    &__phone {
        color: #3C3C70;
        font-size: 1.8rem;
        line-height: 2.8rem;
        margin-bottom: 0.8rem;
        order: 3;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            font-size: 1.4rem;
            line-height: 2rem;
            min-height: 1.8rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            min-height: 2.8rem;
        }

        & .phone-number {
            text-decoration: none;
        }
    }

    &__button {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: #3C3C70;
        text-decoration: underline;
        margin-bottom: 0.8rem;
        order: 4;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            font-size: 1.4rem;
            line-height: 2rem;
        }

    }

    &__label {
        background: #bee6a8;
        display: inline-block;
        border-radius: 0.4rem;
        padding: 0.2rem 0.8rem;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.4rem;
        height: 2rem;
        vertical-align: middle;
        margin-left: 0.8rem;
        display: none;
    }

    &__schedule {
        font-weight: 700;
        font-family: Affect;
        font-size: 2.1rem;
        line-height: 2.5rem;
        color: #3C3C70;
        margin-bottom: 0.8rem;
        order: 5;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
    }

    &__row {
        display: block;
    }

    &__slider {
        width: 100%;
        position: relative;
    }

    &__slide {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 100%;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-sm-width - 1)) {
            width: calc(100% / 2 - 1.2rem);
        }

        @include mix.breakpointRange(var.$desktop-sm-width, (var.$desktop-width - 1)) {
            width: calc((100% - 2 * 2.4rem) / 3);
        }

        @include mix.breakpointRange(var.$desktop-width, (var.$desktop-lg-width - 1)) {
            width: 41rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            width: 46rem;
        }
    }

    &__wrap > &__row > &__slider > &__list > .slick-list > .slick-track > .slick-slide {
        margin-right: 2.4rem;
    }

    &__list {
        width: 100%;

        &:not(.slick-initialized) {
            display: flex;
            gap: 2.4rem;
            justify-content: space-between;
        }

        &.slick-initialized {
            @include mix.breakpointRange(var.$zero-width, (var.$desktop-sm-width - 1)) {
                width: calc(100% + 1.5rem);
            }
        }

        & > .slick-list {
            width: 100%;
        }

        & .slick-track {
            position: relative;
        }
    }

    &__nav-button {
        position: absolute;
        width: 5rem;
        height: 5rem;
        display: none !important;
        margin: auto;
        top: 0;
        bottom: 0;
        z-index: 2;
        cursor: pointer;
    }

    &__prev {
        left: 0;
        @include mix.breakpointRange(var.$zero-width, var.$mobile-width) {

        }

        // От 320px до 600px (tablet-width) — 2 колонки
        @include mix.breakpointRange(var.$mobile-width, var.$mobile-xl-width) {

        }

        // От 600px (tablet-width) до 1280px (desktop-width) — 3 колонки, увеличенные отступы
        @include mix.breakpointRange(var.$mobile-xl-width, var.$desktop-width) {

        }

        // От 1441px (desktop-lg-width) и выше — 4 колонки
        @include mix.breakpoint(var.$desktop-width) {
             left: -6.5rem;
        }
    }

    &__next {
        right: 0;
        @include mix.breakpoint(var.$desktop-width) {
             right: -6.5rem;
        }
    }



    &__text {
        width: 48rem;
        padding-left: 2.4rem;
        display: none;
        align-items: center;
        align-content: center;

        @include mix.breakpointRange(var.$desktop-sm-width, (var.$desktop-width - 1)) {
            width: 40rem;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
    }

    &__caption {
        color: #7B75C9;
        font-weight: 700;
        font-family: Affect;
        font-size: 6rem;
        line-height: 7.7rem;
        margin: 0;

        @include mix.breakpointRange(var.$desktop-sm-width, (var.$desktop-width - 1)) {
            font-size: 4rem;
            line-height: 5rem;
        }

        @include mix.breakpointRange(var.$desktop-sm-width, (var.$desktop-lg-width - 1)) {
            width: 100%;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            font-size: 6rem;
            line-height: 7.7rem;
        }
    }

    &__like-image {
        display: flex;
        margin-left: auto;
        margin-top: 2.4rem;
        width: 20rem;

        @include mix.breakpointRange(var.$desktop-sm-width, (var.$desktop-width - 1)) {
            width: 10rem;
            margin-top: 0.8rem;
        }
    }

    &__section {

        width: 100%;
        // max-width: 100%;
        
        &._size_1 {
            & .showroom-v2 {
                &__contacts,
                &__phone {
                    min-height: auto !important;
                }
            }
        }


        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-bottom: 4rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-sm-width - 1)) {
            margin-bottom: 6rem;

            &._size_1 {
                & .showroom-v2 {
                    display: flex;
                    flex-direction: row;
                }

                & .showroom-v2__slider {
                    width: 100%;

                    & .showroom-v2__slide {
                        width: 100%;
                    }
                }

                & .showroom-v2__images {
                    width: 38rem;
                    height: 100%;

                    & > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                & .showroom-v2__content {
                    width: calc(100% - 38rem);
                    padding: 2rem;
                }
            }
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            margin-bottom: 8rem;

            &._size_1,
            &._size_2 {
                background: #ECF0F5;
                padding-top: 4rem;
                padding-bottom: 4rem;

                & .showroom-v2__text {
                    display: flex;
                }

                & .showroom-v2__title-place {
                    display: none;
                }

                & .showroom-v2__title {
                    margin-bottom: 1.6rem;
                }

                & .showroom-v2__content {
                    padding: 2.4rem;
                }

                & .showroom-v2__row {
                    display: flex;
                }
            }

            &._size_1 {
                & .showroom-v2 {
                    display: flex;
                    flex-direction: row;
                }

                & .showroom-v2__caption {
                    font-size: 3.4rem;
                    line-height: 4rem;

                    &_own {
                        display: inline;
                    }
                }

                & .showroom-v2__slider {
                    width: calc(100% - 47.3rem);

                    & .showroom-v2__slide {
                        width: 100%;
                    }
                }

                & .showroom-v2__slide {
                    width: auto;
                }

                & .showroom-v2__images {
                    width: 38rem;
                    height: 100%;

                    & > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                & .showroom-v2__content {
                    width: 43rem;
                    padding: 1.6rem;
                }

                & .showroom-v2__like-image {
                    width: 12rem;
                    margin-top: 0.8rem;
                }
            }

            &._size_2 {
                & .showroom-v2__slider {
                    width: calc(100% - 40rem);

                    & .showroom-v2__slide {
                        width: 50%;
                        margin-right: 0;
                    }
                }

                & .showroom-v2__caption {
                    font-size: 5rem;
                    line-height: 6rem;

                    &_own {
                        display: none;
                    }
                }
            }

            &._size_3,
            &._size_3_more {
                @include mix.breakpointRange(var.$desktop-sm-width, (var.$desktop-width - 1)) {
                    & .showroom-v2 {
                        background: #fff;

                        &::after {
                            background: #fff;
                        }
                    }
                }

                @include mix.breakpointRange(var.$desktop-width, var.$desktop-max-width) {
                    & .showroom-v2 {
                        background: #F8FBFF;

                        &::after {
                            background: #F8FBFF;
                        }
                    }
                }

                & .showroom-v2__content {
                    padding-bottom: 0;
                }

                & .showroom-v2__schedule {
                    order: 4;
                }

                & .showroom-v2__button {
                    order: 5;
                }
            }

            &._size_3 {
                & .showroom-v2__slider {
                    & .showroom-v2__slide {
                        width: 33%;
                        margin-right: 0;
                    }
                }
            }

            &._size_3_more {
                & .showroom-v2__nav-button {
                    display: grid !important;
                }
            }
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            &._size_1 {
                & .showroom-v2__text {
                    padding-left: 0.4rem;
                }

                & .showroom-v2__caption {
                    font-size: 4rem;
                    line-height: 5.1rem;
                }

                & .showroom-v2__slider {
                    width: calc(100% - 54.3rem);
                }

                & .showroom-v2__like-image {
                    width: 15rem;
                    margin-top: 2.4rem;
                }
            }

            &._size_2 {
                & .showroom-v2__slider {
                    width: calc(100% - 48rem);
                }

                & .showroom-v2__caption {
                    font-size: 6rem;
                    line-height: 7.7rem;
                }
            }

            &._size_3,
            &._size_3_more {
                & .showroom-v2 {
                    background: #F8FBFF;

                    &::after {
                        background: #F8FBFF;
                    }
                }
            }
        }
    }
}

/** DEPENDS CODE */

.showroom-v2 {

    .gallery-v3__track_dots {
        bottom: -1.5rem;
    }

    .gallery-v3 {
        max-width: 100%;
        width: 100%;
        height: 25.3rem;
        max-height: 25.3rem;
        min-height: 25.3rem;
    }
    
    .gallery-v3__page-controller {
        overflow: hidden;
    }

    .gallery-v3__current-image {
        display: grid;
        height: 25.3rem;
        align-items: center;
        overflow: hidden;
    }

    .gallery-v3__slide_wrapper {
        display: grid !important;
        justify-content: center;
        align-items: center;
        height: 25.3rem;

        img {
            max-width: 100%;
            object-fit: contain;
            object-position: center;
            display: block;
            margin: 0 auto;
            border-radius: 8rem 0 0 0;
        }
    }

}

/** \DEPENDS CODE */