@use 'base/variables' as var;

.modal-window-region-switcher-v2 {
    margin-bottom: 3rem;

    li {
        line-height: 2.4rem;
    }

    &__pre-title {
        margin: 0 0 .6rem;
        font-size: 3rem;
        font-weight: 700;
        line-height: 3.8rem;
        border-bottom: none;
        text-decoration: none;

        span {
            border-bottom: none;

        }
    }

    &__wrap {
        margin-top: -3rem;
       /* display: flex;
        flex-wrap: wrap;
        flex-direction: column;*/
        /*display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: max-content;*/
        column-count: 4;
        line-height: 2.4rem;
    }

    &__cities-column {
        width: 25%;
        margin-top: 1rem;
    }

    &__cities-region {
      /*  flex-basis: 25%;*/
        margin-bottom: 1rem;
        padding-right: .6rem;
         break-inside: avoid-column;
        /*flex-grow: 1;*/
    }
}

//deps
.modal-window-region-switcher-v2 {

}