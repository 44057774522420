@use 'base/variables' as var;
@use 'base/mixins' as mix;

.nav-v1 {
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    
    &_direction-type-up {
        rotate: 180deg;
    }

    &_direction-type-left {
        rotate: 90deg;
    }

    &_direction-type-right {
        rotate: -90deg;
    }
}

.nav-v1_filling-type-contained.nav-v1_filling-color-type-gray-tone-3 {
    background-color: var.$color-gray-tone3;
}