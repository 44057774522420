@use 'base/variables' as var;
@use 'base/mixins' as mix;

.mini-cart-v1 {
    position: relative;

    &__content-container {
        position: absolute;
        right: 0;
        top: 100%;
        display: none;
        z-index: 100;

        .button-preloader {
            display: block;
        }
    }

    &__preloader {
        padding: 10rem;
        will-change: transform;
        background-color: #fff;
        z-index: 200;
        display: flex;
        flex-direction: column;
        height: auto;
        transition: all .1s;
        overflow: auto;
        cursor: default;
        overflow: initial;
        transform: none;
        left: initial;
        top: calc(100%);
        right: 0;
        width: 64rem;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    }
}

.mini-cart-v1__mobile-view {
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    // height: 100vh;
    // overflow: hidden;
    .mini-cart-data-v1__js-scrollable {
        padding-right: 2rem;
    }

    .mini-cart-data-v1__scroll-inner {
         overflow: hidden;
    }
}

/** DEPENDS CODE */
.mini-cart-v1 {

}

/** \DEPENDS CODE */

