@use 'base/variables' as var;
@use 'base/mixins' as mix;

.compare {
    position: relative;
    z-index: 1;
    margin-bottom: 6rem;

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 14rem;
    }

    &._empty {
        .compare {
            &__empty {
                display: block;
            }

            &__title {
                display: none;
            }

            &__cards {
                display: none;
            }

            &__list {
                display: none;
            }
        }
    }

    &__inner {
        position: relative;
        margin: 0 -2rem;
        padding: 0 2rem;
        @include mix.breakpoint(var.$desktop-sm-width) {
            overflow-x: auto;
        }
    }

    &__empty {
        display: none;
        font-size: 1.5rem;
        line-height: 1.9rem;
        color: #808DA1;
        opacity: 0.5;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.4rem;
            text-align: center;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2.8rem;
            line-height: 3.2rem;
        }
    }

    &__top-wrap  {
        display: block;
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$mobile-width) {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            display: block;
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            // margin-bottom: 5rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$mobile-width) {
            margin: 0;
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            margin-bottom: 3rem;
        }
    }

    &__diff-btn {
        font-weight: 400;
        &._active {
            background-color: var.$color-main;
            color: #fff;
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 1rem 1.5rem;
            font-size: 1.6rem;
            line-height: 1.9rem;
        }
    }

    &__list {
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: fit-content;
        }
    }

    &__cards {
        display: flex;
        margin: 0 -0.5rem;
        margin-bottom: 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: -1rem;
            margin-right: -1rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            margin-bottom: 5rem;
            margin-left: 0;
            margin-right: 0;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 4.3rem;
        }
    }

    &__card-item {
        width: calc(100% / 2 - 1rem);
        margin: 0 0.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin: 0 1rem;
            width: calc(100% / 2 - 2rem);
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            // width: calc(100% / 2 - 2rem);
            // margin: 0 1rem;
            width: 100%;
            margin: 0;
            &._right {
                display: none;
            }
        }
    }

    &__card-nav {
        display: flex;
        align-items: center;
        width: 14.2rem;
        margin: 0 auto;
        margin-bottom: 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            display: none;
            // margin-bottom: 3rem;
        }

        .slider__nav-prev,
        .slider__nav-next {
            border-radius: 4px;
        }
    }

    &__slider {
        @include mix.breakpoint(var.$desktop-sm-width) {
            display: flex;
        }
    }

    &__slide {
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 30.5rem;
            margin-right: 2rem;
            flex-shrink: 0;
            &:last-child {
                margin-right: 0;
            }
            &._remove-animation {
                animation: .5s dissapear ease-out;
            }
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            width: 34.5rem;
        }
    }

    &__item {
        padding: 2rem 1.3rem;
        padding-top: 1.8rem;
        border-top: 1px solid #EBF0F5;
        &:last-child {
            border-bottom: 1px solid #EBF0F5;
        }

        &._hidden {
            display: none;
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem 1.5rem;
            // padding-top: 2.7rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            padding: 4rem 0;
        }
    }

    &__item-title {
        display: flex;
        align-items: baseline;
        margin: 0;
        margin-bottom: 1.5rem;
        font-family: "Affect", "Arial", sans-serif;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.8rem;
            line-height: 2rem;
            margin-bottom: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            position: sticky;
            max-width: 50rem;
            left: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 3rem;
        }
    }

    &__row {
        display: flex;
        margin-right: -1rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: -2rem;
        }
    }

    &__cell {
        width: calc(100% / 2 - 1rem);
        margin: 0;
        margin-right: 1rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        display: none;
        &._active {
            display: block;
        }
        &._right {
            padding-left: 1rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 2 - 2rem);
            margin-right: 2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            &._right {
                padding-left: 1.5rem;
            }
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 30.5rem;
            display: block;
            flex-shrink: 0;
            &._right {
                display: none;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 34.5rem;
        }
    }

    &__tooltipster {
      display: flex;
      align-items: center;

      .info-tooltip {
        &._compare {
          width: 1.4rem;
          height: 1.4rem;
          color: #3c3c70;
          margin-left: 0.6rem;
        }
      }
    }

    &__value {

    }

    &__tooltip {
        color: var.$color-main;
        margin-left: .8rem;
        // width: 1rem;

        // vertical-align: middle;
        line-height: 0;
        @include mix.breakpoint(var.$tablet-width) {
            width: 1.2rem;
            height: 1.2rem;
        }
    }

    &__associated {
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 14rem;
        }
        .slick-list {
            overflow: initial;
        }
    }

    .slider {
        &__nav-prev,
        &__nav-next {
            width: 3.5rem;
            height: 3.5rem;
            margin: 0;
            background-color: #fff;
            border: 1px solid #EBF0F5;
            @include mix.breakpoint(var.$mobile-width) {
                width: 4.5rem;
                height: 4.5rem;
            }
        }

        &__paging {
            font-size: 1.5rem;
            line-height: 1.9rem;
            margin: 0 auto;
        }
    }

    .card {
        @include mix.breakpoint(var.$tablet-width) {
            width: 100%;
            max-width: none;
            // margin: 0 auto;

        }

        &__head {
            padding: 1rem;
            justify-content: flex-end;
            @include mix.breakpoint(var.$tablet-width) {
                padding: 2rem;
            }
        }
    }

    @media(max-width: var.$tablet-width - 1) {
        .card {
            margin-top: 0;
            // padding: 1rem;
            padding-top: 0;

            &__link {
                padding: 1rem;
                padding-top: 0;
            }
            &__img-wrap {
                width: calc(100% + 1rem * 2);
                margin-left: -1rem;
                margin-bottom: 1rem;
            }

            &__info-numbers {
                margin-bottom: 1.6rem;
            }

            &__review-number {
                padding-left: 0;
            }

            &__title {
                margin-top: 0;
                font-size: 1.3rem;
                line-height: 1.6rem;
            }

            &__footer-top {
                display: block;
            }

            &__price-title {
                display: none;
            }

            &__price-wrap {
                font-size: 0;
                margin-bottom: 1.5rem;
            }

            &__price {
                font-size: 1.8rem;
                line-height: 2.3rem;
            }

            &__select {
                width: 100%;
            }

            &__buy-btn {
                margin-top: 1rem;
            }
        }

        .star {
            margin-bottom: 0.7rem;

            &__icon {
                width: 1rem;
                height: 1rem;
            }

            &__item {
                line-height: 0;
            }
        }

        .select {
            &__option-content ._bold {
                display: none;
            }
        }
    }




}
