@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-footer {
    // position: sticky;
    // bottom: 0;
    background: #EBF0F5;
    box-shadow: 0px -6.54px 10px rgba(0, 0, 0, 0.05);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    transition: bottom .3s;
    z-index: 50;
    // bottom: var(--footer-height);

    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: center;
        }
    }
    &__sum {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-left: 0;
            display: block;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            display: flex;
            align-items: baseline;
        }
    }

    &__sum-text {
        margin: 0;
        margin-right: 1.5rem;
        font-family: "Affect", Arial, sans-serif;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 1.8rem;
            margin-bottom: 0.8rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            font-size: 2.4rem;
            line-height: 2.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 3.2rem;
            line-height: 3.6rem;
            margin: 0;
            margin-right: 4rem;

        }
    }

    &__form {
        display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            width: 45.5rem;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 48rem;
        }
    }

    &__buy-btn {
        width: 50%;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 1.3rem;
            padding-bottom: 1.3rem;
        }
    }

    .price {
        display: flex;
        align-items: flex-end;
        white-space: nowrap;
        // margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            align-items: baseline;
        }
        &__current {
            margin: 0;
            margin-right: .7rem;
            font-size: 1.4rem;
            line-height: 1.8rem;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.6rem;
                line-height: 2rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                font-size: 2.4rem;
                line-height: 3rem;
            }
        }
        &__old {
            font-size: 1rem;
            line-height: 1.3rem;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.4rem;
                line-height: 1.8rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
    }

    .form {
        &__input-item {
            width: 50%;
            margin: 0;
            margin-right: 1.5rem;
            @include mix.breakpoint(var.$desktop-width) {
                margin-right: 4rem;
            }
        }

        &__input {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            @include mix.breakpoint(var.$tablet-width) {
                padding-top: 1.3rem;
                padding-bottom: 1.3rem;
                text-align: center;
            }
        }
    }
}
