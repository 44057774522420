@use 'base/variables' as var;
@use 'base/mixins' as mix;

.mailing {
    margin-bottom: 10rem;
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14rem;
    }
    &__inner.color-section {
        padding: 4rem 3rem;
        padding-bottom: 20.3rem;
        background-image: url("/img/maskot/happy-purple.svg");
        background-repeat: no-repeat;
        background-size: 17.6rem auto;
        background-position: bottom -6rem right 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-bottom: 4rem;
            background-position: bottom right 3rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            background-position: bottom right 17rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            background-position: bottom right 25rem;
        }
    }

    &__title {
        font-family: "Affect";
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        line-height: 112%;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 4.4rem;
            max-width: 34.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: none;
            margin-bottom: 2.5rem;
        }
    }

    &__text {
        margin: 0;
        margin-bottom: 3rem;
        font-size: 1.3rem;
        line-height: 130%;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 27rem;
            font-size: 1.4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: none;
            margin-bottom: 4rem;
        }
    }

    &__submit-btn {
        width: 100%;
        @include mix.breakpoint(var.$tablet-width) {
            width: 16.4rem;
            height: 5.4rem;
        }
    }

    &__form {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }
    }



    .form__input {
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 26.6rem;
            margin-right: 1.5rem;
            margin-bottom: 0;
        }
    }
}
