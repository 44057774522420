@use 'base/variables' as var;
@use 'base/mixins' as mix;

.checkbox-v2 {

    & label {

        column-gap: 1rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: var.$color-text;

        white-space: nowrap;
        cursor: pointer;
        display: flex;

        & input {
            -webkit-appearance: none;
            border: 0.1rem solid var.$color-main;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 0.3rem;
            position: relative;
            cursor: pointer;
            max-height: 1.6rem;
            display: inline-block;

            &:active, &:checked {
                background-repeat: no-repeat;
                // TODO[themeProvider]: common icons without SVG sprite
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNi45NzA2NSAwLjk2OTMwNEM3LjExMDYgMC44MzA1NzUgNy4yOTk1MiAwLjc1MjUgNy40OTY1OCAwLjc1MTk1NkM3LjY5MzYzIDAuNzUxNDEyIDcuODgyOTkgMC44Mjg0NDQgOC4wMjM3IDAuOTY2Mzk4QzguMTY0NDEgMS4xMDQzNSA4LjI0NTE4IDEuMjkyMTQgOC4yNDg1NCAxLjQ4OTE3QzguMjUxOSAxLjY4NjIgOC4xNzc1OCAxLjg3NjYzIDguMDQxNjUgMi4wMTkzTDQuMDQ5NjUgNy4wMDkzQzMuOTgxMDQgNy4wODMyIDMuODk4MjMgNy4xNDI1MSAzLjgwNjE4IDcuMTgzNjdDMy43MTQxMyA3LjIyNDgzIDMuNjE0NzIgNy4yNDcwMSAzLjUxMzkgNy4yNDg4OEMzLjQxMzA4IDcuMjUwNzUgMy4zMTI5MiA3LjIzMjI2IDMuMjE5NCA3LjE5NDU0QzMuMTI1ODkgNy4xNTY4MSAzLjA0MDk0IDcuMTAwNjEgMi45Njk2NSA3LjAyOTNMMC4zMjQ2NDYgNC4zODMzQzAuMjUwOTU5IDQuMzE0NjQgMC4xOTE4NTcgNC4yMzE4NCAwLjE1MDg2NSA0LjEzOTg0QzAuMTA5ODczIDQuMDQ3ODQgMC4wODc4MzEgMy45NDg1MyAwLjA4NjA1NDIgMy44NDc4M0MwLjA4NDI3NzQgMy43NDcxMiAwLjEwMjgwMiAzLjY0NzA5IDAuMTQwNTIzIDMuNTUzNzFDMC4xNzgyNDQgMy40NjAzMiAwLjIzNDM4OSAzLjM3NTQ4IDAuMzA1NjA4IDMuMzA0MjdDMC4zNzY4MjYgMy4yMzMwNSAwLjQ2MTY2IDMuMTc2OSAwLjU1NTA0OCAzLjEzOTE4QzAuNjQ4NDM3IDMuMTAxNDYgMC43NDg0NjYgMy4wODI5NCAwLjg0OTE2OSAzLjA4NDcxQzAuOTQ5ODcyIDMuMDg2NDkgMS4wNDkxOCAzLjEwODUzIDEuMTQxMTggMy4xNDk1MkMxLjIzMzE4IDMuMTkwNTEgMS4zMTU5OCAzLjI0OTYyIDEuMzg0NjUgMy4zMjMzTDMuNDc4NjUgNS40MTYzTDYuOTUxNjUgMC45OTEzMDRDNi45NTc4NCAwLjk4MzU1NiA2Ljk2NDUyIDAuOTc2MjA3IDYuOTcxNjUgMC45NjkzMDRINi45NzA2NVoiIGZpbGw9IiMzQzNDNzAiLz4KPC9zdmc+Cg==);
                background-position: center;
            }
        }
    }
    
}