@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-grid-v1 {

    position: relative;
    display: grid;
    column-gap: 3rem;
    row-gap: 4rem;

    @include mix.breakpointRange(var.$zero-width, var.$mobile-width) {
        display: grid;
        column-gap: 0rem;
        row-gap: 2rem;
        grid-template-columns: repeat(1, 1fr);
    }

    // От 320px до 600px (tablet-width) — 2 колонки
    @include mix.breakpointRange(var.$mobile-width, var.$mobile-xl-width) {
        display: grid;
        column-gap: 0rem;
        row-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }

    // От 600px (tablet-width) до 1280px (desktop-width) — 3 колонки, увеличенные отступы
    @include mix.breakpointRange(var.$mobile-xl-width, var.$desktop-width) {
        display: grid;
        row-gap: 4rem;
        column-gap: 1.6rem;
        grid-template-columns: repeat(3, 1fr);
    }

    // От 1280px (desktop-lg-width) и выше — 4 колонки
    @include mix.breakpoint(var.$desktop-width) {
        grid-template-columns: repeat(4, 1fr);
    }
}

/** DEPENDS CODE */
.product-grid-v1 {
    &__product {
        grid-column: span 1;
    }

    // &__banner {
    //     grid-column: span 4;
    // }

    /* вертикальный баннер */
    &__banner-place-span-1 {
        grid-column: span 1;
    }
    /* \вертикальный баннер */

    /* горизонтальный баннер */
    &__banner-place-span-all {
        // До 320px
        grid-column: span 1;
    
        // От 320px до 600px (tablet-width) — 2 колонки
        @include mix.breakpointRange(var.$mobile-width, var.$mobile-xl-width) {
            grid-column: span 2;
        }
    
        // От 600px (tablet-width) до 1280px (desktop-width) — 3 колонки, увеличенные отступы
        @include mix.breakpointRange(var.$mobile-xl-width, var.$desktop-width) {
            grid-column: span 3;
        }
    
        // От 1280px (desktop-lg-width) и выше — 4 колонки
        @include mix.breakpoint(var.$desktop-width) {
            grid-column: span 4;
        }
    }
    /* \горизонтальный баннер */

     &__html-content-v2 {
        grid-column: span 1 !important;
    }
}
/** \DEPENDS CODE */