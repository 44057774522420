@use 'base/variables' as var;
@use 'base/mixins' as mix;

.advantages-v2 {
    overflow: hidden;
    margin-bottom: 7.2rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8.3rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 12rem;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-top: -3.5rem;
        flex-wrap: wrap;
        @include mix.breakpoint(var.$tablet-width) {
            flex-direction: row;
            margin-top: -3rem;
            margin-right: -3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-right: -10rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-right: -15rem;
        }
    }

    &__item {
        margin-top: 3.5rem;
        display: flex;
        align-items: center;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 3rem;
            margin-right: 3rem;
            width: calc(100% / 2 - 3rem);
            &:nth-child(4) {
                order: 4;
            }
            &:nth-child(2) {
                order: 3;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 10rem;
            width: auto;
            max-width: 21rem;
            flex-direction: column;
            align-items: stretch;
            &:nth-child(4) {
                order: initial;
            }
            &:nth-child(2) {
                order: initial;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 15rem;
        }
    }

    &__icon-container {
        width: 9rem;
        flex-shrink: 0;
        margin-right: 1.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 10.5rem;
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 100%;
            margin-right: 0;
            margin-bottom: auto;
        }
    }

    &__icon {
        height: 4.3rem;
        margin-left: 1.5rem;

        &._thumb-up {
            width: 7.2rem;
        }

        &._mister-green {
            width: 5.1rem;
        }

        &._transport {
            width: 7.9rem;
            margin-left: 0;
        }

        &._sale {
            width: 4.6rem;
            margin-left: 1.7rem;
        }

        &._glass {
            width: 5.2rem;
            height: 5rem;
        }

        &._bow-star {
            width: 5.8rem;
            height: 5rem;
        }

        &._heart {
            width: 5.8rem;
            height: 5rem;
        }

        &._mister-purple {
            width: 4.8rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 0;
            &._thumb-up {
                width: 10.5rem;
                height: 6.2rem;
            }
            &._mister-green {
                width: 7.6rem;
                height: 6.6rem;
                margin-top: 0.8rem;
            }
            &._transport {
                width: 10.1rem;
                height: 5.4rem;
                margin-left: 0;
            }
            &._sale {
                width: 7.8rem;
                height: 7.4rem;
                margin-left: 1.7rem;
                margin-bottom: -0.9rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 0;
            &._thumb-up {
                width: 12.5rem;
                height: 7.5rem;
            }
            &._mister-green {
                width: 9.2rem;
                height: 7.7rem;
                margin-top: 0;
            }
            &._transport {
                width: 15rem;
                height: 8.2rem;
            }
            &._sale {
                width: 8.8rem;
                height: 8rem;
                margin-left: 0;
                margin-top: -1.5rem;
            }
        }
    }

    &__text {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin: 0;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-top: 1.8rem;
        }
    }
}

//DEPENDS CODE
.advantages-v2 {
    .title-v2 {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 55rem;
            margin-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 80rem;
            margin-bottom: 8.4rem;
        }
    }
}