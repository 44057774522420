@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tag-list-v2 {

    margin-bottom: 4.5rem;
    overflow: scroll;

    &_no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: hidden;
    }

    @include mix.breakpoint(var.$tablet-width) {
        margin-left: auto;
        margin-right: auto;
    }

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6rem;
    }


    &__content {
        display: flex;
        flex-wrap: wrap;

        @include mix.breakpoint(var.$tablet-width) {
            margin-right: -1.5rem;
            margin-top: -1.5rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -2.2rem;
            margin-top: -2.2rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: -2.5rem;
            margin-top: -2.5rem;
        }
    }

    &__item-active {
        a {
            background-color: var.$color-text;
            color: #fff;
            border-color: var.$color-text;
        }
    }

    &__link {
        display: block;
        margin-top: 1rem;
        margin-right: 1rem;
        padding: 1.36rem 2rem;
        background: #ebf0f5;
        border-radius: 4px;
        text-decoration: none;
        font-weight: 500;
        text-decoration: none;
        color: var.$color-text;
        transition: all .3s;


        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 1.5rem;
            margin-top: 1.5rem;
            padding: 1.7rem 2.5rem;
            font-size: 1.6rem;
            line-height: 2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2.2rem;
            margin-top: 2.2rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 2.5rem;
            margin-top: 2.5rem;
        }
    }
}

//Dependencies
.tag-list-v2 {
    .link__icon {
        width: 10px;
        height: 10px;
    }
}