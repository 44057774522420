@use 'base/variables' as var;
@use 'base/mixins' as mix;

.gallery-v2 {
    overflow: hidden;
    &__track-wrap {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: min-content auto min-content;
        align-items: center;
        column-gap: 1rem;
    }

    &__track {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 1rem;
    }

    &__video-preview {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__video-preview &__video-preview-icon {
        position: absolute;
        z-index: 1;
        top: calc(50% - (3.8rem / 2));
        left: calc(50% - (3.8rem / 2));
    }

    &__video-preview &__video-preview-image {
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

 }

/** DEPENDS CODE */
.gallery-v2 {
    
    .nav-v1 {
        height: 3.8rem;
        width: 3.8rem;
        border-radius: 0.5rem; 
    }

    .slick-track {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 1rem;
    }

}
/** \DEPENDS CODE */
