@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cities-popup {
  width: 100% !important;
  max-width: 1080px;
  background: #7B75C9;
  padding: 3.6rem 8rem !important;
  color: #fff;

  @media(max-width: var.$tablet-width - 1) {
    padding: 2rem !important;
  }

  .popup__title {
    background: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem !important;

    @media(max-width: var.$tablet-width - 1) {
      padding-right: 1.5rem !important;
    }
  }

  .mfp-close {
    display: none;
  }

  &__close {
    color: #3C3C70;
    stroke: #3C3C70;
    background: inherit;
    font-size: 1.6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    border-bottom: 1px solid transparent;
    transition: border-color .3s;
    @media(hover:hover) {
        &:hover {
            border-bottom-color: currentColor;
        }
    }

    svg {
      width: 10px;
      height: 10px;
      margin-left: 0.6rem;
    }
  }

  a.main-pre-title-href {
    text-decoration: none;
    font-weight: 300;

    &:hover {
      color: #fff;
    }
    & .main-pre-title span:hover {
      border-bottom: 1px solid #fff;
    }
  }

  .main-pre-title-href .main-pre-title._active span {
    border-bottom: 1px solid #fff;
  }


  .cities-region {

    .main-pre-title {
      margin: 0;
      margin-bottom: 0.6rem;
      font-size: 30px;
      font-weight: 700;
      line-height: 3.8rem;
    }


    &._top {
      margin-bottom: 4rem;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
          width: 25%;
          @media(max-width: var.$tablet-width - 1) {
            width: 100%;
          }

        }

      }
    }


    ul {
      display: flex;
      flex-direction: column;

      li {
        padding-bottom: 0.6rem;

        a {
          text-decoration: none;
          font-weight: 300;

          &:hover {
            border-bottom: 1px solid #fff;
            color: #fff;
          }
        }

        &._active {
          a {
            border-bottom: 1px solid #fff;
          }
        }

      }

    }


  }


  &__wrap {
    margin-top: -3rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-height: 190rem;

    @media(max-width: var.$tablet-width - 1) {
      max-height: 100%;
    }

    .cities-region {
      width: 25%;
      margin-bottom: 1rem;
      padding-right: 0.6rem;

      @media(max-width: var.$tablet-width - 1) {
        width: 100%;
      }

      .pre-title {
        margin: 0;
        font-size: 3.4rem;
        font-weight: bold;
      }
    }

  }

  .show-more {
    display: inline-block;
    text-decoration: none;
    font-weight: 300;
    color: #fff;
    border-bottom: 1px dashed #fff;
    margin-top: 0.6rem;
  }

  &__search {
    position: relative;

    input[type="text"] {
      display: block;
      background: #fff;
      padding: 10px 16px;
      border-radius: 4px;
      max-width: 300px;
      width: 100%;
      outline: none;
      border: none;
      margin-bottom: 2rem;
    }
  }

  &__search-dropdown {
    position: absolute;
    top: 44px;
    background: #fff;
    padding: 8px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    left: 0;
    max-width: 300px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    display: none;

    a {
      display: block;
      color: #3c3c70;
      padding: 8px 16px;
      text-decoration: none;

      &:hover, &._active {
        background: #ebf0f5;
      }

    }

    ._nothing {
      padding: 16px;
      text-align: center;
      color: #3c3c70;
      opacity: 0.7;
    }
  }

}
