@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sale-more {
    overflow: hidden;
    .title {
        margin-bottom: 4.5rem;
        max-width: 26rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        .title {
            margin-bottom: 4rem;
            max-width: none;
        }
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
        .title {
            margin-bottom: 6.5rem;
        }
    }
    &__list {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1rem;
            margin-top: -2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: auto;
            flex-wrap: wrap;
            margin-left: 0;
            margin-right: -2rem;
            margin-top: -2rem;
        }
    }

    &__item {
        outline: none;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 2 - 2rem);
            margin: 0 1rem;
            margin-top: 2rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(100% / 3 - 2rem);
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 2rem);
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        color: inherit;
        outline: none;

        .sale-more__title > span {
          border-bottom: 1px solid transparent;
          transition: border-color .3s;
        }
        @media(hover:hover) {
            &:hover {
              .sale-more__title > span {
                  border-bottom-color: currentColor;
              }
            }
        }
    }

    &__img {
        display: block;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 1rem;
        max-width: none;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 2rem;
        }
    }

    &__title {
        margin: 0;
        margin-bottom: 0.5rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        color: #000000;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.7rem;
            line-height: 2.2rem;
            margin-bottom: 1rem;
        }
    }

    &__text {
        margin: 0;
        color: #818DA1;
        font-size: 1.2rem;
        line-height: 1.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }

    .slick-track {
        margin-left: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 2rem;
        }
    }

    .pagination {
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 5rem;
        }
    }

    &__info {
      display: flex;
      flex-direction: row;
      margin: 0 -5px;
      margin-bottom: 0.8rem;
    }

    &__date, &__detail-btn {
      flex: 1 1 50%;
      margin: 0 5px;
      font-family: Arial, sans-serif;
      font-weight: normal;
      font-size: 1.7rem;
      line-height: 1.9rem;
    }

    &__date {
      background: #BEE6A8;
      border-radius: 4px;
      padding: 5px 0 3px 0;
      text-align: center;
      color: #7B75C9;
    }

    &__detail-btn {
      padding: 0.5rem 2rem;
    }
}
