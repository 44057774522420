@use 'base/variables' as var;
@use 'base/mixins' as mix;

.media-card-v1 {
    display: flex;

    mark {
        padding: 0;
    }

    &__col-left, &__col-right {
        flex-basis: 50%;
    }

    &__container {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    &__col-right {
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.5rem;
    }

    &__description {
        flex-grow: 1;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
    }

    &__media {
    }
}

/** DEPENDS CODE */
.media-card-v1 {
    .title-v2 {
        font-size: 2.1rem;
        font-weight: 700;
        line-height: 2.5rem;
        margin-bottom: 0.4rem;
    }

    p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;
    }
}

/** \DEPENDS CODE */

