@use 'base/variables' as var;
@use 'base/mixins' as mix;

.button-v1 {

    text-decoration: none;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    border-radius: 0.5rem; 
    padding: 1.4rem 3rem;
    white-space: nowrap;
    display: block;
    text-align: center;

    &_filling-type-contained {
        color: var.$color-white;
    }

    &_filling-type-outlined {
        border: 0.1rem solid rgba(var.$color-text, 0.3);
        color: var.$color-text;
    }

    &_disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

}

.button-v1_filling-type-text.button-v1_filling-color-type-main {
    background-color: var.$color-main;
}  
.button-v1_filling-type-contained.button-v1_filling-color-type-main {
    background-color: var.$color-main;
}
.button-v1_filling-type-contained.button-v1_filling-color-type-main:hover {
    color: var.$color-white !important;
    background-color: var.$color-main-tone6;
}
.button-v1_filling-type-contained.button-v1_filling-color-type-main:active {
    color: var.$color-white;
    background-color: var.$color-text;
}
.button-v1_filling-type-text.button-v1_text-color-type-main {
    color: var.$color-main;
} 
.button-v1_filling-type-outlined:hover {
    border-color: var.$color-main;
    color: inherit !important;
}
.button-v1_filling-type-outlined:active {
    border-color: var.$color-text;
}

/** DEPENDS CODE */
.button-v1 {
}
/** \DEPENDS CODE */
