@use 'base/variables' as var;
@use 'base/mixins' as mix;

.about-detail {
    &__inner {
      position: relative;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__container {
      position: relative;
      width: 100% !important;
    }

    &__list {
      @include mix.breakpoint(var.$tablet-width) {
        width: calc(100% - 46rem);
      }
      @include mix.breakpoint(var.$desktop-width) {
        width: calc(100% - 46rem);
      }
      @include mix.breakpoint(var.$desktop-lg-width) {
        width: calc(100% - 50rem);
      }
    }

    &__item {
      margin-bottom: 4.5rem;
      display: block !important;
    }

    .composition {

      @include mix.breakpoint(var.$tablet-width) {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 46rem;
        padding-left: 4rem;
      }

      @include mix.breakpoint(var.$desktop-width) {
        width: 46rem;
      }


      @include mix.breakpoint(var.$desktop-lg-width) {
        width: 50rem;
        padding-left: 8rem;
      }

      &__inner {
        position: sticky;
        top: 2rem;
      }

      &__content {
        overflow: hidden;
        padding: 2rem 0;

        ul{
          list-style: disc;
          margin-left: 2rem;
        }
      }
    }
    &__item-title {
      margin: 0;
      padding-bottom: 2.1rem;
      font-weight: 600;
      color: var.$color-text;
      font-size: 1.8rem;
      line-height: 2.5rem;
      border-bottom: 2px solid #EBF0F5;
      margin-bottom: 2rem;

      @include mix.breakpoint(var.$desktop-width) {
          font-size: 2rem;
          line-height: 2.5rem;
      }
    }

    .product-layer{
      position: relative;
      margin-bottom: 3px;
      .layer{
        position:relative;
        padding-bottom: 2px;
        white-space: nowrap;
        &.nobottomp{
          padding-bottom: 0px;
        }
        &.notopp{
          margin-top: -2px;
        }
      }
      .layer-texture{
        width: 60px;
      }
      .layer-title{
        z-index: 10;
        .layer-title-content{
          display: inline-block;
          margin-left: 15px;
          padding: 2px;
          font-size: 1.2rem;
          line-height: 1.5rem;
          &:before{
            content: '— ';
            position: absolute;
            left: 0;
          }

          @include mix.breakpoint(var.$tablet-width) {
              font-size: 1.2rem;
              line-height: 2.3;
          }

        }

        position: absolute;
        left: 64px;
        // font-size: 0.8em;
        @media all and (max-width: 767px){
          // font-size: 0.6em;
        }
        top: 40%;
        &.noTop15{
          top:-1.4rem !important;
        }
        &.noTop{
          top:-1.1rem !important;
        }
        &.top15{
          top:0  !important;
        }
      }

      .info-tooltip {
        svg {
          color: #7b75c9;
        }
      }
    }


    .answers__item:first-of-type {
      border-top: none !important;
    }

    .review__button-wrap, .answers__bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .review__button-wrap {
      margin-top: 4.5rem;
    }
    .answers__bottom {
      margin-top: 1.3rem;
    }


    &__more-certificates {
      display: block;
      font-size: 16px;
      line-height: 19px;
      color: #7B75C9;
      margin-top: 1rem;
      text-decoration: none;
      display: none;

      &:hover {
        span {
          text-decoration-line: underline;
        }
      }
    }

}
