@use 'base/variables' as var;
@use 'base/mixins' as mix;

.p-v1 {
    
    color: var.$color-text;

    &_text-color-type-text {
        color: var.$color-text;
    }
    
    &_text-color-type-gray {
        color: var.$color-gray;
    }

}