@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-card-v3 {
    display: flex;
    background-color: var.$color-white;

    &__head {
        min-width: 21.4rem;
        margin-right: 2.8rem;
    }

    &__head-info-container {

    }

    &__head-container {
        display: flex;
        gap: 0.7rem;
        align-items: center;
    }

    &__head-name {
        padding-bottom: 0.1rem;
        color: var.$color-black-tone4;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.8rem;
    }


    &__head-avatar {
        grid-area: avatar;
        width: 4rem;
        height: 4rem;

        img {
            border-radius: 10rem;
        }
    }

    &__head-date {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: var.$color-gray-tone-15;
    }

    &__head-rating {
        grid-area: rating;
        display: inline-block;
        padding: 4px 12px;
        border: 1px dashed var.$color-main;
        border-radius: 4px;
        text-align: center;
        margin-bottom: .6rem;
        margin-top: 1rem;
    }

    &__from {
        color: var.$color-main;
        font-size: 1.4rem;
        font-weight: 700;
    }

    &__body {
        font-size: 1.4rem;
        line-height: 1.5;
        font-family: var.$font-text;
        color: var.$color-text;
    }

    &__text {
        margin-top: 1.8rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: var.$color-gray-tone-16;
    }
}

/** DEPENDS CODE */
.review-card-v3 {
    .rating-star-v1 {
        height: 2.4rem;
        text-align: center;
        display: block;
    }
}

/** DEPENDS CODE */