@use 'base/variables' as var;
@use 'base/mixins' as mix;

.advantages {
    margin-bottom: 4.4rem;
    padding-top: 1.8rem;
    @include mix.breakpoint(var.$tablet-width) {
        padding-top: 2.3rem;
        margin-bottom: 5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6.8rem;
        padding-top: 3rem;
    }
    @include mix.breakpoint(var.$desktop-lg-width) {
        padding-top: 5.3rem;
    }
    &__list {
        display: flex;
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -2rem;
        }

    }

    &__item {
        width: 23.4rem;
        height: 9.6rem!important;
        padding: 2rem;
        background-color: #f9f9f9;
        border-radius: 2px;
        margin: 0 0.75rem;
        outline: none;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        @include mix.breakpoint(var.$tablet-width) {
            width: 30.5rem;
            height: 11.5rem!important;
            margin: 0 1rem;
            padding: 1rem 2.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 2rem);
            margin-right: 2rem;
            margin-left: 0;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        @include mix.breakpoint(var.$desktop-laptop-width) {
            padding-left: 2.5rem;
            padding-top: 0.5rem;
            padding-bottom: 1.2rem;
            padding-right: 3rem;
        }
    }

    &__item-link {
      width: 100%;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
    }

    &__icon {
        &._sale {
            width: 6rem;
            height: 5.5rem;
        }

        &._thumb-up {
            width: 8.1rem;
            height: 4.9rem;
        }

        &._transport {
            width: 8.6rem;
            height: 5.3rem;
        }

        &._bow-star {
            width: 6.9rem;
            height: 5.5rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            &._sale {
                width: 6.7rem;
                height: 6.3rem;
                margin-right: 1rem;
                margin-bottom: 2.5rem;
            }

            &._thumb-up {
                width: 9.5rem;
                height: 5.7rem;
                // margin-right: 1.7rem;
                margin-bottom: 1rem;
            }

            &._transport {
                width: 10.1rem;
                height: 6.2rem;
            }

            &._bow-star {
                width: 8rem;
                height: 6.25rem;
                margin-right: 1rem;
                margin-bottom: 2rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            &._sale {
                margin-right: 0;
                margin-bottom: 2.3rem;
            }
            &._thumb-up {
                margin-bottom: 6px;
                // margin-right: 10px;
            }
            &._bow-star {
                margin-bottom: 1.8rem;
                margin-right: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            &._sale {
                margin-bottom: 1.3rem;
                margin-right: 0.6rem;
            }
            &._thumb-up {
                margin-bottom: 0;
                margin-right: -0.5rem;
            }
            &._bow-star {
                margin-bottom: 0;
                margin-right: .5rem;
            }
            &._transport {
                margin-right: 0.5rem;
                margin-bottom: -1rem;
            }
        }
    }

    &__text {
        font-family: "Affect";
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin: 0;
        max-width: 11rem;
        margin-right: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.7rem;
            line-height: 2.3rem;
            max-width: 13rem;
        }
        @include mix.breakpoint(var.$desktop-width) {

            // max-width: 13rem;
            // font-size: 1.7rem;
            // line-height: 2.3rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            font-size: 2rem;
            line-height: 2.6rem;
            max-width: 15rem;
        }
    }
}
