@use 'base/variables' as var;

.cities-search-dropdown-v1 {
     padding: 1rem;
      background-color: var.$bgcolor-light-tone1;
      border: 1px solid var.$color-third;
    .js-city-select {
        text-decoration: none;
        display: block;
        padding: .5rem;
        line-height: 2.4rem;
    }
}

//deps
.cities-search-dropdown-v1 {

}