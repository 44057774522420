@use 'base/variables' as var;
@use 'base/mixins' as mix;

.card {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #EBF0F5;
    border-radius: 4px;
    max-width: 42rem;

    @include mix.breakpoint(var.$tablet-width) {
      margin-left: 0;
      margin-right: 0;
    }

    &__wrap-line{
        width:100%;
    }


    &._compare {
        .card__compare-icon {
            stroke: var.$color-main;
        }
    }
    &._favorite {
        .card__like-icon {
            stroke: #F46B5F;
            fill: #F46B5F;
        }
    }

    ._recommend {
        background: rgba(236, 240, 244, 0.6);

        .select {
            border-color: #818DA1;
        }

        .select__face {
            background: transparent;
        }
    }
    ._discounted {
        background: rgba(var.$color-second,0.3);
        //border:1px solid mix(var.$color-border, Red);
        //box-shadow: 0 0 1rem mix(var.$color-border, Red);

        .select {
            border-color: var.$color-second;
        }

        .select__face {
            background: transparent;
        }
    }

    @include mix.breakpoint(var.$tablet-width) {
        .select__dropdown {
            max-width: 26.5rem;
        }
    }

    &__link {
        display: block;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
        text-decoration: none;
        outline: none;
        height: 100%;
        color: var.$color-text;
        transition: all .3s;
        @media(hover:hover) {
            &:hover {
                box-shadow: 0px 4px 20px rgba(189, 129, 129, 0.15);
            }
        }
    }

    &__head {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
       /* padding: 1.8rem;
        padding-right: 2.5rem;*/
    }

    &__head-left {
        display: flex;
        margin-right: auto;
    }

    &__sale {
        font-size: 1.6rem;
        padding: .6rem;
        padding-top: 0.7rem;
        padding-bottom: .9rem;
        margin-right: 1rem;
        min-width: 5.1rem;
    }

    &__gift {
        width: 5.1rem;
        height: 3.2rem;
        background: #F5F7FA;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mix.breakpoint(var.$tablet-width) {
            width: 5rem;
            height: 3.2rem;
        }
    }

    &__gift-icon {
        width: 2.8rem;
        height: 2.8rem;
    }

    &__head-right {
        display: flex;
        align-items: center;
        margin-top: 1.8rem;
        margin-right: 1.8rem;
    }

    &__compare {
        position: relative;
        border: none;
        padding: 0;
        background-color: transparent;
        margin-right: 1.5rem;
        line-height: 1;
        cursor: pointer;
        outline: none;
        font-size: 0;
        @media(hover:hover) {
            &:hover .card__compare-icon {
                stroke: var.$color-main;
            }
        }
        @media(max-width: var.$tablet-width - 1) {
          display: none;
        }
    }

    &__compare-icon {
        transition: all .3s;
        width: 1.57rem;
        height: 1.8rem;
        stroke: #E3E3E3;
    }

    &__like {
        position: relative;
        border: none;
        padding: 0;
        background-color: transparent;
        line-height: 1;
        cursor: pointer;
        outline: none;
        font-size: 0;
        @media (hover: hover) {
            &:hover .card__like-icon {
                stroke: #F46B5F;
                fill: #F46B5F;
            }
          }
    }

    &__like-icon {
        transition: all .3s;
        width: 2.2rem;
        height: 1.9rem;
        stroke: var.$color-main;
        fill: #fff;
        transition: all .3s;
    }

    &__remove {
        border: none;
        padding: 0;
        background-color: transparent;
        line-height: 1;
        cursor: pointer;
        outline: none;
        line-height: 1;
        margin-right: 1.5rem;
        font-size: 0;
        @media(hover:hover) {
            &:hover {
                .card__remove-icon {
                    stroke: var.$color-main;
                }
            }
        }
    }

    &__remove-icon {
        width: 1.6rem;
        height: 1.6rem;
        stroke: #E3E3E3;
        transition: add .3s;
    }

    &__img-wrap {
        position: relative;
        width: calc(100% + 2rem * 2);
        margin-left: -2rem;
        margin-bottom: 1.3rem;
        padding-top: 1px;
        background-color: White;

        ._line {
            display: none;
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 1.5rem;
        }
    }

    &__img {
        display: block;
        width: calc(100% - 2px);
        margin: 0 auto;
    }

    &__top-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 24px;
    }

    &__info-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

    }

    &__info-numbers {
        display: flex;
        font-size: 1.2rem;
        line-height: 1;

    }

    &__review-number {
        position: relative;
        text-decoration: none;
        color: var.$color-main;
        padding: 0 0.5rem;
        border-right: 1px solid #EBF0F5;
        margin: 0;
    }

    &__order-number {
        color: rgba(60, 60, 112, 0.4);
        padding-left: 0.5rem;
        margin: 0;
    }

    &__title {
        width: 100%;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1.5rem;
        margin-top: 0.2rem;
        min-height: 40px;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 0.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 2rem;
            margin-bottom: 1.8rem;
        }
    }

    &__title-size {
        margin: 0;
        display: block;
        @include mix.breakpoint(var.$desktop-width) {
            display: inline;
        }
    }

    &__footer {
        padding-top: 1.5rem;
        border-top: 1px solid #EBF0F5;
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 1rem;
        }
    }

    &__footer-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__price-title {
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #94A2B8;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__price-old {
        color: #A7A7A7;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0;
        position: relative;
        display: inline-block;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
            background-color: var.$color-text;
        }
    }

    &__price {
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.7rem;
        margin: 0;
        @include mix.breakpoint(var.$mobile-width) {
            font-size: 2.1rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: -0.5rem;
        }
    }

    &__select {
        width: 10rem;
        margin-right: 0!important;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        @include mix.breakpoint(var.$mobile-width) {
            width: 12rem;
        }
        &._active {
            border-color: var.$color-text;
            .select__face {
                background-color: #fff;
            }
        }
        .select {
            &__dropdown {
                width: calc(100vw - 6rem);
                @include mix.breakpoint(var.$tablet-width) {
                    width: 32rem;
                }
            }

            &__option-content {
                padding-left: 3rem;
            }
        }



        .select__face {
            // background-color: #F4F6F8;
        }

    }

    &__buy-btn {
        margin-top: 1.2rem;
        font-size: 1.5rem;
        line-height: 1.9rem;
        padding-top: 1.3rem;
        padding-bottom: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 1.5rem;
            padding-top: 1.1rem;
            padding-bottom: 1.4rem;
        }
    }

    &__recommend {
        position: absolute;
        top: 100%;
        transform: translateY(-100%);
        left: 1.8rem;
        padding: 0.2rem 1.2rem 0.5rem;
        display: flex;
        align-items: center;
        background-color: var.$color-main;
        border-radius: 4px;
        font-family: "Affect";
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #fff;
        @include mix.breakpoint(var.$desktop-width) {
            left: 1.8rem;
        }
    }

    &__recommend-icon {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 1rem;
    }

    &__props-list {
        display: none;
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin-bottom: 2rem;
        }
    }

    &__props-item {
        display: flex;
        font-size: 1.2rem;
        line-height: 1.1;
        margin-bottom: 1rem;

    }
    &__props-item__extended-height {
        min-height: 3.8rem;
    }

    &__props-name {
        flex-shrink: 0;
        width: 8rem;
        padding-right: 2rem;
        //color: #818DA2;
        color: rgba(60, 60, 112, 0.4);
    }

    &__props-value {
        //color: rgba(60, 60, 112, 0.4);
        color: #818DA2;

        span {
            display: block;
            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }

    .select__dropdown._right {
        right: -1px; // у селекта бордер
    }

    .select__option-content {
        display: flex;
        justify-content: space-between;
    }

    .category_1-props-list {
      min-height: 82px;
    }
    .category_5-props-list{
        min-height: 92px;
    }

    &__specialpromo{
        position: absolute;
        bottom: 0;
        right: 1.5rem;
        text-align: right;
        width:50px;
    }
    &__specialpromo-activity{
        border-radius: 3px;
        text-align: center;
        font-size: 1rem;
        padding: 0.5rem 0.5rem;
        margin: 0 auto;
    }
    &__specialpromo-icon{
        /*width: 7rem;
        height: 6rem;*/
        position: absolute;
    }
    .card-detail__colorbox{
        &:nth-child(1){
            margin-left: 0 !important;
        }
        div{
            width: 10px !important;
            height: 10px !important;
        }
    }
}

.goods._tile {

    ._tile {
        display: block;
    }

    ._line {
        display: none;
    }
}

.goods._line {
    ._line {
        display: none;
    }
    @include mix.breakpoint(var.$tablet-width) {
        ._tile {
            display: none;
        }

        ._line {
            display: block;
        }

    }

    @include mix.breakpoint(var.$tablet-width) {
        .goods {
            &__list {
                display: block;
                margin: 0;
            }

            &__item {
                margin: 0;
                margin-bottom: 2rem;
                max-width: none;
                width: 100%;
                display: flex;
                padding: 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .card {

            &__img-wrap {
                width: 25rem;
                margin: 0;
                margin-right: 2rem;
                flex-shrink: 0;
                ._tile {
                    display: none;
                }
                ._line {
                    display: block;
                }

                @include mix.breakpoint(var.$desktop-laptop-width) {
                    width: 36rem;
                    margin-right: 4.2rem;
                }
            }

            &__img {
                width: 100%;
            }

            &__head {
                padding: 1rem;
            }

            &__head-left {
                top: 1rem;
                left: 1rem;
            }
            &__head-right {
                margin-top: 1.8rem;
                margin-right: 1rem;
            }

            &__recommend {
                left: 1rem;
                bottom: 1rem;
                top: initial;
                transform: none;
            }

            &__content {
                padding-top: 2.2rem;
                padding-bottom: 2.5rem;
                padding-right: 2rem;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                @include mix.breakpoint(var.$desktop-laptop-width) {
                    padding-top: 3rem;
                    padding-bottom: 3.5rem;
                }
            }

            &__top-wrap {
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // @include mix.ику
            }


            &__info-numbers {
                margin-left: 1rem;
            }

            &__wrap-line {
                display: flex;
                margin-top: 2rem;

                width: 100%;
                @include mix.breakpoint(var.$desktop-laptop-width) {
                }
                // margin-bottom: 2rem;
            }

            &__price-wrap {
                width: 14rem;
                flex-shrink: 0;
                margin-left: 2rem;
                text-align: right;
                @include mix.breakpoint(var.$desktop-laptop-width) {
                    display: flex;
                    justify-content: flex-end;
                    width: auto;
                    align-items: center;
                    &._tile {
                        display: none;
                    }
                }
            }

            &__price {
                font-size: 1.8rem;
                line-height: 2.3rem;
                @include mix.breakpoint(var.$desktop-laptop-width) {
                    margin-left: 2rem;
                    font-size: 2.1rem;
                    line-height: 2.7rem;
                }
            }

            &__footer {
                border: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: auto;
            }

            &__buy-btn {
                width: 17rem;
                margin: 0;
            }

            &__title {
                margin: 0;
            }

            &__review-number {
                position: static;
                z-index: 10;
            }


        }
    }
}

.goods {
    .card__top-wrap {
        width: 100%;
    }
}
