@use 'base/variables' as var;
@use 'base/mixins' as mix;

.promotion-card-v2 {

    // common styles
    height: 27.1rem;
    display: grid;
    position: relative;

    // grid area's label
    .header-v1 {
        grid-area: title;
    }
    .p-v1 {
        grid-area: desc;
    }
    &__image {
        grid-area: image;
    }
    .button-v1 {
        grid-area: action;
    }
    
    // grid areas templates
    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        grid-template-areas: 
            "image"
            "title"
            "desc"
            "action";
        justify-content: center;
        grid-template-rows: 12rem min-content min-content min-content;
        grid-template-columns: 1fr;
    }
    @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
        grid-template-areas: 
            "image title"
            "image desc";
        grid-template-columns: 50% 50%;
        grid-template-rows: 60% 40%;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        grid-template-areas: 
            "image title title"
            "image desc action";
        grid-template-columns: 45% 35% 20%;
        grid-template-rows: 70% 30%;
    }

}

.promotion-card-v2.promotion-card-v2_filling-type-contained.promotion-card-v2_filling-color-type-border {
    background-color: var.$color-border;
    border-color: var.$color-border;
}


/** DEPENDS CODE */
.promotion-card-v2 {

    .header-v1 {
        padding-top: 4rem;
        padding-left: 8rem;
        padding-bottom: 2rem;
        padding-right: 6rem;
        align-self: start; 

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {   
            padding-left: 2.5rem;
            padding-right: 3rem;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            padding-top: 1.5rem;
            padding-bottom: 0;
            padding-left: 1.3rem;
            padding-right: 1.3rem;
        } 
    }

    .header-v1 .header-v1__text {

        font-family: 'Affect';
        font-weight: 400;
        font-size: 4rem;
        line-height: 4.48rem;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            
        
            font-weight: 700;
            font-size: 2.6rem;
            line-height: 2.91rem;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 2.02rem;
            text-align: center;
        } 

    }

    &__image {

        border-radius: 0 12rem 0.4rem 0.4rem;
        background-size: cover;

    }

    .p-v1 {
    
        font-weight: 400;
        font-size: 1.4rem;
        padding-left: 8rem;
        padding-right: 8rem;
        padding-bottom: 4rem;
        margin-bottom: 0;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 1.82rem;
            padding-left: 2.5rem;
            padding-right: 3rem;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            font-weight: 400;
            font-size: 0.62rem;
            line-height: 0.8rem;
            padding: 0 8rem;
            text-align: center;

        }
        
    }

    &__button {

        align-self: end;
        padding-bottom: 4rem;
        padding-right: 6rem;
        margin-bottom: 0;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            display: none;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            padding: 1.5rem 9.8rem 2rem 9.8rem;
        }
    }

    &__button .button-v1 {
        padding: 1.5rem 1.9rem;
        font-weight: 400;
        font-size: 1.4rem;
        text-align: center;
    }

}

/** \DEPENDS CODE */
