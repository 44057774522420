@use 'base/variables' as var;
@use 'base/mixins' as mix;

/** DEPENDS CODE */
.card-page-v1 {

    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .product-card-v1 .client-gallery-v1 {

        margin-top: 4rem;
        margin-bottom: 6rem;

        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            margin-bottom: 4rem;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            margin-bottom: 3rem;
        }

        .header-v1 {
            margin-bottom: 2rem;
        }

        .gallery-v2__track-element, .gallery-v2__track-element img {
            width: 9rem;
            height: 9rem;
            min-width: 9rem;
            min-height: 9rem;
            max-width: 9rem;
            max-height: 9rem;
        }

    }

    .review-grid-v1 {
        margin-top: 4rem;
    }

    .tab-v1 {

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            margin-top: 3rem;
        }
        @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
            margin-top: 4rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            margin-top: 6rem;
        }

        .header-v1 {

            &_lvl-type-2 {
                margin-bottom: 2rem;
                font-weight: 700;
            }

        }

        &__panel-track-element_review {

            .action-group-v1 {
                justify-content: end;
            }

        }

        &__panel-track-element_delivery {

            .info-block-v1 {
                display: block;
            }

            .info-block-grid-v1 {
                @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
                    margin-bottom: 2rem;
                }
                @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
                    margin-bottom: 4rem;
                }
                @include mix.breakpoint(var.$desktop-sm-width) {
                    margin-bottom: 6rem;
                }
            }

            .info-block-grid-v2 .header-v1.header-v1_lvl-type-4 {
                align-items: start;
            }

            .header-v1.header-v1_lvl-type-2:nth-child(4) {
                @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
                    margin-top: 1rem;
                }
                @include mix.breakpointRange(var.$tablet-width, var.$tablet-h-width) {
                    margin-top: 3rem;
                }
                @include mix.breakpoint(var.$desktop-sm-width) {
                    margin-top: 5rem;
                }
            }

            table.table-v1 tr td:nth-child(2) {
                font-size: 2.4rem;
                font-weight: 400;
            }

            table.table-v1 tr td:nth-child(3) {
                font-size: 2.4rem;
                font-weight: 400;
            }

        }

        &__panel-track-element_review {

            .rating-bar-v1 {
                display: grid;
                grid-template-columns: 37% 50%;
                grid-template-rows: min-content;
                align-items: center;

                .badge-v1 .badge-v1__label {

                    @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
                        color: var.$color-text;
                    }

                }

            }

            .popular-info-v1 {
                font-weight: 400;
                font-size: 1rem;
            }

        }

        &__panel-track-element_faq {

            .row .action-group-v1 {
                justify-content: end;
            }

        }

        &__panel-track-element_file {

            .client-gallery-v1 .gallery-v2__track-element:hover {
                cursor: pointer;
                box-shadow: 0px 56px 23px rgba(0, 0, 0, 0.01),
                0px 32px 19px rgba(0, 0, 0, 0.02),
                0px 14px 14px rgba(0, 0, 0, 0.03),
                0px 4px 8px rgba(0, 0, 0, 0.04),
                0px 0px 0px rgba(0, 0, 0, 0.04);
            }

        }


    }

    .tabs-content {
        margin-top: 4.4rem;
    }

    .tabs-content {
        .header-v1 {
            font-size: 3rem;
            font-weight: 700;
            line-height: 4.8rem;
            margin-bottom: 3.6rem;
        }
    }

    &__product-description {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 6rem 0 4rem 0;

        p {
            font-family: var.$font-text;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.4rem;
            color: var.$color-text;

            span {
                text-decoration: underline;
            }
        }

        img {
            margin-right: 1.6rem;
            height: auto;
        }
    }

    &__guarantee {

    }

    &__guarantee-content {
        align-items: flex-start;
        display: flex;

        p {
            align-self: flex-end;
            margin-bottom: 0;
        }
    }

    &__tab {
        margin-bottom: 8rem;
    }

    &__reviews-control-panel {
        display: flex;
        align-items: start;
        gap: 0.5rem;
        margin-bottom: 6rem;
    }

    &__reviews-count {

        white-space: nowrap;

    }

    &__main-description {
        margin-top: 8rem;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        &__reviews-control-panel {
            flex-wrap: wrap;
        }
    }

}

/** \DEPENDS CODE */
.card-page-v1 {
    &__tab {
        .title-v2 {
            font-size: 3rem;
            font-weight: 700;
            line-height: 4.8rem;
            margin-bottom: 3.6rem;
        }
    }

    &__reviews-count .link-v1, &__reviews-control-panel .rating-star-v2 .badge-v1.badge-v1_content-type-label .badge-v1__label {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2rem;
        color: var.$color-black-tone4;
    }

    .review-add-button-v1 {
        justify-content: left;

        .review-add-button-v1__add-button {
            margin-left: 9rem;
            padding: 1rem 4.7rem;
        }

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            & {
                width: 100%
            }
            .review-add-button-v1__add-button {
                margin-left: 0;
            }
        }
    }

    .product-composition-v1 {
        margin: 8rem 0;
    }

    .pagination-v1__content {
        justify-content: center;
    }

    &__description-text {
        p {
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 400;
        }
    }

    .product-composition-v2 {
        margin: 4.5rem 0;
    }

    .subscription-request-v1 {
        margin-bottom: 4rem;
    }
}