@use 'base/variables' as var;
@use 'base/mixins' as mix;

.brand {
    overflow: hidden;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 12rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.4rem;
    }
    .title {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6.5rem;
        }
    }
    &__list {
        display: flex;
        overflow: hidden;

        @media (max-width: var.$tablet-width - 1) {
          max-height: 8rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            // flex-wrap: wrap;
            margin-right: -2rem;
            margin-top: -2rem;
        }
    }
    &__item {
        margin: 0 2rem;
        width: 10.5rem;
        outline: none;
        @include mix.breakpoint(var.$tablet-width) {
            width: 19.7rem;
            margin: 0 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            // width: calc(100% / 6 - 2rem);
            width: 23rem;
            margin-left: 0;
            margin-right: 2rem;
            margin-top: 2rem;
        }

        a {
          display: block;
        }
    }

    &__img {
        @include mix.breakpoint(var.$desktop-width) {
            transition: all 0.3s;
            filter: grayscale(100%);
        }

        &:hover {
            filter: grayscale(0);
        }
    }
}
.brand__list{
    img{
        max-width: 130px;
    }
}
