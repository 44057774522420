@use 'base/variables' as var;
@use 'base/mixins' as mix;

.more {
    margin-bottom: 8rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 9rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6rem;
    }
    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }
    }

    &__old-price {
        background-color: var.$color-second;
        padding: 2rem 1.5rem;
        border-radius: 4px;
        margin-bottom: 1.5rem;
        background-image: url("/img/mister-purple-left.svg");
        background-repeat: no-repeat;
        background-position: bottom right 1.5rem;
        background-size: 7rem;

        .title {
            max-width: 18.5rem;
            margin-bottom: 2.3rem;
        }
        @include mix.breakpoint(var.$mobile-width) {
            background-size: 10.4rem 10.1rem;
            background-position: bottom right 2rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: 47.8rem;
            padding: 3rem 2rem;
            margin-bottom: 0;
            margin-right: 2rem;
            flex-grow: 1;
            background-size: 10.7rem 10.5rem;
            background-position: bottom right 2.5rem;
            .title {
                max-width: 32.2rem;
                margin-bottom: 1rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 84.7rem;
            flex-grow: 0;
            padding: 4rem 3rem;
            background-size: 20.8rem 20.2rem;
            background-position: bottom right 9rem;
            .title {
                max-width: 42rem;
                margin-bottom: 6rem;
            }
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            padding: 4rem 6rem;
            max-width: none;
            width: 95.3rem;
            background-position: bottom right 17rem;
        }
    }

    &__all-sale {
        background-color: #F5F7FA;
        padding: 2rem 1.5rem;
        border-radius: 4px;
        .title {
            margin-bottom: 1.9rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: 23rem;
            padding: 3rem 2rem;
            margin-bottom: 0;
            // margin-right: 2rem;
            flex-grow: 1;
            .title {
                max-width: 20rem;
                margin-bottom: 1rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            .title {
                max-width: 23.2rem;
                margin-bottom: 6rem;
            }
        }
    }

    &__link {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-decoration: none;
        color: var.$color-text;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    &__link-icon {
        width: 5px;
        height: 8px;
        stroke: currentColor;
        margin-left: 5px;
    }

    // .title {

    //     @include mix.breakpoint(var.$tablet-width) {
    //         margin-bottom: 1rem;
    //     }
    //     @include mix.breakpoint(var.$desktop-width) {
    //         margin-bottom: 5.4rem;
    //     }
    // }
}
