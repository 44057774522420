@use 'base/variables' as var;
@use 'base/mixins' as mix;

.size-selector-v1 {
    position: relative;


    &__highlight {
        font-family: var.$font-text;
        color: var.$color-main;
        text-decoration: underline;
        cursor: pointer;
    }

    &__dropdown {
        overflow-y: auto;
        display: none;
        &.active {
            display: block;
        }
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        background: var.$color-white;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
        padding: 0.7rem 0 1.2rem 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    &__dropdown-item {
        text-align: center;
        &:hover {
            font-weight: 600;
            cursor: pointer;
        }
        &:first-child {
            margin-top: 2rem;
        }

        margin-top: 0.9rem;
        font-weight: 400;
        a {
            text-decoration: none;
        }
    }
    &__dropdown-item-active {
        font-weight: 500;
    }
}

/** DEPENDS CODE */
.size-selector-v1 {

}

/** DEPENDS CODE */