@use 'base/variables' as var;
@use 'base/mixins' as mix;

.detail-price {
    display: block;
    margin-bottom: 2rem;
    order: -1;
    @include mix.breakpoint(var.$mobile-2lg-width) {
        display: flex;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        margin-bottom: 1rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        display: block;
        margin-bottom: 3rem;

    }
    &__content-wrap {

        flex-grow: 1;
        grid-template-areas:
                "old empty"
            "current credit";
        display: grid;
        grid-column-gap: 2rem;
        align-items: flex-start;
        @include mix.breakpoint(var.$mobile-2lg-width) {
            display:block;
            width:50%;
        }
        /*@include mix.breakpoint(var.$tablet-width) {
                margin-bottom: 1.5rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // margin-bottom: 0.5rem;
            }*/
        @include mix.breakpoint(var.$tablet-width) {
            flex-wrap: wrap;
            align-items: center;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            //display: block;
            //margin-bottom: 0;
            flex-grow: 1;
            grid-template-areas:
                "old empty"
            "current credit";
            display: grid;
            grid-column-gap: 2rem;
            align-items: flex-start;
            width: 100%;
        }
    }
    &__old {
        position: relative;
        display: inline-block;
        width: max-content;

        font-size: 1.6rem;
        line-height: 2rem;
        color: #94A2B8;
        grid-area: old;
        text-decoration: line-through;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$mobile-2lg-width) {
            text-decoration: none;
            margin-bottom: 2rem;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                transform: translateY(-50%);
                background-color: var.$color-text;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            order: -1;
        }

    }

    &__current {
        display: block;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 1;
        margin-bottom: 3rem;
        grid-area: current;
        @include mix.breakpoint(var.$tablet-width) {
            order: -1;
            margin-right: 1.5rem;
            margin-bottom: 0;
            font-size: 2.5rem;
            margin-bottom: .5rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 2.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            //margin-bottom: 1.2rem;
            margin-bottom:0;
            font-size: 3rem;
            line-height: 4rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            font-size: 3.5rem;
            line-height: 4.4rem;
        }
    }

    &__subtitle {
        display: block;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.5rem;
            line-height: 1.9rem;
        }
    }

    &__month-price {
        display: inline-flex;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: var.$color-main;
        align-items: center;
        cursor: pointer;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 0.5rem;
            font-size: 1.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 0;
            font-size: 1.5rem;
            line-height: 1.9rem;
        }

        .js-month-pay {
          font-weight: 500;
          font-size: 1.3rem;
          line-height: 1.6rem;
          color: var.$color-main;
          text-decoration: none;
        }

    }

    &__btn-wrap {
        flex-grow: 1;
        width: 100%;
        margin-top:1rem;
        .btn {
            font-size: 1.5rem;
            line-height: 1.9rem;
            width: 100%;
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
        }
        @include mix.breakpoint(var.$mobile-2lg-width) {
            //width: 16.5rem;
            margin-top: 0;
            width: 50%;
            display: block;
        }
        @include mix.breakpoint(var.$tablet-width) {
            //display: flex;
            display: block;
            //margin-right: -1rem;
            flex-grow: 0;
            .btn {
                width: calc(100% / 2 - 1rem);
                margin-right: 1rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            //width: 23.4rem;
            width: 100%;
            margin-right: 0;
            .btn {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    &__buy-btn {
        //margin-bottom: 1.2rem;
        margin-right: 0.2rem;
        order: 0;
        margin-bottom: 0;
        flex-grow: 1;
        width: calc(49% - 0.4rem);

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
            order: 2;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 1.2rem;
        }
    }
    &__buy-one {
        width: calc(50% - 0.4rem);
        flex-grow: 1;
        margin-left: 0.4rem;
    }
}
