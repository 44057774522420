@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-input-v1 {

    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    padding: 1.2rem 2rem;
    border: 1px solid var.$color-border-v2;
    border-radius: 0.5rem;
    width: 100%;

    &::placeholder {
        color: var.$color-main-tone7;
    }
    
}