@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-add {
    // &__popup {
        max-width: 43.3rem;
        padding: 2rem;
        padding-top: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 2rem;
            padding-top: 2.5rem;
        }
    // }

    &__title {
        font-size: 1.6rem;
        line-height: 2.1rem;
        font-family: "Affect";
        font-weight: 700;
        margin: 0;
        margin-bottom: 2.4rem;
    }

    &__good {
        padding-top: 1.5rem;
        padding-left: 1rem;
        padding-bottom: 2rem;
        padding-right: 3rem;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$mobile-width) {
            display: flex;
            align-items: center;
        }
    }

    &__img {
        width: 9rem;
        flex-shrink: 0;
        margin-right: 2rem;
    }

    &__good-name {
        font-size: 1.6rem;
        line-height: 2rem;
        // max-width:
    }

    &__bottom {
        text-align: center;
        @include mix.breakpoint(var.$mobile-width) {
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__continue {
        font-size: 1.4rem;
        line-height: 1.9rem;
        border: none;
        margin-bottom: 1rem;
        padding: 0;
        background-color: transparent;
        border-bottom: 1px dashed currentColor;
        outline: none;
        @include mix.breakpoint(var.$mobile-width) {
            font-size: 1.4rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
        }
    }

    &__cart-link {
        width: 100%;
        @include mix.breakpoint(var.$mobile-width) {
            width: auto;
        }
    }

    &__price {
        display: flex;
        align-items: center;
        margin-bottom: 3rem;
    }

    &__price-title {
        margin: 0;
        margin-right: auto;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 1.1;
    }

    &__price-value {
        margin: 0;
        margin-left: 2rem;
        font-size: 1.6rem;
        line-height: 1.1;
    }

    .price {
        display: flex;
        align-items: center;
        &__current {
            font-size: 1.6rem;
            margin: 0;
        }

        &__old {
            margin-right: 1.5rem;
            line-height: 1;
        }
    }
}
