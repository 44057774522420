@use 'base/variables' as var;
@use 'base/mixins' as mix;

.remove-btn {
    width: 3.4rem;
    height: 3.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 0;
    background-color: transparent;
    @include mix.breakpoint(var.$tablet-width) {
        right: 0.5rem;
    }

    &__icon {
        fill: #E3E3E3;
        width: 1.6rem;
        height: 1.6rem;
        transition: all .3s;
    }
}
