@use 'base/variables' as var;
@use 'base/mixins' as mix;

.textile-switcher-v1 {

    margin-top: 1rem;

    &__collection-block {
        margin-top: 1rem;
    }

    &__collection-head {
        font-weight: 600;
        color: #3c3c70;
    }

    &__option-wrap {
        margin-top: 1rem;
        display: grid;
        grid-auto-flow: row;
        row-gap: 2rem;
        grid-template-columns: repeat(auto-fit, 11rem);
    }

    &__option-block {
        cursor: pointer;
    }

    &__option-block.textile-switcher-v1__option-block_disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &__option-block:hover &__option-image,
    &__option-block.textile-switcher-v1__option-block_active &__option-image {
        border-color: rgba(var.$color-text, 0.3);
    }

    &__option-image {
        position: relative;
        width: 11rem;
        height: 11rem;
        border: 0.1rem solid var.$color-white;
        border-radius: 0.6rem;
    }

    &__option-image::before,
    &__option-image img {
        width: 9rem;
        height: 9rem;
        top: 1rem;
        left: 1rem;
    }

    &__option-image img {
        position: absolute;
        object-fit: cover;
        z-index: 1;
    }

    &__option-image::before {
        background-image: url(/img/textile-pattern.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        z-index: 2;
        position: absolute;
    }

    &__option-name {
        margin-top: 1rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;
        color: #3c3c70;
    }

}

// /img/textile-pattern.svg
