@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-common-page-v1 {
    #reviewsList {
        margin-top: 10rem;
        margin-bottom: 10rem;
    }
}

//deps
.review-common-page-v1 {
    .breadcrumb-v1 {
        padding-top: 5.5rem;
        margin-bottom: 3rem;
    }

    @include mix.breakpoint(var.$desktop-width) {
        .breadcrumb-v1 {
            padding-top: 3rem;
            margin-bottom: 3.5rem;
        }
    }

    .review-add-button-v1 {
        justify-content: start;
    }
}