@use 'base/variables' as var;
@use 'base/mixins' as mix;

/*#jivosite_mobile_custom{
  bottom: 50px;
  right: 10px;
  display: none;
  position: fixed;
}*/
/* скрываем оригинальный виджет - чтобы не было два ярлыка на экране*/
/*#jivo_chat_widget{
  display: none;
}*/

/* стиль по умолчанию - для оффлайн сообщений, если никого нет в онлайне */
#jivo_custom_widget{
  bottom: 140px;
  right:0;
  display: none;
  position: fixed;
  z-index: 998;
  /*@include mix.breakpoint(var.$mobile-width) {
    bottom: 140px;
    right:0;
    display: none;
    position: fixed;
    z-index: 3000000;
  }

  @include mix.breakpoint(var.$tablet-width) {
    position: fixed;
    right: 0px;
    top: 30%;
    width: 3rem;
    height: 17rem;
    z-index: 300000;
    cursor: pointer;
    display: block;
    svg:first-child{
      width: 3rem;
      height: auto;
      !* position: absolute; *!
      !* top: -2.2rem; *!
      position: absolute;
      z-index: 300001;
    }
  }*//*
  position: fixed;
  right: -3px;
  top: 30%;
  width: 35px;
  z-index: 300000;
  cursor: pointer;

  height: 222px;
  background-image: url(images/jivo_widget_offline.png);*/
}
/*.jivo_custom_widget_text{
  display:none;
  @include mix.breakpoint(var.$tablet-width) {
    display: block;
    transform: rotate(270deg);
    white-space: nowrap;
    font-size: 0.8em;
    position: absolute;
    top: 8.5rem;
    right: -4.7rem;
    color: white;
  }
}
.jivo_custom_widget_background{
  display:none;
  @include mix.breakpoint(var.$tablet-width) {
    display:block;
    border-left: 3rem solid #7b75c9;
    height: 14rem;
    position: absolute;
    top: 2.3rem;
    border-bottom-left-radius: 1rem;
  }
}*/

/* при наведении ярлык должен сдвигаться вправо на 3px */
#jivo_custom_widget:hover{
  right:0;
}

/* если есть операторы в онлайне - показываем другой ярлык*/
#jivo_custom_widget.jivo_online{
  height: 178px;
}

._mobile-popup-open #jivo_custom_widget {
  display: none !important;
}
