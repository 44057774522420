@use 'base/variables' as var;
@use 'base/mixins' as mix;

.not-found {
    &__section {
        margin-bottom: 10rem;
        margin-top: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 8rem;
            margin-bottom: 20rem;
        }
    }

    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-rows: 1fr;
            grid-template-columns: auto;
            grid-template-rows: auto;
            gap: 2rem;
            grid-template-areas:
    "text get4click"
    "return return";
            justify-content: end;
            align-content: center;
            align-items: stretch;
        }
        @include mix.breakpoint(var.$desktop-width) {
            grid-template-columns: 7fr 4fr 1fr;
            grid-template-areas:
    "text get4click return";
            grid-template-rows: none;
        }
    }



    &__content {
        background-color: var.$color-main;
        border-radius: 4px;
        padding: 3rem 2rem;
        margin-bottom: 2rem;
        background-image: url("/img/mister-purple-sad.svg");
        background-repeat: no-repeat;
        background-size: 9rem 11rem;
        background-position: bottom right 2.5rem;

        .call__form{
            display: inline-block !important;
            width: auto !important;
        }

        &.wrong-data-content{
            background-color: var.$color-second;
            background-image: url("/img/maskot-purpl-degree45-hurray.svg");
            background-position: 100% 200%;
            background-size: 12rem 18rem;
            .title {
                color: #3c3c70;
            }
            @include mix.breakpoint(var.$tablet-width) {
                background-position: bottom right 0;
                background-size: 10rem 10rem;
            }
            @include mix.breakpoint(var.$tablet-h-width) {
                background-position: bottom right 0;
                background-size: 15rem 20rem;
            }

        }

        .title {
            margin-bottom: 1.5rem;
            color: #fff;
        }

        &.success-content {
          background-color: #EBF0F5;
          background-image: url("/img/mister-purple-tricky-desktop.svg");
          .title {
            color: #3c3c70;
          }
        }
        &.nobg-image{
            background-image: none !important;
        }
        &.success-content-thankyou{
            padding:2rem;
        }
        #promocode-element-container{

        }

        @include mix.breakpoint(var.$tablet-width) {
            flex-grow: 1;
            min-height: 30rem;
            width: 100%;
            margin-right: 2rem;
            margin-bottom: 0;
            min-height: 35rem;
            background-image: url("/img/mister-purple-sad-desktop.svg");
            background-size: 16rem 17rem;
            background-position: bottom right 3.2rem;

            .title {
                font-size: 2.8rem;
                max-width: 16.6rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 100%;
            padding: 5rem 6rem;
            background-size: 17rem 20.6rem;
            background-position: bottom right 9rem;
            .title {
                max-width: 24.2rem;
                margin-bottom: 2.5rem;
            }
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            background-position: bottom right 11rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            width: 100%;
        }

    }

    &__text {
        color: #fff;
        font-size: 1.3rem;
        line-height: 1.5;
        @include mix.breakpoint(var.$tablet-width) {
            //max-width: 21.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            max-width: 36.4rem;
        }

    }

    &__return {
        background: #F5F7FA;
        border-radius: 4px;
        padding: 3rem 2rem;
        width: 100%;
        .title {
            margin-bottom: 2.5rem;
        }

        @include mix.breakpoint(var.$tablet-width) {

            display: flex;
            flex-direction: column;
            flex-grow: 0.7;
            .title {
                font-size: 3.2rem;
                margin-bottom: auto;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 6rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {

        }

    }

    &__link {
        color: var.$color-text;
        text-decoration: none;
        font-weight: 500;
    }
}

.success-content {
  .not-found__text {
    color: #3c3c70;
  }
}
.success-return {
  background-color: var.$color-main;
    height:100%;
  color: #fff;
  a {
    color: #fff;
  }
}
.success-container {
  padding: 32px 0;
  @include mix.breakpoint(var.$tablet-width) {
      width: 46.1rem;
  }
  @include mix.breakpoint(var.$desktop-width) {
      width: 84.4rem;
  }
  @include mix.breakpoint(var.$desktop-lg-width) {
      width: 83.4rem;
  }
}
