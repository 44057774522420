@use 'base/variables' as var;
@use 'base/mixins' as mix;

.article-main {
    margin-bottom: 10rem;

    @media (max-width: var.$tablet-width - 1) {
      .container {
        padding: 0;
      }
    }

    &__content {
        padding: 4rem 2rem;
        margin-bottom: 3rem;


        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem 2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            padding-right: 8.8rem;
            margin: 0;
            margin-right: 2rem;
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding-right: 6.2rem;
        }

        p {
            font-size: 1.6rem;
            line-height: 130%;
            margin: 0;
            margin-bottom: 1.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__inner {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
        }
    }

    &__author {
        @include mix.breakpoint(var.$desktop-width) {
            width: 52.2rem;
            flex-shrink: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 58.8rem;
        }

        &._center {
          margin: auto;
        }

    }



    &__title {
        margin: 0;
        margin-bottom: 3rem;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 500;
    }


}
