@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-filter-popup-v1 {
    display: none;
    border-radius: 0.8rem;
    padding: 1.2rem 1.2rem 2.4rem 1.2rem;
    gap: 2.4rem;
    z-index: 10;
    border: 0.1rem solid var.$color-gray-tone12;
    background: var.$color-white;


    &__close {
        cursor: pointer;
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
        & {
            width: 100%;
        }
    }
}

// deps
.mfp-content {
    .product-filter-popup-v1 {
        display: block;
    }
}

.product-filter-popup-v1 {
    .title-v2 {
        font-family: var.$font-text;
        font-weight: 500;
        font-size: 1.6rem;
        margin: 1.1rem auto;
        text-align: Center
    }
}

// \deps