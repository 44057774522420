@use 'base/variables' as var;
@use 'base/mixins' as mix;

.site-footer {
    border-top: 1px solid #E3E3E3;
    padding-bottom: 4.9rem; //sticky
    padding-top: 4rem;
    @include mix.breakpoint(var.$tablet-width) {
        padding-bottom: 0;
    }
    @include mix.breakpoint(var.$desktop-width) {
        padding-top: 0;
    }

    // top
    &__top-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        padding-bottom: 6rem;
        margin-top: -4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: -3rem;
            padding-bottom: 4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 0;
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
        }
    }
    &__logo {
        display: inline-block;
        margin-top: 4rem;
        margin-left: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 3rem;
            margin-right: 4.8rem;
            margin-top: 3rem;
            order: 1;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 0;
            margin-top: 0;
            margin-right: 3.8rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-right: 15rem;
        }
    }

    &__logo-img {
        width: 10.4rem;
        height: 4.6rem;
        display: block;
    }

    &__contact-wrap {
        margin-top: 4rem;
        &:nth-child(odd) {
            margin-left: .5rem;
        }
        &:nth-child(even) {
            margin-right: .5rem;
        }

        &._address {
            width: 100%;
        }
        @include mix.breakpoint(var.$mobile-width) {
            &:nth-child(odd) {
                margin-left: 1rem;
            }
            &:nth-child(even) {
                margin-right: 1rem;
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 3rem;
            &._phone {
                order: 2;
            }
            &._mail {
                margin-left: auto;
                margin-right: auto;
                order: 3;
            }
            &._work-time {
                order: 4;
            }
            &._address {
                order: 5;
                margin-left: 18.2rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                margin-top: 0;
                &._address {
                    width: auto;
                    margin-left: 0;
                }

                &._mail,
                &._phone,
                &._work-time {
                    margin-right: 1rem;
                    margin-left: 1rem;
                }
            }
        }

    }

    &__contact-title {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #C1C1C1;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }

    &__contact-data {
        color: var.$color-text;
        text-decoration: none;
        margin: 0;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 500;
        @include mix.breakpoint(var.$mobile-width) {
            font-size: 1.7rem;
        }
    }

    // end top

    // middle
    &__middle {
        background-color: var.$color-text;
    }

    &__middle-inner {
        padding: 4rem 1rem;
        padding-top: 3.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            // padding: 3rem 0;
            padding-top: 2.5rem;
            padding-bottom: 3rem;
        }
        /*@include mix.breakpoint(var.$desktop-width) {
            display: flex;
            padding: 3.3rem 0;
        }*/
    }

    &__middle-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7.4rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: auto;
            margin-bottom: 0;
        }
    }

    &__middle-column {
        width: 42%;
        margin-top: -1.8rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: flex;
            justify-content: space-around;
            width: auto;
            // width: 50%;
        }
    }

    &__nav-item {
        margin-top: 1.8rem;
        white-space: nowrap;
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 0;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-right: 2.5rem;
        }
    }
    &__nav-link {
        color: #fff;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }

    // end middle

    // bottom
    &__bottom {
        padding-top: 4rem;
        // padding-bottom: 5.2rem;
        font-family: "Roboto";
        color: #AFAFAF;
        font-size: 1.2rem;
        line-height: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 2.5rem 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 2rem;

        }
    }

    &__bottom-inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__copyright {
        margin: 0;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2rem;
            flex-shrink: 0;
        }
    }

    &__data {
        margin: 0;
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 48rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: auto;

        }
    }

    &__ibrush-link {
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 8.5rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-left: 0;
        }
    }

    &__ibrush-icon {
        width: 7rem;
        height: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 5.1rem;
            height: 1.1rem;
        }
    }

    .site-header__indicators-count {
        top: -0.6rem;
        right: -0.9rem;
    }


}
//dependencies
.site-footer {
    .link-v1 {
        color: var.$color-white;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }
    .link-v1:hover {
        border-bottom-color: currentColor;
        color: var.$color-white;
    }
}
