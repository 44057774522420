@use 'base/variables' as var;
@use 'base/mixins' as mix;

.faq-v1 {

    &__item {

        .accordion-button {
            padding: 3.5rem 2.5rem 3.5rem 2.5rem;
            font-size: 2rem;
            line-height: 2.5rem;
        }

        .accordion-button:focus {
            box-shadow: none;
            border-color: var.$color-gray-tone1;
        }

        .accordion-button:not(.collapsed) {
            color: var.$color-white;
            background-color: var.$color-text;
        }

        .accordion-button::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuMTIxMDlMNi41IDcuMTIxMDlMMTIgMS4xMjEwOSIgc3Ryb2tlPSIjN0I3NUM5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuMTIxMDlMNi41IDcuMTIxMDlMMTIgMS4xMjEwOSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        }

        
    }

    .accordion-collapse {
        background-color: var.$color-third;
        color: var.$color-text;
    }
    
    .accordion-item:nth-child(1) {
        border-top: 0.1rem solid var.$color-gray-tone1;
    }

    .accordion-item {
        border: 0;
        border-bottom: 0.1rem solid var.$color-gray-tone1;
    }

}

/** DEPENDS CODE */
.faq-v1 {
}
/** DEPENDS CODE */