@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-section {

    width: 100%;
    // max-width: 100%;

    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5.6rem;
    }
    &__inner {
        position: relative;
        background-color: var.$color-second;
        padding: 2rem 1.5rem;
        background-image: url("/img/transport-purple.svg");
        background-repeat: no-repeat;
        background-size: 10rem 5.4rem;
        background-position: bottom 2rem right 1.5rem;
        border-radius: 4px;
        .title {
            margin-bottom: 1.5rem;
        }
        @include mix.breakpoint(var.$mobile-width) {
            background-position: bottom 2rem right 4rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem 2rem;
            background-size: 22rem 12rem;
            background-position: top 6rem right 6.2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            min-height: 25rem;
            background-size: 30.5rem 16.6rem;
            background-position: center right 35rem;

            .title {
                margin-bottom: 3.4rem;
            }
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            background-position: center right 40rem;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            padding-left: 5rem;
            padding-right: 90rem;
            background-position: center right 49.4rem;
            .title {
                margin-bottom: 3.5rem;
            }
        }
    }

    &__text {
        font-size: 1.4rem;
        line-height: 1.3;
        margin-bottom: 2.2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 1rem;
            margin-top: 1.7rem;
            max-width: 38rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            max-width: 56rem;
        }
    }

    .link {
        margin-bottom: 1.7rem;
        display: inline-block;
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-weight: 400;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            position: absolute;
            right: 10rem;
            bottom: 4rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            right: 14rem;
        }
    }


}
