@use 'base/variables' as var;
@use 'base/mixins' as mix;

.regions {
    margin-bottom: 10rem;

    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 12rem;

        .title {
            margin-bottom: 0;
            margin-right: 2rem;
        }
    }

    .title {
        margin-bottom: 5rem;
    }

    &__wrap {
        border: 1px solid #ebf0f5;
        border-radius: 2px;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 5rem;
            border: 0;
        }
    }

    &__top {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-bottom: 4rem;
        }
    }

    &__select {
        margin-bottom: 2rem;

        @include mix.breakpoint(var.$tablet-width) {
            width: 23rem;
            flex-shrink: 0;
            margin-left: auto;
            margin-bottom: 0;
        }

        .select__face {
            border: 1px solid #ebf0f5;
        }

        .select__option-content {
            text-align: left;
        }
    }

    &__list {
        display: block;
        @include mix.breakpoint(var.$mobile-2lg-width) {
            display: block;
            column-count: 2;
            column-gap: 7rem;
            // -webkit-column-width: 200px;
            // column-width: 180px;
        }
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
            column-count: 3;
            column-gap: 7rem;
            // -webkit-column-width: 200px;
            // column-width: 180px;
        }
        // @include mix.breakpoint(var.$tablet-h-width) {
        //     column-gap: 20rem;
        // }
        @include mix.breakpoint(var.$tablet-h-width) {
            column-count: 4;
            column-gap: 6.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            column-count: 5;
            column-gap: 3rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            column-gap: 5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            column-gap: 11rem;
        }
    }

    &__item {
        margin-bottom: 2rem;
        // max-width: 18rem;
        // display: inline-block;
    }

    &__link {
        font-size: 2rem;
        line-height: 2.2rem;
        color: var.$color-text;
        text-decoration: none;
        padding-right: 1.5rem;

        &._active {
            font-weight: 600;
            background-image: url("/img/right-mini.svg");
            background-repeat: no-repeat;
            background-position: top 0.9rem right;
            background-size: 1rem 1.2rem;
        }
    }

    &__map {
        height: 34rem;
    }

    .map-data__item:not(:last-child) {
        margin-bottom: 1.2rem;
    }


}
