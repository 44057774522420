@use 'base/variables' as var;
@use 'base/mixins' as mix;

.promotion-card-v1 {

    width: 21.5rem !important;
    height: 16.1rem;
    border-radius: 6rem 0 0.4rem 0.4rem;
    position: relative;
    overflow: hidden;
    padding: 1.8rem 3rem 1.5rem 1.5rem;

    &_gradient-type-type1 {
        background: linear-gradient(90deg, #F2709C 0%, #FF9472 100%);
    }

    &_gradient-type-type2 {
        background: linear-gradient(90deg, #C84E89 0%, #F15F79 100%);
    }

    &_gradient-type-type3 {
        background: linear-gradient(90deg, #7474BF 0%, #348AC7 100%);
    }

    &_gradient-type-type4 {
        background: linear-gradient(90deg, #314755 0%, #26A0DA 100%);
    }

    &_gradient-type-type5 {
        background: linear-gradient(90deg, #348F50 0%, #56B4D3 100%);
    }

}

.promotion-card-v1_gradient-type-type1 .promotion-card-v1__date {
    color: #F57696;
}
.promotion-card-v1_gradient-type-type2 .promotion-card-v1__date {
    color: #D05287;
}
.promotion-card-v1_gradient-type-type3 .promotion-card-v1__date {
    color: #6779C1;
}
.promotion-card-v1_gradient-type-type4 .promotion-card-v1__date {
    color: #2F5B73;
}
.promotion-card-v1_gradient-type-type5 .promotion-card-v1__date {
    color: #3B9669;
}

/** DEPENDS CODE */
.promotion-card-v1 {

    .image-v1 {
        position: absolute;
        width: 13rem;
        height: 8rem;
        left: 9rem;
        top: 8.5rem;
    }

    &__subtitle {
        font-family: var.$font-text;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        color: var.$color-white;
        text-align: right;
    }

    .header-v1 {
        font-size: 2.7rem;
        color: var.$color-white;
        text-align: center;
        font-family: var.$font-special;
        font-weight: 900;
        text-align: left;
        margin-left: 1.5rem;
    }

    &__date {
        border-radius: 0.8rem;
        padding: 0.5rem;
        line-height: 1.2;
        font-weight: 500;
        font-size: 1rem;
        font-family: var.$font-text;
        background-color: var.$color-white;
        float: left;
        margin-top: 2.1rem;
    }
}

/** \DEPENDS CODE */
