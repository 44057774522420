@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-row-v7 {
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/** DEPENDS CODE */
.receipt-row-v7 {
    
    &__value {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.44rem;
        color: var.$color-gray;
        text-align: right;
    }

    &__subvalue {
        font-family: var.$font-text;
        font-weight: 500;
        font-size: 1.4rem;
        text-align: right;
        color: var.$color-text;
        white-space: nowrap;
    }

}

/** \DEPENDS CODE */
