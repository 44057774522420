@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
a {
    &:hover {
        color: inherit;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
*:focus {
    outline: var.$color-border solid 1px;
}

html,
body {
    font-size: 10px;
    min-width: 32rem;
    width: 100%;
    max-width: 100%;
}

body {
    // line-height: 1;
    min-width: 32rem;
    width: 100%;
    max-width: 100%;
}

body > * {
    width: 100%;
}

body._google-test-1 .card__buy-btn{
    background-color: var.$color-second;
    color: var.$color-text;
}
body._google-test-1 .card__buy-btn:hover{
    background-color: var.$color-main;
    color: #fff;
}

address {
    font-style: normal;
}

button, [type="button"],.button {
    cursor: pointer;
}

img {
    height: auto;
    max-width: 100%;
}

label {
    cursor: pointer;
    user-select: none;
}

textarea {
    resize: vertical;
}

ul {
    @include mix.list-reset;
}
ul.list-style{
    list-style: disc;
    padding-left: 2rem;
}

@include mix.placeholder {
    text-overflow: ellipsis;
}

input[placeholder] {
    text-overflow: ellipsis;
    white-space: nowrap;
}

input {
    border-radius: 0;
    -webkit-appearance: none;
}

:focus::-webkit-input-placeholder {
    color: transparent;
}

:focus::-moz-placeholder {
    color: transparent;
}

:focus:-moz-placeholder {
    color: transparent;
}

:focus:-ms-input-placeholder {
    color: transparent;
}
/* critical:end */
.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
/* critical:start */
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: var.$container-width;
    padding: 0 1.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        padding: 0 var.$container-padding-x-md;
    }
    @include mix.breakpoint(var.$desktop-width) {
        padding: 0 var.$container-padding-x-lg;
    }
}

.site-wrapper {
    // display: flex;
    // flex-direction: column;
    font-family: "EuclidCircularA", Arial, sans-serif;
    font-size: 1.6rem;
    color: var.$color-text;
    min-height: 100vh;
}

.site-content {
    flex: 1 0 auto;
}

.bg-cover {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// выключает стрелки

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type=number] {
-moz-appearance: textfield;
}

.overflow-hidden {
    overflow: hidden;
}

@media (max-width: var.$tablet-width - 1) {
    ._sticky-header {
        .site-header__top {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        }
    }

    // filter
    ._filter-open {
        .filter {
            top: var(--sort-height);
            height: calc(100 * var(--vh) - var(--footer-height) - var(--sort-height));
            transform: none;
            overflow: hidden;
            .container {
                padding: 0;
            }

            &__inner {
                height: calc(100 * var(--vh) - var(--footer-height) - var(--sort-height));
                overflow-x: hidden;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                @include mix.breakpoint(var.$mobile-width) {
                    transition: all .3s;
                    display: block;
                }
            }
        }
        .head-discount{
            display:none;
        }
        /* critical:start */
        .goods {
            &__sort {
                display:none;
                position: fixed;
                top: 0;
                width: 100vw;
                margin-left: -1.6rem;
                left: 1.6rem;
                padding: .7rem 1.6rem;
                z-index: 150;
                background-color: #fff;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
            }

            .select {
                margin-right: 0;
                @include mix.breakpoint(var.$mobile-width) {
                    margin-right: 1.3rem;
                }
            }

            &__delivery {
                display: none;
            }

            &__filter-link {
                margin-right: 0;
                margin-top: 0;
                display: block;
            }

            &__sort-select {
                margin-top: 0!important;
            }

            ._filter-opened {
                display: block;
            }

            ._filter-closed {
                display: none;
            }


        }
        /* critical:end */
        /*.sticky-footer{
            display: none;
        }*/
        .site-header__top {
            box-shadow: none;
            transform: translateY(-100%);
        }

        .tooltipster-base {
            z-index: 100!important;
        }

        // .sticky-footer__menu-icon {
        //   stroke: #fff;
        // }
    }

    // sticky sort filter
    ._filter-sort-sticked {
        .goods {
            &__sort {
                position: sticky;
                top: 0;
                width: 100vw;
                margin-left: -1.5rem;
                margin-right: -1.5rem;
                padding: .7rem 1.5rem;
                z-index: 150;
                background-color: #fff;
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);

                .select {
                    margin-right: 0;
                    width:25%;
                    &:not(.card__select) {
                        @include mix.breakpoint(var.$mobile-width) {
                            margin-right: 1.3rem;
                            flex-grow: 1;
                            // width: 10rem;
                        }
                    }
                }
            }



            &__delivery {
                display: none;
            }

            &__filter-link {
                margin-right: 0;
                margin-top: 0;
                display: block;
            }

            &__sort-select {
                margin-top: 0!important;
            }
        }

        .site-header__top {
            box-shadow: none;
            transform: translateY(-100%);
        }
    }

    /*._keyboard-open {
        .site-header__top,
        .cart-footer,
        .sticky-footer{
            position: static;
        }

        .filter {
            height: 100vh;
            top: 0;
            background-color: var.$color-main;
        }

        .goods__sort {
            position: static;
            z-index: 0;
        }
    }*/

    ._header-input-open {
        .sticky-footer {
            position: static;
        }

        .site-header {
            &__input-label {
                opacity: 0;
                pointer-events: none;
            }


            &__city-input {
                // width: 400px;
                border: 1px solid currentColor;
                opacity: 1;
                pointer-events: visible;
            }
        }
        // .form__input {
        //     position: static;
        // }

    }
}

@include mix.breakpoint(var.$tablet-width) {
    ._sticky-header {
        .sticky-footer {
            transform: none;
        }
    }

    ._header-input-open {
        .site-header {
            &__input-label {
                opacity: 0;
                pointer-events: none;
            }


            &__city-input {
                border: 1px solid currentColor;
                opacity: 1;
                pointer-events: visible;
            }
        }
    }
}

.section {
    margin-bottom: 8rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 8rem;
    }
}

.site-index{
    .section{
        &:nth-child(1){
            margin-top: 2rem;
            margin-bottom: 3.5rem;
        }
    }
}



// menu start

._menu-open {
    @media(max-width: var.$tablet-width - 1) {
    overflow: hidden;
    }

    .main-catalog {
        transform: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
        }
    }

    .site-header__menu-open, .sticky-footer__menu-icon {
    stroke: #fff;
    }

}

._fixed-header {
    .site-header__top {
        position: fixed;
        top: 0;
    }

    @media(max-width: var.$tablet-width - 1) {
    .site-header {
        margin-top: 61.55px;
    }
    }
    @media(max-width: var.$desktop-width - 1) {
    .site-header {
        margin-top: 67px;
    }
    }

}
/* critical:end */
// menu end

// cart start
._cart-open {
    overflow: hidden;
    .cart-popup {
        // visibility: visible;
        // left: 0;
        // display: flex;
        transform: none;
        // height: 100%;
        // overflow: scroll;
    }
    @include mix.breakpoint(var.$tablet-width) {
        overflow: initial;
        .cart-popup {
            opacity: 1;
            pointer-events: initial;
        }
    }
}
// cart end

// loading start
._loading {
    &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        height: 100vh;
        width: 100%;
        background-color: #fff;
    }
}
// loading end
// scrollbar
.ps__rail-y {
    opacity: 1!important;
    width: 8px!important;
    background-color: #ebf0f5!important;
    border-radius: 8px!important;
}

.ps__rail-x {
    opacity: 1!important;
    height: 8px!important;
    background-color: #ebf0f5!important;
    border-radius: 8px!important;
}
.ps__thumb-y {
    background-color: var.$color-main!important;
    width: 8px!important;
    right: initial!important;
    border-radius: 8px!important;
}

.ps__thumb-x {
    background-color: var.$color-main!important;
    height: 8px!important;
    bottom: initial!important;
    border-radius: 8px!important;
}

//


/* critical:start */
button,
input,
optgroup1,
select,
[type="button"],
.button,
textarea {
    font-family: "EuclidCircularA", Arial, sans-serif !important;
    color: var.$color-text;
}

* {
    -webkit-tap-highlight-color: transparent;
}

.h1-title {
font-size: 2rem !important;
@include mix.breakpoint(var.$tablet-width) {
    font-size: 3.2rem !important;
}
@include mix.breakpoint(var.$desktop-width) {
    font-size: 4rem !important;
}
}

.title {
    font-family: "Affect";
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.1;
    letter-spacing: 0.015em;
    margin-top: 0;
    margin-bottom: 2rem;
    &._white {
        color: #fff;
    }
    @include mix.breakpoint(var.$tablet-width) {
        font-size: 3.2rem;
        margin-bottom: 3rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        font-size: 4rem;
        margin-bottom: 6rem;
    }

}

.link {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-decoration: none;
    color: var.$color-text;
    transition: all .3s;
    @include mix.breakpoint(var.$tablet-width) {
        font-size: 1.6rem;
        line-height: 2rem;
    }

    &__icon {
        width: 5px;
        height: 8px;
        stroke: currentColor;
        margin-left: 5px;
        transition: all .3s;
        @include mix.breakpoint(var.$tablet-width) {
            height: 9px;
        }
    }

    &._grey {
        padding: 1.4rem 1.5rem;
        background: #EBF0F5;
        border-radius: 4px;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 1.7rem 2.5rem;
        }

        @media(hover:hover) {
            &:hover,
            &:focus {
                background-color: var.$color-text;
                color: #fff;
            }
        }
    }
}
/* critical:end */
.color-section {
    padding: 2rem 1rem;
    background-color: var.$color-main;
    border-radius: 4px;
    &._grey {
        background-color: #EBF0F5;
    }
    @include mix.breakpoint(var.$mobile-width) {
        padding: 2rem 1.5rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        padding: 4rem 3rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        padding: 5rem 6rem;
    }
}

// slick

.slick-slide {
    outline: none;

    .review__item {
    height: 100%;
    }
}

.slick-slider {
    display: none;

    &.slick-initialized {
        visibility: visible;
        display: block;
    }
}

._transform-disable {
    transform: none!important;
}

.slick-dots {
    // position: absolute;
    // bottom: 2rem;
    // left: 50%;
    // transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    margin: 0 auto;
    justify-content: center;

    li {
        margin: 0 .5rem;
    }

    button {
        width: 1rem;
        height: 1rem;
        font-size: 0;
        line-height: 0;
        padding: 0;
        border: 1px solid var.$color-main;
        border-radius: 50%;
        cursor: pointer;
        outline: none;
        background-color: #fff;
    }

    .slick-active button {
        background-color: var.$color-main;
    }
}

// magnific popup

.mfp-wrap {
    font-size: 1.6rem;
    color: var.$color-text;
    font-family: "EuclidCircularA", Arial, sans-serif;
}

.mfp-container {
    padding: 0;
}

.mfp-close {
    right: 1.6rem;
    top: 1.6rem;
    // position: sticky;
    &:active {
        top: 1.6rem;
    }
    // transform: translateX(50%);
    width: 9.4rem;
    height: 2.4rem;
    opacity: 1;
    font-size: 1.6rem;
    line-height: 1;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    color: var.$color-main !important;

    span {
    font-size: 13px;
    margin-right: 0.4rem;
    }

    svg {
        width: 1.4rem;
        height: 1.4rem;
        stroke: var.$color-main;
        transition: all .3s;
    }
    @media(hover:hover) {
        &:hover {
        color: var.$color-text !important;
        svg {
            stroke: var.$color-text;
        }
        }
    }
    // &:active {
    //     top: 1.2rem;
    // }
    @include mix.breakpoint(var.$tablet-width) {
        // right: 1.6rem;
        // position: absolute;
        // top: 1.6rem;
        // // transform: none;
        // &:active {
        //     top: 1.6rem;
        // }
    }
}

// tooltipster
/* critical:start */
.tooltip-templates {
    display: none;
}

a{
    color:inherit;
}
.no-underline{
    text-decoration: none;
}


.full-width {
    width: 100%;
}
.hidden {
    display: none !important;
}
.show {
    display: block !important;
}
.empty {
    margin: 2rem auto;
    font-size: 2.8rem;
    line-height: 3.2rem;
    text-align: center;
    color: #808DA1;
    opacity: 0.5;
}


.goods__inner-rel {
position: relative;
}
.page-preloader {
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: 10;
opacity: 0.8;
background: #fff;
font-size: 40px;
display: none;
text-align: center;

img {
    margin: 64px;
}
}

.popup-preloader {
padding: 32px;
text-align: center;
img {
    width: 84px;
    height: 84px;
    margin: auto;
}
}

.catalog {
overflow-x: hidden;
}
/* critical:end */
.tooltipster-box, .tooltipster-base {
z-index: 2000 !important;
}
#goods {
position: relative;
}
.list-view {
@include mix.breakpoint(var.$tablet-width) {
    min-width: 100%;
}
@include mix.breakpoint(var.$mobile-width) {
    margin-left: auto;
    margin-right: auto;
}
}
.select-sort-btn {
@include mix.breakpoint(var.$tablet-width) {
    float: right;
}
}
.catalog-content-data {
.select-sort-btn {
    @include mix.breakpoint(var.$tablet-width) {
        float: left;
    }
}
}
/* critical:start */
.no-wrap {
white-space: nowrap;
}
/* critical:end */
.select-sort-btn {
max-width: 100%;
    @include mix.breakpoint(var.$tablet-width){
        max-width:none;
    }
}

.certificates-ul {
li {
    padding-bottom: 16px;
    &::before {
    display: none;
    }

    img {
    max-width: 180px;
    }
}
}


.wrap_bdf4 {
bottom: 50px !important;
}
/* critical:start */
.invisible {
visibility: hidden;
}
/* critical:end */

@include mix.breakpoint(var.$desktop-lg-width) {
.cart-index {
    overflow-x: hidden;
}
}

.label_d154{
    background-color: red !important;
}


.main-catalog {
    z-index: 300;
    .brand-section__show-more {
    display: none;
    }
}
.main-catalog__product-list._categories {
    display: none;
}

@include mix.breakpoint(var.$desktop-width) {

    .main-catalog {
    $self: &;
    position: absolute;
    width: 100%;
    top: var(--header-discount-height);
    height: 70%;
    border-top: 1px solid #ebf0f5;
    border-bottom: 1px solid #ebf0f5;
    z-index: 300;
    background: #fff;
    padding: 2rem 0;
    overflow-y: none;
    // display: block !important;

    .js-show-category {
        cursor: default;
        &:hover {
        border-bottom: none !important;
        }
    }

    &__top {
        display: none;
    }

    &__container {
        height: 100%;

        & > .container {
            height: 100%;
        }
    }

    &__container-inner {
        // display: flex;
        height: 100%;
    }

    &__product-list {
        padding-top: 0;
    }

    &__product-list._categories {
        position: relative;
        width: 20%;
        margin-right: 2rem;
        display: block;
        height: 100%;
        overflow-y: auto;
        float: left;
        padding-right: 5rem;

        .main-catalog__product-list__wrap {
        background: #EBF0F5;
        border-radius: 4px;
        padding: 14px 9px;
        }

        #{$self}__link-wrap {
        width: 100%;
        height: auto;

        a {
            display: block;
            padding: 0.6rem;
            border-radius: 4px;
            font-size: 16px;
            color: #3C3C70;
            font-weight: bold;
        }
        }
    }
    &__product-list._subcategories {
        position: relative;
        width: 80%;
        float: left;
        height: 100%;
        overflow-y: auto;
        display: block;

        #{$self}__link-wrap {
        display: none;
        }

        #{$self}__item-link {
        border-bottom: 1px solid transparent;
        @media(hover:hover) {
            &:hover {
                border-color: currentColor;
            }
        }
        }

        & > #{$self}__item {
        width: calc(100% - 2rem);
        display: none;

        #{$self}__product-list._sort {
            display: flex;

            & > #{$self}__item {
            width: calc(25% - 2rem);
            margin-right: 2rem;

            &._vendor-item {
                width: 100% !important;
            }

            &:first-child, &:nth-child(2), &:nth-child(3) {
                    margin-top: 0;
                margin-bottom: 4rem;
            }

            #{$self}__popup {
                display: block;
                height: auto;

                &._open {
                height: auto !important;
                }
            }

            }
        }
        }
    }

    &__link-wrap._sale, .js-main-catalog-link._active {
        #{$self}__item-link {
        background-color: var.$color-main;
        color: #fff;
        }
    }

    .brand-section__show-more {
        margin-top: 0;
        display: inline-block;
    }

    }
}
.hover-border,
.main-nav__list li a,
.site-header__popular-list li a,
.site-footer__middle-row li a,
.products__list .products__item .products__text > span,
.map__content .link > span,
.delivery-section .link > span,
.head-discount .moretext,
.head-discount .head-discount__btn-close > span,
// .pagination li:nth-child(3),
// .pagination li:nth-child(5),
.breadcrumbs__list .breadcrumbs__item:not(.active) a,
.card-detail__review,
.detail-price__month-price a.js-month-pay,
.popular-size__order-size > span,
.detail-delivery__subtitle > span,
.factory__link,
.card-detail__subtitle a.js-open-popup,
.detail-footer__month-price a.js-month-pay,
.other-size__link-custom > span,
.cart-popup__product-name > a,
.cart-list__title > a,
.more__link > span,
.default__link,
.site-map a,
.regions__link,
.delivery__link,
.not-found__link > span,
.goods__reset-link,
.advantages__item-link .advantages__text,
.delivery__nav a
{
border-bottom: 1px solid transparent;
transition: border-color .3s;
@media(hover:hover) {
    &:hover {
        border-bottom-color: currentColor;
    }
}
}

.social__list li a {
transition: transform .3s;
&:hover {
    transform: translateY(-0.2rem);
}
}


.products__list .products__item {
.products__text > span {
    border-bottom: 1px solid transparent;
    transition: border-color .3s;
}
@media(hover:hover) {
    &:hover {
        .products__text > span  {
        border-bottom-color: currentColor;
        }
    }
}
}


.site-index {
.filter__submit-btn {
    margin-right: 2rem;
}
.filter__show-btn._border {
    border-color: transparent !important;
    @include mix.breakpoint(var.$tablet-width) {
        width: 15rem !important;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        width: 15rem !important;
    }
    @media(hover:hover) {
        &:hover {
        background-color: transparent !important;
        color: #fff !important;
        }
    }
}

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            .title{
                text-align: center;
                width: 100%;
            }

        }

}


.js-contacts-popup {

.container {
    padding: 0;
}
.call{
    margin-bottom: 1rem !important;
}

.social__list {
    background: #f5f7fa;
    padding: 2rem;
    border-radius: 2px;
    margin-bottom: 8rem;

    svg {
    max-width: 3.8rem;
    max-height: 3.8rem;
    color: var.$color-text;
    }
    @include mix.breakpoint(var.$tablet-width) {
        width: 100% !important;
    }
}

.msg-btn {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    font-size: 1.4rem;

    .when-disable {
    display: none;
    }
    &._disable {
    background-color: #f5f7fa !important;
    color: #a5a5a5 !important;
    .when-no-disable {
        display: none;
    }
    .when-disable {
        display: block;
    }
    }

}

.popup-contacts__list {
    width: auto !important;
    margin-left: 0 !important;
    flex-wrap: wrap;

    .contacts__item {
    width: calc(50% - 0.5rem);
        margin: 0 0 1.5rem 0;
        &:nth-child(1){
            margin-right:0.5rem;
        }
        &:nth-child(2){
            margin-left:0.5rem;
        }
    }
    @include mix.breakpoint(var.$tablet-width) {
        justify-content: space-between;
        background: none;
        border: none;
        padding: 0;
        margin-top: 2rem;
        .contacts__link{
            padding:1rem 2rem 1rem 1.5rem;
        }
    }


}
}


.mfp-gallery {
.mfp-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    &::before, &::after {
    display: none;
    }
    svg {
    stroke: var.$color-main;
    width: 2rem;
    }
}
.mfp-arrow-left {
    svg {
    transform: rotate(180deg);
    }
}
}

._pulse-animated {
    animation: pulse .7s infinite;
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(1);
        background-color: var.$color-main;
    }
    50% {
        transform: scale(.95);
        background-color: var.$color-text;
    }
    100% {
        transform: scale(1);
        background-color: var.$color-main;
    }
}

.cart-thank-you {
.filter__inner {
    display: block;
    .call__title {
    max-width: calc(100% - 7.5rem);
    }
}
}

.delivery-icon {
background: var.$color-second !important;
color: var.$color-text !important;
text-align: center;
padding: .4rem .4rem .6rem .3rem !important;

span {
    display: block;
    font-size: 1rem;
}
}

.hide{
    display: none;
}

._purple-color {
color: var.$color-text;
}

.mascot-help-button{
    position: fixed;
    right: 0px;
    top: 30%;
    width: 3rem;
    height: 17rem;
    z-index: 300000;
    cursor: pointer;
    display: block;

    svg:first-child{
        width: 3rem;
        height: auto;
        position: absolute;
        z-index: 300001;
    }

    &__text{
        display: block;
        transform: rotate(270deg);
        white-space: nowrap;
        font-size: 0.8em;
        position: absolute;
        top: 8.5rem;
        right: -4.7rem;
        color: white;
    }

    &__background{
        display:block;
        border-left: 3rem solid #7b75c9;
        height: 14rem;
        position: absolute;
        top: 2.3rem;
        border-bottom-left-radius: 1rem;
    }
}

.dropdown-search {
margin: 0 16px;
width: calc(100% - 32px);
padding: 8px 16px;
margin-bottom: 8px;

&__nothing {
    text-align: center;
    padding: 24px;
    opacity: 0.7;
}
}

.select__dropdown._with-checkbox {
.dropdown-search {
    margin-right: 0;
    width: calc(100% - 16px);
}
}

.marquiz-widget__container{
    bottom:70px;
    z-index: 50 !important;
}

.button-preloader {
display: none;
position: absolute;
left: calc(50% - 2rem);
top: calc(50% - 2rem);
width: 4rem;
height: 4rem;
}

.catalog-reviews, .reviews {

&__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;

    @include mix.breakpoint(var.$tablet-width) {
    flex-direction: row;
    margin-bottom: 0;
    }

    .review__item-wrap {
    margin-bottom: 2rem;

    @include mix.breakpoint(var.$tablet-width) {
        width: 50%;
    }
    @include mix.breakpoint(var.$desktop-sm-width) {
        width: 33.333%;
    }

    .review__item {
        height: 100%;
        @include mix.breakpoint(var.$tablet-width) {
        width: calc(100% - 2rem) !important;
        }
    }
    }
}

.pagination {
    margin-top: 10rem !important;
}

.more-btn-wrap {
    position: relative;
    text-align: center;
    margin-top: -14rem;

    .more-reviews {
    width: 100%;
    @include mix.breakpoint(var.$desktop-width) {
        max-width: 46rem;
    }
    }

    ._load {
    ._text {
    visibility: hidden;
    }
    .button-preloader {
        display: block;
    }
    }

}
}

.shop-reviews {
&__list {
    display: flex;
    flex-wrap: wrap;
}
}

.youtube-wrapper {
min-height: 18rem;
iframe {
    width: 100%;
    height: 100%;
}
}
.youtube-preview {
position: relative;
width: 100%;
height: 100%;
background-size: cover;
background-position: center;
cursor: pointer;
min-height: 18rem;

&._load {
    .youtube-icon {
    display: none;
    }
    &::after {
    content: '';
    position: absolute;
    top: calc(50% - 3rem);
    left: calc(50% - 3rem);
    width: 6rem;
    height: 6rem;
    background: url('/img/preloader.gif');
    background-size: cover;
    }
}
}
.youtube-icon {
position: absolute;
top: calc(50% - 16px);
left: calc(50% - 24px);

svg {
    width: 48px;
    height: 32px;
}
}

.review-index {
#reviewsList {
    margin-bottom: 10rem;
}
}

._сheck {
font-size: 1.2rem;

.form__checkbox-label {
    display: flex;
    flex-direction: row;
}

.form__label-text {
    &::after,
    &::before {
        top: 0.6rem;
    }
    }
}

.sdek-map {
position: relative;
display: flex;
align-items: center;
justify-content: center;

&._delivery {
    width:100%;
    height:600px;
}

&._cart {
    width:100%;
    height:400px;
}

.ymaps-2-1-79-balloon {
    // max-width: 200px;
    &__layout {
    // border-radius: 4px;
    }
}

.ymaps-2-1-79-balloon-content {
    &__header {
    font-size: 14px;
    }
}

}

.bg-main{
    background-color: var.$color-main;
    color: white;
}
.bg-second{
    background-color: var.$color-second;
    color: var.$color-text;
}

img.mfp-img{
    padding:0 !important;
}

._hide {
    display: none !important;
}

._disabled {
cursor: not-allowed !important;
opacity: 0.5 !important;
}