@use 'base/variables' as var;
@use 'base/mixins' as mix;

.jumbotron-slider-v1 {
    flex-shrink: 0;
    margin-left: auto;

    &__container {
        overflow: hidden;
        width: 62rem;
        flex-shrink: 0;
        margin-left: auto;
        position: relative;
        align-self: flex-end;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 100%;
        }

        @media only screen and (min-width: (var.$tablet-width)) {
            width: 32rem;
        }

        @media only screen and (min-width: (var.$desktop-width)) {
            width: 62rem;
        }
    }

    .slick-dots {
        position: absolute;
        display: flex;
        margin-right: -0.3rem;
        right: 5rem;
        top: 2.5rem;
    }

    &__slider {
        position: relative;
    }

    // По умолчанию показываем только первый слайд, скрываем все остальное
    .slider__slider:not(.slick-initialized) .slider__slide {
        display: none;
    }

    // Показываем первый слайд
    .slider__slider:not(.slick-initialized) .slider__slide:first-child {
        display: block;
    }

    // Когда slick инициализирован, показываем все
    .slick-initialized .slider__slide {
        display: block;
    }
}

//deps

.jumbotron-slider-v1 {

}