@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
.main-nav {
    // display: none;
    grid-area: menu;
    @include mix.breakpoint(var.$desktop-width) {
        // display: block;
        margin-left: auto;
    }

    &__list {
        padding: 0 2.5rem;
        padding-left: 3.9rem;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: center;
            padding: 0;
        }
    }

    &__item {
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0;
            margin-right: 2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-right: 3rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &.active {
          font-weight: bold;
        }
    }

    &__link {
        color: #818da1;
        line-height: 2rem;
        text-decoration: none;
        // transition: all .3s;
        // @media(hover:hover) {
        //     &:hover {
        //         color: var.$color-main;
        //     }
        // }
    }
}

// .main-nav._mobile {
//     display: block;
//     @include mix.breakpoint(var.$desktop-width) {
//         display: none;
//     }
// }
/* critical:end */
