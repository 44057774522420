@use 'base/variables' as var;
@use 'base/mixins' as mix;

.interior-images-v1 {

    &__wrap {

    }

    &__list-place {

        & .card {
            float: left;
            width: 20rem;
            max-width: 20rem;
            background: #fff;
            margin-left: 0;
        }

        & .card:last-child {
            margin-right: 0;
        }

        & .card__link {
            padding: 0;
        }

    }

    &__item {
        @include mix.breakpointRange(var.$zero-width, (560px - 1)) {
            width: 100%;
        }
        @include mix.breakpointRange(560px, (1150px - 1))  {
            width: 55.3rem;
            height: 55.3rem;
        }
        @include mix.breakpointRange(1150px, (var.$desktop-laptop-width - 1)) {
            width: 67rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 83rem;
        }
    }

    &__image {
        width: 100%;

    }

    &__prev {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
        }
        cursor: pointer;
        position: absolute;
        width: 5rem;
        height: 5rem;
        z-index: 11;

        left: 4%;
        top: calc(50% - 2.5rem);
    }

    &__next {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
        }
        cursor: pointer;
        position: absolute;
        width: 5rem;
        height: 5rem;
        z-index: 11;
        
        right: 4%;
        top: calc(50% - 2.5rem);
    }

    &__track {
        position: relative;

        & .slick-dots {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
        }
    }



    // TODO[dependСss]
    // depend: widget-type-in-hyphen-case

    // \depend: widget-type-in-hyphen-case

    // \TODO[dependСss]

}