@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-v1 {

    &__title-place {
        position: relative;
        z-index: 1;
    }

    &__background {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        text-align: right;
        height: 100%;
    }

    &__body {
        background: var.$color-main;
        color: var.$color-white;
        padding: 3rem 6.5rem;
        position: relative;
        z-index: 0;
    }

    &__btn {
        background-color: var.$color-white;
        color: var.$color-text;
        border: 1px solid var.$color-white;
        font-size: 3.6rem;
        padding: 3.2rem;
        line-height: 2rem;
        border-radius: 0.8rem;
        font-weight: 500;
        font-family: var.$font-text;
        cursor: pointer;
        transition: all .3s;
    }

    &__btn:hover {
        background: var.$color-main-tone9;
        color: var.$color-white;
        border-color: var.$color-white;
    }

    &__subtitle {
        text-align: left;
        color: var.$color-white;
        line-height: 2.4rem;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        &__body {
            padding: 1.2rem;
        }

        &__title-place {
            width: 24rem;
        }

        &__subtitle {
            font-size: 1.2rem;
            margin-bottom: 2.8rem;
        }

        &__btn {
            font-size: 1.4rem;
            padding: 1.6rem;
            width: 16rem;
        }
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        &__title-place {
            width: 53rem;
        }
    }

    @include mix.breakpoint(var.$tablet-h-width) {
        &__subtitle {
            font-size: 1.6rem;
        }
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        &__subtitle {
            font-size: 2rem;
            margin-bottom: 5.6rem;
        }
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        &__body {
            padding: 8.8rem 3.5rem;
        }

        &__title-place {
            width: 36.6rem;
        }

        &__subtitle {
            font-size: 1.6rem;
            margin-bottom: 3.6rem;
        }

        &__btn {
            width: 100%;
            font-size: 2.4rem;
            padding: 1.4rem;
        }
    }
}

/** DEPENDS CODE */
.questionnaire-v1 {

    .title-v2 {
        color: var.$color-white;
        font-size: 4rem;
        margin-bottom: 6rem;
        line-height: 1.1;
    }

    @include mix.breakpoint(var.$tablet-width) {
        .title-v2 {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        .title-v2 {
            font-size: 4rem;
            margin-bottom: 6rem;
        }
    }
    @include mix.breakpoint(var.$desktop-notebook-width) {
        .title-v2 {
            font-size: 4.8rem;
            margin-bottom: 1.6rem;
        }
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        .title-v2 {
            font-size: 3.6rem;
            margin-bottom: 1.2rem;
            text-align: left;
        }
    }

     @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        .title-v2 {
            font-size: 2.4rem;
            margin-bottom: 1.2rem;
            text-align: left;
        }

     }

    .subtitle {
        text-align: left;
        color: #fff;
    }

    .picture-v1__bg {
        height: 100%;
    }
}

/** \DEPENDS CODE */
