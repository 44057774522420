@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-form-v1 {
    > * {
        margin-bottom: 4rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &__step-title {
        font-weight: 400;
        font-size: 1.8rem;
        font-family: 'EuclidCircularA';
        padding-bottom: 3rem;
    }

    &__radio-column {
        display: grid;
        grid-template-columns: min-content min-content;
    }

    &__step-two &__step-title {
        padding-bottom: 2rem;
    }

    &__step-two &__delivery-type-block {
        margin-bottom: 3rem;
    }

    &__one-input-row {
        padding-bottom: 2rem;
    }
    
    &__one-input-row .input-text-v1 .label {
        font-weight: 400;
        font-size: 1rem;
        font-family: 'EuclidCircularA';
        
    }

    &__two-input-row {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: grid;
            padding-bottom: 2rem;
        }

        display: flex;
        gap: 2.6rem;
        padding-bottom: 2rem;
    }

    &__two-input-row .input-text-v1 .label {
        font-weight: 400;
        font-size: 1rem;
        font-family: 'EuclidCircularA';
    }

    &__delivery-type-block {
        padding: 3rem;
        padding-bottom: 3rem;
    }


    &__radio-column .input-radio-list-v1 {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: grid;
            row-gap: 2.4rem;
        }

        display: flex;
        padding-bottom: 2rem;
        column-gap: 10rem;
    }

    &__pickup-finder-row {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: flex;
            justify-content: center;
        }

        display: flex;
        column-gap: 3rem;
    }

    &__pickup-finder-row &__pickup-finder-row-desc {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: none;
        }

        font-weight: 400;
        font-size: 1rem;
        font-family: 'EuclidCircularA';
        color: var.$color-gray;
        align-self: center;
    }

    &__pickup-finder-row &__pickup-finder-row-desc strong {
        color: var.$color-main;
        font-size: 1.5rem;
    }

    &__step-three {
        margin-bottom: 1rem;
    }

    &__step-three &__radio-row .input-radio-list-v1 .input-radio-v1 {
        padding-bottom: 1.2rem;
    }

    .stepper-v1 {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: none;
        }

        padding: 3rem;
    }

    .stepper-v1 .stepper-v1__head {
        padding-bottom: 2rem;
    }

    .stepper-v1 .stepper-v1__head .stepper-v1__head-title {
        font-weight: 400;
        color: var.$color-text;
    }

    .stepper-v1 .stepper-v1__track {
        margin-top: 0;
        gap: 1.8rem;
    }

    .stepper-v1 .stepper-v1__track .step-v1 {
        height: 6.6rem;
    }

    .stepper-v1 .stepper-v1__track .step-v1 .step-v1__title {
        font-weight: 400;
        font-size: 1.4rem;
    }

    .stepper-v1 .stepper-v1__track .step-v1 .step-v1__subtitle {
        font-weight: 400;
        font-size: 1.4rem;
    }        

}


/** DEPENDS CODE */
.cart-form-v1 {
    
    .action-group-v1 {
        margin-bottom: 1rem;
    }

}

/** \DEPENDS CODE */
