@use 'base/variables' as var;
@use 'base/mixins' as mix;

.author {
    border: 2px solid var.$color-main;
    border-radius: 6px;
    padding: 4rem 3rem;
    @include mix.breakpoint(var.$tablet-width) {
        display: flex;
        align-items: flex-start;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        padding: 4rem;
    }

    &__avatar {
        display: block;
        border-radius: 50%;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 2.5rem;
        }
    }

    &__content {
        @include mix.breakpoint(var.$tablet-width) {
            width: 28rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 39.5rem;
        }
    }

    &__name {
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 600;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2rem;
        }
    }

    &__position {
        margin: 0;
        margin-bottom: 4rem;
        font-size: 1.4rem;
        line-height: 150%;
        color: #474747;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4.5rem;
        }
    }

    &__last-articles {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 150%;
        font-weight: 500;
        color: #474747;
    }

    &__article-item {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__article-link {
        text-decoration: none;
        color: var.$color-main;
        font-size: 1.4rem;
        line-height: 150%;
        border-bottom: 1px solid transparent;
        transition: border-color .3s;

        &:hover {
          border-bottom: 1px solid currentColor;
        }
    }
}

.author-page {
  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__avatar {
    border-radius: 50%;
    width: 50rem;
    margin-bottom: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  &__content {
    padding-left: 4rem;
  }
}
