.wishlist-page-v1 {

}

//deps
.wishlist-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        margin-bottom: 3.5rem;
    }

    .title-v2 {
        margin-bottom: 6rem;
    }

    .product-grid-v1 {
        margin-bottom: 14rem;
    }

    .category-tile-v1 {
        margin-bottom: 8rem;
    }
}