@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-list-v1 {
}

/** DEPENDS CODE */
.product-list-v1 {
    
    // > * {
    //     margin-bottom: 5rem;
    // }

}

/** \DEPENDS CODE */
