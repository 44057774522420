@use 'base/variables' as var;
@use 'base/mixins' as mix;

.head-discount {
    background-color: var.$color-second;
    font-family: "Affect";
    padding: 0.5rem 0;
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // margin-bottom: -1.6rem;
    // position: relative;
    font-size:1.3rem;
    @include mix.breakpoint(var.$tablet-width) {
        font-size: 1.4rem;
        padding: 1rem 0;
    }
    @include mix.breakpoint(var.$desktop-width) {
        font-size: 1.6rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        // position: fixed;
        // top: 0;
        // left: 0;
        // width: 100%;
        // z-index: 250;
    }
    &__row {
        position: relative;
        //padding: 0.3rem 0;
        vertical-align: middle;
        text-align: center;
        line-height: 1;
        @include mix.breakpoint(var.$tablet-width) {
            //padding: 1rem 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            //padding: 2rem 0;
        }
    }
    .btn{
        padding: .1rem 0.6rem 0.2rem;
        font-size: inherit;
        display: block;
        position:relative;
        margin: 0 auto;
        @include mix.breakpoint(var.$tablet-width) {
            position: absolute;
            right:1.5rem;
            top: 50%;
            transform: translate(0, -50%);
            font-size: inherit;
            right:8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            right:10rem;
        }
    }

    &__mister {
        // display: none;

        position: absolute;
        bottom: -1.1rem;
        width: 10%;
        height: 5.4rem;
        left: 0;

        // transform: translateY(1px);
        @include mix.breakpoint(var.$mobile-lg-width) {
            display: block;
        }
        @include mix.breakpoint(var.$tablet-width) {

        }
        @include mix.breakpoint(var.$desktop-width) {
            //left: calc(50% - 33.3rem);
            width: 5.4rem;
            height: 5.4rem;
        }
    }

    &__mister svg{
        width:0;
        height: 0;
        @include mix.breakpoint(var.$mobile-lg-width) {
            width: auto;
            height: auto;
        }
    }


    &__text {
        text-align: center;
        //font-size: 1.3rem;
        //line-height: 2rem;
        font-weight: 700;
        color: var.$color-text;
        // margin: 0;
        text-decoration: none;
        // margin: 0 2.5rem;
        display: inline-block;
        //vertical-align: middle;
        width: 80%;
        margin-left: 5%;
        margin-right: 5%;
        @include mix.breakpoint(var.$mobile-lg-width) {
            width: 80%;
            margin-left: 10%;
            margin-right: 10%;
        }

       @include mix.breakpoint(var.$tablet-width) {
            width: 60%;
            margin-left: 15%;
            margin-right: 25%;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 60%;
            margin-left: 15%;
            margin-right: 20%;
        }
    }

    &__text-more {
        margin-left: 20px;
        svg{
            width: 2rem;
            height: 2rem;
            display: none;
        }
    }
    .moretext {
      text-decoration: none;
    }

    &__btn-close {
        position: absolute;
        /*right: 0;
        top: 0;*/
        // margin-top: -5px;
        // top: -2px;
        // bottom: -2px;
        //display: flex;
        align-items: baseline;
        //justify-content: center;
        //width: 2rem;
        //height: 2rem;
        background-color: transparent;
        border: 0;
        padding: 0;
        // transform: translateY(-50%);
        color: var.$color-text;
        font-family: "Affect";
        outline: none;
        //line-height: 2rem;
        // font-size: 0;
        // line-height: 0;
        text-decoration: none;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        span {
            display: none;
            // line-height: 1.6rem;
        }

        svg {
            width: 8px;
            height: 8px;
            stroke: currentColor;
        }

        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            height: auto;
            right: 0;

            span {
                display: inline;
            }

            svg {
                margin-top: 3px;
                margin-left: 0.5rem;
            }
        }
    }

    &__inner {
        position: relative;
        // font-size: 0;
        // line-height: 0;
        // vertical-align: middle;
    }
}

.head-discount._hidden {
    display: none;
    height: 0; // нужно для проверки во вьюпорте ли элемент
}
