@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-row-v6 {
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    align-items: center;
}


/** DEPENDS CODE */
.receipt-row-v6 {
    
    &__value {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1rem;
        color: var.$color-main;
    }

    &__subvalue {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.6rem;
        color: var.$color-text;
        margin-top: 1rem;
    }

}

/** \DEPENDS CODE */
