$container-width: 1604px;
$container-padding-mobile: 1.5rem;
$container-padding-tablet: 2rem;
$container-padding-desktop: 4rem;

// taskBitrix14792
// for calc size desktop-menu
// @see frontend/assets/css/blocks/header-v2/_menu-bar.scss:{& .desktop-menu}
$container-padding-x-md: 2rem;
$container-padding-x-lg: 8rem;
// \taskBitrix14792

/* COLORS */

// for each color set the tone with range [1;9],
// where 5 – default color (example: $color-main: #7b75c9;)
// > 5 – the tones are order number from are darker
// < 5 – the tones are order number from are lighter

$color-black: #000;
$color-white: #fff;
$color-black-tone4: #3C3C70;

$color-main: #7b75c9;

$color-gray: #818DA1;
$color-gray-tone4: #747474;
$color-gray-tone3: #ECF0F5;
$color-gray-tone2: #fcfcfc;
$color-gray-tone1: #e0e0e0;
$color-gray-tone6: #9D9D9D;
$color-gray-tone7: #87919c;
$color-gray-tone8: #e3e3e3;
$color-gray-tone9: #96a3ae;
$color-gray-tone10: #d4dce9;
$color-gray-tone11: #818da2;
$color-gray-tone12: #EBF0F5;
$color-gray-tone-13: #f8fbff;
$color-gray-tone-14: #c7c9cc;
$color-gray-tone-15: #B0B0B0;
$color-gray-tone-16: #4A4A4A;
$color-gray-tone-17: #F3F7FA;
$color-gray-tone18: #808080;
$color-lightgray: #D3D3D3;


$color-main-tone6: #5B57A5;
$color-main-tone7: #545A65;
$color-main-tone8: #a7a3de;
$color-main-tone9: #9591d4;
$color-main-tone2: #F5F7FA;
$color-main-tone10: #7a77c3;


$color-second: #bee6a8;
$color-second-tone4: #BDE6AB;
$color-second-tone6: #d2edc4;
$color-third: #ebf0f5;

$color-text: #3c3c70;
$color-border: #ebf0f5;
$color-border-v2: #DEDCDC;
$color-text2: rgba(60, 60, 112, 0.6);
$color-red: #f46b5f;

// bgcolors by theme (light/dark) and in range tone saturation [0..9]
$bgcolor-light-tone0: #FFF;
$bgcolor-light-tone1: #F9F9F9;

/* MEDIA QUERIES */
$zero-width: 0px;
$mobile-width: 320px;
$mobile-l-width: 375px;
$mobile-lg-width: 425px;
$mobile-2lg-width: 475px;
$mobile-xl-width: 600px;
$tablet-width: 768px;
$tablet-h-width: 1024px;
$desktop-sm-width: 1025px;
$desktop-width: 1280px;
$desktop-laptop-width: 1360px;
$desktop-notebook-width: 1440px;
$desktop-lg-width: 1441px;
$desktop-test-width: 1520px;
$desktop-max-width: 1620px;

/* custom variables */
$custom-v1-width: 509px;


// css variables
$review-lines-count: 10;


// устанавливается js
// :root {
//     --header-height: 0;
//     --header-discount-height: 0;
//     --cart-popup-scroll-height: 56.5rem;
//     --sort-height: 0px;
// }

/** NEW CODE (code below MUST BE rewriting) */
$font-header: 'Affect';
$font-text: 'EuclidCircularA';
$font-roboto: 'Roboto';
$font-special: 'GothamPro';

$color-rating: #FEB548;
$color-rating-tone6: #FCB204;
$color-bear: #2ab0d8;
$color-danger: #DC6D6D;
/** NEW CODE (code below MUST BE rewriting) */