@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tag-list-v3 {
    line-height: 4rem;

    &__content {
        display: flex;
    }

    &__item {
        flex-grow: 0;
        margin-right: 1.5rem;
    }


    &__link:hover {
        color: var.$color-white;
        background-color: var.$color-main;
    }

    &__link {
        background-color: var.$color-main-tone2;
        color: var.$color-text;
        padding: 1.1rem 1.5rem 1.1rem 1.5rem;
        font-size: 1.6rem;
        width: 100%;
        display: block;
        border-radius: 4px;
        line-height: 1.2;
        text-align: center;
        text-decoration: none;
        transition: all .3s;
    }

     &__active {
        background-color: var.$color-text;
        color: var.$color-white;
    }
}

//deps
.tag-list-v3 {

}