@use 'base/variables' as var;
@use 'base/mixins' as mix;

.stepper-v1 {

    &__head {
        display: grid;
        grid-auto-flow: column;
    }

    &__head &__head-title {
        color: var.$color-main;
        font-family: 'EuclidCircularA';
        font-weight: 600;
        font-size: 1.4rem;
    }

    &__head &__head-desc {
        color: var.$color-text;
        font-family: 'EuclidCircularA';
        font-weight: 400;
        font-size: 1.2rem;
        text-align: right;
    }

    &__track {
        margin-top: 1.2rem;
        display: grid;
        gap: 1.4rem;
        grid-auto-flow: column;
    }
}