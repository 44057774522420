@use 'base/variables' as var;
@use 'base/mixins' as mix;

.visual-product-property-v1 {
    background-color: var.$color-gray-tone-13;
    border-radius: 0.6rem;
    padding: 0.4rem 0.6rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;
    min-height: 8.6rem;

    &__image {
        flex-shrink: 0;
    }

    &__content {
        flex-grow: 1;
    }

    &__title {
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 0.4rem;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
}

//deps
.visual-product-property-v1 {

}