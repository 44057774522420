@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-page-about-v1 {

    @include mix.breakpoint(var.$desktop-width) {
        &__text {
            margin-bottom: 11rem;
        }
    }
}

/** DEPENDS CODE */
.text-page-about-v1 {
    .products {
        margin-bottom: 5rem;
    }

    .advantages-v2 {
        margin-bottom: 4rem;

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 8rem;

            .title {
                margin-bottom: 5rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 15rem;

            .title {
                margin-bottom: 8rem;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 11rem;
        }


        &__list {
            @include mix.breakpoint(var.$desktop-width) {
                flex-wrap: wrap;
                margin-top: -9rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                flex-wrap: wrap;
                margin-top: -10rem;
            }
        }

        &__item {
            @include mix.breakpoint(var.$desktop-width) {
                width: calc(100% / 4 - 10rem);
            }
        }

        &__icon {

            &._transport {
                width: 8.5rem;
                height: 4.5rem;
            }

            &._sale {
                width: 4.8rem;
                height: 5rem;
            }

            &._glass {
                width: 5.4rem;
                height: 5.2rem;
            }

            &._bow-star {
                width: 5.2rem;
            }

            &._mister-purple {
                width: 5.8rem;
                height: 4rem;
            }

            @include mix.breakpoint(var.$tablet-width) {
                &._sale {
                    width: 7.8rem;
                    height: 7.5rem;
                    margin-left: 0;
                }

                &._bow-star {
                    width: 7.8rem;
                    height: 7.6rem;
                }

                &._transport {
                    width: 10rem;
                    height: 5.5rem;
                    margin-left: -2rem;
                }

                &._glass {
                    width: 7.9rem;
                    height: 8.3rem;
                }

                &._heart {
                    width: 7.7rem;
                    height: 7.7rem;
                    margin-top: -1.2rem;
                }

                &._mister-purple {
                    width: 7.9rem;
                    height: 6.5rem;
                }
            }
            @include mix.breakpoint(var.$desktop-width) {
                &._sale {
                    width: 8.8rem;
                    height: 8.5rem;
                }

                &._bow-star {
                    width: 8.8rem;
                    height: 8.5rem;
                }

                &._transport {
                    width: 15.5rem;
                    height: 8.4rem;
                    margin-left: -5rem;
                    margin-top: -1rem;
                }

                &._glass {
                    width: 8.8rem;
                    height: 9.2rem;
                }

                &._heart {
                    width: 8.8rem;
                    height: 8.8rem;
                    margin-top: -1.2rem;
                }

                &._mister-purple {
                    width: 9rem;
                    height: 7.4rem;
                }
            }

        }

        &__item {
            @include mix.breakpoint(var.$tablet-width) {
                &:nth-child(2) {
                    order: 3;
                }

                &:nth-child(3) {
                    order: 4;
                }

                &:nth-child(4) {
                    order: 2;
                }

                &:nth-child(5) {
                    order: 5;
                }

                &:nth-child(6) {
                    order: 6;
                }

                &:nth-child(7) {
                    order: 8;
                }

                &:nth-child(8) {
                    order: 7;
                }
            }
            @include mix.breakpoint(var.$desktop-width) {
                margin-top: 9rem;

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 3;
                }

                &:nth-child(4) {
                    order: 4;
                }

                &:nth-child(5) {
                    order: 6;
                }

                &:nth-child(6) {
                    order: 5;
                }
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                margin-top: 10rem;
            }
        }
    }

    .image-carousel-v1 {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4.5rem;
        }
    }

    .popular {
        margin-bottom: 4rem;
    }

    .best {
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 6rem;
        }
    }

    .products {
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 10rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 11rem;
        }
    }

    .title-v1 {
        margin-top: 0rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        .title-v1 {
            margin-bottom: 5rem;
        }

    }

    @include mix.breakpoint(var.$tablet-width) {
        .title-v1 {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }

    }


    @include mix.breakpoint(var.$desktop-width) {
        .title-v1 {
            font-size: 4rem;
            margin-bottom: 6rem;
        }

        .breadcrumb-v1 {
            padding-top: 3rem;
            margin-bottom: 3.5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-max-width) {
        .breadcrumb-v1 {
            padding-top: 5.5rem;
        }
    }

    .brand-list {
        @include mix.breakpoint(var.$desktop-width) {
            .title-v2 {
                font-size: 4rem;
                margin-bottom: 6rem;
            }
        }
    }
}