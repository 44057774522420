@use 'base/variables' as var;
@use 'base/mixins' as mix;

.price {
    font-size: 0;
    &__old {
        color: #A7A7A7;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0;
        position: relative;
        display: inline-block;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
            background-color: var.$color-text;
        }
    }

    &__current {
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.5rem;
        margin: 0;
        @include mix.breakpoint(var.$mobile-width) {
            font-size: 2.1rem;
            line-height: 2.7rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: -0.5rem;
        }
    }
}
