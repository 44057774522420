@use 'base/variables' as var;
@use 'base/mixins' as mix;

.category-page-v1 {

    &__answers {
        margin-top: 8rem;
    }

}

/** DEPENDS CODE */
.category-page-v1 {

    margin-top: 3rem;

    .promotion-slider-v1 {
        margin-bottom: 3rem;
    }

    .title {
        margin-top: 2rem;
    }

    .slider-v6 {
        margin: 0 auto;
        margin-bottom: 3rem;
    }
    
}
/** \DEPENDS CODE */