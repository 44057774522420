@use 'base/variables' as var;
@use 'base/mixins' as mix;

.article-grid-v1 {

    @include mix.breakpoint(var.$tablet-width) {
        display: flex;
        flex-wrap: wrap;
        margin-right: -2rem;
        margin-top: -4rem;
    }

    &__preview {
        @include mix.breakpoint(var.$tablet-width) {
            aspect-ratio: 4 / 2.5;
            display: flex;
            justify-content: center;
            align-items: start;
            overflow: hidden;
            border-radius: 4px;
            margin-bottom: 2rem;
        }
        position: relative;
    }

    &__item {
        width: calc(100%);
        margin-bottom: 5rem;
        margin-right: 0rem;
        margin-top: 4rem;

        img {
            display: block;
            border-radius: 4px;
            width: 100%;
            margin-bottom: 2rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            width: calc(50% - 2rem);
            margin-right: 2rem;
            margin-top: 4rem;
            margin-bottom: 0;
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(33.33333% - 2rem);
            margin-right: 2rem;
        }

        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: calc(25% - 2rem);
            margin-top: 5rem;
            margin-right: 2rem;
            margin-bottom: 0;
        }

    }

    &__title {
        margin: 0 0 1rem;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.5rem;
        border-bottom: 1px solid transparent;
        transition: border-color .3s;
    }

    &__link {
        text-decoration: none;
        color: var.$color-black-tone4;
    }

    &__top-bar {
        display: block;
        position: absolute;
        top: 2rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            top: 1.6rem;
        }
        left: 0;
    }

    &__top-bar-tag {
        float: left;
        border-radius: 0.3rem;
        margin-left: 2rem;
        &_primary {
            font-size: 1.2rem;
            padding: 1rem 1rem;
            background-color: var.$color-black-tone4;
            color: var.$color-white;
            font-weight: 400;
            line-height: 1rem;
            margin-bottom: 1rem;
        }
    }

}