@use 'base/variables' as var;
@use 'base/mixins' as mix;

.category-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem 0.8rem;
    border-radius: 0.8rem;
    background: #F9F9F9;
    text-decoration: none;
    justify-content: space-between;
    width: 24.6rem;

    &_vertical {
        flex-direction: column;
        justify-content: start;
        width: 15rem;
        padding: 1.6rem 0.4rem;

        & img {
            mix-blend-mode: darken;
        }

        & .category-link__image {
            height: 7.7rem;
        }

        & .category-link__text {
            width: 100%;
            margin-top: 0.4rem;
            font-weight: 400;
        }
    }

    &_matrasy {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            flex-direction: column-reverse;
            gap: 0.8rem;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            flex-direction: row;
            gap: 0;
        }
    }

    &_sofas {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            width: 13rem;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            width: 23.5rem;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        flex: 1 1 0;
        gap: 2.4rem;
        width: 100%;

        &_vertical {
            gap: 0.8rem;
        }

        &_matrasy {
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
                gap: 0.8rem;
            }
    
            @include mix.breakpoint(var.$tablet-h-width) {
                gap: 2.4rem;
            }
        }
    }

    &__container {
        position: relative;

        & .ps__rail-x {
            height: 2px !important;
        }

        & .ps__thumb-x {
            height: 2px !important;
        }

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            margin-top: 0rem;
            margin-bottom: 2rem;

            &.ps { 
                padding-bottom: 2rem;
            }
        }

        @include mix.breakpointRange(var.$tablet-h-width, (var.$desktop-width - 1)) {
            margin-top: 0rem;
            margin-bottom: 3rem;

            &.ps { 
                padding-bottom: 2rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-top: -2rem;
            margin-bottom: 3rem;
        }
    }

    &__text {
        color: #3C3C70;
        text-align: center;
        font-family: Affect;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: normal;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.2rem;
            width: 100%;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 1.4rem;
            width: calc(100% - 11.8rem);
        }
    }

    &__image {
        width: 11rem;
        height: 6.2rem;
    }
}


// Deps
.category-link {
}