@use 'base/variables' as var;
@use 'base/mixins' as mix;

.detailed-rating-star-v1 { 
    
    &__element {

        display: grid;
        grid-template-columns: 5% 5% calc(85% - 3rem) 5%;
        padding-top: 1.7rem;
        padding-right: 8.7rem;
        justify-content: center;
        column-gap: 1rem;
        align-items: center;

    }

}