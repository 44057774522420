@use 'base/variables' as var;
@use 'base/mixins' as mix;

.price-block-v1{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    gap: 1rem;
    align-items: center;
    
}