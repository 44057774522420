@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-other-sizes-v1 {
    p {
        font-family: var.$font-text;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.3rem;
        color: var.$color-text;

    }

    &__variants {
        display: flex;
        gap: 1.6rem;
        flex-wrap: wrap;
    }

    &__option {
        padding: 0.8rem 1rem;
        border-radius: 0.4rem;
        border: 0.1rem solid rgba(60, 60, 112, 0.16);;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.4rem;
        order: 0;
        flex-grow: 0;
        text-decoration: none;
    }

    &__option__label, &__option__value {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;

    }

    &__show-all {
        margin-top: 0.8rem;
        color: var.$color-main;
    }

}

//Depends code
.product-other-sizes-v1 {
    .title-v2 {
        font-family: var.$font-text;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3rem;
        color: var.$color-text;
        margin-bottom: 1.6rem;
    }
    &__main_title .title-v2{
        font-family: var.$font-header;
        font-size: 3rem;
        font-weight: 700;
        line-height: 4.8rem;
        margin-bottom: 3.6rem;
    }
}

// \Depends codes