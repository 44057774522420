@use 'base/variables' as var;
@use 'base/mixins' as mix;

.remove-shim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2px); // перекрыть букву Д
    padding: 9rem 6rem;
    padding-bottom: 0;

    background-color: #fff;
    border: 1px solid #ebf0f5;
    border-radius: 4px;
    transition: all 0.3s;

    @include mix.breakpoint(var.$tablet-width) {
        height: 100%;
        padding-top: 5.7rem 12.5rem;
        padding-bottom: 0;
        background-color: rgba(255, 255, 255, 0.95);
    }

    @include mix.breakpoint(var.$desktop-sm-width) {
        padding-top: 3rem;
    }

    &__text {
        margin: 0;
        margin-bottom: 4.6rem;
        text-align: center;
        line-height: 1.3;

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4rem;

            ._bold {
                font-weight: 500;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 3.4rem;
        }

        ._bold {
            font-weight: 600;
        }
    }

    &__btn-wrap {
        width: 20rem;
        margin: 0 auto;
        text-align: center;
        @include mix.breakpoint(var.$tablet-width) {
            width: 26rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__favorite,
    &__return {
        text-decoration: none;
        color: var.$color-main;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: none;
            stroke: currentColor;
            margin-right: 1rem;
        }
    }

    &__favorite {
        width: auto;
        height: auto;
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }

        svg {
            width: 1.4rem;
            height: 1.4rem;
            stroke-width: 2px;
        }
    }

    &__return-icon {
        width: 1.3rem;
        height: 1rem;
    }

    &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        height: 2.4rem;
        width: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background-color: transparent;
        padding: 0;
        outline: none;
        @include mix.breakpoint(var.$desktop-sm-width) {
            right: 2.7rem;
        }
        @media(hover:hover) {
            &:hover svg {
                stroke: #a3a3a3;
            }
        }
    }

    &__close-icon {
        width: 1.4rem;
        height: 1.4rem;
        stroke: #c9c9c9;
        transition: all 0.3s;
    }
}

/** DependCSS */
.remove-shim .link-with-icon-v1 {
    white-space: nowrap;
}
/** /DependCSS */