@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-list-v2 {

    @include mix.breakpoint(var.$tablet-width) {

    }
    @include mix.breakpoint(var.$desktop-width) {

    }


    &__ordered-list {
        list-style: none;
        position: relative;
    }

    &__ordered-item {
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
        position: relative;
        padding-left: 0rem;
        margin-bottom: 0.8rem;
        color: var.$color-black;

        &::before {
            content: '';
            position: absolute;
            left: -1.9rem;
            top: 0.8rem;
            width: 0.4rem;
            height: 0.4rem;
            background-color: var.$color-black;
            border-radius: 50%;
            font-weight: 700;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {

        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
        max-width: 27rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
            max-width: none;
        }
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 3rem;
        }
    }


}