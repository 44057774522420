@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tab-v1 {
    
    &__switcher-track {

        padding: 3rem 5rem 0rem 3rem;
        display: none;

        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }

        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;
        border-radius: 0.6rem;
        font-weight: 400;
        font-size: 1.6rem;
        font-family: var.$font-text;
        background-color: var.$color-gray-tone3;

    }

    &__switcher-track-element:hover {

        text-decoration: underline;
        cursor: pointer;

    }

    &__switcher-track-element {

        padding-right: 6rem;
        padding-bottom: 3rem;

        @include mix.breakpointRange(var.$tablet-width, var.$desktop-width) {
            width: 25%;
            padding-right: 3rem;
        }

        &_active {
            text-decoration: underline;
        }

    }

    &__panel-track-element:first-child {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            padding-top: 4rem;
        }
    }

    &__panel-track-element-head {
        display: none;
    }

    &__panel-track-element-head {
        
        cursor: pointer;
        width: 100%;
        margin-bottom: 1.5rem;
        font-family: var.$font-header;
        font-size: 1.6rem;
        font-weight: 400;
        padding: 1.6rem 3.5rem 1.6rem 1rem;
        background-color: var.$color-border;
        border-radius: 0.6rem;
        position: relative;

        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            display: block;
        }

    }

    &__panel-track-element-head::after {
        content: "";
        position: absolute;
        right: 2rem;
        top: calc(50% - (0.9rem / 4));
        width: 1.6rem;
        height: 0.9rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuMTIxMDlMNi41IDcuMTIxMDlMMTIgMS4xMjEwOSIgc3Ryb2tlPSIjN0I3NUM5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        background-repeat: no-repeat;
    }

    &__panel-track-element_active &__panel-track-element-head::after {
        rotate: 180deg;
        top: calc(50% - (0.9rem / 2));
    }

    &__panel-track-element-body {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    &__panel-track-element &__panel-track-element-body {
        display: none;
    }

    &__panel-track-element_active &__panel-track-element-body {
        display: block;
    }

}

/** DEPENDS CODE */
.tab-v1 {
}
/** DEPENDS CODE */