@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider-v5 {

    &__wrap {
        display: grid;
        grid-auto-flow: row;

        .slick-initialized .slider-v5__item.slick-slide {
            display: grid;
        }
    }

    &__track {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow: hidden;

        &_is-prev-visible .slider-v5__prev {
            display: block;
        }

        &_is-next-visible .slider-v5__next {
            display: block;
        }

    }

    &__navigator-place {
        position: absolute;
        height: 20rem;
        width: 100%;
    }

    @include mix.breakpoint(var.$tablet-h-width) {
        &__navigator-place {
            display: block;
        }
        // &__item-image-mobile {
        //     img {
        //         display: none  !important;
        //     }
        //
        //     display: none !important;
        // }
        //
        // &__item-image {
        //     img {
        //         display: block  !important;
        //     }
        //
        //     display: block  !important;
        // }
    }

    // &__item-image-mobile {
    //     display: block  !important;
    //
    //     img {
    //         display: block  !important;
    //     }
    // }
    //
    // &__item-image {
    //     display: none  !important;
    //
    //     img {
    //         display: none  !important;
    //     }
    // }


    &__container {
        position: relative;
    }

    @include mix.breakpoint(var.$tablet-width) {
        .container {
            padding: 0 2rem;
        }
    }

    @include mix.breakpoint(var.$desktop-width) {
        .container {
            padding: 0 8rem;
        }
    }


    &__prev {
        left: 1%;
        cursor: pointer;
        position: absolute;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__next {
        right: 12%;
        cursor: pointer;
        position: absolute;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__list-place {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 2rem;
        overflow: hidden;
    }

    &__item {
        display: block;
        text-decoration: none;
        white-space: nowrap;
    }

    &__item:hover, &__item_is-active {
        // background-color: var.$color-main;
        color: var.$color-border;
    }
}

.slider-v5 {
    .slider-v1__navigator-place {
        display: none;
        position: absolute;
        height: 20rem;
        width: 100%;

        svg path {
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
        }
    }

    .slick-slide {
        display: flex;
        justify-content: center;
    }

    .slick-slide img {
        max-width: 100%; /* Не растягиваем изображение */
        margin: 0 auto; /* Центрируем изображение */
    }
    @media only screen and (min-width: 1024px) {
        .slider-v1__navigator-place {
            display: block;
        }
    }

    .slick-dots {
        @include mix.breakpoint(var.$tablet-h-width) {
            button {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}