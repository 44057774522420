@use 'base/variables' as var;
@use 'base/mixins' as mix;

@keyframes dissapear {
    20% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0);
    }
}


@-webkit-keyframes pulse {
    0% {
        transform: scale(1);
        background-color: var.$color-main;
    }
    50% {
        transform: scale(.95);
        background-color: var.$color-text;
    }
    100% {
        transform: scale(1);
        background-color: var.$color-main;
    }
}
