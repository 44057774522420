@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-page-v1 {


}

/** DEPENDS CODE */

.text-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    @include mix.breakpoint(var.$desktop-width) {
        .breadcrumb-v1 {
            padding-top: 3rem;
            margin-bottom: 3.5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-max-width) {
        .breadcrumb-v1 {
            padding-top: 5.5rem;
        }
    }

    .category-tile-v1 {
        margin-bottom: 8rem;
    }
}

/** \DEPENDS CODE */