@use 'base/variables' as var;
@use 'base/mixins' as mix;

.writer-card-list-v1 {
    display: flex;
    justify-content: center;

}
// \TODO[dependСss]
.writer-card-list-v1 {
    .writer-card-v1 {margin: 5rem}
}