@use 'base/variables' as var;
@use 'base/mixins' as mix;

.you-tube-preview-v1 {

    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    min-height: 18rem;

    &._load {
        .youtube-icon {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 3rem);
            left: calc(50% - 3rem);
            width: 6rem;
            height: 6rem;
            background: url('/img/preloader.gif');
            background-size: cover;
        }
    }

    .youtube-text-play1{
        position: absolute;
        top: calc(50% - 16px - 50px);
        width: 100%;
        text-align: center;
        font-size: 2rem;
        color: var.$color-main;
    }
    .youtube-text-play2{
        position: absolute;
        top: calc(50% - 16px + 50px);
        text-align: center;
        width: 60%;
        left: calc(50% - 30%);
        font-size: 1.5rem;
        color: var.$color-main;
    }
    .youtube-icon {
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 24px);

        svg {
            width: 48px;
            height: 32px;
        }
    }

}

/** DEPENDS CODE */
.you-tube-preview-v1 {

}
/** DEPENDS CODE */