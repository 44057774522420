@use 'base/variables' as var;
@use 'base/mixins' as mix;

// стили независимы для мобилки и десктопа
// @media(max-width: var.$tablet-width - 1) {
//   .main-catalog__btn-close {
//       position: absolute;
//       top: 38px;
//       right: 8px;
//       transform: translateY(-50%);
//       outline: none;
//
//       border: 0;
//       background-color: transparent;
//
//       svg {
//           width: 3rem;
//           height: 3rem;
//           stroke: var.$color-main;
//       }
//   }
//   .search-form__input-wrap {
//     padding-right: 38px;
//   }
//   .search-form__btn {
//     right: calc(2rem + 38px);
//   }
// }
/* critical:start */
.main-catalog{
    // display:none;
}
/* critical: end */

@media(max-width: var.$desktop-width - 1) {
    .main-catalog {
        position: fixed;
        top: 14rem;
        top: var(--header-height);
        left: 0;
        width: 100%;
        height: calc(100% - 14rem - 4.9rem);
        height: calc(100% - var(--header-height) - var(--footer-height));
        transform: translateX(110%);
        overflow: scroll;
        // transition: transform 0.3s;
        background-color: #fff;
        z-index: 300;
        // z-index: 50;
        // @include mix.breakpoint(var.$tablet-width) {
        //     top: 0;
        // }

        &__subtitle {
          width: 100%;
        }

        &__item-icon {
            width: 5px;
            height: 16px;
            position: absolute;
            top: 50%;
            right: 1.5rem;
            transform: translateY(-50%);
            pointer-events: none;
            stroke: var.$color-main;
        }

        &__product-list {
            padding-top: 0.8rem;
            padding-bottom: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &__column:last-child {
            .main-catalog__item:first-child {
                border-top: 1px solid rgba(60, 60, 112, 0.15);
            }
        }

        &__item {
            position: relative;
            border-top: 1px solid rgba(60, 60, 112, 0.15);

            &:first-child {
                border: 0;
            }

            &._sale {
                background-color: var.$color-main;
                border-radius: 4px;
                border: 0;

                > .main-catalog__link-wrap .main-catalog__item-link {
                    color: #fff;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                }
            }

        }

        &__item-link {
            display: block;
            padding: 1.4rem 1.5rem;
            padding-right: 3rem;
            font-size: 1.6rem;
            line-height: 2rem;
            color: var.$color-text;
            font-weight: 500;
            text-decoration: none;
        }

        &__popup {
            position: fixed;
            top: 14rem;
            top: var(--header-height);
            left: 0;
            width: 100%;
            height: calc(100% - 14rem - 4.9rem);
            height: calc(100% - var(--header-height) - var(--footer-height));
            background-color: #fff;
            transform: translate(110%);
            transition: transform .3s;
            padding-left: 1rem;
            padding-right: 1rem;
            z-index: 50;

            &._active {
                transform: none;
            }

            &._popup-scroll {
                overflow: scroll;
            }

            .main-catalog__popup {
                z-index: 60;
            }
        }

        &__btn-back {
            padding: 0;
            background-color: #ebf0f5;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1.5rem;
        }

        &__back-icon {
            width: 5px;
            height: 16px;
            stroke: var.$color-main;
        }

        &__popup-head {
            display: flex;
            align-items: flex-start;
            margin-top: 2.8rem;
            margin-bottom: 2.5rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-top: 3.5rem;
                margin-bottom: 1.5rem;
            }
        }

        &__popup-title {
            margin: 0;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.5rem;

            a {
              text-decoration: none;
              color: #3c3c70;
            }

            ._normal {
                font-weight: 400;
            }

        }

        &__sale {
            margin-top: 2.5rem;
            margin-bottom: 2rem;
        }

        &__sale-img {
            display: block;
            width: 100%;
        }

        &__sale-title {
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 500;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        &__sale-time {
            font-size: 1.2rem;
            line-height: 1.5rem;
            color: #818da1;
            margin: 0.5rem 0;
        }

        &__product-list._sort,
        &__product-list._result {
            padding-left: 0;
            padding-right: 0;
        }

        &__container {
            padding-bottom: 4rem;
            // padding-bottom: var(--footer-height);
        }



        @include mix.breakpoint(var.$tablet-width) {
            .container {
                // padding: 0;
            }
            &__top {
                // padding: 3rem 0;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ebf0f5;
                position: relative;
                justify-content: space-between;
                padding: 3rem 0;
                margin: 0 3rem;
            }

            &__btn-close {
                // position: absolute;
                // top: 50%;
                // right: 0;
                // transform: translateY(-50%);
                outline: none;

                border: 0;
                background-color: transparent;

                svg {
                    width: 3rem;
                    height: 3rem;
                    stroke: var.$color-main;
                }
            }

            &__product-list {
                // padding-right: 0;
                // padding-left: 0;
            }

            &__item-link {
                font-size: 1.8rem;
            }

            &__item-icon {
                width: 8px;
            }
        }
    }

}


.main-catalog__subtitle {
  span {
    font-weight: 500 !important;
  }
}
@include mix.breakpoint(var.$desktop-width) {

    .main-catalog {
        $self: &;
        position: fixed;
        display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 300;
        overflow: auto;


        &__top {
            padding: 3rem 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ebf0f5;
            position: relative;
        }

        &__container {
            margin-left: auto;
            margin-right: auto;
            max-width: var.$container-width;
            padding: 0 4rem;
        }

        &__logo-wrap {
            width: 25%;

            img {
                display: block;
            }
        }

        &__btn-close {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            outline: none;

            border: 0;
            background-color: transparent;

            svg {
                width: 3rem;
                height: 3rem;
                stroke: var.$color-main;
            }
        }

        &__btn-close {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            outline: none;

            border: 0;
            background-color: transparent;

            svg {
                width: 3rem;
                height: 3rem;
                stroke: var.$color-main;
            }
        }

        &__popup-title {
            display: none;
        }

        &__sale {
            display: none;
        }


        &__product-list {
            padding-top: 4rem;
            margin-right: -2rem;
            margin-bottom: -4.5rem;
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            width: calc(50% - 2rem);
            margin-right: 2rem;
            margin-bottom: 4.5rem;
            @include mix.breakpoint(var.$desktop-sm-width) {
                width: calc(100% / 3 - 2rem);
            }
            @include mix.breakpoint(var.$desktop-width) {
                width: calc(100% / 4 - 2rem);
            }

            &._sale {
                .main-catalog__link-wrap {
                    background-color: var.$color-main;
                }

                .main-catalog__item-link {
                    color: #fff;
                }
            }
        }

        ._all-product {
            margin-bottom: 2rem !important;
        }

        &__item-link {
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 500;
            color: #7b75c9;
            text-decoration: none;
        }

        &__item-icon {
            display: none;
        }

        &__link-wrap {
            background: #ebf0f5;
            border-radius: 4px;
            position: relative;
        }

        &__btn-back {
            display: none;
        }

        &__product-list._sort {
            padding-top: 0;
            margin: 0;
            #{$self}__item {
                margin: 0;
                // margin-top: 2rem;
                width: 100%;
            }
            #{$self}__item-link {
                color: var.$color-text;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
            }

            #{$self}__popup {
                height: 0;
                overflow: hidden;
                transition: height .3s;
                // display: none;

                &._active {
                    height: var(--calc-height);
                    // display: block;
                }
            }
        }

        &__product-list._result {
            margin: 0;
            // margin-top: 1rem;
            // padding: 1.5rem;
            // background: #f9f9f9;
            // border-radius: 0 0 4px 4px;
            justify-content: space-between;
            #{$self}__item {
                margin: 0;
                margin-bottom: 1rem;
            }
            #{$self}__item-link {
                color: var.$color-text;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 1.8rem;
            }


            #{$self}__column {
                flex: 1;
                margin-bottom: 4rem;
                margin-top: 0.6rem;

                .main-catalog__item {
                  line-height: 1rem;
                }

            }
        }

        .container {
            padding: 0;
        }

    }

}

@include mix.breakpoint(var.$desktop-lg-width) {
    .main-catalog {
        .search-form {
            width: auto;
        }
    }
}

.main-catalog__subtitle {
  width: 100%;
}
