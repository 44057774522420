@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */

.search-dropdown {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    padding: 0 1.5rem;
    padding-bottom: 2rem;
    background-color: #F9F9F9;
    border: 1px solid #EBF0F5;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    transition: opacity .3s, max-width .3s;
    z-index: 200;

    @include mix.breakpoint(var.$tablet-width) {
        display: block;
        max-height: 0;
        overflow: hidden;
    }

    &__section {
        border-top: 1px solid #EBF0F5;
        padding: 2rem 0;
    }

    &__title {
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-weight: 500;
    }

    &__popular-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -2rem;
        margin-top: -1.5rem;
    }

    &__popular-item {
        margin-right: 2rem;
        margin-top: 1.5rem;
    }

    &__popular-link {
        display: block;
        padding: .6rem 2rem;
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        background-color: var.$color-main;
        border-radius: 1.8rem;
        transition: all .3s;
        @media(hover:hover) {
            &:hover {
                background-color: var.$color-text;
            }
        }
    }

    &__category-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -2rem;
        margin-top: -1.5rem;
    }

    &__category-item {
        margin-right: 2rem;
        margin-top: 1.5rem;
    }

    &__category-link {
        display: block;
        padding: .6rem 2rem;
        font-size: 1.5rem;
        line-height: 1.9rem;
        font-weight: 500;
        color: var.$color-main;
        text-decoration: none;
        background: #EBF0F5;
        border-radius: 4px;
        transition: all .3s;
        @media(hover: hover) {
            &:hover {
                background-color: var.$color-main;
                color: #fff;
            }
        }
    }

    &__good-list {
        position: relative;
        max-height: 22rem;
        @include mix.breakpoint(var.$tablet-width) {
          max-height: 36rem;
        }
        &.ps--active-y {
            padding-right: 1rem;
        }
    }

    &__good-item {
        margin-bottom: 1rem;
        &:last-of-type {
            margin-bottom: 0;
        }

        object {
            width: fit-content;
            height: fit-content;
        }
    }

    &__submit-btn {
        margin-top: 2rem;
        padding: .9rem 1.5rem;
        font-weight: 400;
    }
}
/* critical:end */
