@use 'base/variables' as var;
@use 'base/mixins' as mix;

.share-v1 {
    cursor: pointer;
    position: relative;

    &__dropdown {
        filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.2));
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 0.6rem;
        padding: 0.6rem 0.8rem 0.6rem 0.8rem;
        gap: 1.6rem;
        background: var.$color-white;
    }

    &__item {
        &:hover {
            text-shadow: none;

            span {
                font-weight: 600;
            }
        }

        span {
            display: inline-block;
            transition: font-weight 0.2s;
            font-weight: 400;
            text-shadow: 0 0 0 transparent;
            font-synthesis: none;
        }
        min-width: 14rem;
        display: flex;
        align-items: center;
        gap: 0.4rem;
        margin-bottom: 0.4rem;
        cursor: pointer;
    }


    &:hover {
        & .share-v1__dropdown {
            display: block;
        }
    }

    &__success {
        color: #0a5328;
    }

    &__error {
        color: #b91c1c;
    }
}

// DEPENDS CODE
.share-v1 {

}

// \ DEPENDS CODE