@use 'base/variables' as var;
@use 'base/mixins' as mix;

.client-gallery-v1 {

    display: grid;
    width: 100%;

}

/** DEPENDS CODE */
.client-gallery-v1 {
}
/** \DEPENDS CODE */
