@use 'base/variables' as var;
@use 'base/mixins' as mix;

.image-2-list-v1 {

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 100%;
    grid-template-columns: 18.8rem auto;

    &__list {

    }

    &__list ul {
        margin-top: 5.1rem;
    }

    &__list ul li {
        color: var.$color-bear;
        text-decoration: dashed;
        margin-bottom: 2rem;
        position: relative;
        padding-left: 4.3rem;
        text-decoration: underline dashed;
        cursor: pointer;
        line-height: 2rem;
    }

    &__list ul li::before {
        content: "";
        position: absolute;
        display: inline-block;
        width: 4rem;
        height: 0.1rem;
        background-color: var.$color-bear;
        padding-right: 0.5rem;
        top: calc(50% - (0.1rem / 2));
        left: 0;
    }

}