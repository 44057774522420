@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-page-exchange-v1 {

    margin-bottom: 5.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8.5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.2rem;
    }

    &__info-block {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: flex-start;
            padding-left: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-left: 6rem;
        }
    }

    &__border-section {
        margin-bottom: 4rem;
    }

    &__wrap {
        @include mix.breakpoint(var.$tablet-width) {
            order: -1;
            margin-right: 2rem;
            flex-grow: 1;
            max-width: 62rem;
            width: 43.2rem;

        }
        @include mix.breakpoint(var.$desktop-max-width) {
            max-width: 80rem;
        }
    }

    &__text {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        line-height: 150%;
        color: #7C7C7C;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__section {
        margin-bottom: 5rem;
        &:last-child {
            margin-bottom: 0;
        }
        &_bordered {
            padding-top: 5rem;
            border-top: 1px solid #D6D6D6
        }

        &_grey {
            padding: 2rem 3rem;
            background: #F5F7FA;
            border-radius: 4px;
            .default {
                &__title {
                    margin-bottom: 1.5rem;
                    max-width: 21rem;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    font-weight: 700;
                    @include mix.breakpoint(var.$tablet-width) {
                        max-width: initial;
                    }
                }
                &__text {
                    color: #474747;
                }
            }
        }
    }

    &__title {
        margin: 0;
        margin-bottom: 3rem;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 600;
    }

    &__subtitle {
        margin-top: 4rem;
        margin-bottom: 1.5rem;
        color: #474747;
        font-weight: 500;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        color: var.$color-main;
        text-decoration: none;
        // &[href^="mailto"] {
        //     border-bottom: 1px solid currentColor;
        // }
    }

    &__mark {
        font-size: 1.2rem;
        line-height: 150%;
        color: #A3A3A3;
    }

    ._mt-0 {
        margin-top: 0;
    }

}

/** DEPENDS CODE */
.text-page-exchange-v1 {

    .header-with-cta-v1 {
        margin-bottom: 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-bottom: 6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 9rem;
        }
    }

    .info-list-v1 {
        margin-bottom: 4.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-left: 1.8rem;
            padding-right: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-left: 5rem;
            padding-right: 6.5rem;
            padding-bottom: 4rem;
        }
    }

}
/** DEPENDS CODE */