@use 'base/variables' as var;
@use 'base/mixins' as mix;

.badge-list-v1 {
    vertical-align: top;
    gap: 1.4rem;
}

/** DEPENDS CODE */
.badge-list-v1 {

}
/** \DEPENDS CODE */
