@use 'base/variables' as var;
@use 'base/mixins' as mix;

.wave-divider-v1 {
    width: 100%;
    height: 2.2rem;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left 0 bottom 0;
    @include mix.breakpointRange(var.$zero-width, var.$mobile-lg-width) {
        background-image: url(/img/wave-small.svg);
    }
    @include mix.breakpointRange(var.$mobile-lg-width, var.$tablet-width) {
        background-image: url(/img/wave-medium.svg);
    }
    @include mix.breakpoint(var.$tablet-width) {
        background-image: url(/img/wave-large.svg);
    }
}