@use 'base/variables' as var;
@use 'base/mixins' as mix;

.article-view-page-v1 {

}

/** DEPENDS CODE */

.article-view-page-v1 {

    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .text-v2 {

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 8rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 8rem;
        }
    }

    .writer-card-list-v1  {
        margin-bottom: 8rem;
    }

    .review-add-button-v1 {
        margin-top: 4.5rem;
    }

    .pagination-v1__content {
        justify-content: center;
    }

    .article-grid-v1 {
        margin-bottom: 8rem;
    }
}

/** \DEPENDS CODE */