@use 'base/variables' as var;
@use 'base/mixins' as mix;

.footer-v2 {
    background-color: #ECF0F5;
    padding-bottom: 4.9rem; //sticky
    margin-top: 5rem;
    @include mix.breakpoint(var.$tablet-width) {
        padding-top: 4rem;
        padding-bottom: 0;
    }

    &__flex {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 100%;
        grid-template-rows: min-content;
        gap: 0px 0px;
        grid-template-areas:
                "logo"
                "contact"
                "additional"
                "client"
                "catalog"
                "about"
                "social"
                "others"
        ;
        @include mix.breakpoint(var.$tablet-width) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: min-content min-content min-content;
            gap: 0px 0px;
            grid-template-areas:
    "catalog client about contact additional"
    "logo logo social social social"
    "others others others others others";
        }

        &__catalog {
            grid-area: catalog;
        }

        &__client {
            grid-area: client;
        }

        &__about {
            grid-area: about;
        }

        &__contact {
            grid-area: contact;
        }

        &__additional {
            grid-area: additional;
            border-top: 1px solid var.$color-main;
            padding-top: 2rem;
            @include mix.breakpoint(var.$tablet-width) {
                border: none;
                padding: 0;
            }
        }

        &__logo {
            grid-area: logo;
        }

        &__social {
            grid-area: social;

            @include mix.breakpoint(var.$tablet-width) {
                .footer-v2__group-content {
                    float: right;
                }
            }
        }

        &__others {
            grid-area: others;
            color: var.$color-main;
            font-size: 1rem;
            border-top: 1px solid var.$color-main;
            padding-top: 2rem;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.4rem;
                border: none;
                padding: 0;
            }
        }
    }


    &__group-title {
        position: relative;
        color: #262647;
        font-weight: 700;
        margin-bottom: 2rem;

    }

    &__group-content {
        margin-bottom: 3.2rem;

        &.collapse-content {
            display: none;
            @include mix.breakpoint(var.$tablet-width) {
                display: block;
            }
        }
    }

    &__group {
        ul li {
            margin-bottom: 1.2rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-bottom: 2rem;
            }
        }

        ul a {
            text-decoration: none;
        }
    }


    // top
    &__top-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        padding-bottom: 6rem;
        margin-top: -4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: -3rem;
            padding-bottom: 4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 0;
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
        }
    }

    &__logo {
        display: inline-block;
    }

    &__logo-img {
        width: 10.4rem;
        height: 4.6rem;
        display: block;
        margin-right: 5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 30rem;
            height: 15rem;

        }
    }

    &__contact-wrap {
        margin-top: 4rem;

        &:nth-child(odd) {
            margin-left: .5rem;
        }

        &:nth-child(even) {
            margin-right: .5rem;
        }

        &._address {
            width: 100%;
        }

        @include mix.breakpoint(var.$mobile-width) {
            &:nth-child(odd) {
                margin-left: 1rem;
            }
            &:nth-child(even) {
                margin-right: 1rem;
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 3rem;
            &._phone {
                order: 2;
            }
            &._mail {
                margin-left: auto;
                margin-right: auto;
                order: 3;
            }
            &._work-time {
                order: 4;
            }
            &._address {
                order: 5;
                margin-left: 18.2rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                margin-top: 0;
                &._address {
                    width: auto;
                    margin-left: 0;
                }

                &._mail,
                &._phone,
                &._work-time {
                    margin-right: 1rem;
                    margin-left: 1rem;
                }
            }
        }

    }

    &__contact-title {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #C1C1C1;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }

    &__contact-data {
        color: var.$color-text;
        text-decoration: none;
        margin: 0;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 500;
        @include mix.breakpoint(var.$mobile-width) {
            font-size: 1.7rem;
        }
    }

    // end top

    // middle
    &__middle {
        background-color: var.$color-text;
    }

    &__middle-inner {
        padding: 4rem 1rem;
        padding-top: 3.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            // padding: 3rem 0;
            padding-top: 2.5rem;
            padding-bottom: 3rem;
        }
        /*@include mix.breakpoint(var.$desktop-width) {
            display: flex;
            padding: 3.3rem 0;
        }*/
    }

    &__middle-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7.4rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: auto;
            margin-bottom: 0;
        }
    }

    &__middle-column {
        width: 42%;
        margin-top: -1.8rem;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: flex;
            justify-content: space-around;
            width: auto;
            // width: 50%;
        }
    }

    &__nav-item {
        margin-top: 1.8rem;
        white-space: nowrap;
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 0;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-right: 2.5rem;
        }
    }

    &__nav-link {
        color: #fff;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }

    // end middle

    // bottom
    &__bottom {
        padding-top: 4rem;
        // padding-bottom: 5.2rem;
        font-family: "Roboto";
        color: #AFAFAF;
        font-size: 1.2rem;
        line-height: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 2.5rem 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 2rem;

        }
    }

    &__bottom-inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__copyright {
        margin: 0;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2rem;
            flex-shrink: 0;
        }
    }

    &__data {
        margin: 0;
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 48rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: auto;

        }
    }

    &__ibrush-link {
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 8.5rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-left: 0;
        }
    }

    &__ibrush-icon {
        width: 7rem;
        height: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 5.1rem;
            height: 1.1rem;
        }
    }

    .site-header__indicators-count {
        top: -0.6rem;
        right: -0.9rem;
    }

    .cursor-pointer {
        cursor: pointer;
    }
}

// depends code
.footer-v2 {
    .collapse-title {
        cursor: pointer;

        &:after {
            position: absolute;
            content: "";
            width: 1.4rem;
            height: .8rem;
            right: 1.8rem;
            top: 0;
            bottom: 0;
            margin: auto;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxbDYgNiA2LTYiIHN0cm9rZT0iIzNDM0M3MCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
            @include mix.breakpoint(var.$tablet-width) {
                display: none;
            }
        }

    }

    .site-footer__group-title {
        margin-bottom: 2rem;
        position: relative;
    }

    .site-footer__group-content {
        margin-bottom: 3.2rem;
    }
}