@use 'base/variables' as var;
@use 'base/mixins' as mix;

.divider-v1 {

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        margin-top: 1.8rem;
        margin-bottom: 1.8rem;
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    &__divider {
        width: 100%;
        height: 100%;
    }
    
}