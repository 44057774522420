@use 'base/variables' as var;
@use 'base/mixins' as mix;

.question-form {
  margin-bottom: 8rem;

  @media (max-width: var.$tablet-width - 1) {
    .form__input-container {
      margin-right: 0 !important;
      margin-bottom: 1rem;
    }
  }

  .delivery-form__inner {
    @include mix.breakpoint(var.$tablet-width) {
      background-position: bottom -2px right 5rem;
      background-image: url(/img/mister-purple-tricky-tablet.svg);
      background-repeat: no-repeat;
      background-size: 15%;
    }
    @include mix.breakpoint(var.$desktop-sm-width) {
      background-size: 9%;
    }
  }
}

#questionSearch {
  .form__input-container {
    margin-right: 2rem;
  }
}

.question {
  margin-bottom: 8rem;

  &__item {
    margin-bottom: 24px;

    &-title {
      margin: 0;
      
      &._title, a {
        display: block;
        background-color: #3c3c70;
        color: #fff;
        font-size: 2rem;
        line-height: 2.5rem;
        padding: 3.5rem 2.5rem;
        text-decoration: none;
      }
    }

    &-answer {
      background-color: #EBF0F5;
      font-size: 1.6rem;
      line-height: 1.3;
      padding: 2.5rem;
    }
  }

}
