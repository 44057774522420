.catalog-zone-v1 {
    &__applied-tag-list-filter-wrap {
        overflow: hidden;
    }
}

//Deps
.catalog-zone-v1 {
    .tag-list-v12, .tag-list-v13 {
        margin: 0 0 4.4rem 0;
    }
}
