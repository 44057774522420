@use 'base/variables' as var;
@use 'base/mixins' as mix;

.locality-input-v1 {
    &__input {
        border: none;
        border-bottom: 0.1rem solid var.$color-gray-tone3;
        //  width: Hug (283px)
        //height: Hug (27px)
        padding: 0.8rem 0;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.0rem;
        color: var.$color-black-tone4;
        width: 100%
    }
}

//deps
.locality-input-v1 {
    p {
        margin-top: 1.6rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: var.$color-gray-tone11;
    }
}

// \deps