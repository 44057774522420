@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popup {
    position: relative;
    margin: 0 auto;
    padding: 1.5rem;
    padding-top: 4.2rem;
    background-color: #fff;
    &._scrollable {
        .mfp-close {
            position: fixed;
            @include mix.breakpoint(var.$tablet-width) {
              right: calc(50% - 28rem);
            }
        }
        .popup__title {
            position: sticky;
            top: 0;
            z-index: 200;
            width: calc(100% + 3rem);
            margin-left: -1.5rem;
            padding-left: 1.5rem;
            //padding-right: 9rem;
        }
    }
    .title {
        margin-bottom: 3.5rem;
        font-size: 2.4rem;
        line-height: 1.2;
    }

    @include mix.breakpoint(var.$tablet-width) {
        width: 60rem;
        padding: 5rem 4rem;
        border-radius: 6px;
        &._quickview-popup {
          width: 90rem;

          .mfp-close {
            right: calc(50% - 42rem);
          }
          .quickview-title {
            display: block;
            padding-right: 7rem;
            font-size: 3rem !important;
            text-decoration: none;
          }
        }

        .title {
            font-size: 4rem;
            line-height: 1.1;
            margin-bottom: 3rem;
        }
    }


    &__title {
        padding-top: 1.2rem;
        padding-bottom: 1.5rem;
        background-color: #fff;

        .info-tooltip {
          color: var.$color-main;
          width: 1.6rem;
          height: 1.6rem;
        }
    }

    &__btn-submit {
        font-size: 1.5rem;
        font-weight: 500;
        padding-top: 1.8rem;
        padding-bottom: 1.9rem;
        margin-bottom: 2rem;
    }

    .form {
        &__input-item {
            &._comment {
                margin-bottom: 3.5rem;

                textarea {
                    height: 7rem;
                }
            }

            &._rating,
            &._attach {
                margin-bottom: 4rem;
            }

            @include mix.breakpoint(var.$tablet-width) {
                &._phone {
                    width: 20.5rem;
                    margin-right: 2rem;
                }
                &._email {
                    width: 29.5rem;
                }
                &._comment {
                    margin-bottom: 1.8rem;
                }

                &._rating {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1.3rem 2rem;
                    background: #F5F7FA;
                    border-radius: 4px;
                    margin-bottom: 1.8rem;
                }
            }
        }

        &__rating-text {
            @include mix.breakpoint(var.$tablet-width) {
                margin-bottom: 0;
            }
        }

        &__attach-btn {
            color: var.$color-main;
            padding-top: 1.1rem;
            padding-bottom: 1.1rem;
            @include mix.breakpoint(var.$tablet-width) {
                width: 18.6rem;
                margin-right: auto;
                margin-bottom: 0;
            }
        }

    }


    &.sale-popup {

      .sale-popup__header {
        padding: 3rem;
        background: var.$color-main;
        background-image: url("/img/mister-procent.png");
        background-repeat: no-repeat;
        background-position: bottom right 2.5rem;
        background-size: 9.6rem 10.2rem;
        border-radius: 4px;

        .title {
          color: #fff;
          margin-bottom: 1.5rem !important;

          .hi-1 {
            font-size: 2.5rem;
            @media (min-width: var.$tablet-width) {
              display: block;
              font-size: 5rem;
            }
          }
          .hi-2 {
            font-size: 2.5rem;
            @media (min-width: var.$tablet-width) {
              display: block;
              font-size: 4.5rem;
            }
          }
          .hi-3 {
            font-size: 2rem;
            display: block;
            @media (min-width: var.$tablet-width) {
              font-size: 4rem;
            }
          }

        }
      }

      .sale-popup__info {
        margin: 1rem 0 2rem;
        color: #898989;
        font-size: 1rem;
        width:80%;
        @media (min-width: var.$tablet-width) {
          width:80%;
          margin: 1.5rem 0 3rem;
          font-size: 1.4rem;
        }
      }

      .sale-popup__content {

      }

      .sale-popup__footer {
        /*background: var.$color-main;*/
        color: #fff;
        border-radius: 4px;
        /*padding: 18px;*/
        /*text-align: center;*/
        /*margin-top: 32px;*/
        font-size: 1rem;
        text-align: left;
        width: 60%;
        @media (min-width: var.$tablet-width) {
          display: block;
        }

      }

    }


}
