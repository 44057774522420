@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-grid-v2 {


}

/** DEPENDS CODE */
.review-grid-v2 {
    .review-card-v3 {
        margin-bottom: 3.6rem;
    }
}
/** DEPENDS CODE */