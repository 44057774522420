@use 'base/variables' as var;
@use 'base/mixins' as mix;

.showroom-page-v1 {

}

// deps

.showroom-page-v1 {

    .brand-list {
        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .image-carousel-v1__slide {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
        .image-carousel-v1__slide, img {
            max-width: 13rem;
            object-fit: contain;
        }
    }
    .breadcrumb-v1 {
        padding-top: 5.5rem;
        margin-bottom: 3rem;
    }

    .image-carousel-v1 {
        margin: 6.5rem 0 13.4rem 0;
    }

    .ya-map-v1 {
        margin-bottom: 11rem;
    }

    .title-v2 {
        margin-bottom: 1.6rem;
    }

    .contact-grid-v1 {
        margin-bottom: 8rem;
    }

    .tag-list-v3 {
        margin-bottom: 3.2rem;
    }

    .tag-list-v1 {
        margin-top: 8rem;
    }

    @include mix.breakpoint(var.$desktop-width) {

        .breadcrumb-v1 {
            padding-top: 3rem;
            margin-bottom: 3.5rem;
        }

        .tag-list-v3 {
            margin-bottom: 3.2rem;
        }
    }

    .widgetTracker-showroom {
        display: none;
    }
}

// \deps