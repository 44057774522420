@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sales {
    overflow: hidden;
    margin-bottom: 7.6rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8.8rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.6rem;
    }
    &__list {
        display: flex;
        @include mix.breakpoint(var.$desktop-width) {
            flex-wrap: wrap;
            margin-right: -2rem;
            margin-top: -2rem;
        }
    }
    &__item {
        display: flex;
        width: 26.2rem;
        margin: 0 0.75rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 30.5rem;
            margin: 0 1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 2rem);
            margin-left: 0;
            margin-right: 2rem;
            margin-top: 2rem;
        }
    }

    &__link {
        width: 100%;
        color: var.$color-text;
        text-decoration: none;
        outline: none;
    }

    &__img-wrap {
        background: rgba(185, 185, 185, 0.2);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        display: block;
        height: 10rem;
        @include mix.breakpoint(var.$tablet-width) {
            height: 16rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            height: 18rem;
        }
    }

    &__name {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 25rem;
            font-size: 2rem;
            line-height: 2.5rem;
            margin-top: 2.5rem;
            margin-bottom: 1rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            margin-bottom: 2rem;
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    }

    &__date {
        color: #818DA1;
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }
}
