@use 'base/variables' as var;
@use 'base/mixins' as mix;

.button-link-with-arrow-right-v1 {

    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 1.5rem;
    background-color: var.$color-third;
    line-height: 1;
    padding: 1.4rem 1.5rem;
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        font-size: 1.3rem;
        padding: 1.2rem 2.4rem;
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        font-size: 1.5rem;
        padding: 1.2rem 2rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        font-size: 1.6rem;
        padding: 1.5rem 2rem;
    }
    text-decoration: none;
    font-weight: 400;
    color: var.$color-black-tone4;
    // TODO[themeProvider]: common radian's
    border-radius: 5px;

    &__arrow {
        stroke: var.$color-black-tone4;
    }

    &:hover {
        background-color: var.$color-text;
        color: var.$color-white;
        .button-link-with-arrow-right-v1__arrow {
            stroke: var.$color-white;
        }
    }

    &:active {
        // must be small emphasize!
        background-color: rgba(var.$color-text, 0.8);
        color: var.$color-white;
        .button-link-with-arrow-right-v1__arrow {
            stroke: var.$color-white;
        }
    }
    
}