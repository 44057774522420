@use 'base/variables' as var;
@use 'base/mixins' as mix;

.html-content-v1 {
    &__content {
        text-align: center;
        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            display: none;

        }
    }

    &__mobile_content {
        text-align: center;
        @include mix.breakpoint(var.$desktop-width) {
            display: none;
        }
    }

    &__without-mobile {
        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            display: block;
        }
    }
}

/** DEPENDS CODE */
.html-content-v1 {

}

/** DEPENDS CODE */