@use 'base/variables' as var;
@use 'base/mixins' as mix;


.infoblock-wide .ourbenefits .benefit-headers {
    background: #bee6a8;
    font-size: 16px;
    font-weight: 700;
}

.infoblock-wide .ourbenefits .benefit-headers, .infoblock-wide .ourbenefits .benefit-texts {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 1rem;
}

.infoblock-wide .ourbenefits .benefit-headers > div, .infoblock-wide .ourbenefits .benefit-texts > div {
    flex: 1;
    text-align: center;
}

.infoblock-wide .ourbenefits .benefit-space {
    height: .4rem;
}

.infoblock-wide .ourbenefits .benefit-texts {
    background: #7b75c9;
    color: #fff;
    font-size: 13px;
}

.infoblock-wide .ourbenefits .benefit-headers, .infoblock-wide .ourbenefits .benefit-texts {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding: 1rem;
}