@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popular-category-v1 {
    &__wrap {

    }

    //OLD CODE
    &__title-place {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-top: 5rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 6rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 12rem;
        }
    }

    &__list-place {

        display: grid;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            grid-template-columns: 1fr;
            column-gap: 0;
            row-gap: 2.4rem;
            margin-top: 2rem;
            margin-bottom: 6rem;
            // TODO[dependСss]
            // depend: info-card-v1
            & .info-card-v1 {
                grid-column-end: span 1;
                grid-row-end: span 1;
            }
            // \depend: info-card-v1
            // \TODO[dependСss]
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 2rem;
            row-gap: 3rem;
            margin-top: 3rem;
            margin-bottom: 6rem;
            // TODO[dependСss]
            // depend: info-card-v1
            & .info-card-v1 {
                grid-column-end: span 1;
                grid-row-end: span 1;
            }
            // \depend: info-card-v1
            // \TODO[dependСss]
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-template-columns: repeat(4, 1fr);
            column-gap: 3rem;
            row-gap: 4rem;
            margin-top: 3.6rem;
            margin-bottom: 12rem;
            // TODO[dependСss]
            // depend: info-card-v1
            & .info-card-v1 {
                grid-column-end: span 1;
                grid-row-end: span 1;
            }
            & .info-card-v1:nth-child(6n - 2),
            & .info-card-v1:nth-child(6n - 3) {
                grid-column-end: span 2;
                grid-row-end: span 1;
            }
            // \depend: info-card-v1
            // \TODO[dependСss]
        }

    }

    // TODO[dependСss]
    // depend: widget-type-in-hyphen-case

    // \depend: widget-type-in-hyphen-case

    // \TODO[dependСss]

}

//depend

.popular-category-v1 {
    .info-card-v1__desc {
        text-align: center;
    }
}