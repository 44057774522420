@use 'base/variables' as var;
@use 'base/mixins' as mix;

.control-prev-button-v1 {
    
    &:hover {
        fill: var.$color-main;
        stroke: var.$color-third;
    }

    &:active {
        path {
            fill: var.$color-main;
            stroke: var.$color-third;
        }
    }
}