@use 'base/variables' as var;
@use 'base/mixins' as mix;

.article {
    margin-bottom: 4rem;
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
    }
    &__section {
        margin-bottom: 5rem;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-left: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: flex-start;
        }
    }
    &__product-prices-image{
        width:100%; display: block; margin:0 auto; text-align: center;
        max-width: 80%;
        @include mix.breakpoint(var.$tablet-h-width){
            max-width: 700px;
        }
        @include mix.breakpoint(var.$desktop-width){
            max-width: 800px;
        }
        @include mix.breakpoint(var.$desktop-max-width){
            max-width: 1000px;
        }
    }
    &__product-prices-price{
        width:100%;
        text-align: center;
    }

    &__section._table {
        margin-top: -2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
        }
        .article__left {
            p {
                font-size: 1.6rem;
                margin-bottom: 3.5rem;
            }
            @include mix.breakpoint(var.$tablet-width) {
                padding-left: 2rem;
                p {
                    margin-bottom: 6rem;
                }
            }
            @include mix.breakpoint(var.$desktop-width) {
                margin-right: 63rem;
                padding: 0;
                p {
                    margin-bottom: 4.5rem;
                }
            }

        }
    }

    &__img-wrap {
        display: none;
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            flex-shrink: 0;
            width: 54.2rem;
            margin: 0;
            position: sticky;
            top: 4rem;
        }

        img {
            display: block;
        }
    }

    &__left {
        @include mix.breakpoint(var.$desktop-width) {
            flex-grow: 1;
            margin-right: 8.8rem;
        }
    }

    &__left {
        h2 {
            margin-top: 5rem;
            margin-bottom: 3rem;
            font-size: 2rem;
            line-height: 2.5rem;
            font-weight: 500;
            &:first-child {
                margin-top: 0;
            }
            @include mix.breakpoint(var.$tablet-width) {
                font-weight: 600;
            }
        }

        h3 {
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.5rem;
            margin: 3rem 0;
            &:first-child {
                margin-top: 0;
            }
            @include mix.breakpoint(var.$tablet-width) {
                margin: 2rem 0;
            }
        }

        img {
            display: block;
            margin: 3rem 0;
            @include mix.breakpoint(var.$tablet-width) {
                margin: 5rem 0;
            }
            @include mix.breakpoint(var.$desktop-width) {
                display: none;
            }
        }

        p {
            margin: 0;
            margin-bottom: 1.5rem;
            font-size: 1.4rem;
            line-height: 130%;
            color: #818DA1;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.6rem;
            }
        }

        b {
            font-weight: 500;
            color: var.$color-text;
        }

        ol {
            margin: 1.5rem 0;
            padding-left: 1.4rem;

            > li {
                font-size: 1.4rem;
                line-height: 130%;
                color: #818DA1;
                &::marker {
                    font-weight: 700;
                    color: var.$color-text;
                }
                @include mix.breakpoint(var.$desktop-width) {
                    font-size: 1.6rem;
                }
            }
        }

        ul {
            // list-style: initial;
            padding-left: 1.4rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-top: 5rem;
            }
            > li {
                position: relative;
                font-size: 1.4rem;
                line-height: 130%;
                color: #818DA1;
                &:not(.article__cell-item)::before {
                    content: '·';
                    position: absolute;
                    left: -1.4rem;
                    top: -1px;
                    // width:
                    font-size: 1.6rem;
                    color: var.$color-text;
                }
                @include mix.breakpoint(var.$desktop-width) {
                    font-size: 1.6rem;
                }
            }
        }
    }

    &__table {
        @include mix.breakpoint(var.$desktop-width) {
            width: 91rem;
            // padding-left: 2rem;
        }
    }

    &__table-wrap {
        // margin-bottom: 4rem;
    }


    &__table-title {
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 400;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 0;
        }
    }

    &__table-row {
        border-radius: 4px;
        &:nth-child(even) {
            background: #EBF0F5;
            .article {
                &__cell-inner {
                    border-color: #fff;
                }
                &__cell-link {
                    border: 0;
                    color: var.$color-text;
                }
            }

        }
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1.5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            flex-wrap: nowrap;
            margin-bottom: 0;
            &:not(:first-child) .article__cell-title {
                display: none;
            }
            &:nth-child(even) {
                background: none;
            }
            &:last-child {
                .article__cell-inner {
                    padding-bottom: 2.5rem;
                }
            }
        }
    }


    &__table-cell {
        padding: 2.5rem 2rem;
        padding-bottom: 0;
        &:last-child {
            .article__cell-inner {
                border: 0;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            &._name {
                width: 100%;
            }

            &._advantages,
            &._shortcomings {
                width: calc(50% - 2rem);
                .article__cell-inner {
                    border: 0;
                }
            }

            &._advantages {
                margin-right: 1rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 0;
            padding-top: 2.3rem;
            padding-bottom: .7rem;
            &._name {
                width: 15.5rem;
                margin-right: 3rem;
            }

            &._advantages {
                flex-grow: 1;
                margin-right: 3rem;
                background-color: #EBF0F5;

                padding: 2rem 2.5rem;
                padding-top: 2.3rem;
                padding-bottom: 0.7rem;
            }

            &._shortcomings {
                width: 27.5rem;
            }
        }
    }

    &__cell-inner {
        padding-bottom: 2.5rem;
        border-bottom: 1px solid #EBF0F5;

        @include mix.breakpoint(var.$desktop-width) {
            border: 0;
            padding: 0;
        }
    }

    &__cell-title {
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        line-height: 130%;
        font-weight: 500;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 3.5rem;
        }
    }

    &__cell-link {
        margin-bottom: 0;
        margin-bottom: 1rem;
        font-size: 1.6rem;
        text-decoration: none;
        display: inline;
        color: var.$color-main;
        border-bottom: 1px solid currentColor;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__cell-list {
        padding: 0;
        list-style: none;
        margin-top: 0 !important;
    }

    &__cell-item {
        font-size: 1.4rem;
        line-height: 130%;
        color: #818DA2;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
    }

    &__cell-value {
        margin: 1rem 0;
        // margin-bottom: 1rem;
        font-size: 1.4rem;
        line-height: 130%;
        color: #818DA2;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
    }

    .article-share{
        text-align: center;
        margin: 5rem 0;
    }

    section .container {
      padding:  0;
      .best__list:before {
        left: 99%;
      }
    }

    &-main {
      overflow-x: hidden;
    }

    .best__slider-container {
      padding: 0 3rem;
    }
    .best {
      margin-bottom: 0;

      .best__slider-nav._next {
        right: 2rem;
      }
      .best__slider-nav._prev {
        left: 0;
      }
    }

    .slick-list {
      overflow: visible;
    }
    .bg-main,.bg-second{
        padding:1rem;
    }
}
