@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-card-v1 {

    background-color: var.$color-gray-tone2;
    border-radius: 0.6rem;
    padding: 4rem 3rem;

    &__head {
        white-space: nowrap;
        display: grid;
        grid-template-columns: min-content min-content;
        grid-template-rows: min-content min-content min-content;
        grid-template-areas: 
            "avatar name"
            "avatar date"
            "rating rating";
    }

    &__letter {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-right: .8rem;
        background: var.$color-second;
        border: 1px solid var.$color-main;
        color: var.$color-main;
        text-align: center;
        line-height: 2.6rem;
        font-weight: 700;
    }

    &__head-avatar {
        grid-area: avatar;
        width: 4rem;
        height: 4rem;

        img {
            border-radius: 10rem;
        }
    }

    &__head-name {
        grid-area: name;
        padding-bottom: 0.2rem;
        padding-left: 1rem;
        color: var.$color-main;
    }

    &__head-date {
        grid-area: date;
        padding-left: 1rem;
        color: var.$color-gray;
    }

    &__head-rating {
        padding-top: 1rem;
        grid-area: rating;
    }

    &__data-block {
        padding: 2rem 0;
    }

    &__data-block-element {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        img {
            flex: 1 1 calc((100% / 4) - 0.5rem);
            max-width: calc((100% / 4) - 0.5rem);
            height: auto;
            transition: transform 0.3s ease;
            border-radius: 0.3rem;
        }

        img:hover {
            transform: scale(1.1);
        }
    }
}

/** DEPENDS CODE */
.review-card-v1 {
    .rating-star-v1 {
        height: 2.4rem;
    }
}

/** DEPENDS CODE */