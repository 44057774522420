@use 'base/variables' as var;
@use 'base/mixins' as mix;

.interior-v1 {

    &__wrap {

    }

    &__title-place {
        // @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        //     margin-top: 5rem;
        // }
        // @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        //     margin-top: 6rem;
        // }
        // @include mix.breakpoint(var.$tablet-h-width) {
        //     margin-top: 12rem;
        // }
    }

    &__list-place {
        display: none;
        // display: grid;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            & .interior-card-v1 {
                float: left;
                width: 18rem;
                max-width: 18rem;
                margin-right: 2rem;
            }
            // grid-template-columns: repeat(2, 1fr);
            // // column-gap: 2rem;
            // // TODO[removeBeforeRelease]: if js-plugin disabled or error
            // row-gap: 2rem;
            // // \TODO[removeBeforeRelease]: if js-plugin disabled or error
            // margin-top: 2rem;
            // margin-bottom: 6rem;
            // // TODO[dependСss]
            // // depend: interior-card-v1
            // \depend: interior-card-v1
            // \TODO[dependСss]
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            & .interior-card-v1 {
                float: left;
                width: 21rem;
                max-width: 21rem;
                margin-right: 2rem;
            }
            // grid-template-columns: repeat(3, 1fr);
            // // column-gap: 2rem;
            // // TODO[removeBeforeRelease]: if js-plugin disabled or error
            // row-gap: 2rem;
            // // \TODO[removeBeforeRelease]: if js-plugin disabled or error
            // margin-top: 3rem;
            // margin-bottom: 6rem;
            // // TODO[dependСss]
            // // depend: interior-card-v1
            // \depend: interior-card-v1
            // \TODO[dependСss]
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            & .interior-card-v1 {
                float: left;
                width: 33.8rem;
                max-width: 33.8rem;
                margin-right: 3rem;
            }
            // grid-template-columns: repeat(4, 1fr);
            // // column-gap: 3rem;
            // row-gap: 4rem;
            // margin-top: 3.6rem;
            // margin-bottom: 12rem;
            // // TODO[dependСss]
            // // depend: interior-card-v1
            // \depend: interior-card-v1
            // \TODO[dependСss]
        }
        & .interior-card-v1:last-child {
            margin-right: 0;
        }

    }

    &__prev {
        display: none;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: block;
        }
        cursor: pointer;
        position: absolute;
        left: 1%;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__next {
        display: none;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: block;
        }
        cursor: pointer;
        position: absolute;
        right: 1%;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__track {

        position: relative;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-bottom: 6rem;
            margin-top: 2.4rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-bottom: 6rem;
            margin-top: 3rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 12rem;
            margin-top: 3.4rem;
        }
    }

    // TODO[dependСss]
    // depend: widget-type-in-hyphen-case

    // \depend: widget-type-in-hyphen-case

    // \TODO[dependСss]

}

// deps code
.interior-v1 {

    .title-v1 {
        font-weight: 700;
        font-family: Affect;
        letter-spacing: .015em;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 2rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            font-size: 3.2rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 4rem;
        }
    }

}
// \deps code