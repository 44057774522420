@use 'base/variables' as var;
@use 'base/mixins' as mix;

.catalog-filtered-grid {

    display: flex;
    flex-direction: column;

    &_is-show-filter {
        // flex-direction: row;

        .goods .catalog-filtered-grid__toggle-wrap {
            display: none;
        }
    }

    flex-wrap: nowrap;
    align-content: flex-start;
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
    }
    @include mix.breakpoint(var.$tablet-h-width) {
    }

    &__active-filter-panel {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: min-content auto;
        grid-template-areas: "toggle applied-tags";
        column-gap: 3.1rem;
        margin-bottom: 2rem;
    }

    &__active-filter-panel &__toggle-wrap {
        grid-area: toggle;
    }

    &__active-filter-panel .filter-applied-tags-v1__wrap {
        grid-template-areas: applied-tags;
    }

    &_is-show-filter &__active-filter-panel &__toggle-wrap {
        max-width: 100%;
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 33.8rem;
        }

    }

    &__filter-place {
        display: none;
    }

    &__filter-place &__common-wrap {
        display: none;
    }

    &_is-show-filter &__filter-place &__common-wrap {
        display: block;
    }

    &_is-show-filter &__filter-place {
        display: block;
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 33.8rem;
            min-width: 33.8rem;
            margin-right: 3.1rem;
        }
    }

    &_is-show-filter &__list-place .filter-applied-tags-v1__wrap {
        @include mix.breakpoint(var.$tablet-h-width) {
            display: block;
            margin-bottom: 3.4rem;
        }
    }

    &_is-show-filter.catalog-filtered-grid_mobile {
        flex-direction: column;
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__filter-place {
        height: 6.6rem;
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap {
        display: block;
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__filter-active-wrap {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: block;
        }
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap &__filter-wrap {
        overflow: hidden;
        max-height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        min-height: 100vh;
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap &__filter-wrap::-webkit-scrollbar {
        display: none;
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap &__header,
    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap &__footer,
    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap &__filter-block {
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }

    &_is-show-filter.catalog-filtered-grid_mobile &__common-wrap &__blocks-wrap {
    }

    &__common-wrap {
        width: 100%;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: none;
            position: absolute;
            height: 100vh;
            max-height: 100vh;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            top: 0;
            left: 0;
            z-index: 49;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            clear: both;
            border: 1px solid var.$color-border;
            padding: 1.6rem 2.4rem;
        }
    }

    &__list-place {
        width: 100%;
    }

    &_is-show-filter &__list-place {
        @include mix.breakpoint(var.$tablet-h-width) {
            width: calc(100% - calc(33.8rem + 3.1rem));
        }
    }

    &__filter-wrap {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            max-width: 49.9rem;
            background-color: var.$color-white;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
        }
    }

    &__header {
        display: none;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: grid;
        }
        grid-template-rows: 1fr;
        grid-template-columns: calc(100% - 3rem) 3rem;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.2;
        color: var.$color-black-tone4;
        padding-bottom: 2rem;
        padding-top: 2rem;
        border-bottom: 1px solid var.$color-border;
    }

    &__header-text {
    }

    &__header-close-icon {
        justify-self: end;
        cursor: pointer;
    }

    &__blocks-wrap {

    }

    // filter to custom widgets
    &__filter-block {
        border-bottom: 1px solid var.$color-border;
    }

    &__filter-block_is-open {
        padding-bottom: 2rem;
    }

    &__filter-head-wrap {
        cursor: pointer;
        display: grid;
        grid-template-areas: "head-title head-corner";
        grid-template-columns: auto min-content;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &__filter-block_special-select &__filter-input-row {
        padding-top: 2rem;
    }

    &__filter-block_is-open &__filter-head-wrap {
        padding-bottom: 0rem;
    }

    &__filter-block_no-content &__filter-head-wrap {
        grid-template-areas: "head-checkbox head-title";
        grid-template-columns: min-content auto;
        padding-bottom: 2rem;
    }

    &__filter-block_special-select &__filter-input-row {
        column-gap: 3rem;
        grid-template-columns: repeat(2, minmax(12rem, 15rem));
    }

    &__filter-input-group-top-label &__filter-input-label {
        padding-bottom: 1rem;
    }

    &__filter-head-checkbox {
        grid-area: head-checkbox;
        width: 2rem;
        margin-right: 1.4rem;
    }

    &__filter-head-title {
        grid-area: head-title;
        font-size: 1.8rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.6rem;
        }
        font-weight: 500;
        line-height: 1.2;
        color: var.$color-black-tone4;
        white-space: nowrap;
    }

    &__filter-head-corner {
        align-self: center;
        grid-area: head-corner;
        width: 1.4rem;
        height: 0.8rem;
        margin-left: 1.4rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTQgOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzNDM0M3MCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    }

    &__filter-block_is-open &__filter-head-corner {
        transform: rotate(180deg);
    }

    &__filter-block_is-open &__filter-content-wrap {
        padding-top: 2.4rem;
        display: block;
    }

    &__filter-active-wrap {
        display: none;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: block;
        }
    }

    &__filter-active-wrap .filter-applied-tags-v1__wrap {
        margin-top: 1.4rem;
        padding-bottom: 1.4rem;
        border-bottom: 1px solid var.$color-border;
    }

    &__filter-content-wrap {
        display: none;
    }

    &__filter-content-wrap .search-form__input {
        width: calc(100% - 3.2rem);
    }

    &__filter-content-wrap button.select-v1 {
        text-align: left;
    }

    &__filter-input-row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 50%;
    }

    &__filter-input-group-left-label {
        display: grid;
        grid-template-columns: min-content auto;
    }

    &__filter-input-group-left-label:nth-child(1) {
        justify-content: start;
    }

    &__filter-input-group-left-label:nth-child(2) {
        justify-content: end;
    }

    &__filter-input-label {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var.$color-main-tone7;
        align-self: center;
        padding-right: 1rem;
    }

    &__filter-input-group-left-label .text-input-v1 {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            max-width: 12rem;
        }
        //max-width: 9rem;
        text-align: center;
    }

    &__filter-runner-row {
        padding-top: 2rem;
    }

    &__filter-options {

    }

    &__filter-option {
        padding-bottom: 1.6rem;
    }

    &__filter-option:last-child {
        padding-bottom: 0;
    }

    &__filter-option_is-active &__filter-suboption {
        display: block;
    }

    &__filter-suboption {
        display: none;
        padding-left: 3.2rem;
        padding-bottom: 1.4rem;
    }

    &__filter-suboption:nth-child(2) {
        padding-top: 2rem;
    }

    &__filter-suboption:last-child {
        padding-bottom: 0.4rem;
    }

    &__filter-search {
        padding-bottom: 2rem;
        position: relative;
    }

    &__filter-search-close {
        position: absolute;
        top: calc(2.2rem - 0.8rem) !important;
        right: 1.5rem;
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;
    }

    &__filter-search-close-icon {
        stroke: var.$color-main;
    }

    // \filter to custom widgets


    &__footer {

        padding: 1rem 0;
        display: grid;
        grid-auto-flow: column;
        column-gap: 2rem;
        background-color: White;
        // position: fixed;
        z-index: 100;
        width: 100%;
        max-width: 29rem;
        bottom: 0;
        // box-shadow: 0px 10px 20px 0px black;
    }

    &__filter-panel {
        display: flex;
        margin-bottom: 4rem;
        align-items: flex-start;
    }

    &__sort-panel {
        display: grid;
        margin-bottom: 2.4rem;

        @include mix.breakpointRange(var.$zero-width, var.$mobile-xl-width) {
            grid-template-columns: 1fr;
            grid-auto-flow: row;
            row-gap: 1rem;
        }

         @include mix.breakpoint(var.$mobile-xl-width) {
            grid-auto-flow: column;
            grid-template-columns: auto 21.5rem;
        }
    }

    &__position-panel {

    }

    // TODO[dependСss]

    &__sort-panel .pagination-summary-v1 {
        align-self: end;
    }

    &__sort-panel .select-v2 .select2-container .select2-selection {
        background-color: var.$color-main-tone2;
        border-color: var.$color-main-tone2 !important;
    }

    // depend: .goods__inner
    & .goods__inner {
        flex-direction: column;
    }

    & .goods__inner .goods__reset-wrap {
        margin-left: 0;
        flex-grow: 1;
        margin-bottom: 0;
    }

    & .goods__inner .goods__reset-wrap .goods__coincidence {
        padding: 0;
        margin-right: 0;
    }

    & .goods__inner .goods__sort {
        padding-bottom: 0;
        padding-top: 0;
    }

    & .goods__inner .goods__sort .select {
        width: auto;
    }

    & .goods__inner .goods__sort .select__face {
        padding: 1.1rem 3rem 1.1rem 1rem;
        background-color: var.$color-main-tone2;
    }

    & .goods__inner .goods__sort .select__face .select__title {
        padding-left: 0;
    }

    & .goods__list {
        margin-top: 0;
    }

    & .goods__sort .select__icon {
        width: 1.4rem;
        height: 0.8rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.3rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            font-size: 1.4rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 1.5rem;
        }
    }

    // \depend: .goods__inner

    // depend: .goods__item
    &_is-show-filter &__list-place .goods__item {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: calc(100%);
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            width: calc(100% / 2 - 2rem);
        }
        width: calc(100% / 3 - 2rem);
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        &__list-place .goods__item:nth-child(1) {
            margin-top: 0;
        }
        &__list-place .filter-applied-tags-v1__wrap {
            display: none;
        }
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        &__list-place .goods__item:nth-child(1),
        &__list-place .goods__item:nth-child(2) {
            margin-top: 0;
        }
        &__list-place .filter-applied-tags-v1__wrap {
            display: none;
        }
    }
    @include mix.breakpointRange(var.$tablet-h-width, (var.$desktop-lg-width - 1)) {
        &__list-place .goods__item:nth-child(1),
        &__list-place .goods__item:nth-child(2),
        &__list-place .goods__item:nth-child(3) {
            margin-top: 0;
        }
    }

    &_is-show-filter &__list-place {
        @include mix.breakpoint(var.$desktop-lg-width) {
            .goods__item:nth-child(1),
            .goods__item:nth-child(2),
            .goods__item:nth-child(3) {
                margin-top: 0;
            }
            .goods__item:nth-child(4) {
                margin-top: 4rem;
            }
        }
    }

    &__list-place {
        @include mix.breakpoint(var.$desktop-lg-width) {
            .goods__item:nth-child(1),
            .goods__item:nth-child(2),
            .goods__item:nth-child(3),
            .goods__item:nth-child(4) {
                margin-top: 0;
            }
        }
    }

    // \depend: .goods__item

    // depend: .checkbox-v1 in filter-head-checkbox
    &__filter-head-checkbox .checkbox-v1__wrap {
        height: 2rem;
    }

    // \depend: .checkbox-v1 in filter-head-checkbox

    // depend: .button-link-v1 and button-link-v2
    &__footer .button-v1 {
        padding: 1.4rem 0rem;
        line-height: 1.8rem;
        text-align: center;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    // \depend: .button-link-v1 and button-link-v2

    // \TODO[dependСss]

    .checkbox-v1__label {
        .card-detail__colorbox {
            margin-right: 10px !important;
            width: 1.5rem !important;
            height: 1.5rem !important;
            padding-top: 0.2rem !important;
            padding-bottom: 0 !important;

            div {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }

}

/** DEPENDS CODE */
.catalog-filtered-grid {

    // TODO[needImpl] добавлен неправильный
    // wrapper выше виджета .product-grid-v1
    .product-grid-v1__wrapper {
        width: 100%;
    }
    
    &_is-show-filter .product-grid-v1__wrapper {
        width: calc(100% - 36.9rem);
    }

    /**
     * При открытых фильтрах:
     * <= 1024 - боковой фильтр в popup-окне (_is-show-filter не применим)
     * >= 1024 && <= 1280 - две колонки
     * >= 1280 - 3 колонки
     */
    &_is-show-filter .product-grid-v1__banner-place-span-all {
        @include mix.breakpointRange(var.$zero-width, var.$mobile-width) {
            grid-column: span 1;
        }

        // От 320px до 1280px (tablet-width) — 2 колонки
        @include mix.breakpointRange(var.$mobile-width, var.$desktop-width) {
            grid-column: span 2;
        }

        // От 1280px (tablet-width) — 3 колонки, увеличенные отступы
        @include mix.breakpoint(var.$desktop-width) {
            grid-column: span 3;
        }
    }

    /**
     * При открытых фильтрах:
     * <= 1024 - боковой фильтр в popup-окне (_is-show-filter не применим)
     * >= 1024 && <= 1280 - две колонки
     * >= 1280 - 3 колонки
     */
    &_is-show-filter .product-grid-v1 {

        @include mix.breakpointRange(var.$zero-width, var.$mobile-width) {
            grid-template-columns: repeat(1, 1fr);
        }

        // От 320px до 1280px (tablet-width) — 2 колонки
        @include mix.breakpointRange(var.$mobile-width, var.$desktop-width) {
            grid-template-columns: repeat(2, 1fr);
        }

        // От 1280px (tablet-width) — 3 колонки, увеличенные отступы
        @include mix.breakpoint(var.$desktop-width) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &_is-show-filter .product-grid-v1 .product-grid-v1__banner {
        grid-column: span 3;
    }

    &_is-show-filter &__sort-panel {

        @include mix.breakpoint(var.$tablet-h-width) {
            margin-left: calc(33.8rem + 3.1rem);
        }

    }

    &__sort-panel {
        .pagination-summary-v1 {
            white-space: nowrap;
        }

        .select-v2 {

        }
    }

    &__active-filter-panel &__toggle-wrap {
        .filter-toggle-v1__wrap {
            max-width: 100%;
            @include mix.breakpoint(var.$tablet-h-width) {
                max-width: 25.5rem;
            }
        }
    }

    // &_is-show-filter .product-grid-v1 {
    //     &__banner-place {
    //         @include mix.breakpointRange(var.$desktop-width, var.$desktop-lg-width) {
    //             grid-column: span 3;
    //         }
    //         @include mix.breakpoint(var.$desktop-lg-width) {
    //             grid-column: span 3;
    //         }

    //     }
    // }

}

/** \DEPENDS CODE */