@use 'base/variables' as var;
@use 'base/mixins' as mix;

.search-card {
    position: relative;
    &._favorite .card__like-icon{
        stroke: #F46B5F;
        fill: #F46B5F;
    }

    &._compare .card__compare-icon {
        stroke: var.$color-main;
    }

    display: flex;
    align-items: center;
    padding: .3rem 2rem;
    background-color: #fff;
    text-decoration: none;
    color: var.$color-text;

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &__img-wrap {
        width: 7rem;
        flex-shrink: 0;
        margin-right: 3rem;
        @include mix.breakpoint(var.$desktop-width) {
            width: 9rem;
            margin-right: 2rem;
        }
    }

    &__img {
        display: block;
    }

    &__content {
        margin-right: auto;
        padding: 1.7rem 0;
    }

    &__top-wrap {
        display: block;
        margin-bottom: 0.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: center;
        }
    }

    &__title {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 400;
    }

    &__buttons {
        display: flex;
        align-items: center;
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            // margin-left: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            position: static;
            margin-left: 8rem;
        }

        .card__like-icon {
            fill: #E3E3E3;
            stroke: #E3E3E3;
        }
    }

    &__rating {
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0;
        }
    }

    .star {
        margin-right: 2rem;
        &__item {
            line-height: 0;
        }
    }


}
