@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider-v4 {

    &__wrap {
        display: grid;
        grid-auto-flow: row;
        .slick-initialized .slider-v4__item.slick-slide {
            display: grid;
            margin-right: 2rem;
        }
    }

    &__track {
        position: relative;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        
        &_is-prev-visible {
            padding-left: 6.8rem;
        }
        &_is-prev-visible .slider-v4__prev {
            display: block;
        }
        &_is-next-visible {
            padding-right: 6.8rem;
        }
        &_is-next-visible .slider-v4__next {
            display: block;
        }
        
    }

    &__prev {
        display: none;
        position: absolute;
        left: 0px;
        top: calc(50% - 1.9rem);
        width: 3.8rem;
        height: 3.8rem;
        cursor: pointer;
    }

    &__next {
        display: none;
        position: absolute;
        right: 0px;
        top: calc(50% - 1.9rem);
        width: 3.8rem;
        height: 3.8rem;
        cursor: pointer;
    }

    &__list-place {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        column-gap: 2rem;
        overflow: hidden;
    }

    &__item {
        border: 1px solid var.$color-main;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.2;
        padding: 1.5rem 2.6rem;
        border-radius: 3rem;
        color: var.$color-main-tone6;
        display: block;
        text-decoration: none;
        white-space: nowrap;
    }

    &__item:hover, &__item_is-active {
        background-color: var.$color-main;
        color: var.$color-border;
    }

    // TODO[dependСss]
    // depend: slick overwrite
    &__list-place .slick-slide {
        margin-right: 2rem;
    }
    // \depend: slick overwrite
    // \TODO[dependСss]
}