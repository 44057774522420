@use 'base/variables' as var;
@use 'base/mixins' as mix;

.category-tile-v1 {
    &__container {
        display: flex;
        flex-wrap: wrap;
    }
}

// deps

.category-tile-v1 {
    
    .title-v1 {

        margin-top: 0;

        @include mix.breakpointRange(var.$tablet-width,  var.$desktop-width -1) {
            margin-bottom: 2rem;
            font-size: 3.2rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 5rem;
            font-size: 4rem;
        }
    }

}

// \deps