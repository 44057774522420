@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
.site-header {
    position: relative;
    z-index: 100;
    transition: padding .3s;
    @include mix.breakpoint(var.$tablet-width) {
        padding: 0;
    }
    &__top {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 200;
        background-color: #fff;
        @include mix.breakpoint(var.$tablet-width) {
            position: relative;
            border-bottom: 1px solid var.$color-border;
        }
    }

    &__top-content {
        display: grid;
        grid-template-areas:
        'logo contacts'
        'special special';
        align-items: center;
        padding-top: 1.2rem;
        padding-bottom: 1rem;
        background-color: #fff;
        will-change: padding;
        position: relative;
        z-index: 200;
        @include mix.breakpoint(var.$tablet-width) {
            grid-template-areas:
        'contacts menu'
        'special special';
            padding: 1.8rem 0;
            padding-bottom: 1.4rem;
            // z-index: 1;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 2.2rem 0;
            position: static;
        }
    }
    &__geo-contacts{
        display:flex;
        grid-area: contacts;
        margin-left: auto;
        .btn{
            padding: 0.5rem 0.5rem;
            height: 2.5rem;
            margin-top: 0.3rem;
        }
    }
    &__geo-contacts-btn{
        position: relative;
        text-decoration: none;
        color: var.$color-main;
        width: 6rem;
        height: 3rem;
        border: 0;
        font-size: 1rem;
        background-color: transparent;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        outline: none;
        line-height: 1.5rem;
        margin-left: auto;
    }
    &__geo-contacts-icon{
        stroke: var.$color-main;
        fill: var.$color-main;
        width: 28px;
        height: 28px;
    }
    &__call-icon{
        transform: scale(1.1)
    }
    &__contacts-icon{
        transform: scale(0.8);
        stroke-width: 1.5;
        height: auto;
        width: 30px;
    }
    &__logo {
        margin-right: 0.5rem;
        flex-shrink: 0;
        transition: all .3s;
        width: 9rem;
        will-change: transform;
        transform-origin: left;
        grid-area: logo;

        img {
            width: 100%;
            display: block;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: 11.3rem;
            display: block;
            margin-right: -2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 9.2rem;
            width: 13rem;
        }

        @include mix.breakpoint(var.$desktop-test-width) {
            margin-right: 0;
        }

    }

    &__contacts {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        transition: all .3s;
        will-change: transform;
        transform-origin: right;
        grid-area: contacts;
        @include mix.breakpoint(var.$tablet-width) {
            justify-content: flex-start;
            flex-grow: 0;
            align-items: center;
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            justify-content: flex-start;
            flex-grow: 0;
            align-items: center;
            margin-right: 2rem;
        }
    }

    &__city {
        position: relative;
        color: rgba(60, 60, 112, 0.6);
        text-decoration: none;
        text-align: right;
        font-weight: 500;
        font-size: 1.3rem;
        line-height: 1.1;
        margin: 0;
        margin-bottom: 0.5rem;
        width: 22rem;
        transition: all .3s;
        border-bottom: 1px dashed transparent;
        @include mix.breakpoint(var.$mobile-width) {
            margin-right: 0.8rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            color: var.$color-text;
            font-weight: 500;
            text-align: left;
            width: 22rem;
            margin-right: 2rem;
            line-height: 1;
            min-width: 8.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 1.1;
            min-width: 11.5rem;
            width: 22rem;
            // margin-right: 6rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {

            width: 22rem;
            // margin-right: 6rem;
        }

        &._open {
            .delivery-form__icon {
                transform: rotateX(180deg);
            }

            .select__dropdown {
                opacity: 1;
                pointer-events: initial;
                max-height: initial;
                overflow: initial;
                left: -5rem;
                top: calc(100% + 7px);
                width: 18rem;
                @include mix.breakpoint(var.$tablet-width) {
                    left: 0;
                    width: 22rem;
                }
            }

            .form__input {
                // // text-align: center;
                // @include mix.breakpoint(var.$tablet-width) {
                //     text-align: left;
                // }
            }
        }

        &._dropdown-hidden {
            .select__dropdown {
                display: none;
            }
            .delivery-form__icon {
                transform: none;
            }
        }
    }

    &__city-input {
        position: absolute;
        top: -2px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        // visibility: hidden;
        color: rgba(60, 60, 112, 0.6);
        line-height: 1.1;
        font-size: 1.6rem;
        // transform: scale(0.79) translateY(-1px);
        // transform-origin: right;
        padding: .2rem 1rem;
        // padding-left: 1rem;
        // padding-right: 1rem;
        border: none;
        text-align: left;
        &::placeholder {
            font-size: 1.4rem;
        }
        // &::placeholder {
        //     color: inherit;
        // }
        @include mix.breakpoint(var.$tablet-width) {
            &::placeholder {
                font-size: 1.6rem;
            }
            // transform: scale(0.92) translateY(1px);
            // transform-origin: left;

            color: var.$color-text;
            text-align: left;
            // font-weight: 700;
            padding: 3px;
            top: -6px;


        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            transform: none;
        }
    }

    &__input-label {
        transition: color .3s;
        @media(hover:hover) {
            &:hover {
                color: var.$color-main;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            font-weight: 700;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
    }

    // &__

    &__phone {
        line-height: 1.1;
        font-weight: 500;
        color: var.$color-text;
        transition: all .3s;
        text-decoration: none;
        display: inline-block;
        transform-origin: right;
        will-change: transform;
        @media(hover:hover) {
            &:hover {
                color: var.$color-main;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            margin-right: 2rem;
            font-weight: 700;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
    }

    &__phone-wrap {
        width: 100%;
        order: -1;
        text-align: right;
        @include mix.breakpoint(var.$tablet-width) {
            order: initial;
            width: auto;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            order: initial;
            width: auto;
            margin-bottom: 0;
        }
    }

    &__working-hours {
        color: rgba(60, 60, 112, 0.6);
        font-size: 1.3rem;
        line-height: 1.1;
        margin: 0;
        transition: all .3s;
        width: 100%;
        text-align: right;

        @media (max-width: var.$mobile-width - 1) {
          padding-left: 4px;
          width: auto;
        }

        @include mix.breakpoint(var.$mobile-width) {
                text-align: left;
                width: auto;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            color: #9c9c9c;
            order: initial;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
    }

    &__menu-close {
        display: none;
        /*width: 18%;
        height: 18%;*/
    }

    &__menu-toggle {
        padding: 0;
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-left: 1.5rem;
        will-change: transform;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 0;
            width: 4.9rem;
            height: 4.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: none;
        }
    }

    &__bottom {
        padding-top: 0.6rem;
        padding-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 1.8rem 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 3.5rem;
            padding-bottom: 2.2rem;
        }
    }

    &__bottom-content {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        @include mix.breakpoint(var.$desktop-width) {
            align-items: flex-start;
        }
    }


    &__popular-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 3.5rem;
            // margin-bottom: 0.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin: 0;
        }
    }

    &__popular-item {
        font-family: "Roboto";
        font-size: 1.3rem;
        line-height: 1.5rem;
        padding: 0 0.5rem;
        @include mix.breakpoint(var.$mobile-width) {
            padding-right: 1.2rem;
            padding-left: 1.2rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.4rem;
            line-height: 1.6rem;

            &:first-of-type {
                padding-left: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            &:last-of-type {
                padding-right: 0;
            }
        }

        &:not(:last-of-type) {
            border-right: 1px solid var.$color-border;
        }

        &.active {
          font-weight: bold;
        }
    }

    &__popular-link {
        text-decoration: none;
        color: var.$color-text;
        border-bottom: 1px solid transparent;
        transition: all .3s;
        @media(hover:hover) {
            &:hover {
                border-color: currentColor;
            }
        }
    }
    &__specialpromo-btn{
        font-size: 1.5rem !important;
        padding: 0.5rem !important;
        width: 70% !important;
        margin: 0.5rem auto 0 !important;
        grid-area: special;
    }

    &__catalog-wrap {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            /*flex-wrap: wrap;*/
            align-items: flex-start;
            /*width: 63.5rem;*/
            width: 100%;
            margin-right: 2rem;
            margin-top: 0.6rem;
        }
        @include mix.breakpoint(var.$desktop-test-width) {
            width: 101rem;
            justify-content: space-between;
            align-items: center;
            margin-right: auto;
            margin-left: 8rem;
        }
    }

    &__btn {
        width: 19rem;
        margin-right: 2rem;
        font-size: 1.5rem;
        line-height: 1;
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            padding: 0;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-test-width) {
            margin-right: 0;
            margin-bottom: 0;
        }
    }

    &__menu-open {
        fill: #fff;
        /*width: 17.6px;
        height: 11.1px;*/
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 1rem;
        }
    }

    &__indicators-wrap {
        position: relative;
        margin-left: auto;
        margin-top: 1.7rem;
        grid-area: menu;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 2rem;
            margin-top: 1.4rem;
        }

        @include mix.breakpoint(var.$desktop-test-width) {
            margin-left: 0;
        }
    }

    &__indicators {
        display: grid;
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-template-columns: repeat(4, min-content);
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-template-columns: repeat(3, min-content);
        }
        grid-template-rows: 1fr;
        column-gap: 4rem;
        align-items: end;
    }

    &__indicators-item {
    }

    &__indicators-link {
        text-decoration: none;
        position: relative;
        display: block;
        color: var.$color-text;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all .3s;
        border-radius: 4px;
        padding: 0.5rem;
        @media(hover:hover) {
            &:hover {
                background-color: #EBF0F5;
            }
        }

    }

    &__indicators-label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #7B75C9;
    }

    &__indicators-count {
        background-color: var.$color-second;
        border-radius: 50%;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: 400;
        font-family: "Affect";
        text-align: center;
        position: absolute;
        right: -5px;
        top: 0;
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: 2rem;
        height: 2rem;
    }

    &__confirm-city{
        padding: 1rem;
        position: fixed;
        bottom:15rem;
        right:0;
        background-color: var.$color-second;
        color: var.$color-text;
        z-index:1;
        font-size: 1.4rem;
        &._pulse-animated{
            animation: pulse .4s infinite;
            background-color: var.$color-second !important;
        }
        @include mix.breakpoint(var.$tablet-width){
            position: absolute;
            top:-2rem;
            left:0;
            bottom:auto;
            right: auto;
        }
    }

    &__confirm-city-button{
        margin-top:0.5rem;
        display: flex;
        .btn{
            font-size: inherit;
            margin-right: 1rem;
            padding: 1rem;
            white-space: nowrap;
        }

    }

}
._header-menu {

  ._toggle {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #ebf0f5;
    background: transparent;
    padding: 0;
  }

  .select__icon {
    left: calc(50% - 0.8rem);
  }

  .select__dropdown {
    min-width: 16rem;
    right: 0;
  }
}
/* critical:end */