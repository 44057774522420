@use 'base/variables' as var;
@use 'base/mixins' as mix;

.news-detail {
    margin-bottom: 5rem;
    &__top.color-section {
        padding: 0;
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: flex-end;
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 5rem;
        }
    }

    &__top-content {
        padding: 2rem 1.5rem;
        padding-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            align-self: stretch;
            padding: 4rem;
            padding-left: 3rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 6rem;
        }
    }

    &__rating{
        display: flex;
        .star{
            margin: 0 1rem;
        }
    }

    &__top-title {
        margin: 0;
        margin-bottom: 1.5rem;
        font-family: "Affect", Arial, sans-serif;
        font-size: 2rem;
        line-height: 112%;
        letter-spacing: 0.015em;
        font-weight: 700;
        max-width: 26rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
            font-size: 3.2rem;
            max-width: none;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4.4rem;
            max-width: 49.5rem;
        }
    }

    &__top-desc {
        margin: 0;
        margin-bottom: 3rem;
        font-size: 1.3rem;
        line-height: 131%;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            // margin-bottom: 4rem;
            max-width: 44rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 53rem;
        }
    }

    &__img {
        display: block;
        width: 100%;
        max-width: 45rem;
        margin-left: auto;
        border-radius: 120px 4px 4px 4px;
        @include mix.breakpoint(var.$tablet-width) {
            width: 32rem;
            max-width: none;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 50rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 54rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 57rem;
        }
    }

    &__desc {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            padding-left: 3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding-left: 0;
        }

    }


    &__desc-title {
        margin: 0;
        margin-bottom: 3rem;
        font-size: 2rem;
        line-height: 2.5rem;
        font-weight: 500;
        @include mix.breakpoint(var.$tablet-width) {
            // padding-left: 3rem;
            // padding-right: 5.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-weight: 600;
        }
    }

    &__catalog-link {
        width: 16.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            margin-top: auto;
        }
    }

    &__desc-wrap {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
            margin-right: 10.8rem;
            padding-left: 3rem;
            padding-right: 5.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 0;
            margin-bottom: 0;
            margin-right: 5.6rem;
            padding: 0;
        }
    }

    &__desc-text {


            margin: 0;
            margin-bottom: 2rem;
            font-size: 1.4rem;
            line-height: 130%;
            color: #818DA1;
            &:last-of-type {
                margin-bottom: 0;
            }

            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.6rem;
                // line-height: 1.8rem;
            }

            @include mix.breakpoint(var.$desktop-width) {
                // flex-grow: 1;
                // margin-right: 10.8rem;
                // font-size: 1.6rem;
            }

    }

    &__advantages.color-section {
        padding: 0;
        padding-top: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 3rem;
            background-image: url("/img/maskot/quest-green-tablet.svg");
            background-repeat: no-repeat;
            background-size: 19rem auto;
            background-position: bottom right 4.4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 52.2rem;
            flex-shrink: 0;
            padding-bottom: 14.7rem;
            background-size: 17.6rem auto;
            background-position: bottom right 4.3rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 70.8rem;
            padding-bottom: 5rem;
            background-size: 22rem auto;
            background-position: bottom right 4.2rem;
        }

    }

    &__advantages-text {
        margin: 0;
        margin-bottom: 1.5rem;
        padding: 0 1.5rem;
        font-size: 1.6rem;
        line-height: 130%;
        font-weight: 500;
        &:last-of-type {
            margin-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 37rem;
            padding: 0;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.8rem;
            max-width: none;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            max-width: 37.3rem;
        }
    }

    &__bg-img {
        display: block;
        width: 100%;
        max-width: 34.5rem;
        margin-left: auto;
    }

    &__extra-section {
        .best {
            margin-bottom: 5rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-bottom: 6rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                margin-bottom: 11rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                margin-bottom: 7rem;
            }
        }
        .best__title {
            font-family: "EuclidCircularA", Arial, sans-serif;
            font-size: 2rem;
            line-height: 2.5rem;
            font-weight: 500;
            max-width: none;
        }
    }

    &__more-news {
        .news__list {
            @include mix.breakpoint(var.$desktop-sm-width) {

            }
        }

        .news__item {
            @include mix.breakpoint(var.$desktop-sm-width) {
                width: calc(100% / 4 - 2rem);
            }
        }
        // margin-bottom: 4rem;
        // @include mix.breakpoint(var.$tablet-width) {
        //     margin-bottom: 5.7rem;
        // }
        // @include mix.breakpoint(var.$desktop-width) {
        //     margin-bottom: 7rem;
        // }
        // @include mix.breakpoint(var.$desktop-max-width) {
        //     margin-bottom: 5rem;
        // }
    }

    &__more-title {
        font-size: 2rem;
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 3.2rem;
            margin-bottom: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4.4rem;
        }
    }

    &__offer {
      padding: 1rem;
      background: #BEE6A8;
      border-radius: 4px;
      margin-bottom: -1rem;

      @include mix.breakpoint(var.$tablet-width) {
          margin-bottom: -3rem;
      }

      p {
        margin: 0;
      }
    }

    .sale-more__date {
      display: inline-block;
      font-size: 13px;
      line-height: 15px;
      color: #3C3C70;
      padding: 6px 12px;
      margin: 0;
    }
}
