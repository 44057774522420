@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-show-more-v1 {
    text-align: center;
    margin: 4rem auto 0;
}

/** DEPENDS CODE */
.product-show-more-v1 {

}
/** \DEPENDS CODE */
