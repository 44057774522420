@use 'base/variables' as var;
@use 'base/mixins' as mix;

.ya-map-v1 {
    &__render-map {
        height: 38.5rem;
        width: 100%;
        flex-grow: 1;
        margin-right: 2rem;
    }
}

//deps
.ya-map-v1 {

}
