@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-info-v3 {

    &__container {

    }

    &__wrapper {
        display: flex;
        gap: 6rem;
    }
    &__additional {
       .delivery-info-v3__row { margin-top: 8rem;}
    }
    &__options {
        border-right: 0.1rem solid var.$color-gray-tone3;
        min-width: 46rem;
        max-width: 46rem;
    }

    &__information {
        flex-grow: 1;
    }

    &__row {
        display: flex;
        gap: 2.8rem;
        justify-content: space-between;

        &-hide {
            display: none;
        }
    }

    &__col {
        flex-basis: 50%;
    }

    &__text-block {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.9rem;
        margin-bottom: 6rem;
    }


}

/** DEPENDS CODE */
.delivery-info-v3 {
    &__text-block {
        .title-v2 {
            font-size: 2.1rem;
            font-weight: 700;
            line-height: 2.5rem;
            margin-bottom: 0.8rem;
        }

        p, li {
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2.9rem;
        }

        ul {
            margin-left: 2.5rem;
            list-style-type: disc;
        }

        li {

        }

    }

    .delivery-info-v4 {
        margin: 8rem 0;
    }

    .media-card-v1 {
        margin-bottom: 3.6rem;
    }

    .radio-list-v2 {
        margin-right: 6rem;
    }

    .info-list-v2 {
        margin-top: 0.8rem;
    }

    .showroom-address-v2 {
        margin-bottom: 3.6rem;
    }
}

/** \DEPENDS CODE */
