@use 'base/variables' as var;
@use 'base/mixins' as mix;

#jivo-iframe-container {
    display: none;
}

.jivo-custom-v1 {
    bottom: 19rem;
    right: 0;
    display: none;
    position: fixed;
    z-index: 3000000;
    cursor: pointer;

    &:hover {
        right: 0;
    }


}

/* если есть операторы в онлайне - показываем другой ярлык*/
#jivo_custom_widget.jivo_online {
    height: 178px;
}

._mobile-popup-open #jivo_custom_widget {
    display: none !important;
}