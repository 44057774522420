@use 'base/variables' as var;
@use 'base/mixins' as mix;

.visual-product-property-grid-v1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
}

//deps
.visual-product-property-grid-v1 {
  /*  .visual-product-property-v1 {
        flex: 0 1 calc(33.333% - 1.2rem);
    }*/
}
