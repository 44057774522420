.promotion-page-v1 {

    &__flex-header {
        margin-bottom: 6.5rem;
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
    }

}

//deps
.promotion-page-v1 {
    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    &__flex-header {
        .tag-list-v9 {
            margin: 1.7rem 0;
        }
    }

    .promotion-grid-v1 {
        margin-bottom: 10rem;
    }
}