@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider {
    position: relative;
    align-self: flex-end;

    &__dots {
        position: absolute;
    }

    &__slide {
        outline: none;
    }

    &__slide-img {
        border-radius: 120px 4px 4px 4px;
        display: block;
        width: 100%;
    }

    &__nav-list {
        position: absolute;
        bottom: 3rem;
        right: 3rem;
        display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            right: 4rem;
            bottom: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            right: 6rem;
            bottom: 3rem;
        }
    }

    &__nav-prev,
    &__nav-next {
        background: #ECF0F4;
        border-radius: 2px;
        border: 0;
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        outline: none;
    }

    &__nav-prev {
        margin-right: 1.5rem;
    }

    &__nav-icon {
        width: 10px;
        height: 18px;
        stroke: var.$color-main;
    }

    &__paging {
        margin: 0;
        padding: 0 .5rem;
    }

    .slick-dots {
        position: absolute;
        left: 1.5rem;
        bottom: 1.5rem;

        display: flex;
        margin-right: -3px;

        li {
            margin-right: 3px;
        }

        button {
            font-size: 0;
            background: #ECF0F4;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            border: 0;
            padding: 0;
            outline: none;
        }

        .slick-active button {
            background-color: var.$color-main;
        }

        @include mix.breakpoint(var.$tablet-width) {
            bottom: initial;
            left: initial;
            top: 4rem;
            right: 3rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            right: 5rem;
        }
    }
}
