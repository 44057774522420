@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v8 > * {
    flex-grow: 0;
    flex-shrink: 0;
}

.product-card-v8 {

    display: flex;
    flex-direction: column;
    outline: none;
    height: 100%;
    color: #3c3c70;
    transition: all .3s;
    border-radius: .6rem;
    border: .05rem solid #ecf0f5;
    width: 20rem;
    max-width: 20rem;
    min-width: 20rem;
    background: #fff;
    margin-left: 0;
    margin-top: 0;

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        margin-right: 2rem;
        width: 18rem;
        max-width: 18rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        margin-right: 3rem;
        width: 20rem;
        max-width: 20rem;
    }

    &__footer-wrap {
        order: 3;
        padding: 1.4rem 1rem 2.4rem 1rem;
        margin-top: auto;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {

        /* Ограничиваем максимальную высоту */
        max-height: 100%;

        /* Высота должна подстраиваться по контенту */
        height: auto;

        &__rating_mobile {
            display: flex;
        }
        &__rating {
            display: none;
        }
        &__real-modifier-switcher {
            width: 50%;
        }
        .old-price-v1, .sale-price-v1 {
            width: 100%;
        }
        .product-card-v8__price {
            flex-direction: column;
        }
        &__footer-wrap {
            margin-top: inherit;
        }
    }
    @include mix.breakpoint(var.$tablet-width) {
        &__rating_mobile {
            display: none;
        }
        &__rating {
            display: flex;
        }
    }

    &__price-from {
        color: var.$color-gray-tone11;
        font-family: var.$font-text;
        font-size: 1.2rem;
        // padding-left: 1.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
    }

    &:hover {
        border-radius: 6px;
        background: var.$color-white;
        box-shadow: 0px 0px 16px 4px rgba(60, 60, 112, 0.16);
    }

    border-radius: 0.6rem;

    display: flex;
    flex-direction: column;

    /** TODO */
    overflow: hidden;
    /** TODO */

    &__body-wrap {
        order: 2;
        padding: 1.2rem 1rem 0rem 1rem;
    }

    &__gallery {
        order: 1;
        position: relative;
        min-height: 21.8rem;
        max-height: 21.8rem;
    }

    &__gallery &__icon-wrap {
        position: absolute;
        top: 1.75rem;
        right: 1.7rem;
        display: flex;
        z-index: 5;
        flex-direction: row;
    }

    &__badge-rating-wrap {
        display: grid;
        grid-template-columns: auto min-content;
        margin-top: 1.5rem;
    }

    &__badge-rating-wrap &__rating {
        // align-self: end;
        justify-self: end;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.2rem;
    }

    .character-list-v1 {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

    .character-list-v1 .character-element-v1 {
        display: grid;
        grid-template-columns: 40% calc(60% - 0.3rem);
        column-gap: 0.3rem;
        align-items: center;
    }

    &__price-real-modifier-switcher-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.4rem;
        min-height: 2.8rem;
    }
}


/** DEPENDS CODE */
.product-card-v8 {

    .gallery-v3 {
        max-width: 100%;
        width: 100%;
        height: 21.8rem;
        max-height: 21.8rem;
        min-height: 21.8rem;
    }
    
    .gallery-v3__page-controller {
        overflow: hidden;
    }

    .gallery-v3__current-image {
        display: grid;
        height: 21.8rem;
        align-items: center;
        overflow: hidden;
    }

    .gallery-v3__slide_wrapper {
        display: grid !important;
        justify-content: center;
        align-items: center;
        height: 21.8rem;

        img {
            max-width: 100%;
            object-fit: contain;
            object-position: center;
            display: block;
            margin: 0 auto;
        }
    }

    .action-group-v1 {
        grid-template-columns: auto;
    }

    .action-group-v1 .button-v1 {
        width: 100%;
        padding: 1.5rem 0;
        font-weight: 600;
        font-size: 1rem;
        max-height: 3.3rem;
        color: #FFF;
        font-family: var.$font-text;
        font-style: normal;
        line-height: 1.2rem;
    }

    .action-group-v1 .button-v1_filling-type-contained.button-v1_filling-color-type-main:hover {
        background: var.$color-text;
        color: var.$color-white !important;
    }

    .link-v1 {
        font-size: 1.2rem;
        color: #3C3C70;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4rem;
        display: block;
        min-height: 2.8rem;
        max-height: 2.8rem;
        overflow: hidden;
    }

    &__real-modifier-switcher {
        // justify-self: end;
        // width: 100%;
        // min-width: 100%;
        max-width: 40%;
        min-width: 40%;
    }

    &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__icon-wrap .link-with-icon-v1 {
        width: 1.4rem;
        height: 1.4rem;
        gap: 0;
        margin-right: 1.1rem;
    }

    &__icon-wrap .link-with-icon-v1:last-child {
        margin-right: 0;
    }

    &__price .sale-price-v1 {
        font-size: 1.4rem;
        line-height: 1.6rem;
        width: fit-content;
        align-self: end;
    }

    &__price .old-price-v1__value {
        color: var.$color-main;
    }

    &__price .old-price-v1 * {
        font-size: 1rem;
        line-height: 1.2rem;
        white-space: nowrap;
    }
}

/** \DEPENDS CODE */