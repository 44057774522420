@use 'base/variables' as var;
@use 'base/mixins' as mix;

.distrib-info {
    margin-bottom: 8rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 9rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 14rem;
    }
    &__inner {
        display: none;
        border-radius: 2px;
        align-items: stretch;
        padding-bottom: 210px;
        background: #7C75C9 url(/img/distrib-image-mobile.png) no-repeat right bottom / 31.8rem 21.6rem;

        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: flex-end;
            background: #7C75C9 url(/img/distrib-image-desktop.png) no-repeat 100% 100% / auto 21.6rem;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            padding-bottom: 0;
            background: #7C75C9 url(/img/distrib-image-mobile.png) no-repeat 100% 100% / contain;
        }

        @include mix.breakpoint(var.$desktop-width) {
            align-items: stretch;
            padding-bottom: 0;
            background: #7C75C9 url(/img/distrib-image-desktop.png) no-repeat right top / contain;
        }
    }

    // &__title {
    //     @include mix.breakpoint(var.$tablet-width) {
    //         font-size: 3.2rem;
    //         line-height: 112%;
    //     }
    // }

    &__title {
        color: #fff;

        &_green {
            color: #BEE6A8;
        }

        & h1 {
            margin-top: 0;
        }
    }

    &__content-wrap {
        padding: 2rem;
        margin-bottom: 1rem;


        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-direction: column;
            padding: 4rem 3rem;
            padding-right: 3.4rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            // margin-right: 5rem;
            margin-right: 0;
            margin-bottom: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-self: stretch;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            margin-right: 6.5rem;
        }

        & .title {
            margin-bottom: 2.5rem;
            max-width: 100%;

            @include mix.breakpoint(var.$tablet-width) {
                font-size: 3.2rem;
                max-width: 52rem;
                margin-bottom: 2.5rem;
            }
        }
    }

    &__text {
        font-size: 1.4rem;
        line-height: 1.3;
        margin-bottom: 2rem;
        margin-top: 0;
        &:last-of-type {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            // font-size: 1.6rem;
            line-height: 1.8rem;
            max-width: 55.3rem;
            &:last-of-type {
                margin-bottom: 2rem;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 2.5rem;
            &:last-of-type {
                margin-bottom: auto;
            }
        }
    }

    &__btn {
        margin-top: 0;
        width: 100%;
        
        font-size: 1.6rem;
        line-height: 2rem;
        padding-top: 1.2rem;
        padding-bottom: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            // margin-top: 4.5rem;
            margin-top: auto;
            width: 38rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 1rem;
        }

        &_desktop {
            display: none;

            @include mix.breakpoint(var.$tablet-width) {
                display: inline-block;
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }

    &__after {
        display: block;
        margin-top: 4rem;

        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            margin-top: 10rem;
        }
    }

    &__column {
        padding-right: 0rem;
        padding-top: 2rem;

        @include mix.breakpoint(var.$desktop-width) {
            padding-right: 2rem;
        }
    }

    .slider {
        align-self: flex-end;
        @include mix.breakpoint(var.$tablet-width) {
            width: 34.1rem;
            margin-left: auto;
            flex-shrink: 0;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            // width: 52rem;
            width: 37rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 63rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            // width: 71rem;
        }

        &__nav-list {
            left: initial;
            top: initial;
            right: 1.5rem;
            bottom: 2rem;
            @include mix.breakpoint(var.$tablet-width) {
                right: 3rem;
                bottom: 3rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                right: 3rem;
                bottom: 4rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                right: 5rem;
                bottom: 5rem;
            }
        }

        .slick-dots {
            @include mix.breakpoint(var.$tablet-width) {
                left: initial;
                top: initial;
                right: 4rem;
                top: 3rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                right: 5rem;
                top: 2.5rem;
            }
        }
    }

    &__slider {
        position: relative;
        max-width: 80rem;
        // margin: 0 auto;
        @include mix.breakpoint(var.$tablet-width) {
            width: 34.1rem
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 62rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 70.8rem;
        }
    }

    // &__slide {
    //     outline: none;
    // }

    // &__slide-img {
    //     border-radius: 120px 4px 4px 0px;
    //     display: block;
    //     width: 100%;
    // }

    // &__nav-list {
    //     position: absolute;
    //     bottom: 3rem;
    //     right: 3rem;
    //     display: flex;
    //     @include mix.breakpoint(var.$tablet-width) {
    //         right: 4rem;
    //         bottom: 5rem;
    //     }
    //     @include mix.breakpoint(var.$desktop-width) {
    //         right: 6rem;
    //         bottom: 3rem;
    //     }
    // }

    // &__nav-prev,
    // &__nav-next {
    //     background: #ECF0F4;
    //     border-radius: 2px;
    //     border: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     width: 3rem;
    //     height: 3rem;
    //     outline: none;
    // }

    // &__nav-prev {
    //     margin-right: 1.5rem;
    // }

    // &__nav-icon {
    //     width: 10px;
    //     height: 18px;
    //     stroke: var.$color-main;
    // }
}
