@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-v1 {

    padding: 3rem 4rem;
    position: relative;
    border-top: 0.1rem solid rgba(60,60,112,0.3);
    border-left: 0.1rem solid rgba(60,60,112,0.3);
    border-right: 0.1rem solid rgba(60,60,112,0.3);
    border-radius: 0.6rem;
}


/** DEPENDS CODE */
.receipt-v1 {

    .action-group-v1 {
        margin-top: 1.5rem;
        justify-content: center;
    }

    .wave-divider-v1 {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    
}

/** \DEPENDS CODE */
