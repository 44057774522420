@use 'base/variables' as var;
@use 'base/mixins' as mix;

.subscribe-v1 {

    width: 100%;
    // max-width: 100%;

    background-color: var.$color-second;
    padding: 2rem;
    font-size: 2rem;
    font-family: Affect;
    font-weight: 700;
    justify-content: space-between;

    @media only screen and (min-width: var.$tablet-h-width) {
        padding: 4rem;
        font-size: 4rem;
        display: flex;
    }

    &__input-container {
        width: 80%;
    }

    &__btn-action {
        color: var.$color-white;
        background-color: var.$color-second;
    }

    &__subscribe__success {
        display: none;
    }

    &__banner {
        svg {
            width: 100%;
        }
    }
}

//deps
.subscribe-v1 {

    .btn._green {
        background-color: var.$color-second;
        color: var.$color-text;
    }

    .btn._green:hover {
        color: var.$color-main !important;
        background-color: var.$color-white !important;
    }

    .btn._text-white {
        color: var.$color-white !important;
    }

    .form__input {
        width: 100%;
        padding: 1.2rem 2rem 1.4rem;
        font-size: 1.6rem;
        line-height: 2rem;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ebf0f5;
        outline: none;
    }

    .form__input-item {
        width: 100%;
        margin-bottom: 0 !important;
    }

    .form__input-item .form-group {
        width: 100%;
    }

}