@use 'base/variables' as var;
@use 'base/mixins' as mix;

.modal-window-add-to-cart-v1 {
    &__preloader {
        min-height: 8rem;
        .button-preloader {
            display: block;
        }
    }
}

//deps
.modal-window-add-to-cart-v1 {

}