@use 'base/variables' as var;
@use 'base/mixins' as mix;

.catalog {
    .filter {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$desktop-max-width) {
            background-color: var.$color-main;
            position: sticky;
            top: 0;
            z-index: 50;
        }

        &__inner {
            background-image: none;
            @include mix.breakpoint(var.$desktop-max-width) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &__submit-btn {
            margin-top: 0;
        }

        @media(min-width: var.$tablet-width) and (max-width: var.$desktop-width) {
            &__category-item {
                &:first-of-type {
                    // padding-left: 0.5rem;
                }

                &:last-of-type {
                    padding-right: 2rem;
                }
            }

            &__list-wrap {
                /*margin-left: -3rem;*/
                width: calc(100% + 6rem);
            }
        }
    }

    .goods {

        @include mix.breakpoint(var.$desktop-width) {
            .pagination {
                margin-top: 5rem;
            }
        }
        @media(max-width: var.$tablet-width) {
            .pagination {
                margin-top: 3rem;
            }
        }
    }

    .articles {
        @include mix.breakpoint(var.$desktop-width) {
            .pagination {
                margin-top: 6rem;
            }
        }
    }

    .sales {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 7rem;
        }
    }
}

.catalog-beds {
    .filter {
        @include mix.breakpoint(var.$desktop-max-width) {
            background-color: var.$color-main;
            position: sticky;
            top: 0;
            z-index: 100;
        }

        &__inner {
            background-image: none;
            @include mix.breakpoint(var.$desktop-max-width) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .form {
            &__input-item {
                &._color {
                    .select__face {
                        padding-top: 5px;
                        padding-bottom: 5px;
                        min-height: 4.7rem;
                    }
                }
                @include mix.breakpoint(var.$desktop-sm-width) {
                    &._extra {
                        width: calc(100% / 3 - 1rem);
                    }
                }
                @include mix.breakpoint(var.$desktop-max-width) {
                    &._extra {
                        width: calc(100% / 3 - 2rem);
                    }
                }
            }
        }
    }
}

.not-found {
    .info-section {
        margin-bottom: 7rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 3.5rem;
        }
    }
}

.distrib {
    .breadcrumbs {
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3.5rem;
            padding-top: 3rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            padding-top: 5.5rem;
        }
    }
}

.about-page {
    .products {
        margin-bottom: 5rem;
    }

    .advantages-second {
        margin-bottom: 4rem;

        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 8rem;

            .title {
                margin-bottom: 5rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 15rem;

            .title {
                margin-bottom: 8rem;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 11rem;
        }


        &__list {
            @include mix.breakpoint(var.$desktop-width) {
                flex-wrap: wrap;
                margin-top: -9rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                flex-wrap: wrap;
                margin-top: -10rem;
            }
        }

        &__item {
            @include mix.breakpoint(var.$desktop-width) {
                width: calc(100% / 4 - 10rem);
            }
        }

        &__icon {

            &._transport {
                width: 8.5rem;
                height: 4.5rem;
            }

            &._sale {
                width: 4.8rem;
                height: 5rem;
            }

            &._glass {
                width: 5.4rem;
                height: 5.2rem;
            }

            &._bow-star {
                width: 5.2rem;
            }

            &._mister-purple {
                width: 5.8rem;
                height: 4rem;
            }
            @include mix.breakpoint(var.$tablet-width) {
                &._sale {
                    width: 7.8rem;
                    height: 7.5rem;
                    margin-left: 0;
                }

                &._bow-star {
                    width: 7.8rem;
                    height: 7.6rem;
                }

                &._transport {
                    width: 10rem;
                    height: 5.5rem;
                    margin-left: -2rem;
                }

                &._glass {
                    width: 7.9rem;
                    height: 8.3rem;
                }

                &._heart {
                    width: 7.7rem;
                    height: 7.7rem;
                    margin-top: -1.2rem;
                }

                &._mister-purple {
                    width: 7.9rem;
                    height: 6.5rem;
                }
            }
            @include mix.breakpoint(var.$desktop-width) {
                &._sale {
                    width: 8.8rem;
                    height: 8.5rem;
                }

                &._bow-star {
                    width: 8.8rem;
                    height: 8.5rem;
                }

                &._transport {
                    width: 15.5rem;
                    height: 8.4rem;
                    margin-left: -5rem;
                    margin-top: -1rem;
                }

                &._glass {
                    width: 8.8rem;
                    height: 9.2rem;
                }

                &._heart {
                    width: 8.8rem;
                    height: 8.8rem;
                    margin-top: -1.2rem;
                }

                &._mister-purple {
                    width: 9rem;
                    height: 7.4rem;
                }
            }

        }

        &__item {
            @include mix.breakpoint(var.$tablet-width) {
                &:nth-child(2) {
                    order: 3;
                }

                &:nth-child(3) {
                    order: 4;
                }

                &:nth-child(4) {
                    order: 2;
                }

                &:nth-child(5) {
                    order: 5;
                }

                &:nth-child(6) {
                    order: 6;
                }

                &:nth-child(7) {
                    order: 8;
                }

                &:nth-child(8) {
                    order: 7;
                }
            }
            @include mix.breakpoint(var.$desktop-width) {
                margin-top: 9rem;

                &:nth-child(2) {
                    order: 2;
                }

                &:nth-child(3) {
                    order: 3;
                }

                &:nth-child(4) {
                    order: 4;
                }

                &:nth-child(5) {
                    order: 6;
                }

                &:nth-child(6) {
                    order: 5;
                }
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                margin-top: 10rem;
            }
        }
    }

    .brand {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4.5rem;
        }
    }

    .popular {
        margin-bottom: 4rem;
    }

    .best {
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 6rem;
        }
    }

    .products {
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 10rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 11rem;
        }
    }
}

.catalog-detail {
    .best {
        @include mix.breakpoint(var.$desktop-max-width) {
            .title {
                margin-bottom: 0;
            }
        }

        .title {
            margin-bottom: 4rem;
        }
    }
}

.delivery-page {
    .call {
        margin-bottom: 11rem;

        &__inner {
            @include mix.breakpoint(var.$tablet-width) {
                background-position: bottom -1px right 2rem;
                background-size: 24.8rem 26rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                background-position: bottom -1px right 11.5rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                background-position: bottom -1px right 12rem;
            }
        }

        &__title {
            max-width: 16rem;
            @include mix.breakpoint(var.$tablet-width) {
                max-width: 37rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                max-width: 65rem;
            }
        }

        &__desc {
            @include mix.breakpoint(var.$tablet-width) {
                max-width: 37rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                max-width: 65rem;
            }
        }
    }
}

.pay-page,
.sale-page {
    .call {
        margin-bottom: 5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 11rem;
        }

        &__inner {
            @include mix.breakpoint(var.$tablet-width) {
                background-position: bottom -1px right 2rem;
                background-size: 24.8rem 26rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                background-position: bottom -1px right 11.5rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                background-position: bottom -1px right 12rem;
            }
        }

        &__title {
            max-width: 16rem;
            @include mix.breakpoint(var.$tablet-width) {
                max-width: 37rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                max-width: 65rem;
            }
        }

        &__desc {
            @include mix.breakpoint(var.$tablet-width) {
                max-width: 37rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                max-width: 65rem;
            }
        }
    }
}

.contacts-page {
    .showroom {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 10rem;
        }
    }

    .popular {
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 10rem;
        }
    }
}

.cart-page {
    .delivery-section {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 11rem;
        }
    }
}

.custom-size-page {
    .call {
        margin-bottom: 11rem;
    }
}

.compare-page {
    .delivery-section {
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 11rem;
        }
    }
}
