@use 'base/variables' as var;
@use 'base/mixins' as mix;

.quality {
    margin-bottom: 5rem;
    .title {
        margin-bottom: 1.8rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 13rem;
        .title {
            font-size: 2.8rem;
            line-height: 1.2;
        }
    }

    @include mix.breakpoint(var.$desktop-laptop-width) {
        margin-bottom: 15rem;
        .title {
            font-size: 4rem;
            margin-bottom: 2.5rem;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 10rem;
    }

    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }
    }
    &__main {
        min-height: 24.8rem;
        padding: 2rem 1.5rem;
        background-color: #F5F7FA;
        border-radius: 4px;
        margin-bottom: 1.5rem;
        background-image: url("/img/mister-purple-left.svg");
        background-repeat: no-repeat;
        background-size: 10.4rem 10.1rem;
        background-position: bottom right 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            min-height: 25.1rem;
            padding: 4rem 3rem;
            padding-bottom: 2rem;
            margin-bottom: 0;
            margin-right: 2rem;
            flex-grow: 1;
            width: 47.8rem;
            background-size: 14.3rem auto;
            background-position: bottom -2rem right 6rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            background-size: 20rem auto;
            background-position: bottom right 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 84.7rem;
            background-size: 20.8rem auto;
            background-position: bottom right 5.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            width: 95rem;
            flex-grow: 0;
            background-size: 21.4rem auto;
            background-position: bottom right 7.5rem;
        }
    }

    &__main-text {
        color: #818DA2;
        font-size: 1.3rem;
        line-height: 1.3;
        margin: 0;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-right: 21rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 43rem;
            margin-right: 0;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            max-width: 46rem;
        }
    }

    &__brokers {
        background: #7B75C9;
        border-radius: 4px;
        padding: 2rem 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 2rem;
            width: 23rem;
            flex-grow: 1;
            max-width: 38rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem 3rem;
            max-width: none;
            width: 41rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            width: 46.3rem;
            .title {
                margin-bottom: 1.5rem;
            }
        }
    }

    &__brokers-text {
        color: #fff;
        font-size: 1.3rem;
        line-height: 1.3;
        margin: 0;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            max-width: 33rem;
        }
    }
}
