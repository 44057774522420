@use 'base/variables' as var;
@use 'base/mixins' as mix;

.gallery-v4 {
    display: none;

    &__container {
        position: relative;
    }

    &__page-controller {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

    &__overlay {
        cursor: pointer;
        flex-grow: 1;
    }

    &__track_dots {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, minmax(0, auto));
        column-gap: 0.2rem;
        width: 100%;
        margin: auto;
        position: absolute;
        bottom: -1.6rem;
        z-index: 2;
        height: 0.8rem;
    }

    &__track-element {
        height: 0.8rem;
        width: 0.8rem;
        background: var.$color-gray-tone10;
        border-radius: 1.2rem;
        cursor: pointer;
    }

    &__track-element:hover {
        background: var.$color-gray-tone9;
    }

    &__track-element_active {
        background: var.$color-text;
        background: var.$color-gray-tone9;
    }

    &__current-image {

    }

    &__slide_wrapper img {
        margin: auto;
    }

    &__slider-nav {
        margin-top: 6rem;
    }

    &__slide-nav {
        width: 12rem;
        height: 12rem;
        overflow: hidden;
        padding: 1rem 1rem;

        img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 0.6rem;
            object-fit: cover;
        }
    }

    &__slider-nav {
        .slick-current {
            .gallery-v4__slide-nav {
                border-radius: 0.6rem;
                border: 0.1rem solid var.$color-gray-tone1;
            }
        }
    }
}

/** DEPENDS CODE */
.gallery-v4 {
    .slick-track {
        display: flex;

        .slick-slide {
            //width: 100%;
            flex-shrink: 0;
            flex-grow: 1;
        }
    }
}

.gallery-v4__popup {
    .mfp-arrow {
        width: 20px;
        height: 150px;
        opacity: 1;
        cursor: pointer;
        background: none;
        z-index: 9999;
    }

    .mfp-arrow::before {
        border: none;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
    }
    .mfp-arrow-right {
        margin-right: 5rem;
    }
    .mfp-close {margin-right: 2.5rem;}
    .mfp-arrow-right::before {
        background-image: url("data:image/svg+xml;utf8,<svg width='10' height='24' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L3.66667 5L1 9' stroke='%237B75C9' stroke-width='1.3'/></svg>");
    }

    .mfp-arrow-left::before {
        background-image: url("data:image/svg+xml;utf8,<svg width='10' height='24' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.66699 1L1.00033 5L3.66699 9' stroke='%237B75C9' stroke-width='1.3'/></svg>");
    }

}

/** \DEPENDS CODE */
