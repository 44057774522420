@use 'base/variables' as var;
@use 'base/mixins' as mix;

.star {
    display: flex;
    width: 9rem;
    margin-right: -5px;
    &__item {
        margin-right: 5px;
    }
    &__icon {
        fill: #FEB548;
        // stroke: #FEB548;
        // width: 13px;
        height: 13px;
        line-height: 0;
        font-size: 0;
        width: 86px;

        &-stroke {
          fill: transparent;
          stroke: #FEB548;
        }
    }
}
