@use 'base/variables' as var;
@use 'base/mixins' as mix;

.seo-text-block-v1 {
}

/** DEPENDS CODE */
.seo-text-block-v1 {
    
    .seo-text-block {

        &__container {
    
        }

        &__text-wrapper {
            max-height: 100px;
            overflow: hidden;
            transition: all 0.5s ease 0s;
            position: relative;

            &:after {
                width: 100%;
                height: 50px;
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                background: linear-gradient(0deg, #ffffff, rgba(#FFFFFF, 0.75), rgba(#FFFFFF, 0));
            }
    
            &.opened {
                max-height: max-content !important;
                padding-bottom: 40px !important;
            }

        }

        &__toogler {
            border-bottom: 1px dashed;
            width: max-content;
            cursor: pointer;
        }
    }

}
/** DEPENDS CODE */