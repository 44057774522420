@use 'base/variables' as var;
@use 'base/mixins' as mix;

.brand-info-v1 {
    display: flex;
    flex-direction: column;

    &__content-wrapper {
        display: flex;
        align-items: center;
    }

    &__text {
        font-family: var.$font-text;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var.$color-black-tone4;
    }

    .link-v1 {
        align-self: flex-end;
        margin-top: 0.8rem;
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-decoration-line: underline;
        color: var.$color-main;
    }
}

// Depends code
.brand-info-v1 {
    .image-v1 {
       // margin-right: 4.3rem;
    }
}

// \Depends code