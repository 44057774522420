@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-v1 {

    p {
        margin-bottom: 4rem;
        font-family: var.$font-text;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2.24rem;
    }
    
    ul {
        margin-bottom: 2rem;
        font-family: var.$font-header;
        font-weight: 400;
        font-size: 2.1rem;
        line-height: 2.52rem;
    }
    
    li {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.24rem;
    }
    
    h1 {
    
    }
    
    h2 {
    
    }
    
    h3 {
    }
    
    h4 {
        margin-bottom: 1rem;
        font-family: var.$font-header;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.88rem;
    }
    
    h5 {
    
    }
    
    h6 {
        margin-bottom: 1rem;
        font-family: var.$font-header;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.54rem;
    }
    
}
