@use 'base/variables' as var;
@use 'base/mixins' as mix;

.subtitle-v1 {

    font-weight: 400;
    color: var.$color-main-tone7;
    line-height: 1.2;
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        font-size: 1.4rem;
        text-align: center;
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        font-size: 1.6rem;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        font-size: 1.6rem;
    }
}