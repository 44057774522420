@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-old-card-v1 {

    &__title {
        margin-top: 1.4rem;
        font-size: 1.4rem;
        line-height: 1.2;
        color: var.$color-black-tone4;
    }

    &__price {
        margin-top: 1.6rem;
        font-size: 1.8rem;
        color: var.$color-black-tone4;
        font-weight: 500;
        line-height: 1.2;
    }

    &__image {
        width: 100%;
        max-height: 20rem;
        max-width: 100%;
    }

    &__image img {
        display: grid;
        align-self: center;
        justify-self: center;
    }

    // TODO[dependСss]
    // \TODO[dependСss]
}