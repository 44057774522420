@use 'base/variables' as var;
@use 'base/mixins' as mix;

.promo-form-v1 {
    
    font-weight: 400;
    font-size: 1.2rem;
    color: var.$color-gray; 
    text-align: right;   
    padding-top: 2rem;

}


/** DEPENDS CODE */

.promo-form-v1 {
    
}

/** \DEPENDS CODE */
