@use 'base/variables' as var;

.add-to-wishlist-v1 {
    &:focus {
         outline : none;
    }
    svg {
        transition: all .3s;
        stroke: var.$color-main-tone10;
        fill: var.$color-white;
    }

    &[data-add-to-wishlist-v1-is-in-wishlist="1"], &:hover {
        svg {
            transition: all .3s;
            stroke: var.$color-main-tone10;
            fill: var.$color-main-tone10;
        }
    }
}

//deps
.add-to-wishlist-v1 {

}