@use 'base/variables' as var;
@use 'base/mixins' as mix;

.menu-bar {
    &__alllink{
        color: var.$color-main;
        font-size: 1.4rem;
        text-tecoration: underline;
        margin-bottom: 1rem;
    }
    &_is-popover-only-1st-lvl {
        display: none;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: grid;
        }
    }

    &__is-popover-only-2st-lvl {
        display: none;
    }

    &__is-popover-only-3rd-lvl {
        display: none;
    }

    &_is-popover-only-3rd-lvl{
        & .menu-bar__item{
            grid-template-columns: none !important;
        }

    }

    &_is-open {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {        
            position: absolute;
            top: 0px;
            left: 0px;
            // over #jivo_custom_widget (z-index: 3000000)
            z-index: 999;
            height: 100vh;
            display: grid;
            grid-template-areas: "left-bar right-bar";
            grid-template-rows: 1fr;
        }
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            grid-template-columns: auto 4rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-template-columns: 50rem auto;
        }
        // for dynamic resize case's
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            & .menu-bar__catalog-list {
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: 1fr;
                height: auto;
                margin: 3rem 3rem 3.4rem 3rem;
                row-gap: 2.6rem;
            }
            & .menu-bar__item {
                margin: 0;
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: auto 1.6rem;
                position: relative;

                &.menu-bar__item_is-active{
                    display:block;
                }
            }
            & .menu-bar__item:hover .menu-bar__corner {
                stroke: var.$color-main;
            }
            & .menu-bar__link {
                line-height: 1;
            }
            & .menu-bar__corner {
                display: grid;
                align-self: center;
                stroke: var.$color-black-tone4;
            }
        }
    }

    &__wrap:not(&_is-open) {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: none;
        }
    }

    &__wrap {
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-column: 1 / 11;
        }
        grid-area: mb-menu;
        width: 100%;
    }

    &__top-wrap {
        padding: 1.9rem 1.6rem 0 1.6rem;

        & .search-form__input {
            padding-left: 5rem;
            padding-top: 1rem;
            padding-bottom: 1.1rem;
            font-size: 1.2rem;
            font-weight: 400;
        }

        & .search-form__close {
            position: absolute;
            top: calc(50% - 0.8rem) !important;
            right: 1.5rem;
            width: 1.6rem;
            height: 1.6rem;
            cursor: pointer;
        }

        & .search-form__close-icon {
            stroke: var.$color-main;
        }

    }

    &__footer-wrap {
        margin: 3rem 3rem 0 3rem;
        grid-template-columns: 100%;
        grid-template-rows: 1fr;
        row-gap: 1.8rem;
    }

    &__footer-block {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr;
        row-gap: 0.4rem;
    }

    &__footer-block-title {
        font-weight: 400;
        font-size: 1.2rem;
        color: var.$color-gray;
    }

    &__footer-block:nth-child(1) &__footer-block-content {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto min-content;
        grid-template-areas: "phone callback";
        & .info-bar__callback-button {
            margin-right: 0;
            grid-area: callback;
            @include mix.breakpointRange(var.$zero-width, (359px)) {
                font-size: 1.2rem;
            }
        }
        & .site-header__phone {
            @include mix.breakpointRange(var.$zero-width, (359px)) {
                font-size: 1.4rem;
            }
            grid-area: phone;
            display: grid;
            align-self: end;
        }
    }
    
    &__footer-block-content p {
        font-size: 1.4rem;
        font-weight: 400;
        color: var.$color-black-tone4;
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    &__footer-block-content p a {
        text-decoration: none;
        color: inherit;
    }

    &__footer-social-block {
        margin-top: 1.2rem;
    }

    &__divider {
        margin: 0 3rem;
        height: 0.1rem;
        background-color: var.$color-third;
    }

    &__favorite-item {
        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }
        margin: 0 3rem;
        margin-top: 2.7rem;
    }

    &__indicators-link {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 2.9rem auto;
        grid-template-areas: "icon label";
        position: relative;
        justify-self: start;
        align-items: center;
        text-decoration: none;
    }
    
    &__indicators-icon {
        grid-area: icon;
        justify-content: start;
        stroke: var.$color-main;
    }
    
    &__indicators-count {
        
    }

    &__indicators-label {
        grid-area: label;
        justify-content: start;
        font-size: 1.4rem;
        font-weight: 400;
        color: var.$color-main;
        line-height: 1;
    }

    // it is the dropdown (submenu) in the common menu
    &__submenu {
        display: none;
    }

    &__submenu &__menu-item {
        padding-left: 1rem;
    }

    &__menu-item_is-active &__submenu {
        margin-top: 2rem;
        display: grid;
        row-gap: 1.5rem;
        grid-auto-flow: row;
        grid-auto-rows: 1.4rem;
    }

    &__menu-item_is-active &__menu-corner {
        transform: rotate(90deg);
    }

    &__menu-corner {
        display: grid;
        align-self: center;
        stroke: var.$color-gray;
    }

    &__menu-list {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr;
        height: auto;
        margin: 3rem 3rem 3.4rem 3rem;
        overflow: inherit;
        row-gap: 2rem;
    }

    &__menu-item {
        font-size: 1.4rem;
        color: var.$color-gray;
        margin: 0;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto 1.6rem;
    }

    &__menu-link {
        text-decoration: none;
        line-height: 1;
    }

    &__left-bar {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            grid-area: left-bar;
            background-color: #fff;
            min-width: 280px;
            overflow: scroll;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            height: 100%;
        }
    }

    &__right-bar {
        cursor: pointer;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            background-color: var.$color-main-tone6;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            background-color: rgba(var.$color-main-tone6, 0.5);
        }
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            grid-area: right-bar;
            position: relative;
        }
    }

    &__right-bar-close {
        stroke: #fff;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 1.8rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            position: absolute;
            top: 1.8rem;
            left: 1.8rem;
        }
    }

    &__catalog-list {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: none;
        }
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
        height: 100%;
        overflow: hidden;
    }

    &__item {
        box-sizing: border-box;
        height: 100%;
        display: grid;
        align-content: center;
        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-laptop-width - 1)) {
            margin-right: 3rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-right: 6rem;
        }
        cursor: pointer;
    }

    &__item_active {
        color: var.$color-main;
    }

    &__item:hover > a {
        color: var.$color-main;
    }

    &__item &__item-content-wrap:hover {
        color: var.$color-main;
    }

    &__link {
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
    }

    &__corner {
        display: none;
    }

    // it is the wrap for of the 2nd+3rd level submenu
    &__submenu-wrap {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: calc(100% - 4rem);
            // left: calc(-1 * calc(100% - 4rem));
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            width: 50rem;
            // left: -50rem;
        }
        background-color: var.$color-white;
        // over 1st level menu (z-index: 9999999)
        z-index: 99999991;
        height: 100vh;
        &_is-open {
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
                display: grid;
            }
        }
    }

    // submenu elements
    // location switcher
    &__second-level-wrap_location-switcher {
        display: grid;
        grid-template-areas: 
            "breadcrumb-place"
            "search-place"
            "list-place";
        grid-template-columns: 100%;
        grid-template-rows: min-content min-content calc(100vh - 11.8rem);
        .menu-bar__breadcrumb-wrap {
            grid-area: breadcrumb-place;
        }
        .menu-bar__top-wrap {
            grid-area: search-place;
        }
        .menu-bar__scroll-list {
            grid-area: list-place;
            overflow-x: hidden;
            overflow-y: scroll;
            margin-right: 3rem;
            //margin-top: 3rem;
        }
        .menu-bar__catalog-list {
            margin-top: 0;
        }
    }

    // catalog submenu 2nd
    &__second-level-wrap_catalog-submenu-2nd {
        display: grid;
        grid-template-areas: 
            "breadcrumb-place"
            "main-list-place"
            "special-list-place";
        grid-template-columns: 100%;
        // new requirements
        // grid-template-rows: 5.7rem calc(100vh - 23.7rem) 18rem;
        grid-template-rows: 5.7rem auto min-content;
        .menu-bar__breadcrumb-wrap {
            grid-area: breadcrumb-place;
        }
        .menu-bar__catalog-list {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0;
        }
        .menu-bar__item {
            align-items: center;
        }
        .submenu__special-list {
            margin-top: 0;
        }
        .submenu__special-item {
            margin-bottom: 0;
            grid-template-columns: 4rem
        }
        .submenu__special-text-place {
            align-self: center;
        }
        .menu-bar__item-icon {
            stroke: var.$color-black-tone4;
        }
        .menu-bar__scroll-list {
            grid-area: main-list-place;
            overflow-x: hidden;
            overflow-y: scroll;
            margin-right: 3rem;
            //margin-top: 3rem;
        }
        .submenu__special-list {
            grid-area: special-list-place;
            background-color: #ECF0F5;
            overflow: hidden;
            grid-auto-rows: 5rem;
            padding: 1.5rem 4rem 1.5rem 0;
        }
    }

    // catalog submenu 3rd
    &__second-level-wrap_catalog-submenu-3rd {
        display: grid;
        grid-template-areas: 
            "breadcrumb-place"
            "main-list-place";
        grid-template-columns: 100%;
        // new requirements
        grid-template-rows: 5.7rem calc(100vh - 5.7rem);
        .menu-bar__breadcrumb-wrap {
            grid-area: breadcrumb-place;
        }
        .menu-bar__catalog-list {
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 0;
        }
        .menu-bar__scroll-list {
            grid-area: main-list-place;
            overflow-x: hidden;
            overflow-y: scroll;
            margin-right: 3rem;
            //margin-top: 3rem;
        }
        .menu-bar__item {
            display: block;
            width: 100%;
            &_is-active .menu-bar__item-dropdown-wrap {
                display: grid;
            }
            &_is-active .menu-bar__item-icon {
                transform: rotate(180deg);
            }
        }
        .menu-bar__item-content-wrap {
            margin: 0;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: auto 1.6rem;
            position: relative;
            align-items: center;
        }
        .menu-bar__item-dropdown-wrap {
            display: none;
            grid-auto-flow: row;
            grid-auto-rows: 1.8rem;
            row-gap: 1.8rem;
            padding-bottom: 1.4rem;
            padding-top: 2rem;
        }
        .menu-bar__item-dropdown-item {
            position: relative;
            padding-left: 1rem;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.8rem;
            color: var.$color-gray;
            &_is-dot:before {
                content: "";
                width: 0.6rem;
                height: 0.6rem;
                border-radius: 50%;
                background: var.$color-main;
                display: block;
                position: absolute;
                top: 40%;
                left: -0.1rem;
            }
        }
    }

    &__submenu-wrap .menu-bar__item a {
        text-decoration: none;
    }

    &__submenu-wrap .menu-bar__breadcrumb-wrap {
        padding: 1.6rem;
        border-bottom: 0.1rem solid var.$color-border;
    }

    &__submenu-wrap .menu-bar__breadcrumb-item {
        display: grid;
        grid-template-columns: 2.2rem auto;
        grid-template-rows: 1fr;
        grid-template-areas: "corner link";
        align-items: center;
        cursor: pointer;
    }

    &__submenu-wrap .menu-bar__breadcrumb-item .search-form__icon {
        grid-area: corner;
        stroke: var.$color-main;
    }

    &__submenu-wrap .menu-bar__breadcrumb-item a {
        grid-area: link;
        text-decoration: none;
        color: var.$color-main;
        font-size: 1.6rem;
        font-weight: 400;
    }
    // \submenu elements
}