@use 'base/variables' as var;
@use 'base/mixins' as mix;

.map {
    margin-bottom: 7.6rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8.6rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.3rem;
    }
    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
        }
    }

    &__content {
        position: relative;
        border-radius: 4px;
        background-color: #EBF0F5;
        margin-bottom: 1.5rem;
        padding: 2rem 1.5rem;
        background-image: url("/img/mister-purple-tricky.svg");
        background-repeat: no-repeat;
        background-size: 7rem 6.5rem;
        background-position: bottom right 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem 2rem;
            width: 47.8rem;
            flex-grow: 1;
            margin-bottom: 0;
            margin-right: 2rem;
            background-size: 12.6rem 13.8rem;
            background-position: bottom right 4rem;
            background-image: url("/img/mister-purple-tricky-tablet.svg");
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 84.6rem;
            padding: 4rem 3rem;
            background-size: 16rem 21rem;
            background-position: bottom right 16.8rem;
            background-image: url("/img/mister-purple-tricky-desktop.svg");
        }

        @media(min-width: 1360px) {
            background-size: 20rem 21rem;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            width: 89.6rem;
            padding: 4rem;
            background-position: bottom right 23.4rem;
        }
    }

    &__text {
        font-size: 1.4rem;
        line-height: 1.3;
        margin: 0;
        margin-bottom: 3.2rem;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: calc(100% - 18rem);
            margin-bottom: 1.1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 38rem;
        }
    }

    &__map-container {
        min-height: 21rem;
        background-color: #EBF0F5;
        @include mix.breakpoint(var.$tablet-width) {
            width: 23rem;
            min-height: 18rem;
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 41.3rem;
        }
    }

    .title {
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 38rem;
            margin-bottom: 2.5rem;
        }
    }

    .link {
        font-size: 1.2rem;
        line-height: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            position: absolute;
            right: 4rem;
            bottom: 4rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            right: 5.5rem;
            display: inline-flex;
            align-items: center;
            .link__icon {
                // stroke: 2px;
                height: 1.2rem;
                margin-top: 2px;
            }
        }
    }
}
