@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tag-list-v14 {
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0;
    @media only screen and (min-width: 1280px) {
        // padding: 0 8rem;
    }
    @media only screen and (min-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        // max-width: 1604px;
    }

    @media only screen and (min-width: 768px) {
        flex-wrap: wrap;
        border: 0;
    }

    &__content {
        border-right: 1.5rem solid transparent;
        display: flex;
        @media only screen and (min-width: 1280px) {
            margin-right: -2.5rem;
            margin-top: -2.5rem;
        }
    }


    &__item {
        @media only screen and (min-width: 1280px) {
            margin-right: 2.4rem;
            margin-top: 2.5rem;
        }
        @media only screen and (min-width: 768px) {
            margin-right: 1.5rem;
            margin-top: 1.5rem;
        }
        flex-shrink: 0;
        margin-right: 1rem;
    }

    &__link {
        display: flex;
        align-items: baseline;
        padding: 1.4rem 1.5rem 1.5rem;
        text-decoration: none;
        color: #3c3c70;
        background: #fff;
        border: 1px solid #ebf0f5;
        border-radius: 4px;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 500;
        @media only screen and (min-width: 1280px) {

            transition: all .3s;
            padding-right: 2.4rem;
            padding-left: 2.4rem;

        }
        @media only screen and (min-width: 768px) {

            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.6rem 2.5rem;
            padding-right: 2.5rem;
            padding-left: 2.5rem;

        }
    }

    &__link:hover {
        background-color: #3c3c70;
        color: #fff;
        border-color: #3c3c70;
    }

    &__wrapped {
        flex-wrap: wrap;
    }
}

// deps
.tag-list-v14 {
    .icon-v1 {
        @media only screen and (min-width: 768px) {
            height: 10px;
        }
        width: 5px;
        height: 9px;
        margin-left: 1rem;
        transform: translateY(1px);
        stroke: currentColor;
    }

    @media only screen and (min-width: 1280px) {
        .title-v2 {
            margin-bottom: 6.8rem;
        }
    }
    @media only screen and (min-width: 1280px) {
        .title-v2 {
            font-size: 4rem;
            margin-bottom: 6rem;
        }
    }
    @media only screen and (min-width: 768px) {
        .title-v2 {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }
    }
}


// \deps
