@use 'base/variables' as var;
@use 'base/mixins' as mix;

.list-view-pager {
  width: 100%;
}

.pagination {
    display: flex;
    margin-top: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    --webkit-overflow-scrolling: touch;
    @include mix.breakpoint(var.$tablet-width) {
        margin-top: 4rem;
    }

    &__control, .prev > a, .next > a, .prev > span, .next > span,
    .last > a, .last > span, .first > a, .first > span {
        background: #ECF0F4;
        border-radius: 4px;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.6rem;
        height: 3.6rem;

        outline: none;
        @media(hover:hover) {
            &:hover {
                background-color: var.$color-main;
                .pagination__control-icon {
                    stroke: #fff;
                }
            }
        }
        @include mix.breakpoint(var.$mobile-width) {
            width: 4.6rem;
            height: 4.6rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: 5.4rem;
            height: 5.4rem;
        }
    }


    .disabled {
      display: none;
      // & > a, & > span {
      //   color: #cfcfdc;
      //   @media(hover:hover) {
      //       &:hover {
      //         background: #ECF0F4;
      //       }
      //     }
      // }
    }


    &__control-icon {
        width: 0.7rem;
        height: 1.4rem;
        stroke: var.$color-main;
    }

    &__list {
        margin: 0 1rem;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        @include mix.breakpoint(var.$tablet-width) {
            flex-grow: 0;
        }
    }

    &__item, li {
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 1.5rem;
            &:last-of-type {
                margin-right: 0;
            }
        }
        @media (max-width: var.$tablet-width - 1) {
          &.first, &.next {
            margin-right: 1rem;
          }
        }
    }

    &__link, li a {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 3.6rem;
        min-height: 3.6rem;
        text-decoration: none;
        color: #9D9DB7;
        font-size: 1.7rem;
        line-height: 2.2rem;
        &._current {
            font-weight: 600;
            color: var.$color-text;
        }
        @include mix.breakpoint(var.$mobile-width) {
            min-width: 4.6rem;
            min-height: 4.6rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            min-width: 5.4rem;
            min-height: 5.4rem;
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }
}

.list-view-pager, .pagination {
  // width: 100%;
  li.active a {
    font-weight: 600;
    color: var.$color-text;
  }
}
