@use 'base/variables' as var;
@use 'base/mixins' as mix;

.receipt-row-v3 {
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr;
    justify-content: space-between;
    align-items: center;
}


/** DEPENDS CODE */
.receipt-row-v3 {
    
    &__value {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.68rem;
        color: var.$color-gray;
    }

    &__subvalue {
        font-family: var.$font-text;
        font-weight: 500;
        font-size: 2rem;
        text-align: right;
        color: var.$color-text;
        white-space: nowrap;
    }
}

/** \DEPENDS CODE */
