@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-bar {

    &__submenu {
        display: none;
        position: absolute;
    }

    &__info-content {
        width: 100%;
        grid-area: ib-content;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            grid-column: 1 / 11;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-column: 1 / 6;
        }
        justify-self: start;
        display: grid;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            grid-template-areas: 
                "location phone";
            grid-template-columns: auto min-content;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-template-areas: 
                "location phone callback schedule";
            grid-template-columns: 4fr 1fr 1fr 1fr;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-template-areas: 
                "location phone callback schedule";
            grid-template-columns: 24.3rem repeat(3, min-content);

        }
        grid-template-rows: 1fr;
        height: 100%;
    }

    &__location, &__phone, &__callback, &__schedule, &__menu {
        height: 100%;
    }

    &__location {
        cursor: pointer;
        grid-area: location;
        justify-self: start;
        display: grid;
        grid-template-areas: "icon-place text-place";
        grid-template-rows: 1fr;
    }

    &__phone {
        grid-area: phone;
        display: grid;
        grid-template-areas: "phone";
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            justify-content: end;
        }

        & .site-header__phone {
            white-space: nowrap;
            grid-area: phone;
            align-self: center;
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
                font-weight: 500;
                font-size: 1.2rem;
            }
            @include mix.breakpoint(var.$tablet-h-width) {
                font-weight: 500;
                font-size: 1.6rem;
                margin-right: 2rem;
            }
        }

    }

    &__callback {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            justify-self: end;
        }
        grid-area: callback;
        display: grid;
        align-content: center;
        grid-template-areas: "text";
        grid-template-columns: min-content;
    }

    &__callback-button {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            justify-self: end;
        }
        font-size: 1.4rem;
        font-weight: 400;
        grid-area: text;
        justify-content: start;
        white-space: nowrap;
        margin-right: 2rem;
        border-bottom: 1px dashed;
        cursor: pointer;
    }

    &__schedule {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            justify-self: end;
        }
        grid-area: schedule;
        display: grid;
        align-content: center;

        & .site-header__working-hours {
            white-space: nowrap;
            font-size: 1.2rem;
            color: #818DA1;
        }

    }

    &__menu {
        width: 100%;
        grid-area: ib-menu;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            display: none;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-column: 6 / 11;
        }
        justify-self: end;
        align-content: center;
    }

    &__menu-list {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        padding: 0;
        height: 100%;
    }

    &__menu-item {
        box-sizing: border-box;
        height: 100%;
        display: grid;
        align-content: center;
        margin-left: 3rem;
        grid-auto-flow: column;
        grid-template-columns: auto 2rem;
        grid-template-rows: 1fr;
        grid-template-areas: "label corner";
        align-items: center;
        @include mix.breakpointRange(var.$tablet-h-width, 1100px) {
            margin-left: 0.5rem;
        }
        li{
            display: block;
        }
    }

    &__menu-item:hover {
        & > a {
            color: var.$color-main;
        }
    }

    &__dropdown-wrap {
        cursor: pointer;
        position: relative;

        &_is-open {

            & > a {
                color: var.$color-main;
            }
            
            & > svg {
                stroke: var.$color-main;
            }

            & .info-bar__submenu {
                display: grid;
                grid-auto-flow: row;
                row-gap: 1.5rem;
                position: absolute;
                top: 5.2rem;
                right: 0;
                z-index: 99999999;
                background-color: var.$bgcolor-light-tone1;
                padding-bottom: 1.5rem;
            }

            & .info-bar__menu-item {
                padding-left: 2rem;
                padding-right: 2rem;
                margin: 0;
                justify-content: end;
                
            }

        }

    }

    &__menu-link {
        color: #818DA1;
        line-height: 2rem;
        text-decoration: none;
        font-size: 1.4rem;
        font-weight: 400;
        white-space: nowrap;
        grid-area: label;
    }

    &__corner {
        grid-area: corner;
        stroke: var.$color-main;
        margin-left: 1rem;
    }

    &__location-icon-place {
        grid-area: icon-place;
        justify-self: center;
        align-self: center;
        svg._desktop-only {
            stroke: var.$color-black-tone4;
            margin-bottom: 0.3rem;
        }
    }

    &__location-text-place {
        grid-area: text-place;
        justify-self: center;
        align-self: center;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.3rem;
            font-weight: 400;
            margin-left: 1rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 1.4rem;
            font-weight: 400;
            margin-left: 1.4rem;
        }
    }
}