@use 'base/variables' as var;
@use 'base/mixins' as mix;

.old-price-v1 {


    font-weight: 400;
    font-size: 1.6rem;
    color: var.$color-black-tone4;
    line-height: 2.1rem;
    font-family: var.$font-text;

    &__label {

    }

    &__value {
        text-decoration: line-through;
    }


}