@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-page-v1 {

    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .title {
        font-family: 'Affect';
        font-weight: 700;
        font-size: 3rem;
        line-height: 3.6rem;
        margin-bottom: 1.5rem;
    }

    h1.title + .p-v1 { 
        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            width: 100%;
        }
        
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2.56rem;
        margin-bottom: 3rem;
        width: 65%;
    }

    &__product-list-receipt-wrap {

        display: grid;
    
        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            grid-template-columns: 100%;
            grid-template-rows: auto auto auto;
            padding-bottom: 4rem;
            grid-template-areas: 
                "left"
                "right";
        }
    
        @include mix.breakpoint(var.$desktop-sm-width) {
            grid-template-rows: auto auto;
            grid-auto-columns: 1fr;
            grid-template-columns: 65fr 35fr;
            column-gap: 10rem;
            grid-template-areas: 
                "left right";
        }
    
        .cart-page-v1__left {
            grid-area: left;
        }
    
        .cart-page-v1__right {
            grid-area: right;
        }

    }

    &__form-title {

        margin-bottom: 4rem;
        
    }
    
    &__form-title .header-v1__text {        
        font-weight: 700;
        font-size: 3rem;
    }
    
}

/** DEPENDS CODE */

.cart-page-v1 {

    #cart-order-wrap + .text-v1 ul {
        list-style-type: disc;
        padding-left: 2rem;
    }
    
}

/** \DEPENDS CODE */