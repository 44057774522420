@use 'base/variables' as var;
@use 'base/mixins' as mix;

.contacts {
    overflow: hidden;
    margin-bottom: 4.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 4rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6rem;
        .title {
            margin-bottom: 7rem;
        }
    }
    &__list {
        width: 100vw;
        margin-left: -1.5rem;
        display: flex;

        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            margin-left: 0;
            justify-content: space-between;
            padding: 3rem 2rem;
            background-color: #F5F7FA;
            border-radius: 4px;
            border: 1px solid #EBF0F5;
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 4rem;
        }
    }

    &__item {
        margin-right: 1.5rem;
        outline: none;
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 0;
            flex: 1;
        }
    }

    &__link {
        display: block;
        padding: 1rem 2rem 1rem 1.5rem;
        background: #F5F7FA;
        border-radius: 2px;
        outline: none;
        text-decoration: none;
        font-size: 1.5rem;
        line-height: 1.9rem;
        color: var.$color-text;
        @media(hover:hover) {
            &:hover,
            &:focus {
                .contacts__subtitle {
                    color: var.$color-main;
                }
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 0;
            font-size: 1.4rem;
            line-height: 1.8rem;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

    &__subtitle {
        display: block;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #A5A5A5;
        margin-bottom: 0.6rem;
        transition: all .3s;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.2rem;
            line-height: 1.5rem;
            margin-bottom: 0.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }

    }

    &__copy-hint {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        padding: .2rem .4rem;
        background-color: #fff;
        color: #000;
        font-size: 0.8rem;
        line-height: 1rem;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1px;
    }

    .slick-track {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}
