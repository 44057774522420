@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review {
    overflow: hidden;

    .title {
        margin-bottom: 4.4rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            text-align: center;
            margin-bottom: 2rem;
        }
    }

    &__big-button-wrap {
      text-align: center;

      @include mix.breakpoint(var.$tablet-width) {
        .review__btn {
          padding-left: 8rem;
          padding-right: 8rem;
        }
      }
    }

    &__youtube {
      height: 22rem;
      margin-bottom: 1rem;
    }

    &__product-link {
      color: #3c3c70;
      text-decoration: none;
      font-size: 1.4rem;
      margin-bottom: 1rem;
      color: #7B75C9;

      &:hover {
        text-decoration: underline;
      }
    }

    &__button-wrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            justify-content: center;
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }
    }

    &__btn {
      &._big {
        width: auto !important;
        margin-top: 2rem;
      }
    }

    &__top-wrap {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3rem;

            .title {
                margin-bottom: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6.3rem;
        }
    }

    &__control-list {
        display: flex;
        margin-left: 4rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-left: 0;
        }

    }

    &__control-item {
        width: 4.6rem;
        height: 4.6rem;
        padding: 0;
        border: 0;
        background: #ebf0f5;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        transition: all .3s;
        @media(hover:hover) {
            &:hover,
            &:focus {
                background-color: var.$color-main;
                .review__icon {
                    stroke: #fff;
                }
            }
        }

        @include mix.breakpoint (var.$tablet-width) {
            width: 5.4rem;
            height: 5.4rem;

            &:first-of-type {
                margin-right: 1.5rem;
            }
        }

        &:first-of-type {
            margin-right: 1.2rem;
        }
    }

    &__icon {
        width: 6px;
        height: 10px;
        stroke: var.$color-text;
    }

    &__list {
        visibility: hidden;
        display: flex;
    }

    &__btn {
        width: 13rem;
        @include mix.breakpoint(var.$mobile-width) {
            width: 16.3rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: 17.3rem;
        }
    }

    &__item {
        display: flex!important;
        flex-direction: column;
        margin-right: 0.75rem;
        margin-left: 0 !important;
        width: calc(100vw - #{var.$container-padding-mobile} * 2);
        // old link
        outline: none;
        background: #fcfcfc;
        border-radius: 4px;
        padding: 1.5rem;
        padding-bottom: 2rem;
        position: relative;
        color: var.$color-text;
        text-decoration: none;
        transition: all 0.3s;
        // display: block;
        //
        @media(hover:hover) {
            &:hover {
                background: #f6f6f6;
                .review__arrow {
                    opacity: 1;
                }
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 3rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100vw / 2 - #{var.$container-padding-tablet} * 1.5);
            margin: 0 1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: auto;
        }


        &._catalog {

        }

        p {
          margin-top: 0;
        }

    }

    &__catalog-item {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
    }

    .slick-track {
        margin-left: 1.5rem;
        display: flex!important;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: 0;
        }
    }

    .slick-list {
        @include mix.breakpoint(var.$desktop-width) {
            margin: 0 -1rem;
        }
    }

    .slick-slide {
        height: inherit;
    }

    .slick-arrow.slick-hidden {
        display: block !important;
        pointer-events: none;

        svg {
            opacity: 0.3;
        }
    }

    &__link {
        // outline: none;
        // background: #fcfcfc;
        // border-radius: 4px;
        // padding: 1.5rem;
        // padding-bottom: 2rem;
        // position: relative;
        // color: var.$color-text;
        // text-decoration: none;
        // transition: all 0.3s;
        // display: block;
        // @media(hover:hover) {
        //     &:hover {
        //         background: #f6f6f6;
        //         .review__arrow {
        //             opacity: 1;
        //         }
        //     }
        // }
        // @include mix.breakpoint(var.$desktop-width) {
        //     padding: 3rem;
        // }
    }

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        line-height: normal;
    }

    &__img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-right: 0.8rem;
        background: #BEE6A8;
        border: 1px solid #7B75C9;
        color: #7B75C9;
        text-align: center;
        line-height: 2.6rem;
        font-weight: bold;

        // @include mix.breakpoint(var.$desktop-width) {
        //     width: 4.6rem;
        //     height: 4.6rem;
        // }
    }

    &__name {
        font-size: 1.4rem;
        font-weight: 500;
        margin: 0;
        color: #7B75C9;
        line-height: 1.6rem;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
    }

    &__date {
      font-size: 1.2rem;
      color: #9F9F9F;
    }

    &__text {
        font-size: 1.4rem;
        margin-top: 0;
        margin-bottom: 0;
        color: #3A4568;
        line-height: 18px;
        max-height: calc(18px * #{var.$review-lines-count});

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: var.$review-lines-count !important;
        -webkit-line-clamp: var.$review-lines-count !important;
        -webkit-box-orient: vertical;

        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -moz-box;
        // -moz-box-orient: vertical;
        // display: -webkit-box;
        // -webkit-line-clamp: 6;
        // -webkit-box-orient: vertical;
        // line-clamp: 6;
        // box-orient: vertical;
        @include mix.breakpoint(var.$tablet-width) {
            // -webkit-line-clamp: 7;
            // line-clamp: 7;
        }
        @include mix.breakpoint(var.$desktop-width) {
            // -webkit-line-clamp: 5;
            // line-clamp: 5;
            // margin-bottom: 2.5rem;
        }
    }

    &__more-btn {
      color: #9F9F9F;
      display: inline-block;
      border: 1px solid #7B75C9;
      border-radius: 4px;
      font-size: 1.2rem;
      line-height: 150%;
      padding: 0 1.6rem;
      text-decoration: none;
      margin-top: 0.6rem;
      transition: 0.3s color;
      display: none;

      &:hover {
        color: #7B75C9;
      }
    }

    &__content {
      &._open {
        .review__text {
          display: block;
          overflow: visible;
          max-height: 100%;
        }
      }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        font-size: 1.2rem;
        line-height: 1.5rem;
        color: #b0b0b0;
        margin: 0;
        margin-top: auto;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
    }

    &__arrow {
        position: absolute;
        right: 1.5rem;
        bottom: 2rem;
        width: 2rem;
        height: 0.8rem;
        @include mix.breakpoint(var.$desktop-width) {
            opacity: 0;
            bottom: 3.5rem;
            right: 3rem;
        }

    }



    &__row-item {
      margin-bottom: 2rem;
      padding: 2rem;
      border-radius: 8px;

      .header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 1rem;
        flex-direction: row;
      }
      ._type {

      }
      ._content {
        font-size: 1.4rem;
      }
    }


    &__top-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 6rem;

      @include mix.breakpoint(var.$tablet-width) {
          flex-direction: row;
      }

      // & > div {
      //   @include mix.breakpoint(var.$tablet-width) {
      //       width: 50%;
      //   }
      // }

      // .top-rating {
      //   display: flex;
      //   align-items: center;
      //   flex-direction: column;
      //   margin-bottom: 2rem;
      //   @include mix.breakpoint(var.$tablet-width) {
      //       flex-direction: row;
      //   }
      //
      //   .circle-progress {
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     flex-direction: column;
      //     width: 130px;
      //     height: 130px;
      //     border-radius: 100%;
      //     border: 4px solid var.$color-main;
      //     font-size: 1.8rem;
      //     font-weight: bold;
      //     margin-right: 2rem;
      //     margin-bottom: 2rem;
      //
      //     span {
      //       font-size: 3.2rem;
      //       line-height: 2.8rem;
      //     }
      //   }
      //
      //   .star {
      //     display: inline-block;
      //     margin: 0 1rem;
      //   }
      // }

      .link-set__list {
        flex-wrap: wrap;

        .link-set__item {
          margin-bottom: 1rem;
          @include mix.breakpoint(var.$tablet-width) {
              margin-bottom: 0;
          }
        }
      }

    }

    // &__top-rating {
    //   display: flex;
    //   justify-content: center;
    // }

    &__item-wrap {

      &._shop {
        @include mix.breakpoint(var.$tablet-width) {
            width: 50%;
        }

        .rating-wrap {
          display: inline-block;
          padding: 4px 12px;
          border: 1px dashed #7B75C9;
          border-radius: 4px;
          text-align: center;
          margin-bottom: 0.6rem;

          ._type {
            color: #7B75C9;
            font-size: 1.4rem;
            font-weight: bold;
          }

          .star {
            margin: auto;
          }
        }
      }
    }

    &__header {
      h1 {
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.4rem;
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
        }
      }
    }

    &__ratings-wrap {
      display: flex;
      justify-content: space-between;
      background: var.$color-main;
      color: var.$color-white;
      border-radius: 2px;
      margin: 3rem 0;
      padding: 2.6rem 1.6rem;
      @include mix.breakpoint(var.$tablet-width) {
        padding: 2.6rem 3.3rem;
      }

      @include mix.breakpoint(var.$tablet-width) {
          background-image: url("/img/maskot-call-mobile.svg");
          background-repeat: no-repeat;
          background-size: 17.9rem 16rem;
          background-position: bottom -1px right 2rem;
      }
      @include mix.breakpoint(var.$desktop-width) {
          background-position: bottom -2px right 11.5rem;
      }

      ._main {
        h3 {
          font-size: 2rem;
          margin-bottom: 0;
          @include mix.breakpoint(var.$tablet-width) {
            font-size: 4rem;
          }
        }
        .star {
          margin: 1rem 0;
          width: auto;
        }
        .star__icon {
          height: 24px;
          width: 118px;
          @include mix.breakpoint(var.$tablet-width) {
            height: 34px;
            width: 182px;
          }
        }
        ._pre {
          font-size: 1.4rem;
        }
      }
      ._info {
        flex-grow: 1;
        width: 100%;
        padding-left: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
          padding-left: 8rem;
        }

        .rating-row {
          display: flex;
          align-items: center;
          flex-direction: row;
          font-size: 1.2rem;
          margin-bottom: 0.6rem;
          @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
          }

          .star {
            margin-right: 0.4rem;
            @include mix.breakpoint(var.$tablet-width) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
}
