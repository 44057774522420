@use 'base/variables' as var;
@use 'base/mixins' as mix;

.other-lines {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -1rem;
  
  .oL-content{
    width: 33%;
    margin-bottom: 4rem;
    text-align: center;
    padding: 0 1rem;
  }
}
