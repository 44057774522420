@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-slider-v1 {

    width: 100%;
    // max-width: 100%;

    @include mix.breakpoint(var.$tablet-width) {
        &__top-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__button-wrap {
        display: flex;
    }

    @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
        &__button-wrap {
            justify-content: center;
            margin-bottom: 2rem;
        }
        &__title-place {
            width: 100%;
        }
        &__button-wrap {
            display: flex;
            width: 100%;
        }
    }

    &__track-element {
        padding: 0 1rem 0 0;
        height: 100%;
    }

    &__track {
        margin-bottom: 5rem;
    }
}

//deps
.review-slider-v1 {
    .review-card-v1 {
        height: 100%;
    }

    &__track {
        .slick-slide > div {
            height: 100%;
        }
    }

}