@use 'base/variables' as var;
@use 'base/mixins' as mix;

.other-size {
    .title {
        margin-bottom: 1rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        // width: 37.5rem;
        margin-left: auto;
        margin-bottom: 7rem;
        .title {

            font-family: "EuclidCircularA", Arial, sans-serif;
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 0;
        }
    }


    @include mix.breakpoint(var.$desktop-width) {
        width: 100%;
        .title {
            font-family: "Affect", Arial, sans-serif;
            font-size: 4.4rem;
            line-height: 1.1;
            margin-bottom: 2rem;
        }
    }

    &__wrap {
        margin-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: center;
        }
    }

    &__top {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 2rem;
            border-bottom: 2px solid #EBF0F5;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            padding-bottom: 0;
            border: 0;
        }
    }

    &__product-name {
        margin: 0;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.4rem;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin-bottom: 0;
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    &__link-custom/*,&__more-link*/ {
        text-decoration: none;
        color: var.$color-main;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    &__link-custom {
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: auto;
            font-size: 1.6rem;
            line-height: 2rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            font-size: 1.8rem;
            line-height: 2.3rem;
            margin-top: -4.2rem;
            .link__icon {
                width: 8px;
                height: 15px;
                margin-left: 1.8rem;
                margin-bottom: -0.3rem;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -1.5rem;
        margin-top: -1.5rem;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-top: 5rem;
            margin-top: -2rem;
            margin-right: -2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: -1.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 0;
        }
    }

    &__item {
        width: calc(100% / 3 - 1.5rem);
        margin-right: 1.5rem;
        margin-top: 1.5rem;
        &._extra {
            display: none;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 4 - 1.5rem);

            &._extra {
                display: block;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 12 - 2rem);
            margin-top: 2rem;
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-top: 1.5rem;
        }
    }

    &__link {
        text-decoration: none;
        display: block;
        padding: 1rem;
        padding-bottom: 0.8rem;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: center;
        transition: all .3s;
        color: #383838;
        @media(hover:hover) {
            &:hover,
            &:focus {
                color: #fff;
                background-color: var.$color-text;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 1.3rem;
            padding-bottom: 1.4rem;
        }
    }

    /*&__more-link {
        .link__icon {
            width: 1rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }
    }*/

    &__list-container {
        &._more {
            .other-size__item._extra {
                display: block;
            }

            .link__icon {
                transform: rotateX(180deg);
            }
        }
    }
}
