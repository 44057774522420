@use 'base/variables' as var;
@use 'base/mixins' as mix;

.calc-v1 {
    position: relative;
    margin-bottom: 3rem;
    color: #3C3C70;
    width: 100%;

    &__wrap {
        margin-bottom: 9.8rem;
    }
    &__wrap-catalog {
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 6rem;
        }
    }

    &__body {
        background: #7B75C9;
        color: #fff;
        padding: 3rem 6.5rem;
        position: relative;
        z-index: 0;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.2rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            padding: 8.8rem 3.5rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            padding: 3rem 6.5rem;
        }
    }

    &__body-catalog {
        background: #7B75C9;
        color: #fff;
        padding: 3rem 6.5rem;
        position: relative;
        z-index: 0;
        padding: 1rem 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem 6.5rem;
        }
    }

    &__title-place {
        position: relative;
        z-index: 1;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 24rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: 36.6rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 53rem;
        }

        & .title-v2 {

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                font-size: 2.4rem;
                margin-bottom: 1.2rem;
                text-align: left;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
                font-size: 3.6rem;
                margin-bottom: 1.2rem;
                text-align: left;
            }

            @include mix.breakpoint(var.$desktop-notebook-width) {
                font-size: 4.8rem;
                margin-bottom: 1.6rem;
            }
        }

        & .subtitle-v2 {
            text-align: left;
            color: #fff;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                font-size: 1.2rem;
                margin-bottom: 2.8rem;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
                font-size: 1.6rem;
                margin-bottom: 3.6rem;
            }

            @include mix.breakpoint(var.$desktop-notebook-width) {
                font-size: 2rem;
                margin-bottom: 5.6rem;
            }
        }
    }

    &__title-place-2 {
        display: flex;
        width: 100%;
        @include mix.breakpoint(var.$tablet-width) {
            width: 60%;
        }
        & .title-v2 {
            color: #fff;
            width:70%;
            margin-bottom: 0!important;
            font-size: 4vw !important;
            @include mix.breakpoint(var.$tablet-width) {
                margin-bottom: 0!important;
                font-size: 1.9vw !important;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                font-size: 3rem !important;
            }
        }
    }

    &__btn {
        border-radius: 8px;
        z-index: 0;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 16rem;
            font-size: 1.4rem;
            padding: 1.6rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: 100%;
            font-size: 2.4rem;
            padding: 1.4rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 100%;
            font-size: 3.6rem;
            padding: 3.2rem;
        }
    }

    &__btn2 {
        border-radius: 8px;
        z-index: 0;
        font-size: 3vw !important;
        padding: 2vw 3vw !important;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.9vw !important;
            padding: 1vw 2vw !important;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 1.5rem 3rem !important;
            font-size: 2.5rem !important;
        }

    }

    &__background {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        text-align: right;
        height: 100%;

        img {
            height: 100%;
        }

        &_banner {

            @include mix.breakpointRange(var.$zero-width, (510px - 1)) {
                height: auto;
                width: 100%;
            }

            img {
                @include mix.breakpointRange(var.$zero-width, (510px - 1)) {
                    height: auto;
                    width: 100%;
                    vertical-align: bottom;  
                }          
            }
        }
    }

    &__background2 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        text-align: right;
        width: 20%;
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 25%;
        }

        img {
            /*height: 100%;*/
            display:none;
            @include mix.breakpoint(var.$tablet-width) {
                display: block;
            }
        }
    }

    &__form {
        position: relative;
        width: 100%;
        margin-top: 6rem;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-top: 5rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 6rem;
        }
    }

    &__column {
        
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 100%;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: 27rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 43rem;
        }

        &_mobile-top-0 {
            margin-top: 0 !important;
        }
    }

    &__column + &__column {
        
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-left: 0;
            margin-top: 2.4rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            margin-left: 2rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            margin-left: 8rem;
        }
    }

    &__header {
        font-weight: 500;
        line-height: 1.1;
        text-align: center;
        white-space: nowrap;
        margin-top: 0;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.8rem;
            margin-bottom: 1.2rem;
            padding-top: 1rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            font-size: 2.4rem;
            margin-bottom: 4rem;
            padding-top: 0rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            font-size: 4.4rem;
            margin-bottom: 4rem;
            padding-top: 0rem;
        }
    }

	&__label {
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 1px solid #7B75C9;
        width: 100%;
        
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.2rem;
            margin-bottom: 0.8rem;
            padding-right: 0;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            padding: 1.2rem;
            padding-right: 0;
            margin-bottom: 0.8rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            padding: .6rem;
            margin-bottom: 3rem;
        }
    }

    &__label:hover,
    &__radio:checked + &__label {
        
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            border-width: .3rem;
            padding: 1rem;
            padding-right: 0;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            border-width: .3rem;
            padding: 1rem;
            padding-right: 0;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            border-width: .4rem;
            padding: .3rem;
        }
    }

    &__image {
        margin-right: 2rem;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 3rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: 3rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 11rem;
        }
    }

    &__text {

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.6rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            font-size: 1.8rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            font-size: 2.4rem;
        }
    }

    &__question {
        position: relative;
        display: none;
        margin: 0 auto;
        z-index: 0;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: calc(100% - 8.2rem);
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: calc(100% - 5rem);
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 94rem;
        }

        &_active {
            display: block;
        }

        &_final {
            margin: 0;
            padding: 6rem 6.5rem;
            background: #7B75C9;
            color: #fff;
            width: 100% !important;
            margin-bottom: -3rem;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                padding: 7rem 1.4rem;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
                padding: 7.7rem 5.5rem;
            }

            @include mix.breakpoint(var.$desktop-notebook-width) {
                padding: 6rem 6.5rem;
            }
        }
    }

    &__row {
        display: -webkit-flex;
        display: flex;
        justify-content: center;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            -webkit-flex-direction: column;
            flex-direction: column;
            max-width: 27rem;
            margin: 0 auto;
            width: 100%;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            -webkit-flex-direction: row;
            flex-direction: row;
            width: 100%;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            -webkit-flex-direction: row;
            flex-direction: row;
            width: 100%;
        }
    }

    &__radio {
        display: none;
    }

    &__nav {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &__back,
    &__close {
        position: absolute;
        cursor: pointer;
    }

    &__back {

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            left: 1rem;
            top: -4rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            left: 2.4rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            left: 5rem;
        }
    }

    &__close {

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            right: 1rem;
            top: -4rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            right: 2.4rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            right: 5rem;
        }
    }

    &__final-title {
        margin-bottom: 3.5rem;
        position: relative;
        z-index: 1;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 28rem !important;
            font-size: 2.4rem;
            text-align: left !important;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: 57rem;
            font-size: 4rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 67rem;
            font-size: 4.8rem;
        }
    }

    &__link {
        position: relative;
        z-index: 1;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 16rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
            width: 55rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 53rem;
        }
    }


}

.calc-popup-v1 {
	position: relative;
    overflow: hidden;
    padding: 0;
    display: flex;
    background: #fff;

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        max-width: calc(100% - 3.2rem);
        margin: 5rem 1.6rem;
        display: block;
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        max-width: 76.9rem;
        margin: 5rem auto;
        display: block;
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        max-width: 144rem;
        margin: 1.6rem auto;
    }

    &_banner {
        margin: 1.6rem auto !important;
        width: calc(100% - 3.2rem);
        max-width: 144rem !important;
    }

    &__close {
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        top: 3.5rem;
        right: 3.5rem;
        cursor: pointer;
    }

    &__close-icon {
        stroke: var.$color-main;
    }

    // костыль для совместимости
    .title-v2 {
      font-family: Affect;
      font-weight: 700;
      font-size: 2rem;
      line-height: 1.1;
      letter-spacing: .015em;
      margin-top: 0;
      margin-bottom: 2rem;
    }
}