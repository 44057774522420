@use 'base/variables' as var;
@use 'base/mixins' as mix;

.title-v2 {

    font-family: "Affect";
    font-weight: 700;
    color: var.$color-black-tone4;
    line-height: 1.2;
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        font-size: 2rem;
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
        font-size: 2.6rem;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        font-size: 4rem;
    }
}
/** DEPENDS CODE */
.title-v2 {

}

/** \DEPENDS CODE */