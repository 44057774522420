@use 'base/variables' as var;
@use 'base/mixins' as mix;

.textarea-v1 {

    width: 100%;

    &__label {
        font-family: var.$font-text;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    &__input {
      font-family: var.$font-header;
      font-weight: 700;
      font-size: 1.6rem;
      border: 0;
      -webkit-appearance: none;
      padding: 2rem;
      border: 0.1rem solid rgba(var.$color-text, 0.3);
      border-radius: 0.6rem;
      width: 100%;

      &._error {
         border-color: #eebeba !important;
      }

    }

    &__input::-webkit-input-placeholder {
        color: rgba(var.$color-text, 0.3);
    }
    &__input:-moz-placeholder {
       color: rgba(var.$color-text, 0.3);
       opacity: 1;
    }
    &__input::-moz-placeholder {
       color: rgba(var.$color-text, 0.3);
       opacity: 1;
    }
    &__input:-ms-input-placeholder {
       color: rgba(var.$color-text, 0.3);
    }
    &__input::-ms-input-placeholder {
       color: rgba(var.$color-text, 0.3);
    }
    &__input::placeholder {
       color: rgba(var.$color-text, 0.3);
    }

}