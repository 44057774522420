@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-option-item-v1 {
    line-height: 1.5;

    &__radio {
        display: none;
    }

    &__label {
        display: flex;
        align-items: center;
        border-radius: 20px;
        border: 1px solid var.$color-main;
        width: 100%;

        &:hover,
        &__radio:checked + &__label {
            border-width: .3rem;
            padding: 1rem 0 1rem 1rem;
        }
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        &__label {
            padding: .6rem;
            margin-bottom: 3rem;
        }
        &__label:hover,
            &__radio:checked + &__label {
                border-width: .4rem;
                padding: .3rem;
            }

        &__text {
            font-size: 2.4rem;
        }
        &__image {
            width: 11rem;
        }
    }
    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        &__label {
            padding: 1.2rem 0 1.2rem 1.2rem;
            margin-bottom: .8rem;


        }

         &__label:hover,
            &__radio:checked + &__label {
                border-width: .3rem;
                padding: 1rem 0 1rem 1rem
            }

        &__text {
            font-size: 1.8rem;
        }

        &__image {
            width: 3rem;
        }
    }
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        &__label {
            margin-bottom: .8rem;
            padding: 1.2rem 0 1.2rem 1.2rem;
        }
        &__text {
            font-size: 1.6rem;
        }
        &__image {
            width: 3rem;
        }
    }


    &__image {
        margin-right: 2rem;
    }

    &__text {
        line-height: 1;
    }

}

//deps
.questionnaire-option-item-v1 {

}
