@use 'base/variables' as var;
@use 'base/mixins' as mix;

.badge-v1 {
    
    display: inline-flex;
    vertical-align: top;
    border-radius: 0.6rem;

    &__wrap {
        margin: auto 1.4rem;
    }

    &__wrap > div {
        display: inline-block;
    }

    &_text-color-type-white {
        color: var.$color-white;
    }

    &_text-color-type-main {
        color: var.$color-main;
    }

    &_text-color-type-text {
        color: var.$color-text;
    }

    &_filling-type-outlined {
        border: 0.1rem solid;
    }

    &_content-type-label &__label {
        font-family: var.$font-header;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.92rem;
    }
    
    &_content-type-icon {
    }
    &__icon {
        margin-right: 0.3rem;
    }
    &_content-type-label-and-icon &__label {
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
    }
}

.badge-v1_is-recommended {
    font-family: var.$font-header !important;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.1rem;
    border-radius: 0 .6rem 0 0;
}

.badge-v1_is-recommended .badge-v1__label {
    font-size: 1.2rem;
    font-family: var.$font-header !important;
    line-height: 1.4rem;
}

.badge-v1_is-in-showroom {
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #bee6a8;
    border-radius: .6rem 0 0 0;
    padding: .5rem;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: var.$font-header !important;
    line-height: 1.4rem;
    height: 2.4rem;
    align-items: center;
}

.badge-v1_is-in-showroom .badge-v1__wrap {
    margin: 0;
}

.badge-v1_is-in-showroom .badge-v1__label {
    font-size: 1.2rem;
    font-family: var.$font-header !important;
    line-height: 1.4rem;
}

.badge-v1_filling-type-outlined .badge-v1__wrap {
    margin: auto 0.6rem;
}

.badge-v1_filling-type-contained.badge-v1_filling-color-type-white {
    background-color: var.$color-white;
}

.badge-v1_filling-type-contained.badge-v1_filling-color-type-main {
    background-color: var.$color-main;
}

.badge-v1_filling-type-contained.badge-v1_filling-color-type-secondary {
    background-color: var.$color-second;
}

.badge-v1_filling-type-contained.badge-v1_filling-color-type-danger {
    background-color: var.$color-danger;
}

.badge-v1_filling-type-contained.badge-v1_filling-color-type-text {
    background-color: var.$color-text;
}

.badge-v1_filling-type-contained.badge-v1_filling-color-type-rating {
    background-color: var.$color-rating;
}

.badge-v1_filling-type-outlined.badge-v1_filling-color-type-white {
    border-color: var.$color-white;
    background-color: var.$color-white;
}

.badge-v1_filling-type-outlined.badge-v1_filling-color-type-main {
    border-color: var.$color-main;
    background-color: var.$color-white;
}

.badge-v1_filling-type-outlined.badge-v1_filling-color-type-text {
    border-color: var.$color-text;
    background-color: var.$color-white;
}

/** DEPENDS CODE */
.badge-v1 {
}
/** \DEPENDS CODE */
