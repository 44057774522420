@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v3 {
    grid-column: 3 / span 4;
    display: grid;
    column-gap: 1.5rem;
    align-self: center;

    &__image {
        grid-column: 1 / span 1;
    }

    &__title {
        grid-column: 2 / span 4;
        text-decoration: none;
        color: inherit !important;
    }

    &__title:hover {
        text-decoration: underline;
    }
}

/** DEPENDS CODE */

/** DEPENDS CODE */