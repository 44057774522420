@use 'base/variables' as var;
@use 'base/mixins' as mix;

.table-v1 {
    
    width: 100%;

    td {
        padding: 2rem 7.5rem 5.8rem 7.5rem;
        text-align: left;
        vertical-align: top;
    }

    td:first-child {
        padding-left: 0;
    }

    td:last-child {
        padding-right: 0;
        text-align: right;

    }

    tr {
        border-top: 0.2rem solid rgba(var.$color-text, 0.3);
    }

}

/** DEPENDS CODE */
.table-v1 {

}
/** DEPENDS CODE */