@use 'base/variables' as var;
@use 'base/mixins' as mix;

.full-size-icon {

    display: grid;
    align-content: start;
    width: 100%;
    height: 100%;
    
}