@use 'base/variables' as var;
@use 'base/mixins' as mix;

.faq-v2 {
    &__item {
        margin-bottom: 1.6rem;
        padding-left: 4.4rem;
        padding-top: 0.2rem;
        position: relative;
    }

    &__icon, &__icon-active {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__icon {
        display: none;
    }

    &__icon-active {
        display: block;
    }

    &__item-head {
        &::before {
            content: "";
        }

        font-family: var.$font-text;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;
        color: var.$color-black-tone4;
        text-decoration: underline;
        cursor: pointer;
    }

    &__item-head.collapsed {
        .faq-v2__icon {
            display: block;
        }

        .faq-v2__icon-active {
            display: none;
        }
    }

    &__item-body {
        margin-top: 1.6rem;
        font-family: var.$font-text;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var.$color-black-tone4;
        display: none;
    }
}

//depends code
.faq-v2 {
    .title-v2 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 4.8rem;
        margin-bottom: 3.6rem;
    }
}

// \depends code