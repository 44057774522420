@use 'base/variables' as var;
@use 'base/mixins' as mix;

.filter-block-select-v1 {

}

/** DEPENDS CODE */
.filter-block-select-v1 {

    .select2.select2-container {
        width: 100% !important;
    }

}
/** DEPENDS CODE */