@use 'base/variables' as var;
@use 'base/mixins' as mix;

.runner-v1 {

    &__wrap {

    }

    &__active-range {
        width: 100%;
        height: 0.1rem;
        background-color: rgba(var.$color-main, 0.5);
    }

    &__range {

    }

    // TODO[dependCss]: noUiSlider
    // @see https://refreshless.com/nouislider/more/#section-styling
    &__wrap .noUi-connect {
        background-color: var.$color-main;
    }

    &__wrap.noUi-target.noUi-horizontal {
        height: 0.1rem;
    }
    &__wrap.noUi-target {
        height: 0.1rem;
        border: none;
        background: none;
        color: none;
    }
    &__wrap .noUi-handle {
        box-shadow: none;
        border: none;
        background: var.$color-main;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 1.6rem;
        top: -0.8rem;
        margin-left: -0.8rem;
    }
    &__wrap .noUi-handle:after, &__wrap .noUi-handle:before {
        display: none;
    }

    &__wrap .noUi-handle:hover {
        background: rgba(var.$color-main, 0.95);
    }
    // \TODO[dependCss]: noUiSlider
    
}