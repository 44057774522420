@use 'base/variables' as var;
@use 'base/mixins' as mix;

.order {
    margin-bottom: 5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 6rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 8rem;
    }

    &__inner {
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 3rem;
            padding-right: 1.6rem;
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            padding: 8.8rem 11rem;
            padding-bottom: 6rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            padding-left: 12.3rem;
            padding-right: 15rem;
        }
    }

    &__left {
        @include mix.breakpoint(var.$tablet-width) {
            width: 32.2rem;
            margin-right: 3.4rem;
            max-width: 42rem;
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 52rem;
            max-width: none;
            flex-grow: 0;
        }
    }

    &__info {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            .order__input-title {
                margin-bottom: 1.5rem;
            }
        }
    }

    &__title {
        margin-bottom: 2.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 5rem;
        }
    }

    &__form {
        @include mix.breakpoint(var.$tablet-width) {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
        }
    }
    &__input-title {
        margin-top: 2rem;
        color: #818DA2;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 0;
        }
    }
    &__delivery-text {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1rem;
        color: #818DA2;
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-bottom: 2.7rem;
        }
    }

    &__small-text {
        display: none;
        margin: 0;
        font-size: 1rem;
        line-height: 1.3rem;
        color: #818DA2;
        opacity: 1;
        padding-left: 3rem;
        margin-top: 0.6rem;
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-right: 2rem;
            // &:first-of-type {
            //     width: 22.7rem;
            // }
            // &:last-of-type {
            //     width: 27.3rem;
            // }
        }

        &._shown {
            display: block;
        }
        span {
            display: block;
            margin-top: 2rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-top: 1rem;
            }
        }

        ._purple {
          color: var.$color-main;
        }
    }

    &__pay-way {
        @include mix.breakpoint(var.$desktop-laptop-width) {
          #order-payment_type {
            display: flex;
            flex-wrap: wrap;
            margin-right: -2rem;
          }
        }

    }

    &__input-radio {
        display: block;
        margin-bottom: 1rem;
        padding: 1.6rem 1.5rem;
        border: 1px solid currentColor;
        border-radius: 4px;
        font-size: 1.2rem;
        line-height: 1.1;
        color: var.$color-main;
        width: 100%;
        background-color: #FFF;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-right: 2rem;
            // &:first-of-type {
            //     width: 22.7rem;
            // }
            //
            // &:last-of-type {
            //     width: 27.3rem;
            // }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 1.5rem;
        }
        &:last-of-type{
            margin-bottom: 2rem;
            @include mix.breakpoint(var.$desktop-max-width) {
                margin-bottom: 1.5rem;
            }
        }

        .form__label-text {
            &::after,
            &::before {
                left: 1.5rem;
            }
        }
    }

    &__right {
        background-color: #fff;
        padding: 1.5rem;
        padding-bottom: 3.2rem;
        position: relative;
        border-radius: 4px;
        background-image: url("/img/wave.png");
        background-repeat: repeat-x;
        background-position: bottom center;

        @include mix.breakpoint(var.$tablet-width) {
            width: 32.4rem;
            flex-grow: 1;
            max-width: 42rem;
            margin-top: 2.4rem;
            padding: 2rem;
            padding-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 41.2rem;
            max-width: none;
            flex-grow: 0;
            margin-top: 0;
            padding: 5.5rem;
            padding-top: 4.8rem;
        }
    }

    &__form-line {
        @include mix.breakpoint(var.$desktop-laptop-width) {
            display: flex;
            margin-bottom: 5rem;
        }
    }

    &__promo-apply:disabled, &__promo-remove:disabled {
        opacity: 0.5;
    }

    &__promo-apply, &__promo-remove {
        border: none;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        text-decoration: none;
        color: #818DA2;
        font-size: 1.2rem;
        border-bottom: 1px dashed currentColor;
    }

    &__promo-remove {
      color: #f46b5f;
      border-color: #f46b5f;
    }

    &__promo-error {
      position: absolute;
      font-size: 1rem;
      color: #f46b5f;
      padding-left: 21px;
    }

    &__detail {
        color: #818DA2;
        margin-bottom: 3.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-left: 0.5rem;
        }
    }

    &__detail-wrap {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 1.5rem;
        }
        &._sum {
            color: var.$color-text;
            padding-top: 1.2rem;
            margin-top: 2rem;
            align-items: baseline;
            border-top: 1px dashed #EBF0F5;
            @include mix.breakpoint(var.$tablet-width) {
                margin-top: 1.5rem;
            }

            .order__detail-desc {
                font-size: 1.8rem;
                line-height: 3rem;
                font-weight: 700;
                @include mix.breakpoint(var.$mobile-width) {
                    font-size: 2.4rem;
                }
            }
        }
    }

    &__detail-title {
        width: 10rem;
        margin: 0;
        flex-shrink: 0;
        font-size: 1.2rem;
        margin-right: 1rem;
        @include mix.breakpoint(var.$mobile-width) {
            margin-right: 2rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 1.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2rem;
        }
    }

    &__detail-desc {
        margin: 0;
        font-size: 0.9rem;
        line-height: 1rem;
        &._price {
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    &__installment-btn.btn {
        color: var.$color-main;
        margin-bottom: 1rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.2rem;
            line-height: 1.5rem;
            padding-top: 1.2rem;
            padding-bottom: 1.2rem;
            margin-bottom: 2rem;
        }
    }

    &__submit-btn {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        line-height: 2.3rem;
        font-weight: 500;
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2.1rem;
            line-height: 2.7rem;
            padding-top: 1.8rem;
            padding-bottom: 1.9rem;
            margin-bottom: 2rem;
        }
    }

    &__private-text {
        margin: 0;
        font-size: 1rem;
        line-height: 1.3;
        text-align: left;
    }

    .form {
        &__input {
            padding: 1.2rem 1.5rem;
            @include mix.breakpoint(var.$desktop-laptop-width) {
                padding-left: 2rem;
                padding-right: 2rem;
            }
            &._comment {
                height: 17rem;
                min-height: 7rem;
                @include mix.breakpoint(var.$tablet-width) {
                    height: 7rem;
                }
            }

        }

        &__input-item {
            width: 100%;
            margin-bottom: 1rem;

            @include mix.breakpoint(var.$tablet-width) {
                margin-bottom: 2rem;
            }
            &._comment {
                margin-bottom: 1.7rem;
                @include mix.breakpoint(var.$tablet-width) {
                    margin-bottom: 0.8rem;
                }
                @include mix.breakpoint(var.$desktop-laptop-width) {
                    margin-bottom: 0.5rem;
                }
            }
            &._promo {
                position: relative;
                margin-bottom: 1.5rem;
                @include mix.breakpoint(var.$tablet-width) {
                    margin-bottom: 3rem;
                }

                .form__input {
                    padding-right: 7.5rem;
                    &::placeholder {
                        font-size: 1.3rem;
                    }
                    @include mix.breakpoint(var.$mobile-width) {
                        &::placeholder {
                            font-size: 1.6rem;
                        };
                    }
                }
            }
            &._required::after {
                transform: translateY(45%);
                @include mix.breakpoint(var.$tablet-width) {
                    transform: translateY(30%);
                    font-size: 2rem;
                    left: 0.5rem;
                }
            }

            &._phone {
                @include mix.breakpoint(var.$tablet-width) {
                    width: 100%;
                }
                @include mix.breakpoint(var.$desktop-laptop-width) {
                    width: 20.5rem;
                    flex-shrink: 0;
                    margin-right: 2rem;
                    margin-bottom: 0;
                }
            }

            &._email {
                @include mix.breakpoint(var.$tablet-width) {
                    width: 100%;
                }
                @include mix.breakpoint(var.$desktop-laptop-width) {
                    flex-grow: 1;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__radio-item {
      opacity: 1;
    }

    &__open-sdek {
      padding: 1.4rem 3rem;
      margin-top: 2rem;
    }

    &__sdek-popup {
      @include mix.breakpoint(var.$tablet-width) {
          width: 80rem !important;
      }

      .js-sdek-form {
        margin-bottom: 2rem;
      }
      .js-input-container._sdek {
        @include mix.breakpoint(var.$tablet-width) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
}
