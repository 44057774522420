@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-grid-v1 {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;

    &__empty {
        font-size: 2.8rem;
        line-height: 3.2rem;
        color: var.$color-gray-tone4;
        text-align: center;
        opacity: .5;
    }
}

/** DEPENDS CODE */
.review-grid-v1 {
    .review-card-v3 {
        margin-bottom: 3.6rem;
    }
}
/** DEPENDS CODE */