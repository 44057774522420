@use 'base/variables' as var;
@use 'base/mixins' as mix;

.pay {
    margin-bottom: 2.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 10rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 4rem;
    }
    .title {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4.3rem;
        }
    }

    &__inner {
        max-width: 47.8rem;
        margin: 0 auto;
        @include mix.breakpoint(var.$tablet-width) {
            padding-top: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: none;
            padding-bottom: 5rem;
        }
    }

    &__wrap {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: flex-end;
        }
    }

    &__list {
        margin-bottom: 4rem;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            margin-bottom: 0;
        }
    }

    &__item {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }

        &._sum {
            margin-top: 2.4rem;
            padding-top: 2.6rem;
            border-top: 1px dashed #818DA2;
            @include mix.breakpoint(var.$tablet-width) {
                margin-top: 4rem;
                padding-top: 4rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 0;
            margin-right: 1rem;
            &._sum {
                margin: 0;
                padding: 0;
                border: 0;
            }
        }
    }

    &__subtitle {
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        line-height: 1.1;
        color: #818DA2;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 1.5rem;
        }
    }

    &__value {
        margin: 0;
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 2.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2.1rem;
            line-height: 2.7rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.7rem;
            line-height: 2.1rem;

        }

        @include mix.breakpoint(var.$desktop-laptop-width) {
            font-size: 2.1rem;
            line-height: 2.7rem;

        }
    }

    &__link {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.9rem;
        @include mix.breakpoint(var.$desktop-width) {
            width: 16.8rem;
            margin-left: 7.5rem;
            padding-top: 1.2rem;
            padding-bottom: 1.3rem;
        }
    }
}


