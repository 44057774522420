@use 'base/variables' as var;
@use 'base/mixins' as mix;

.submenu-wrap {
    position: relative;
    grid-area: mb-submenu;
    grid-column: 1 / 11;
}

.submenu {

    display: none;
    background-color: #fff;
    position: absolute;
    //height: 60rem;
    z-index: 99999;
    @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
        top: 0rem;
    }
    @include mix.breakpoint(var.$tablet-h-width) {
        top: 0rem;
    }
    // @include mix.breakpoint(var.$tablet-width) {
    //     min-width: calc(100% - (#{var.$container-padding-x-md} * 2));
    //     max-width: calc(100% - (#{var.$container-padding-x-md} * 2));
    //     left: var.$container-padding-x-md;
    // }
    // @include mix.breakpoint(var.$desktop-width) {
    //     min-width: calc(100% - (#{var.$container-padding-x-lg} * 2));
    //     max-width: calc(100% - (#{var.$container-padding-x-lg} * 2));
    //     left: var.$container-padding-x-lg;
    // }
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

    &_active {
        display: block;
    }

    &__wrapper {
        float: left;
        width: 100%;
        margin-right: -100%;
        height: 100%;
        display: none;
        background-color: #fff;
        grid-template-areas: "left-bar right-bar";
        //grid-template-rows: 60rem;
        grid-template-columns: 31.5rem calc(100% - 31.5rem);
    }

    &__wrapper_active {
        display: grid;
    }

    &__left-bar {
        grid-area: left-bar;
        background-color: #F9F9F9;
        border-right: 0.1rem solid #ECF0F5;
        height: 100%;
        padding-top: 4rem;
        display: grid;
        grid-template-areas: 
            "menu"
            "special";
        grid-template-columns: 1fr;
        // grid-template-rows: calc(100% - 18rem) 18rem;
        // new requirement: a special block can be with 
        // several special links, or without links at all
        grid-template-rows: auto min-content;
    }

    &__main-menu-place {
        grid-area: menu;
    }

    &__main-menu-list {
        margin-left: 3rem;
    }

    &__main-menu-item {
        width: calc(100% + 0.1rem);
        padding: 1.5rem 3rem;
        cursor: pointer;
    }

    &__main-menu-item_active {
        background-color: #fff;
        border-radius: 0.5rem 0 0 0.5rem;
    }

    &__main-menu-item_active &__main-menu-link {
        color: #7B75C9;
    }
    &__main-menu-link-head {
        color: var.$color-main;
        text-decoration: underline;
        font-size: 1.4rem;
    }

    &__main-menu-link {
        font-size: 1.6rem;
        font-weight: 400;
        text-decoration: none;
    }

    &__main-menu-link:hover {
        color: #7B75C9;
    }

    &__special-place {
        // max-height: 18rem;
        // height: 18rem;
        background-color: #ECF0F5;
        grid-area: special;
        overflow: hidden;
    }

    &__special-list {
        display: grid;
        // margin-top: 3.5rem;
        padding: 4rem 4rem 4rem 0;
    }

    &__special-item {
        display: grid;
        grid-template-areas: "icon text";
        grid-template-rows: 1fr;
        grid-template-columns: 6rem calc(100% - 6rem);
        margin-bottom: 2rem;
    }

    &__special-item:last-child {
        margin-bottom: 0rem;
    }

    &__special-icon-place {
        grid-area: icon;
        display: grid;
        align-self: center;
        justify-self: end;
    }

    &__special-text-place {
        grid-area: text;
        margin-left: 1rem;
    }

    &__special-link {
        text-decoration: none;
    }

    &__special-link:hover {
        color: #7B75C9;
    }

    &__right-bar {
        grid-area: right-bar;
        height: 100%;
        max-width: 100%;
        background-color: #fff;
    }

    &__submenu-place {
        height: 100%;
        padding: 4rem 4rem 0 2rem;
    }

    &__submenu-wrapper {
        position: relative;
        float: left;
        width: 100%;
        margin-right: -100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        display: none;
    }

    &__submenu-wrapper_active {
        display: block;
    }

    &__submenu-tag-block {
        float: left;
        // margin: 0 0 6rem;
        padding-bottom: 6rem;
        padding-left: 2rem;
        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-lg-width - 1)) {
            width: 33.3%;
        }
        @include mix.breakpoint(var.$desktop-lg-width) {
            width: 25%;
        }
    }

    &__submenu-tag-title {
        font-size: 1.4rem;
        font-weight: 500;
        color: #001A34;
        margin-bottom: 2rem;
    }

    // &__submenu-tag-list {
    // }

    &__submenu-tag-item {
        margin-bottom: 1rem;
        position: relative;

        &_is-dot:before {
            content: "";
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            background: var.$color-main;
            display: block;
            position: absolute;
            top: 45%;
            left: -1.4rem;
        }

    }

    &__submenu-tag-link {
        // white-space: nowrap;
        font-size: 1.4rem;
        font-weight: 400;
        text-decoration: none;
    }

    &__submenu-tag-link:hover {
        color: #7B75C9;
    }

    &__submenu-tag-item:last-child {
        margin-bottom: 0;
    }

}