@use 'base/variables' as var;
@use 'base/mixins' as mix;

.map-data {
    // display: flex;
    flex-wrap: wrap;
    padding: 2rem 1.5rem;
    // @include mix.breakpoint(var.$tablet-width) {
    //     padding: 0;
    //     display: none;
    //     margin-top: 1.2rem;
    // }
    // @include mix.breakpoint(var.$desktop-width) {
    //     margin-top: 2.6rem;
    // }

    &__item {
        // margin-right: 2rem;
        // @include mix.breakpoint(var.$tablet-width) {
        //     margin-right: 0;
        // }
        // @include mix.breakpoint(var.$desktop-width) {
        //     margin-right: 3.8rem;
        // }

    }

    &__phone {
        text-decoration: none;
        color: var.$color-text;
    }

    &__title {
        display: block;
        color: #A5A5A5;
        font-size: 1.3rem;
        line-height: 1.6rem;
    }

    &__value {
        font-size: 1.5rem;
        line-height: 1.9rem;
    }

    &__baloon {
        @include mix.breakpoint(var.$tablet-width) {
            width: 20rem;
        }
    }

    &__baloon-text {
        margin: 0;
        font-family: "EuclidCircularA", Arial, sans-serif;
        color: var.$color-text;
        font-size: 1.2rem;
        line-height: 1.1;
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        b {
            font-weight: 600;
        }
    }
}
