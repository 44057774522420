@use 'base/variables' as var;
@use 'base/mixins' as mix;

.button-link-v2 {

    display: grid;
    background-color: var.$color-white;
    line-height: 1;
    padding: 1.2rem 4rem;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.4rem;
    color: var.$color-main;
    // TODO[themeProvider]: common radian's
    border-radius: 5px;

    &:hover {
        background-color: var.$color-main;
        color: var.$color-white;
    }

    &:active {
        // must be small emphasize!
        background-color: rgba(var.$color-main, 0.8);
    }
    
}