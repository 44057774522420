@use 'base/variables' as var;
@use 'base/mixins' as mix;

.filter-form-v1 {

}

/** DEPENDS CODE */
.filter-form-v1 {

    .filter-block-range-v1 .input-text-v2 {
        color: var.$color-main-tone7;
        width: 8rem;
        height: 3.4rem;
        max-width: 8rem;
        max-height: 3.4rem;
        min-width: 8rem;
        min-height: 3.4rem;
    }
    
    .text-input-v1 {
        padding: 1.1rem 1.5rem;
    }

    .input-text-v2 {
        color: var.$color-main-tone7;
    }

    .select-v1 {
        padding: 0.9rem 4.5rem 0.9rem 1.8rem;
        background-position: right 1.6rem top 1.5rem;
        color: var.$color-main-tone7;
    }

}
/** \DEPENDS CODE */