@use 'base/variables' as var;
@use 'base/mixins' as mix;

.filter-toggle-v1 {

    &__wrap {
        cursor: pointer;
        display: grid;
        grid-template-areas: "icon text count";
        grid-template-rows: 1fr;
        grid-template-columns: 3.4rem min-content min-content;
        background-color: var.$color-main-tone6;
        color: var.$color-third;
        border-radius: 0.5rem;
        align-items: center;
        padding: 1.6rem 1.9rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.3rem;
        }
        justify-content: center;
        height: min-content;
    }

    &__icon {
        padding-right: 1.4rem;
        stroke: var.$color-third;
        fill: var.$color-third;
        width: 100%;
        max-height: 2rem;
    }

    &__text {
        font-weight: 500;
        line-height: 1.2;
        white-space: nowrap;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            font-size: 1.5rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 1.6rem;
        }
    }

    &__count {
        margin-left: 1rem;
        background-color: var.$color-second;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.2rem;
        padding: 0.3rem 0.6rem;
        border-radius: 2rem;
        color: var.$color-main-tone6;
    }

    &__wrap:hover {
        background-color: var.$color-main;
        color: var.$color-white;
    }

    &__wrap:active {
        // must be small emphasize!
        background-color: rgba(var.$color-main, 0.8);
    }

    &__wrap:hover &__count, &__wrap:active &__count {
        color: var.$color-main;
    }
    
}