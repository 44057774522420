@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-switcher-v1 {
    display: grid;
    gap: 0.8rem;

    &__preview-item {

        width: 5rem;
        height: 5rem;

        cursor: pointer;
        border-radius: 6px;
        border: 1px solid var.$color-white;
        box-sizing: border-box;
    }

    &__preview-item img {
        display: inline-block;
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        -o-object-position: center;
        object-position: center;
    }
    
    &__preview-item:hover {
        border-color: rgba(var.$color-gray-tone11, 0.50);
    }

    // &__preview-item._active {
    //     border-color: rgba(var.$color-gray-tone11, 0.50);
    // }

    &__more-counter {
        &:hover {
            color: rgba(var.$color-gray-tone11, 1);
        }

        border: 0.1rem solid rgba(var.$color-text, 0.16);
        border-radius: 5rem;
        color: rgba(var.$color-gray-tone11, 1);
        font-family: var.$font-text;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.44rem;
        padding: 0.4rem;
        text-decoration: none;

    }

    &__more-counter-container {
        margin-top: 1.5rem;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        &__preview-item {
            width: 3rem;
            height: 3rem;
        }
        &__more-counter {
            font-size: 0.9rem;
        }
        &__more-counter-container {
            margin-top: 0.4rem;
        }
    }
}


/** DEPENDS CODE */
.product-switcher-v1 {
    .slick-slide {
        margin: 0 0.8rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/** \DEPENDS CODE */
