@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v4 {

    width: 21.5rem;
    min-width: 21.5rem;;
    max-width: 21.5rem;;
    border: 0.1rem solid rgba(var.$color-text, 0.3);
    border-radius: 0.6rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content;
    justify-items: center;

}


/** DEPENDS CODE */
.product-card-v4 {

    .image-v1 {
        margin-bottom: 4rem;
    }

    .action-group-v1 {
        margin-bottom: 2rem;
        align-self: end;
    }

    .action-group-v1 .button-v1 {
        padding: 0.75rem 2.55rem;
        font-weight: 600;
        font-size: 0.87rem;
    }

}

/** \DEPENDS CODE */
