@use 'base/variables' as var;
@use 'base/mixins' as mix;

.character-list-v3 {

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1.8rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            border-bottom: 1px dashed var.$color-main;
            bottom: 0.5rem;
            z-index: -1;
        }
    }

    &__title {
        margin-right: auto;
        padding-right: 2.5rem;
    }

    &__value {
        margin-left: auto;
        padding-left: 2.5rem;
    }

    &__value a {
        &:first-child {
            padding-left: 0;
        }

        padding-left: 1rem;
    }

    &__title, &__value {
        font-family: var.$font-text;
        background: var.$color-white;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;
    }
}

/** DEPENDS CODE */
.character-list-v3 {
    .tooltip-v1 {
        display: inline-block;
    }

    .title-v2 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 4.8rem;
        margin-bottom: 3.6rem;
    }
}

/** DEPENDS CODE */