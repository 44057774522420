@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-composition-v2 {


    @include mix.breakpoint(var.$tablet-width) {
        // position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 46rem;
        // padding-left: 4rem;
    }

    @include mix.breakpoint(var.$desktop-width) {
        width: 46rem;
    }


    @include mix.breakpoint(var.$desktop-lg-width) {
        width: 50rem;
        // padding-left: 8rem;
    }

    &__inner {
        position: sticky;
        top: 2rem;
    }

    &__content {
        overflow: hidden;
        padding: 2rem 0;

        ul {
            list-style: disc;
            margin-left: 2rem;
        }
    }

    .product-layer {
        position: relative;
        margin-bottom: 3px;

        .layer {
            position: relative;
            padding-bottom: 2px;
            white-space: nowrap;

            &.nobottomp {
                padding-bottom: 0px;
            }

            &.notopp {
                margin-top: -2px;
            }
        }

        .layer-texture {
            width: 60px;
        }

        .layer-title {
            z-index: 10;

            .layer-title-content {
                display: inline-block;
                margin-left: 15px;
                padding: 2px;
                font-size: 1.2rem;
                line-height: 1.5rem;

                &:before {
                    content: '— ';
                    position: absolute;
                    left: 0;
                }

                @include mix.breakpoint(var.$tablet-width) {
                    font-size: 1.2rem;
                    line-height: 2.3;
                }

            }

            position: absolute;
            left: 64px;
            // font-size: 0.8em;
            @media all and (max-width: 767px) {
                // font-size: 0.6em;
            }
            top: 40%;

            &.noTop15 {
                top: -1.4rem !important;
            }

            &.noTop {
                top: -1.1rem !important;
            }

            &.top15 {
                top: 0 !important;
            }
        }

        .info-tooltip {
            svg {
                color: #7b75c9;
            }
        }
    }
}

//deps
.product-composition-v2 {

}