@use 'base/variables' as var;
@use 'base/mixins' as mix;

.count-changer-v1 {

    display: grid;
    grid-template-columns: min-content min-content min-content;
    width: 7.8rem;
    height: 3rem;
    border: 0.1rem solid rgba(var.$color-text, 0.3);
    border-radius: 0.6rem;
    align-items: center;
    justify-content: center;
    // padding: 0 0.7rem 0 0.7rem;
    
    &__input {

    }

    &__increment, &__decrement {
        cursor: pointer;
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
    }

    &__increment {
        padding-left: 1px;
    }

    &__decrement {
        padding-right: 1px;
    }

    &__input input {
        -webkit-appearance: none;
        border: 0;
        outline-width: 0;
        width: 3.8rem;
        height: 2.7rem;
        padding: 0 0.8rem;
        text-align: center;
        color: var.$color-text;
        font-family: var.$font-text;
        font-weight: 400;
        font-size: 1.2rem;
    }

    // reset browser border-styles  
    &__input input:focus, &__input input:focus-visible, &__input input:active {
        border: 0;
        outline-width: 0;
        -webkit-appearance: none;
    }

}

/** DEPENDS CODE */
/** \DEPENDS CODE */
