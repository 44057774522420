@use 'base/variables' as var;
@use 'base/mixins' as mix;

/** DEPENDS CODE */
.city-confirmation-popup-v1 {
    padding: 1rem;
    position: fixed;
    bottom: 25rem;
    right: 0;
    background-color: #bee6a8;
    color: #3c3c70;
    z-index: 1;
    font-size: 1.4rem;
    
     &._pulse-animated{
        animation: pulse .4s infinite;
        background-color: var.$color-second !important;
    }
    @include mix.breakpoint(var.$tablet-width){
        position: absolute;
        top:-2rem;
        left:0;
        bottom:auto;
        right: auto;
    }
}

// Depends
.city-confirmation-popup-v1 {

}