@use 'base/variables' as var;
@use 'base/mixins' as mix;

.contact-grid-v1 {
    &__subtitle {
        font-size: 2.4rem;
        text-align: center;
        font-weight: 700;
        margin-bottom: 1.6rem;
        font-family: var.$font-header;
        line-height: 1.1;
        letter-spacing: .015em;
        margin-top: 0;


        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            text-align: left;
            font-size: 2rem;
            font-weight: 400;
        }
    }

    &__address-more {
        margin-top: 1.6rem;
        display: flex;
        justify-content: center;
    }
}

//deps
.contact-grid-v1 {
    &__address-more {
        .button-v1 {
            color: var.$color-text;
            background: var.$color-border;
            font-weight: 500;
            width: auto;
            padding: 1.7rem 2rem;
            font-size: 1.6rem;
            line-height: 2rem;
            transition: all .3s;
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                font-size: 1.2rem;
                width: 100%;
            }
        }

        .button-v1:hover {
            background-color: var.$color-main;
            color: var.$color-white;
        }
    }
}