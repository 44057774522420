@use 'base/variables' as var;
@use 'base/mixins' as mix;

.select {
    position: relative;

    &._active {
        .select__icon {
            transform: translateY(-50%) rotateX(180deg);
        }

        .select__dropdown {
            opacity: 1;
            pointer-events: initial;
            max-height: initial;
            overflow: initial;
        }
    }

    &._selected {
        .select__icon {
            display: none;
        }

        .select__reset {
            display: block;
        }
    }

    &._grey {
        .select__face {
            /*background-color: #f5f7fa;*/
            background-color: var.$color-third;

        }
    }

    &__reset {
        padding: 0;
        border: 0;
        background-color: transparent;
        width: 1.3rem;
        height: 1.3rem;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-75%);
        z-index: 2;
        outline: 0;
        display: none;
        stroke: var.$color-main;
    }

    &__icon-reset {
        width: 1.3rem;
        height: 1.3rem;
    }

    &__type-icon{
        stroke: var.$color-text;
        position: absolute;
        top: 28%;
        left: 1rem;
        height: 2rem;
        width: 2rem;
    }
    &__title{
        padding-left: 2.5rem;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        width: 1.6rem;
        height: 0.6rem;
        transition: transform 0.6s;
        stroke: var.$color-main;
    }

    &__face {
        width: 100%;
        position: relative;
        background-color: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 4px;
        color: var.$color-text;
        font-size: 1.5rem;
        line-height: 1.9rem;
        outline: none;
        border: 0;
        border-radius: 4px;
        padding: 1.4rem 1.5rem;
        padding-right: 1.8rem;
        text-align: left;
        transition: all .3s;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .select__color-img {
            width: 2.3rem;
            height: 2.3rem;
            margin-right: 1rem;
        }
    }

    &__checkbox-boss {
        position: absolute;
        top: 50%;
        left: -0.3rem;
        transform: translateY(-50%);
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 1rem;
        pointer-events: none;

        /*background-color: #F9F9F9;
        border: 1px solid #EBF0F5;*/
        background: #FFF;
        border: 1px solid var.$color-main;
        border-radius: 2px;
        z-index: 1;

        svg {
            display: none;
            width: 0.7rem;
            height: 0.7rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__checkbox {
        border: 0;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        outline: none;


        // &::before {
        //     content: '';
        //     width: 1.4rem;
        //     height: 1.4rem;
        //     margin-right: 1rem;

        //     background-color: #F9F9F9;
        //     border: 1px solid #EBF0F5;
        //     border-radius: 2px;
        // }

        &._checked {
            .select__checkbox-extra {
                background-color: var.$color-main;
                border-color: var.$color-text;
                svg {
                    display: block;
                }
                // background-image: url("/img/checkbox.svg");
                // background-repeat: no-repeat;
                // background-position: center center;
            }
        }
    }

    &__checkbox-extra {
        position: relative;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 1rem;

        /*background-color: #F9F9F9;
        border: 1px solid #EBF0F5;*/
        background: #FFF;
        border: 1px solid var.$color-main;
        border-radius: 2px;

        svg {
            display: none;
            width: 0.7rem;
            height: 0.7rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__expand-list {
        display: none;
        width: 100%;
        padding: 1.5rem;
        padding-left: 3rem;
    }

    &__expand-item {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__checkbox-controls {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__option-item {
      &._link {
        display: block;
        &:hover {
          border-bottom-color: #ebf0f5 !important;
        }
      }

        &._checked {
            .select {
                &__checkbox-boss {
                    border-color: var.$color-text;
                    background-color: var.$color-main;
                    // background-image: url("/img/checkbox.svg");
                    // background-repeat: no-repeat;
                    // background-position: center center;
                    svg {
                        display: block;
                    }
                }

                &__option-content {
                    color: #fff;
                    background-color: var.$color-main!important;
                    // background-image: url("/img/down-mini.svg");
                    // background-repeat: no-repeat;
                    // background-position: center right 2rem;
                    // svg {
                    //     display: block;
                    // }
                }

                &__expand-list {
                    display: block;
                }
            }
        }

        &._hidden {
            display: none;
        }

        &._optgroup {
            padding-top: 1rem;
            padding-left: 1.5rem;
            font-weight: 700;
            border-bottom: 1px solid #ebf0f5;
            color: #3c3c70;
            &:first-child {
                padding-top: 0;
            }
        }
    }

    &__color-img {
        margin-right: 0.5rem;
        width: 1.4rem;
        height: 1.4rem;
    }

    &__color-wrap {
        display: flex;
        align-items: center;
    }



    &__dropdown {
        position: absolute;
        top: calc(100% + 5px);
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background-color: #fff;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        z-index: 50;

        opacity: 0;
        pointer-events: none;
        max-height: 0;
        overflow: hidden;



        // &._right {
        //     right: 0;
        // }

        // &._left {
        //     left: 0;
        // }

        &._top {
            top: 0;
            transform: translateY(-100%);
        }

        &._with-checkbox {
            padding: 1.5rem;
            color: var.$color-text;
            font-size: 1.5rem;
            line-height: 1.9rem;



            .select__option-item {
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .select__option-content {
                width: auto;
                text-align: left;
                padding-left: 2.5rem;
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 0;
                position: relative;
                border-color: #fff;


                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 1.4rem;
                    height: 1.4rem;
                    //background: #f9f9f9;
                    background: #FFF;
                    //border: 1px solid #ebf0f5;
                    border: 1px solid var.$color-main;
                    border-radius: 2px;
                    transform: translateY(-50%);
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 1.4rem;
                    height: 1.4rem;
                    transform: translateY(-50%);
                    background-image: url("/img/checkbox.svg");
                    background-repeat: no-repeat;
                    // background-position: 50% 50%;
                    background-position: right 3px top 4px;
                    // background-size: 7px 7px;
                    display: none;


                }

                &._selected {
                    background-color: #fff;
                    border-color: #fff;
                    color: var.$color-text;

                    &::after {
                        display: block;
                    }

                    &::before {
                        background: var.$color-main;
                        border-color: var.$color-text;
                    }
                }

                @media(hover:hover) {
                    &:hover {
                        background-color: #fff;
                    }
                }


            }

            .select__face span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            // .select__option-content {
            //     padding-left: 2.5rem;
            //     text-align: left;
            //     &::after {

            //     }
            //     &._selected {
            //         background-color: #fff;
            //         border-color: #EBF0F5;
            //         color: var.$color-text;
            //     }
            //     // display: flex;
            // }
            // .form__tooltip {
            //     margin-left: 5px;
            //     flex-shrink: 0;
            // }
            .info-tooltip {
                margin-left: 5px;
                flex-shrink: 0;
            }
        }

        &._scroll {
            &::after,
            &::before {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 3.5rem;
                pointer-events: none;
                transition: all 0.3s;
                z-index: 1;
            }

            &::after {
                bottom: 1.5rem;
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
            }

            &::before {
                top: 1.5rem;
                background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #fff 100%);
                opacity: 0;
            }

            &._down-position {
                &::after {
                    opacity: 0;
                }

                &::before {
                    opacity: 1;
                }
            }

            .select__dropdown-inner.ps--active-y {
                margin-right: 1.5rem;
            }


        }

        &._good-size {
            right: 0;

            .select__option-content {
                display: flex;
                justify-content: space-between;
            }
        }

        &._range {
            padding: 0;
        }

        &._checkbox-accordion {
            padding-left: 0;
            min-width: 26.5rem;
            .select {
                &__option-content {
                    width: calc(100% + 2.2rem);
                    margin-left: -1.6rem;
                    border: 0;
                    // margin-right: -0.6rem;
                    padding: 1.5rem;
                    padding-left: 3.8rem;

                    &::after,
                    &::before {
                        display: none;
                    }
                    @media(hover:hover) {
                        &:hover {
                            outline: 1px solid #ebf0f5;
                            &:not(._selected) {
                                background-color: #ebf0f5;
                            }
                        }
                    }
                    // @media(hover:hover) {
                    //     &:hover:not(._selected) {
                    //     }
                    // }

                }

                &__option-list {
                    // padding-left: 0.4rem;
                }

                &__option-item {
                    margin: 0;
                    margin-left: 1.6rem;
                    margin-right: .6rem;

                    border-bottom: 1px solid #EBF0F5;
                    &:first-child {
                        border-top: 1px solid #EBF0F5;
                    }
                    // display: flex;
                    // flex-wrap: wrap;
                    // align-items: center;
                }

                &__checkbox {
                    text-align: left;
                }

                &__checkbox-extra {
                    flex-shrink: 0;
                }

                &__dropdown-inner.ps--active-y {
                    margin-right: 0;
                    @include mix.breakpoint(var.$desktop-width) {
                        // margin-right: 1.5rem;
                    }
                }

            }
        }

        &._empty {
            .select {
                &__option-content._empty {
                    display: block;

                }
            }
        }


        // perfect-scrollbar
        .ps__rail-y {
            opacity: 1 !important;
            width: 8px !important;
            background-color: #ebf0f5;
            border-radius: 8px !important;
        }

        .ps__thumb-y {
            background-color: var.$color-main !important;
            width: 8px !important;
            right: initial !important;
            border-radius: 8px !important;
        }

        // @include mix.breakpoint(var.$tablet-width) {
        //     max-width: 26.5rem;
        // }
    }

    &__dropdown-inner {
        position: relative;
        max-height: 30rem;
    }

    &__option-content {
        width: 100%;
        border: 0;
        color: var.$color-text;
        text-decoration: none;
        display: inline-block;
        text-align: center;
        border-bottom: 1px solid #ebf0f5;
        padding: 0;
        background-color: transparent;
        font-size: 1.5rem;
        line-height: 1.9rem;
        padding: 1.3rem 1.5rem;
        outline: none;
        align-items: center;

        &._selected {
            background-color: var.$color-main;
            color: #fff;
            border-color: var.$color-main;
        }

        &._empty {
            pointer-events: none;
            color: #818DA2;
            display: none;
            border-color: transparent!important;
            text-align: center!important;
        }

        &._disabled {
          opacity: 0.2;
          pointer-events: none;
        }

        @media(hover:hover) {
            &:hover:not(._selected) {
                background-color: #ebf0f5;
            }
        }

        ._bold {
            font-weight: 500;
        }
    }

    &__range:nth-child(2) {
        margin-left: 1.4rem;

        &::before {
            vertical-align: middle;
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1px;
            background-color: #c5c5d4;
            margin-right: 1.4rem;
        }
    }

}
