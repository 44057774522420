@use 'base/variables' as var;
@use 'base/mixins' as mix;

.image-carousel-v1 {
    &__slide {
        width: 23rem;
        margin-left: 0;
        margin-right: 2rem;
        margin-top: 2rem;
    }
}

//deps
.image-carousel-v1 {

}