@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tag-list-item-v10 {
    flex-grow: 0;

    &__link:hover {
        span {
            color: var.$color-white;
        }

        background-color: var.$color-main;
    }

    &__link {
        background-color: var.$color-gray-tone3;
        color: var.$color-text;
        padding: 1.05rem 1.4rem 1.05rem 1.4rem;
        font-size: 1.4rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.6rem;
        line-height: 1.68rem;
        text-align: center;
        text-decoration: none;
        transition: all .3s;
        white-space: nowrap;
    }

    &__active {
        background-color: var.$color-main;

        span {
            color: var.$color-white;
        }
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {

    }
}

// deps
.tag-list-item-v10 {
    .icon-v1 {
        margin: 0rem 0 0 1.1rem;
    }

    .icon-v1 {
        fill: var.$color-black-tone4;
        stroke: var.$color-black-tone4;
    }

    &__active, &__link:hover {
        .icon-v1 {
            fill: var.$color-white;
            stroke: var.$color-white;
        }
    }
}
// \deps