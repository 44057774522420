@use 'base/variables' as var;
@use 'base/mixins' as mix;

.custom-size {
    margin-bottom: 5rem;
    &__list {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6rem;
        }
        .popular__list {
            display: block;
            margin-right: 0;
            width: 100vw;
            margin-left: -1.5rem;
            border: 0;
            @include mix.breakpoint(var.$tablet-width) {
                display: flex;
                width: auto;
                margin: 0;
                margin-top: -2rem;
                margin-right: -2rem;
            }
        }

        .popular__item {
            margin-right: .7rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-right: 2rem;
                margin-top: 2rem;
            }
        }

        .slick-track {
            margin-left: 1.5rem;
        }
    }
    &__top {
        margin-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-bottom: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 7rem;
        }
    }

    &__title-wrap {
        padding: 1.5rem;
        padding-top: 2rem;
        padding-right: 12.5rem;
        margin-bottom: 2rem;
        background-image: url("/img/custom-size.svg");
        background-repeat: no-repeat;
        background-size: 11.4rem 9.5rem;
        background-position: bottom right -2px;
        background-color: var.$color-main;
        border-radius: 4px;
        @include mix.breakpoint(var.$tablet-width) {
            width: 43.2rem;
            flex-grow: 1;
            margin: 0;
            margin-right: 2rem;
            padding: 3rem 2rem;
            padding-right: 4rem;
            background-image: none;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 6rem;
            padding-right: 32rem;
            background-image: url("/img/custom-size_desktop.svg");
            background-size: 32.5rem 20rem;
        }

        &._with-pre {
          h1 {
            font-size: 2.8rem;
            max-width: 90%;
            @include mix.breakpoint(var.$tablet-width) {
              font-size: 3rem;
            }
          }
          p {
            color: #fff;
            max-width: 90%;
          }

          @media (max-width: var.$tablet-width - 1) {
            background-image: none;
            padding-right: 1.5rem;
            h1, p {
              max-width: 100%;
            }
          }
        }

        &._grey {
          background-color: var.$color-third;
          color: var.$color-text;
          background-image: url("/img/quest-purple.svg");
          background-size: 11.4rem 8.5rem;

          @include mix.breakpoint(var.$desktop-width) {
            background-size: 27.5rem 20rem;
          }

          h1 {
            color: var.$color-text;
          }
          p {
            color: var.$color-text;
          }
        }

        &._ideal {
          width: 100%;
          @include mix.breakpoint(var.$desktop-width) {
            width: 100%;
          }
          margin-top: 3.6rem;
          margin-bottom: 3.6rem;
        }
    }

    &__content {
      order: -1;
      flex-grow: 1;

      @include mix.breakpoint(var.$tablet-width) {
        margin-right: 2rem;
      }
    }

    &__default {
      width: 100% !important;
    }

    &__title,
    &__request-title {
        margin: 0;
        font-family: "Affect";
        font-size: 2rem;
        line-height: 120%;
        letter-spacing: 0.015em;
        color: #fff;
        font-weight: 700;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 3.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4rem;
            line-height: 4.6rem;
        }
    }

    &__title {
        max-width: 46rem;
    }

    &__request {
        background-color: var.$color-second;
        padding: 2rem 1.5rem;
        padding-top: 1.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 27.5rem;
            padding: 3rem 2rem;
            flex-grow: 1;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            flex-direction: column;
            width: 41.3rem;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 5rem;
            padding-top: 4rem;
        }
    }

    &__request-title {
        margin-bottom: 1.5rem;
        color: var.$color-text;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: auto;
        }
    }

    /*&__more-link {
        font-weight: 500;
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 1rem;
        }
    }*/
}
