@use 'base/variables' as var;
@use 'base/mixins' as mix;

.main-menu-v1 {
    position: relative;
    &__loader {
        display: none;
        background: var.$color-white;
        width: 100%;
        height: 10rem;
        z-index: 300;
        position: absolute;
        .button-preloader {
            display: block;
        }
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            display: none;
        }
    }

    &:hover {
        .main-menu-v1__loader {
            display: block;
        }
    }
}

//deps
.main-menu-v1 {

}