@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sticky-footer {
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
    background-color: var.$color-white;
    // position: fixed;
    position: sticky;
    bottom: -1px;
    // left: 0;
    width: 100%;
    // transform: translateY(100%);
    transition: all 0.3s;
    z-index: 300;
    will-change: transform;


    &__inner {
        padding: 0;
        height: 5.4rem;
        display: flex;
        justify-content: space-between;
        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }
        align-items: center;
    }

    &__btn {
        text-decoration: none;
        display: grid;
        align-self: end;
        margin-bottom: 0.8rem;

        .site-header__indicators-count  {
            top: -5px;
        }


        &._open {
            .sticky-footer__icon {
                display: none;
                &._close {
                    display: block;
                    stroke: var.$color-main;
                }
            }
        }

    }

    &__btn:nth-child(1) &__btn-label {
        margin-top: 0.5rem;
    }

    &__btn-label {
        margin-top: 0.3rem;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.522rem;
        color: var.$color-main;
    }

    &__icon {
        display: grid;
        place-self: center;
        &._close {
            display: none;
        }
    }

    // TODO: q1 (where close-icon in figma?)
    // &__close-icon {
    //     width: 23.5px;
    //     height: 15.5px;
    //     stroke: #fff;
    // }
}
