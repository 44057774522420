@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-columns-v1 {
    &__row {
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        -webkit-flex-direction: row;
        flex-direction: row;
        width: 100%;
        gap: 2rem;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            -webkit-flex-direction: column;
            flex-direction: column;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            -webkit-flex-direction: row;
            flex-direction: row;
        }
    }

    &__col {
        width: calc(50% - 1rem);

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            width: 100%;
        }

        @include mix.breakpoint(var.$tablet-h-width) {
            width: calc(50% - 1rem);
        }
    }
}

/** DEPENDS CODE */
.questionnaire-v1 {
}

/** \DEPENDS CODE */
