@use 'base/variables' as var;
@use 'base/mixins' as mix;

.news {
    margin-bottom: 8rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 11rem;
    }

    .link-set {
        margin-bottom: 4.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6rem;
        }
    }

    .pagination {
        margin-top: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 6rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-top: 6.7rem;
        }
    }

    &__list {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            margin-top: -4rem;
            margin-right: -2rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-top: -5rem;
        }

        .list-view-pager {
          width: 100%;
        }

    }

    &__item {
        margin-bottom: 5rem;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 2 - 2rem);
            margin-right: 2rem;
            margin-top: 4rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(100% / 3 - 2rem);
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: calc(100% / 4 - 2rem);
            margin-top: 5rem;
        }

        &:hover {
          .news__title span {
            border-bottom: 1px solid currentColor;
          }
        }
    }
    /*.active{
        background-color: var.$color-main;
        color: White;
    }*/

    &__link {
        text-decoration: none;
        color: var.$color-text;
    }

    &__category-link {
        // display: inline-block;
        text-decoration: none;
        color: var.$color-text;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    &__link-wrap {
        margin-bottom: 1rem;
        line-height: 1.2rem;
    }

    &__img {
        display: block;
        border-radius: 4px;
        width: 100%;
        margin-bottom: 2rem;
    }

    &__title {
        margin: 0;
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.5rem;
        border-bottom: 1px solid transparent;
        transition: border-color .3s;
    }

    &__text {
        margin: 0;
        color: #818DA1;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    &__filter-wrap {
      margin-bottom: 3rem;

      .sort-filter {
        max-width: 20rem;
      }
    }
}
