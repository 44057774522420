@use 'base/variables' as var;
@use 'base/mixins' as mix;

.checkbox-v1 {

    &__wrap {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(3, min-content);
        grid-template-rows: 2rem;
        grid-template-areas: "checkbox label tooltip";

        img, svg {
            vertical-align: text-top;
        }
    }

    &_disabled &__checkbox,
    &_disabled &__label
    {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &__checkbox {
        grid-area: checkbox;
        -webkit-appearance: none;
        border: 1px solid var.$color-border-v2;
        padding: 0.9rem;
        border-radius: 0.3rem;
        display: inline-block;
        position: relative;
        cursor: pointer;
        max-height: 2rem;

        &:checked {
            background-color: var.$color-main;
            border: 1px solid var.$color-main;
            background-repeat: no-repeat;
            // TODO[themeProvider]: common icons without SVG sprite
            background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogICAgPHBhdGggZD0iTTIuOTM5MDIgNS4yMTk5NEwwLjg4Mjg5MSAzLjE0NzJMMCA0LjAzNzIzTDIuOTM5MDIgN0w5IDAuODkwMDI4TDguMTE3MTEgMEwyLjkzOTAyIDUuMjE5OTRaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
            background-size: 0.9rem 0.7rem;
            background-position: 0.5rem 0.5rem;
        }

    }

    &__label {
        grid-area: label;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var.$color-main-tone7;
        padding-left: 1.2rem;
        padding-right: 0.4rem;
        white-space: nowrap;
        cursor: pointer;
        display: flex;
    }

    &__colorbox {
        display: inline-block;
        border-bottom: 2px solid var.$color-white;
        text-decoration: none;
        margin-right: 1rem !important;
        width: 1.5rem !important;
        height: 1.5rem !important;
        padding-top: 0.2rem !important;
        padding-bottom: 0 !important;

        div {
            border-radius: 50%;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &__tooltip {
        cursor: pointer;
        grid-area: tooltip;
    }

    &__tooltip-icon {
        width: 1.4rem;
        height: 1.4rem;
    }
    
}