@use 'base/variables' as var;
@use 'base/mixins' as mix;

table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 0.2rem;
    border: 0.1rem solid var.$color-gray-tone18;

    tbody {
        display: table-row-group;
        vertical-align: middle;
        unicode-bidi: isolate;
        border-color: inherit;
    }

    tr {
        display: table-row;
        vertical-align: inherit;
        unicode-bidi: isolate;
        border: 0.1rem solid var.$color-gray-tone18;
    }

    th {
        display: table-cell;
        vertical-align: inherit;
        font-weight: bold;
        text-align: center;
        unicode-bidi: isolate;
        border: 0.1rem solid var.$color-gray-tone18;
    }

    td {
        display: table-cell;
        vertical-align: inherit;
        unicode-bidi: isolate;
        border-collapse: separate;
        border: 0.1rem solid var.$color-gray-tone18;
    }
}