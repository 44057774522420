@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-gallery-v1 {

    width: 100%;
    max-width: var.$container-width;
    overflow-x: hidden;
    margin-bottom: 6rem;

    &__category-list {
        display: flex;
        // max-width: 100%;
        margin-bottom: 2rem;
        margin-right: -0.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            //max-width: 48%;
            margin-right: -1.5rem;
            // margin-bottom: 0;
            line-height: 4rem;
            margin-bottom: -1rem;
        }

        @media (max-width: (var.$tablet-width - 1)) {
            margin-right: -1.5rem;
            max-width: 100%;
            overflow-x: scroll;
            padding-left: 0rem;

            .product-gallery-v1__category-link {
                padding: 1.1rem;
            }
            .product-gallery-v1__category-item:last-child {
                padding-right: 1.5rem;
                margin-right: 0 !important;
            }
        }
    }

    &__category-item {
        margin-right: 0.6rem;
        flex-grow: 1;
        transition: all .3s;
        &._active {
            .product-gallery-v1__category-link {
                background-color: var.$color-text;
            }

            .product-gallery-v1__category-link {
                color: var.$color-white;
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            flex-grow: 0;
            margin-right: 1.5rem;
        }
    }
    
    &__category-link {
        width: 100%;
        display: block;
        background-color: #F5F7FA;
        border-radius: 4px;
        color: var.$color-text;
        font-size: 1.4rem;
        line-height: 1.2;
        text-align: center;
        text-decoration: none;
        // padding-top: 0.9rem;
        // padding-bottom: 0.9rem;
        padding: 1.1rem 0.6rem;
        transition: all .3s;
        @include mix.breakpoint(var.$tablet-width) {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            font-size: 1.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            &:hover {
                background-color: var.$color-main;
                color: var.$color-white;
            }

        }
    }

    &__slider-element {
        position: relative;
    }

    &__head .header-v1_lvl-type-2 {
        
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 4rem;
        }

        margin-bottom: 0rem;
        font-weight: 700;
        align-items: center;

    }

    &__head &__nav-group {

        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }

        display: grid;
        grid-template-columns: min-content min-content;
        column-gap: 2rem;

    }

    &__head &__nav-group .nav-v2 {
        justify-items: center;
        align-items: center;
        cursor: pointer;
        border-radius: 0.4rem;
        width: 4rem;
        height: 4rem;
    }

    &__head {
        display: grid;
        grid-template-columns: auto min-content;
        margin-bottom: 2rem;
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            grid-template-columns: 1fr;
        }

    }

    &__body {
        position: relative;
    }

    &__body .nav-v2 {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            display: none;
        }
    }

    &__body .nav-v2 {
        width: 1.6rem;
        height: 2.8rem;
        position: absolute;
        top: calc(50% - (1.6rem / 2));
    }

    &__body .nav-v2:last-child {
        right: -4.7rem;
    }

    &__body .nav-v2:first-child {
        left: -4.7rem;
    }

    &__track {
        display: flex;

        // > * {

        //     margin-right: 2rem;
        //     @include mix.breakpoint(var.$desktop-lg-width) {
        //         margin-right: 3rem;
        //     }

        //     @include mix.breakpointRange(var.$zero-width, var.$mobile-lg-width) {
        //         width: 100%;
        //         min-width: 100%;
        //         max-width: 100%;
        //     }
        //     @include mix.breakpointRange(var.$mobile-lg-width, var.$desktop-width) {
        //         width: calc(50% - 2rem);
        //         min-width: calc(50% - 2rem);
        //         max-width: calc(50% - 2rem);
        //     }
        //     @include mix.breakpointRange(var.$desktop-width, var.$desktop-lg-width) {
        //         width: calc(33.3% - 2rem);
        //         min-width: calc(33.3% - 2rem);
        //         max-width: calc(33.3% - 2rem);
        //     }
        //     @include mix.breakpoint(var.$desktop-lg-width) {
        //         width: calc(25% - 3rem);
        //         min-width: calc(25% - 3rem);
        //         max-width: calc(25% - 3rem);
        //     }
        // }
    }

    &__slider-element-wrap {
        // не нужно использовать
        // height: 100%; для child-элементов
        // parent-элемента с display: flex!
        // подробности см.:
        // https://stackoverflow.com/a/46956430/27339616
        margin-right: 1rem;
    }
    &__slider-element-wrap &__slider-element {
    }
    
}

/** DEPENDS CODE */
.product-gallery-v1 {

    &__head {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-width) {
            .title-v2 {
                text-align: center;
                margin-bottom: 2rem;
            }
        }
    }

    &__track .slick-list {
        padding: 1rem 0rem !important;
        overflow: hidden;
    }

    &__track .slick-track {
        display: flex !important;
        align-items: stretch !important;
    }

    &__slider-element-wrap.slick-slide {
        height: inherit !important;
    }

    &__slider-element.product-card-v2 {
        height: 100%;
    }

    &__slider-element.product-card-v2 .page-preloader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    
    &__slider-element.product-card-v2 .page-preloader img {

    }

    .product-card-v2 .real-modifier-switcher-v1 .select-v2 .select2 {
        width: 100% !important;
    }
}

/** \DEPENDS CODE */
