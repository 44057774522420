@use 'base/variables' as var;
@use 'base/mixins' as mix;

.search-bar {

    &__logo-place {
        grid-area: sb-logo;
        display: grid;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            grid-template-areas: "lp-menu-subplace lp-logo-subplace lp-search-subplace";
            grid-template-columns: min-content auto min-content;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-template-areas: "lp-menu-subplace lp-logo-subplace";
            grid-template-columns: min-content auto;
            grid-column: 1 / 6;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-template-areas: "lp-logo-subplace";
            grid-column: 1 / 3;
        }
    }

    &__menu-subplace {
        @include mix.breakpoint(var.$tablet-h-width) {
            display: none;
        }
        grid-area: lp-menu-subplace;
        width: 1.8rem;
        height: 1.8rem;
        justify-self: start;
        align-self: center;
        cursor: pointer;
    }

    &__search-subplace {
        @include mix.breakpoint(var.$tablet-width) {
            display: none;
        }
        grid-area: lp-search-subplace;
        width: 1.8rem;
        height: 1.8rem;
        justify-self: end;
        align-self: center;
        cursor: pointer;
    }

    &__logo-subplace {
        grid-area: lp-logo-subplace;
        align-self: center;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
            width: 12.5rem;
            height: 2.4rem;
            justify-self: center;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            width: 13.8rem;
            height: 2.7rem;
            justify-self: center;
        }
        @include mix.breakpointRange(var.$tablet-h-width, (var.$desktop-notebook-width - 1)) {
            width: 16rem;
            height: 3.1rem;
            justify-self: start;
        }
        @include mix.breakpoint(var.$desktop-notebook-width) {
            width: 19rem;
            height: 3.6rem;
            justify-self: start;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
    }

    &__search-place {
        grid-area: sb-search;
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            display: none;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-column: 3 / 9;
        }
        justify-self: center;
        align-self: center;

        & .search-form {
            width: 100%;
        }

        & .search-form__input {
            padding-left: 5rem;
            padding-top: 1.1rem;
            font-size: 1.2rem;
            font-weight: 400;
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {
                max-width: 0rem;
            }
            @include mix.breakpointRange(var.$tablet-h-width, (var.$desktop-width - 1)) {
                max-width: 35rem;
                min-width: 35rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                max-width: 46rem;
                min-width: 46rem;
            }
        }

        & .search-form__input::placeholder {
            color: #818DA1;
        }

    }

    &__action-place {
        grid-area: sb-action;
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            grid-column: 6 / 11;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            grid-column: 9 / 11;
        }
        justify-self: end;
        align-self: center;
        
        & .site-header__indicators-item:nth-child(1) {
            display: none;
            @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
                display: block;
            }
        }

    }
}