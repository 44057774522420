@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-info-v4 {
    display: flex;
    gap: 3.2rem;

    &__container {
        flex-grow: 1;
    }
}

/** DEPENDS CODE */
.delivery-info-v4 {
    .title-v2 {
        font-size: 2.1rem;
        font-weight: 700;
        line-height: 2.5rem;
        margin-bottom: 0.8rem;
    }

    p {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2rem;
    }
}

/** \DEPENDS CODE */

