@use 'base/variables' as var;
@use 'base/mixins' as mix;

/* critical:start */
.visually-hidden,
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden,
input[type="file"].visually-hidden,
select.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

// только на одном разрешении
@media(max-width: var.$tablet-width - 1) {
    ._tablet-only:not(._mobile-only),
    ._desktop-only:not(._mobile-only) {
        display: none;
    }
}

@media(min-width: var.$tablet-width) and (max-width: var.$desktop-width - 1) {
    ._mobile-only:not(._tablet-only),
    ._desktop-only:not(._tablet-only) {
        display: none;
    }
}

@media(min-width: var.$desktop-width) {
    ._mobile-only:not(._desktop-only),
    ._tablet-only:not(._desktop-only) {
        display: none;
    }
}

._no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

// слайдер по ширине девайса на мобилке и планшете

._slider-out-container {
    @media(max-width: var.$desktop-width - 1) {
        width: calc(100% + #{var.$container-padding-mobile} * 2);
        margin-left: -#{var.$container-padding-mobile};
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% + #{var.$container-padding-tablet} * 2);
            margin-left: -#{var.$container-padding-tablet};
        }
        .slick-track {
            margin-left: var.$container-padding-mobile;
            @include mix.breakpoint(var.$tablet-width) {
                margin-left: var.$container-padding-tablet;
            }
        }
    }
}

._slider-overflow-wrap {
    overflow: hidden;
    .slick-list {
        overflow: initial;
    }
}
/* critical:end */