@use 'base/variables' as var;
@use 'base/mixins' as mix;

.card-detail {
    @include mix.breakpoint(var.$desktop-width) {
        //margin-bottom: 13rem;
        margin-bottom: 8rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        //margin-bottom: 14rem;
        margin-bottom: 12rem;
    }

    &__inner {
        padding-bottom: 2rem;
        border-bottom: 1px solid #ebf0f5;
        @include mix.breakpoint(var.$tablet-width) {
            padding-bottom: 0;
            border-bottom: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            border-top: 1px solid #ebf0f5;
            padding-top: 4rem;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 2.3rem;
        line-height: 1.1;
        color: #000;
        margin-top: 0;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 3rem;
            margin-bottom: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 3.2rem;
        }
    }

    &__about {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1.8rem;
        @include mix.breakpoint(var.$desktop-width) {
            padding-bottom: 0;
            margin-bottom: 4rem;
        }
    }


    &__set-number {
        width: 100%;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #a5a5a5;
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            margin-right: 4rem;
            margin-bottom: 0;
        }
    }

    &__rating {
        padding-right: 0.6rem;
        border-right: 1px solid #ebf0f5;
        display: flex;
        align-items: center;

        .star__item {
            line-height: 0;
        }
    }

    &__rating-text {
        color: #383838;
        font-size: 1.4rem;
        line-height: 1.1;
        margin-left: 1rem;
        font-weight: 700;
    }

    &__review {
        padding: 0 0.6rem;
        border-right: 1px solid #ebf0f5;
        text-decoration: none;
        color: var.$color-main;
        font-size: 1.4rem;
        line-height: 1.1;
    }

    &__orders {
        padding-left: 0.6rem;
        font-size: 1.4rem;
        line-height: 1.1;
        color: rgba(60, 60, 112, 0.4);
    }


    &__row {

        grid-auto-rows: auto min-content;
        /*@include mix.breakpoint(var.$tablet-width) {
            display: grid;
            // grid-template-columns: 1fr 1fr;
            grid-template-columns: minmax(34.5rem, 1.5fr) 1fr;
            grid-template-areas:
            "sl pop"
            "cont cont";
            grid-column-gap: 2rem;
            grid-row-gap: 5rem;
            // display: flex;
            // width: 100%;

            align-items: flex-start;
        }*/
        @include mix.breakpoint(var.$desktop-sm-width - 1) {
            display: grid;
            //grid-template-columns: minmax(56rem, 1.5fr) 1fr;
            grid-template-columns: auto 42rem;
            // flex-wrap: wrap;
            // grid-template-columns: minmax() 1fr;
            grid-template-areas:
                "sl pop"
                "sl cont";
            grid-column-gap: 4rem;
            // display: flex;
            // align-items: flex-start;
        }

        // @include mix.breakpoint(var.$desktop-sm-width) {

        // }

        /*@include mix.breakpoint(var.$desktop-laptop-width) {
                grid-template-columns: 80rem auto;
            grid-column-gap: 4rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            grid-template-columns: 95rem auto;
            // grid-column-gap: 9rem;
        }*/

    }

    &__left {
        padding-top: 2.5rem;
        padding-bottom: 2rem;
        position:relative;
        // border-bottom: 1px solid #ebf0f5;
        border-top: 1px solid #ebf0f5;
        min-width: 0;
        min-height: 0;
        // margin-bottom: 4rem;
        cursor: pointer;
        @include mix.breakpoint(var.$tablet-width) {
            border-top: 0;
            grid-area: sl;
            min-width: 34.5rem;
            // margin-right: 2rem;
            border: 0;
            padding-top: 0;
            padding-bottom: 0;
            // margin-bottom: 2rem;
            // max-width: 34.5rem;
            // margin-left: auto;
            // margin-right: auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            position: sticky;
            top: 2rem;
            // flex-grow: 1;
            // width: 40%;
            margin-bottom: 0;
            // width: 65rem;
            // padding-top: 0;
        }

        @include mix.breakpoint(var.$desktop-lg-width) {
            // flex-grow: 0;
            // margin-left: 0;
            // width: 82.7rem;
        }
    }

    &__right {
        @include mix.breakpoint(var.$tablet-width) {
            grid-area: cont;
            display: flex;
            flex-direction: column;
            margin-top:3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            // margin-left: 40%;
            // width: 52.2rem;
            // margin-left: 10.6rem;
        }
    }



    &__tablet-right {
        display: flex;
        flex-direction: column;

        @include mix.breakpoint(var.$tablet-width) {
            padding-top: .7rem;
            grid-area: pop;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            padding-top: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            // width: 40%;
        }
    }

    &__popular-size {
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0;
        }
    }

    &__extra {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-bottom: 3rem;
            padding-bottom: 3rem;
            border-bottom: 1px solid #ebf0f5;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin-bottom: 0;
            padding-bottom: 0;
            border: 0;
        }
    }

    &__img-list {
        visibility: hidden;
    }

    &__img-slide {
        position: relative;
        outline: none;
        // margin: 0;

        iframe {
          width: 100%;
          height: 100%;
          min-height: 30rem;
          @include mix.breakpoint(var.$tablet-width) {
            min-height: 30rem;
            margin-top: 7rem;
          }
          @include mix.breakpoint(var.$desktop-width) {
            min-height: 44rem;
          }
        }

        &._youtube {
          .card-detail__video-icon {
            svg {
              width: 64px;
              height: 50px;
            }
          }
          .youtube-preview {
            min-height: 30rem;
            @include mix.breakpoint(var.$tablet-width) {
              min-height: 50rem;
            }
          }
        }
    }

    &__img {
        width: 100%;
    }

    &__main-nav-next,
    &__main-nav-prev {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: WHITE;
        width: 3rem;
        border-radius: 3rem;
        height: 3rem;
        padding: 0;
        border: 0;
        z-index: 10;
        //background-color: transparent;
        outline: none;
        /*@include mix.breakpoint(var.$desktop-width) {
            display: none;
        }*/
        svg {
            width: 1.8rem;
            height: 1.8rem;
            stroke: var.$color-main;
        }

    }


    &__main-nav-next {
        right: .5rem;
    }
    &__main-nav-prev {
        left: .5rem;
    }

    &__labels {
        position: absolute;
        top: 1.5rem;
        left: 1.6rem;
        display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            top: 2.5rem;
            left: 2.5rem;
        }
    }

    &__like {
        position: absolute !important;
        top: 2.5rem;
        right: 2.6rem;
        display: none;
        @media(max-width: var.$tablet-width - 1) {
          display: block;
        }
        @include mix.breakpoint(var.$tablet-width) {
            top: 2.5rem;
            left: 2.5rem;
        }
        @media (hover: hover) {
          &:hover .card__like-icon {
            stroke: #F46B5F;
            fill: #F46B5F;
          }
        }
        &._active {
          .card__like-icon {
            stroke: #F46B5F;
            fill: #F46B5F;
          }
        }
    }

    &__sale,
    &__gift,
    &__hit {
        margin-right: 1rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 6rem;
        min-height: 3.8rem;
        padding: 0.5rem;
    }

    &__sale {
        background-color: var.$color-text;
        color: #fff;
        font-family: "Affect";
        font-weight: 700;
        font-size: 1.9rem;
        line-height: 2.5rem;
        padding-bottom: 0.8rem;
    }

    &__gift {
        background: #f5f7fa;
        padding-bottom: 0.8rem;
    }

    &__gift-icon {
        width: 2.8rem;
        height: 2.8rem;
    }

    &__hit {
        background: var.$color-second;
        font-size: 1.3rem;
        line-height: 1.3rem;
        font-weight: 500;
    }

    &__main-slider {
        position: relative;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            // margin-bottom: 2.5rem;
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-bottom: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-bottom: 2.5rem;
        }

        .slick-slide {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            max-height: 740px
        }

        .slick-list {
            margin: 0 -1.5rem;
        }
    }

    &__main-nav {
        display: none;
        @include mix.breakpoint(var.$tablet-width) {
            display: block;
        }
    }

    &__nav-prev,
    &__nav-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        background-color: transparent;
        padding: 1rem 1.5rem;
        outline: none;
    }

    &__nav-prev {
        left: 0;
    }

    &__nav-next {
        right: 0;
    }

    &__nav-icon {
        width: 1rem;
        height: 2rem;
        stroke: var.$color-main;
        transition: all .3s;
    }

    &__thumbnails {
        display: none;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: flex;
        }

        &._no-transform {
            .slick-track {
              transform: none !important;
            }
        }

        &._no-transform-5 {
          .slick-track {
            @include mix.breakpoint(1600px) {
              transform: none !important;
            }
          }
        }

        // .slick-list {
        //     margin-right: -1rem;
        // }
    }

    &__thumbnails-list {
        display: flex;
        flex-grow: 1;
        //visibility: hidden;
        // flex-wrap: wrap;
        min-width: 0;
        min-height: 0;
        overflow: hidden;
        @include mix.breakpoint(var.$tablet-width) {
            // margin-right: 0;
            overflow: initial;
        }
        @include mix.breakpoint(var.$desktop-width) {
        }
        .slick-slide {
            border: 1px solid transparent;
        }

        .slick-current {
            border-color: var.$color-main;
            // border: 1px solid var.$color-main;
        }

        .slick-list {
            margin-left: -.5rem;
            margin-right: -.5rem;
            @include mix.breakpoint(var.$desktop-width) {
                margin: 0;
            }
        }
    }

    &__thumbnail-item {
        // width: 5.8rem;
        // width: calc((100vw - 3rem) / 5 - .8rem);
        // height: 6.2rem;
        // overflow: hidden;
        padding: 1px;
        border: 1px solid var.$color-border;
        margin-right: .5rem;
        margin-left: .5rem;
        // @include mix.breakpoint(var.$tablet-width) {
        //     width: calc((100vw - 9.3rem) / 5 - 2rem);
        //     // height: auto;
        //     margin-right: 2rem;

        // }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2rem;
            margin-left: 0;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            margin-right: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 2.8rem;
        }

        &._video {
          position: relative;
        }
    }

    &__thumbnail-link {
        outline: none;
        display: block;

        img {
            width: 100%;
        }
    }

    &__video-icon {
      position: absolute;
      top: calc(50% - 14px);
      left: calc(50% - 20px);
      z-index: 1;

      svg {
        width: 40px;
        height: 28px;
      }
    }

    &__subtitle {
        margin: 0;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 2rem;
        display: flex;
        align-items: center;
    }

    &__otherColor {
        //display: inline-flex;
        &__value{
            font-weight: bold;
            margin-left: 0.5rem;
        }
        &>div{
            margin-bottom:1rem;
        }
    }

    &__colorbox {
        margin-right: 20px;
        display: inline-block;
        border-bottom: 2px solid White;
        padding-bottom: 0.5rem;


        div {
            width: 25px;
            height: 25px;
            border-radius: 50%;

        }
        &._current{
            /*border-color: var.$color-main;
            border-width: 2px;
            border-style: solid;*/
            border-bottom: 2px solid var.$color-main;
            padding-bottom: 0.5rem;
        }

    }

    &__btn-row {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: center;
        }
    }

    &__extra-item {
        margin-bottom: 1.7rem;
        border-bottom: 1px solid #EBF0F5;
        padding-bottom: 2rem;

        &._expanded {
            .additional-good__list {
                display: block;
            }

            .card-detail {
                &__change-link,
                &__extra-name {
                    opacity: 0;
                    visibility: hidden;
                    // max-height: 0;
                }
            }
        }

        &._empty {
            .card-detail {
                &__extra-name {
                    opacity: 0;
                    visibility: hidden;
                    max-height: 0;
                }

                &__extra-value {
                    margin-bottom: 0;
                }
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 3.8rem;
            border-bottom: none;
            width: 29rem;
            margin-bottom: 0;
            padding-bottom: 0;
            flex-grow: 1;
            &:nth-child(even) {
                margin-right: 0;
                width: 40rem;
            }
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 100%;
            margin-bottom: 3rem;
            padding-bottom: 3rem;
            border-bottom: 1px solid #EBF0F5;
            &:nth-child(even) {
                width: auto;
            }
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__extra-value{
        display: flex;
        align-items: center;
        margin-bottom: 1.4rem;

        .card-detail__subtitle {
            font-size: 1.4rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            .card-detail__subtitle {
                font-size: 1.6rem;
                margin-right: 1rem;
            }
        }
    }

    &__extra-name {
        margin: 0;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1;
        color: #383838;
        display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
        }
    }

    &__extra-price {
        padding-left: 2rem;
        margin-left: auto;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 0;
            padding-left: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: auto;
            padding-left: 2rem;
        }
    }

    &__change-link {
        margin-left: auto;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: var.$color-main;
        text-decoration: none;
        transition: all .2s;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 1.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-left: auto;
            align-self: flex-end;
        }
    }

    &__price-down {
      margin-bottom: 1.6rem;
        display: block;
      a {
          font-size: 1.4rem;
          color: var.$color-text;
          //text-decoration: none;
          //border-bottom: 2px dotted #3c3c70;
          @include mix.breakpoint(var.$tablet-width) {
              font-size: 1.6rem;
          }
      }
    }

    &__size-popup {
        .form {
            &__attach-btn {
                color: var.$color-main;
                @include mix.breakpoint(var.$tablet-width) {
                    flex-grow: 1;
                    margin-right: 3.3rem;
                }
            }
        }
    }

    &__one-click {
        .form {

            &__input-item {
                &._name {
                    @include mix.breakpoint(var.$tablet-width) {
                        width: 29.5rem;
                        margin-right: 2rem;
                    }
                }
                &._phone {
                    @include mix.breakpoint(var.$tablet-width) {
                        margin-right: 0;
                    }
                }
                &._comment textarea {
                    height: 12rem;
                }
            }
        }
    }

    &__feedback {
        @include mix.breakpoint(var.$desktop-width) {
            width: 65rem;
        }

        .popup {
            &__title {
                @include mix.breakpoint(var.$desktop-width) {
                    font-size: 3.5rem;
                    // text-align: center;
                }
            }
        }

        .form {

            &__input-item {
                &._name {
                    @include mix.breakpoint(var.$tablet-width) {
                        width: 29.5rem;
                        margin-right: 2rem;
                    }
                }
                &._phone {
                    @include mix.breakpoint(var.$tablet-width) {
                        margin-right: 0;
                    }
                }
                &._comment textarea {
                    height: 12rem;
                }
            }
        }
    }

    &__credit {
        grid-area: credit;
        @include mix.breakpoint(var.$tablet-width) {
            width: 100%;
            display: block;
            margin-top: 3.4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin-top: auto;
        }
    }

    .slider__nav-list {
        display: none;
        @include mix.breakpoint(var.$desktop-width) {
            position: static;
            display: flex;
            flex-direction: column;
            margin-right: 0.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 0;
        }
    }


    .slider__nav-prev-new,
    .slider__nav-next-new {
      width: 2.4rem;
      min-height: 5rem;
      transition: all .3s;
      background: inherit;
      border: none;

      @media(hover:hover) {
        &:hover {
          .slider__nav-icon {
              stroke: var.$color-text;
          }
        }
      }
    }
    .slider__nav-prev-new {
      margin-right: 0.6rem;
    }
    .slider__nav-next-new {
      margin-left: 0.6rem;
    }


    .slider__nav-prev,
    .slider__nav-next {
        width: 4.8rem;
        height: 4.8rem;
        transition: all .3s;
        @media(hover:hover) {
            &:hover {
                background-color: var.$color-main;
                .slider__nav-icon {
                    stroke: #fff;
                }
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 4rem;
            height: 4rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 4.8rem;
            height: 4.8rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 5.8rem;
            height: 5.8rem;
        }
    }

    .slider__nav-prev {
        margin-top: auto;
        margin-right: 0;

    }

    .slider__nav-next {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .info-tooltip {
        color: var.$color-main;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 1rem;
    }


    &._quickview {
      margin-bottom: 0;

      & > .container {
        padding: 0;
      }

      .card-detail__row {
          @include mix.breakpoint(var.$desktop-width) {
              display: grid;
              grid-template-columns: 30rem 1fr;
              // flex-wrap: wrap;
              // grid-template-columns: minmax() 1fr;
              grid-template-areas:
                "sl pop"
                "cont cont";
              grid-column-gap: 7rem;
              // display: flex;
              // align-items: flex-start;
          }
      }

        .card-detail__extra {
            @include mix.breakpoint(var.$tablet-h-width) {
                display: block;
                margin-bottom: 0;
                padding-bottom: 0;
                border: 0;
            }
        }


      .card-detail__left {
        position: relative;

          @include mix.breakpoint(var.$desktop-width) {
              // position: sticky;
              // top: 8rem;
              margin-bottom: 0;
              min-width: 30rem;
              max-width: 100%;
          }

      }

      .popular-size__btn-container {
        margin-bottom: 3rem;
      }

    }

    &__specialpromo{
        position: absolute;
        bottom: 3.5rem;
        right: 1.5rem;
        text-align: right;
        z-index:1;
        width:50px;
    }
    &__specialpromo-activity{
        border-radius: 3px;
        text-align: center;
        font-size: 1rem;
        padding: 0.5rem 0.5rem;
        margin: 0 auto;
    }
    &__specialpromo-icon{
        /*width: 7rem;
        height: 6rem;*/
        position: absolute;
    }

}
