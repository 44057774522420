@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tooltip-v1 {

    // display: flex;
    // flex-direction: row;
    // align-items: flex-start;
    // padding: 1.5rem;
    // gap: 1rem;
    
    // position: absolute;
    // width: 39.9rem;
    // height: 7rem;
    // left: 41.7rem;
    // top: 19.96rem;
    
    // background: var.$color-gray-tone4;
    // border-radius: 0.6rem;

    // .content {
    //     font-family: var.$font-text;
    //     font-weight: 400;
    //     font-size: 0.8rem;
    //     line-height: 0.96rem;
    //     color: var.$color-gray;
    // }
}

/** DEPENDS CODE */
.tooltip-v1 {


}
/** \DEPENDS CODE */