@use 'base/variables' as var;
@use 'base/mixins' as mix;

.rating-star-vote-v1 {
    display: flex;
    max-width: 37.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        justify-content: flex-start;
    }

    &__star-item {
        padding: 0 .5rem;
    }

    .rating-icon {
        display: block;
        width: 4rem;
        height: 4rem;
        stroke: var.$color-main;
        fill: transparent;

        @include mix.breakpoint(var.$tablet-width) {
            width: 2rem;
            height: 2rem;
        }

    }

    &__item-active, &__item-selected {
        .rating-icon {
            fill: var.$color-main;
        }
    }

    .rating-icon:hover {
        fill: var.$color-main;
    }


}

//Dependence
.review-modal-v1 {

}