@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sale-price-v1 {

    font-family: var.$font-text;
    color: var.$color-black-tone4;
    font-weight: 600;
    font-size: 4rem;

    &__no-price {
        font-size:1.2rem;
        color: var.$color-gray-tone6;
    }
}