@use 'base/variables' as var;
@use 'base/mixins' as mix;

.interior-card-v1 {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__link {
        position: relative;
        width: 100%;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            height: 18rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            height: 21rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            height: 33.8rem;
        }
        height: auto !important;
        position: relative;
        display: block;
    }

    &__image {
        width: 100%;
    }

    &__hover {
        display: none;
    }

    &__link:hover &__hover {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(var.$color-black-tone4, 0.5);
        cursor: pointer;
        left: 0;
        top: 0;
    }

    &__hover-icon {
        fill: none;
        stroke: #ffffff;
        position: absolute;
        top: calc(50% - 2.2rem);
        left: calc(50% - 2.2rem);
    }

}