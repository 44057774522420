@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tag-list-v12 {
    line-height: 4rem;

    &__content {
        display: flex;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-h-width - 1)) {

    }
}

// deps
.tag-list-v12 {
    .slick-slide > div {
        margin-right: 1.5rem;
    }
}
// \deps
