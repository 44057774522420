@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-v2 {
    line-height: 2.4rem;

    p {
        line-height: 2.4rem;
        font-size: 1.6rem;
        margin: 1.6rem 0;
    }

    h2 {
        line-height: 3.6rem;
        font-size: 2.4rem;
        font-weight: 700;
        margin: 2rem 0;
    }

    h3 {
        font-size: 1.872rem;
        line-height: 2.80833rem;
        font-weight: 700;
        margin: 1.872rem 0;
    }
}

// \TODO[dependСss]
.text-v2 {

}