@use 'base/variables' as var;
@use 'base/mixins' as mix;

.category-tile-element-v1 {

    width: calc(33.33333% - 1.4rem); /* 2 - 2/3 = 1.4rem */
    margin-right: 2rem;
    border-radius: 4rem 0 0 0;
    height: 16.7rem;
    overflow: hidden;
    position: relative;
    margin-top: 1.5rem;

    @include mix.breakpoint(var.$tablet-width) {
        &:nth-child(3n) {
            margin-right: 0rem;
        }
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        width: calc(50% - 1rem); /* 2 - 2/2 = 1rem */
        margin-right: 2rem;
        &:nth-child(2n) {
            margin-right: 0rem;
        }

        &:last-child:nth-child(odd) {
            width: 100%;
            margin-right: 0;
            .category-tile-element-v1__img {
                 right: 0rem;
            }
        }
    }


    &__link {
        display: flex;
        color: var.$color-text;
        height: 16.7rem;
        text-decoration: none;
        width: 100%;
    }

    &__text-wrap {
        display: flex;
        flex-direction: column;
        padding: 2.5rem .5rem 2.5rem 2rem;
    }

    &__img {
        position: absolute;
        right: 0rem;
        bottom: 0;
        z-index: 0;
        width: 30rem !important;
        height: 16.7rem;

         @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            right: -13rem;
            max-width: none;
        }

    }

    &__title {
        font-size: 2.3rem;
        font-family: var.$font-text;
        text-transform: uppercase;
        line-height: 1.1;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: auto;
        z-index: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.4rem;
        }
    }


    &__text {
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: rgba(60, 60, 112, 0.6);
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            display: none;
        }
    }

}

//deps
.category-tile-element-v1 {

}