@use 'base/variables' as var;
@use 'base/mixins' as mix;

.saving-money-v1 {

    font-family: var.$font-text;
    color: var.$color-main;
    font-size: 1.4rem;
    line-height: 1.8rem;

    &__label {
        color: var.$color-main;
        font-weight: 400;
    }

    &__value {
        color: var.$color-main;
        font-weight: 400;
    }

}