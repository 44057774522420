@use 'base/variables' as var;
@use 'base/mixins' as mix;

.product-card-v5 {

    width: 33.8rem;
    min-width: 33.8rem;
    max-width: 33.8rem;
    padding: 2rem;
    border: 0.1rem solid rgba(var.$color-text, 0.3);
    border-radius: 0.6rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content min-content min-content auto;

}

.product-card-v5.product-card-v5_filling-type-contained.product-card-v5_filling-color-type-border {
    background-color: var.$color-border;
    border-color: var.$color-border;
}


/** DEPENDS CODE */
.product-card-v5 {

    .header-v1 {

        &__label {
            font-size: 2rem; 
        }

        margin-bottom: 2rem;
    }

    .image-v1 {
        margin-bottom: 2rem;
        border: 0.1rem solid rgba(var.$color-text, 0.3);
        border-radius: 0.6rem;
    }

    .link-v1 {
        font-weight: 400;
        font-size: 1.6rem; 
        margin-bottom: 1.5rem;
    }

    .p-v1 {
        font-weight: 400;
        font-size: 1.2rem;
    }

    .action-group-v1 {
        align-self: end;
    }

    .action-group-v1 .button-v1 {
        padding: 1rem 12.85rem;
        font-weight: 600;
        font-size: 1.2rem;
    }

}

/** \DEPENDS CODE */
