@use 'base/variables' as var;
@use 'base/mixins' as mix;

.sale {
    margin-bottom: 6rem;
    .title {
        margin-bottom: 1.5rem;

        a {
          text-decoration: none;
          color: #fff;
        }
    }
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5rem;
    }

    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 6rem;
        .title {
            margin-bottom: 2.5rem;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 7rem;
    }

    &__inner.color-section {
        padding: 0;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            // align-items: flex-end;
        }
    }
    &__text {
        margin: 0;
        margin-bottom: 3rem;
        color: #fff;
        font-size: 1.3rem;
        line-height: 1.3;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.6rem;
            line-height: 1.5;
        }
    }

    &__content {
        padding: 2rem 1.5rem;
        padding-bottom: 3rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 3rem;
            display: flex;
            flex-direction: column;
            max-width: 50rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 3rem;
            width: 65rem;
            max-width: none;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
        }
    }

    &__btn {
        width: 16rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 18.7rem;
            margin-top: auto;
            font-size: 1.6rem;
            line-height: 2rem;
        }
    }

    .slider {
        @include mix.breakpoint(var.$tablet-width) {
        }



        @include mix.breakpoint(var.$tablet-width) {
            flex-shrink: 0;
            width: 34rem;
            align-items: flex-end;
            margin-left: auto;
        }

        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 52rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            width: 63.5rem;
        }

        @include mix.breakpoint(var.$desktop-laptop-width) {
            width: 60rem;
        }

        // @include mix.breakpoint(var.$desktop-lg-width) {
        //     width: 71rem;
        // }

        &__nav-list {
            z-index: 2;
            bottom: 2rem;
            @include mix.breakpoint(var.$tablet-width) {
                right: 3rem;
                bottom: 4rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                right: 4rem;
            }
        }

        .slick-dots {
            z-index: 2;
            bottom: 1.6rem;
            @include mix.breakpoint(var.$tablet-width) {
                right: 3rem;
                bottom: initial;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                right: 4rem;
            }
        }
    }
}
