@use 'base/variables' as var;
@use 'base/mixins' as mix;

.link-with-icon-v1 {

    display: grid;
    grid-template-columns: repeat(2, min-content);
    gap: 0.5rem;
    align-items: center;

    &__label {
        white-space: nowrap;
    }

    &_color-type-text {
        color: var.$color-text;
    }

    &_color-type-main {
        color: var.$color-main;
    }

    &_color-type-main:hover {
        color: var.$color-main-tone8;
    }

    &_color-type-secondary {
        color: var.$color-second;
    }

    &_color-type-secondary:hover {
        color: var.$color-second-tone6;
    }

    &_decoration-type-none {
        text-decoration: none;
    }

    &_decoration-type-underline {
        text-decoration: underline;
    }

}

.link-with-icon-v1:hover {
    color: inherit;
}