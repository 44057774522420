@use 'base/variables' as var;
@use 'base/mixins' as mix;

.showroom {
    margin-bottom: 3rem;
    .title {
        margin-bottom: 1.5rem;
        max-width: 20rem;
    }
    @include mix.breakpoint(var.$tablet-width) {
        .title {
            max-width: 30rem;
            margin-bottom: 3rem;
        }
        margin-bottom: 5.5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        .title {
            font-size: 4rem;
            max-width: 42rem;
            margin-bottom: 3rem;
        }
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        .title {
            max-width: 43rem;
        }
    }

    &__inner {
        background: #EBF0F5;
        border-radius: 4px;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;

        }
    }

    &__content {
        padding: 2rem 1.5rem;
        margin-bottom: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 4rem 3rem;
            padding-right: 3.3rem;
            margin-bottom: 0;
            max-width: 44.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 49.1rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 5rem 6rem;
            max-width: none;
        }
    }

    &__text {
        font-size: 1.3rem;
        line-height: 1.3;
        margin-top: 0;
        margin-bottom: 2.7rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            margin-bottom: 5.7rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 4rem;
            font-size: 1.6rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            max-width: 55rem;
            margin-bottom: 3.5rem;
        }
    }

    &__contacts {
        display: flex;
    }

    &__contact-wrap._address {
        width: 17rem;
        margin-right: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 18.2rem;
            margin-right: 3.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 23rem;
            margin-right: auto;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 6.7rem;
        }
    }

    &__contact-wrap._working-hours {
        width: 10.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 11.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 13.2rem;
        }
    }

    &__subtitle {
        font-size: 1.6rem;
        line-height: 1.3;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2.1rem;
            line-height: 2.7rem;
        }
    }

    &__contact-value {
        margin: 0;
        color: #87919C;
        font-size: 1.3rem;
        line-height: 1.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.4rem;
            line-height: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }

    .slider {
        @include mix.breakpoint(var.$tablet-width) {
            flex-shrink: 0;
            width: 34.1rem;
            margin-left: auto;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 52rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 62rem;
        }

        &__nav-list {
            left: initial;
            top: initial;
            right: 1.5rem;
            bottom: 2rem;
            @include mix.breakpoint(var.$tablet-width) {
                right: 3rem;
                bottom: 3rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                right: 3rem;
                bottom: 4rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                right: 5rem;
                bottom: 5rem;
            }
        }

        .slick-dots {
            @include mix.breakpoint(var.$tablet-width) {
                left: initial;
                top: initial;
                right: 4rem;
                top: 3rem;
            }
            @include mix.breakpoint(var.$desktop-max-width) {
                right: 5rem;
                top: 2.5rem;
            }
        }
    }
}
