@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-tooltip {
    background-color: transparent;
    border: 0;
    padding: 0;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    color: #fff;
    outline: none;

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
    &._dark {
        color: var.$color-main;
    }
}
