@use 'base/variables' as var;
@use 'base/mixins' as mix;

.exchange {
    margin-bottom: 5.5rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 8.5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.2rem;
    }

    &__top {
        margin-bottom: 3.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            margin-bottom: 6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 9rem;
        }
    }

    &__title,
    &__request-title {
        margin: 0;
        font-size: 2rem;
        line-height: 120%;
        letter-spacing: 0.015em;
        font-family: "Affect";
        font-weight: 700;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 3.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4.1rem;
            line-height: 112%;
        }
    }

    &__title {
        max-width: 16.5rem;
        color: #ffffff;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 17.5rem;
        }
    }

    &__title-wrap {
        margin-bottom: 1.5rem;
        padding-top: 2rem;
        padding-bottom: 2.8rem;
        padding-left: 1.5rem;
        background-image: url("/img/maskot/sad-purple.svg");
        background-repeat: no-repeat;
        background-size: 8.6rem 7.6rem;
        background-position: bottom right 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 43.2rem;
            flex-grow: 1;
            margin: 0;
            margin-right: 2rem;
            padding: 3rem 2rem;
            background-size: 14.4rem 12.8rem;
            background-position: bottom right 5.1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 6rem;
            background-size: 20rem 17.7rem;
            background-position: bottom right 11rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            background-position: bottom right 20rem;
        }
    }

    &__request {
        padding: 2rem 1.5rem;
        background-color: #F5F7FA;
        @include mix.breakpoint(var.$tablet-width) {
            width: 27.5rem;
            flex-grow: 1;
            max-width: 40rem;
            flex-shrink: 0;
            padding: 3rem 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 41.3rem;
            padding: 4rem 5rem;
            max-width: none;
            flex-grow: 0;
        }
    }

    &__request-title {
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 2rem;
        }
    }

    &__rule-title {
        max-width: 27rem;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: none;
        }
    }

    &__popup {
        .form__attach-btn {
            padding-top: 1.1rem;
            padding-bottom: 1.1rem;
            color: var.$color-main;
        }
    }

    .more {
        &__link {
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 500;
            font-family: "EuclidCircularA", Arial, sans-serif;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }

        &__link-icon {
            height: 1rem;
            margin-left: 2px;
        }
    }

    // mix
    &__important-title {
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 3rem;
        }
    }

    &__important {
        margin-bottom: 4.8rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding-left: 1.8rem;
            padding-right: 1.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding-left: 5rem;
            padding-right: 6.5rem;
            padding-bottom: 4rem;
        }
    }

    &__border-section {
        margin-bottom: 4rem;
    }
}
