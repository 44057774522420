@use 'base/variables' as var;
@use 'base/mixins' as mix;

.input-radio-v4 {

    width: 100%;
    padding-left: 2.8rem;
    position: relative;

    &__label {
        font-family: var.$font-text;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    &__label-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        user-select: none;
    }

    &__radio-mark {
        font-size: 1.8rem;
        line-height: 2.2rem;
        font-family: var.$font-text;
        font-weight: 600;
        margin-left: auto;
    }

    &__input {
    }

    &__input:checked::before {
        background-image: url('data:image/svg+xml;utf8,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="11" fill="%237B75C9"/><path d="M9.89551 15.9363C9.5651 15.9363 9.28662 15.797 9.06006 15.5186L6.17847 11.929C6.08879 11.8204 6.02507 11.7166 5.9873 11.6174C5.94954 11.5136 5.93066 11.4074 5.93066 11.2988C5.93066 11.0581 6.0109 10.8599 6.17139 10.7041C6.33187 10.5436 6.53719 10.4634 6.78735 10.4634C7.06584 10.4634 7.29948 10.5837 7.48828 10.8245L9.86719 13.8831L14.4692 6.56226C14.5778 6.40177 14.6887 6.28849 14.802 6.22241C14.9153 6.15161 15.0569 6.11621 15.2268 6.11621C15.4722 6.11621 15.6729 6.19409 15.8286 6.34985C15.9891 6.5009 16.0693 6.69678 16.0693 6.9375C16.0693 7.0319 16.0528 7.12866 16.0198 7.22778C15.9915 7.3269 15.9419 7.43075 15.8711 7.53931L10.731 15.4973C10.5327 15.79 10.2542 15.9363 9.89551 15.9363Z" fill="white"/></svg>');
    }

    &__input::before {
        left: 0;
        top: 0.2rem;
        content: "";
        position: absolute;
        cursor: pointer;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 2.2rem;
        background-image: url('data:image/svg+xml;utf8,<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM11 20.5C16.2467 20.5 20.5 16.2467 20.5 11C20.5 5.75329 16.2467 1.5 11 1.5C5.75329 1.5 1.5 5.75329 1.5 11C1.5 16.2467 5.75329 20.5 11 20.5Z" fill="%237B75C9"/></svg>');
    }

    &__radio-label-icon {
        display: block;
    }

    &__radio-label {
        display: block;
        color: var.$color-main-tone7;
    }

}

/** DEPENDS CODE */
.input-radio-v4 {
    label {
        display: flex;
        width: 100%;
    }

    &, .form-group, label, input, input-radio-v4__label-block, input-radio-v4__radio-label {
        &:active, &:focus, &:focus-within {
            user-select: none;
            outline: none;
        }
    }
}

/** \DEPENDS CODE */