@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-info-v2 {
    &__item {

    }

    &__label {
        font-family: var.$font-text;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var.$color-black-tone4;
    }

    &__value {
        font-family: var.$font-text;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        text-decoration: none;

        a {
            color: var.$color-black-tone4;
            text-decoration: underline;
            font-weight: 600;
        }

        span {
            color: var.$color-main;
            text-decoration: none;
            font-weight: 600;
        }
    }
}

// Depends code
.delivery-info-v2 {
    .icon-v1 {
        margin-right: 2.4rem;
    }
}

// \Depends code