@use 'base/variables' as var;
@use 'base/mixins' as mix;

.header-v1 {

    color: var.$color-black-tone4;
    font-family: var.$font-header;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: end;

    &_lvl-type-1 {
        font-size: 4rem;
        font-weight: 700;
    }

    &_lvl-type-2 {
        font-size: 3rem;
        font-weight: 400;
    }

    
    &_lvl-type-4 {
        font-family: var.$font-text;
        font-weight: 600;
        font-size: 2.8rem;
    }
    
    &_lvl-type-5 {
        font-size: 1.8rem;
        font-weight: 400;
    }

    &__label {
        justify-content: end;
        color: var.$color-main;
        font-weight: 600;
        font-family: var.$font-text;
        align-content: end;
    }
}

/** DEPENDS CODE */
.header-v1 {

    .icon-v1 {
        margin-right: 2.1rem;

        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            margin-right: 1rem;
        }

    }

}
/** DEPENDS CODE */