@use 'base/variables' as var;
@use 'base/mixins' as mix;

.cart-order-payment-manager-v1 {
    
    background-color: var.$color-gray-tone12;
    padding: 3rem 2rem;
    border-radius: 0.4rem;
    margin-bottom: 2rem;

    &__payment-block {
        font-family: var.$font-text;
        font-weight: 700;
        font-size: 2rem;
        display: grid;
        grid-auto-flow: column;
        justify-content: left;
        margin-bottom: 1rem;
    }

    &__payment-title {
        color: var.$color-main;
    }

    &__payment-value {
        color: var.$color-text;
    }

}

/** Deps code */

.cart-order-payment-manager-v1 {

}

/** \Deps code */