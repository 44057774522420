@use 'base/variables' as var;
@use 'base/mixins' as mix;

.showroom-address-v2 {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.6rem;

    &__phone-wrap {
        order: 1;
        flex-grow: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            text-align: left;
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: 0.8rem;
        font-size: 2.1rem;
        font-weight: 700;
        font-family: var.$font-header;
        line-height: 2.5;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-weight: 400;
        }
    }

    &__phone {
        font-size: 1.8rem;
        font-weight: 400;
        display: block;
        line-height: 2.8rem;
        text-decoration: none;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 3rem;
            font-weight: 700;
            margin-top: 0.8rem;
        }
    }

    &__phone:hover {
        color: var.$color-text;
    }


    &__label-wrap {
        width: 4rem;
        order: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            order: 0;
        }
    }

    &__info {
        order: 1;
        max-width: 30rem;
    }


    &__schedule {
        order: 1;
        font-size: 2rem;
        width: 40rem;
        font-weight: 700;
        font-family: var.$font-header;
    }


    &__content {
        @media only screen and (min-width: var.$tablet-width) {
            flex-direction: row;
        }

        flex-grow: 1;
        display: flex;
        flex-direction: column;

    }

    &__address {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.88rem;
        margin-bottom: 0.8rem;
    }

    &__to-map-btn {
        margin-top: 0.8rem;
        font-size: 1.8rem;
        color: var.$color-black-tone4;
        text-decoration: underline;
    }
}

/** DEPENDS CODE */
.showroom-address-v2 {
}

/** DEPENDS CODE */