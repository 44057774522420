@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-list-v1 {

    border: 2px solid var.$color-main;
    border-radius: .6rem;
    padding: 2rem;
    margin-bottom: 3.8rem;

    @include mix.breakpoint(var.$tablet-width) {
        position: sticky;
        top: 5rem;
        width: 27.5rem;
        max-width: 40rem;
        flex-grow: 1;
        max-width: 40rem;
        flex-shrink: 0;
        margin-bottom: 0;
        margin-left: auto;
    }
    @include mix.breakpoint(var.$desktop-width) {
        width: 41.3rem;
        flex-grow: 0;
        max-width: none;
        padding: 5rem;
        padding-right: 4.4rem;
        padding-top: 4rem;
    }

    .default {
        &__text {
            color: #474747;
        }
    }

    &__title {
        margin: 0;
        margin-bottom: 1.5rem;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
        max-width: 27rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
            max-width: none;
        }
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
            margin-bottom: 3rem;
        }
    }

    &__ordered-item {
        position: relative;
        padding-left: 2rem;
        margin-bottom: 2rem;
        font-size: 1.4rem;
        &::before {
            content: attr(data-marker);
            position: absolute;
            left: 0;
            top: 0;
            font-weight: 700;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin: 0;
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

}