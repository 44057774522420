@use 'base/variables' as var;
@use 'base/mixins' as mix;

.footer-seo-links-v1 {
    ul {
        margin-bottom: 1rem;
    }
    li {
        display: inline-block;
        margin-right: 1rem;
    }
}


/** DEPENDS CODE */
.footer-seo-links-v1 {

}

/** \DEPENDS CODE */

