@use 'base/variables' as var;
@use 'base/mixins' as mix;

.social {
    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        @include mix.breakpoint(var.$tablet-width) {
            width: 28rem;
            margin: 0 auto;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 1rem;
        }
    }

    &__link {
        color: #fff;
        text-decoration: none;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.3rem;
            line-height: 1.6rem;
        }

        svg {
          max-width: 2.8rem;
          max-height: 2.8rem;
          color: #fff;
        }
    }

    &__separator {
        transform: translateY(-3px);
    }


}
