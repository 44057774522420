@use 'base/variables' as var;
@use 'base/mixins' as mix;

.jumbotron-v3 {


}

// DEPENDS CODE
.jumbotron-v3 {
    @include mix.breakpoint(var.$desktop-width) {
        .title {
            margin-bottom: 7rem;
        }
    }

    @include mix.breakpoint(var.$desktop-lg-width) {
        .title {
            margin-bottom: 4rem;
        }
    }
}