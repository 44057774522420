@use 'base/variables' as var;
@use 'base/mixins' as mix;

.jumbotron-v1 {

    &__data-block-container {
        display: flex;
        margin-bottom: 3rem;
    }
    &__text {
        margin: 1.6rem 0;
    }
    &__special-label {
        display: inline-block;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: var.$color-text;
        padding: 0.6rem 1.2rem;
        margin: 0;
    }

    &__col-first {
        width: 23rem;
        margin-right: 6.7rem;
    }

    &__col-second {

    }

    &__title {
        color: var.$color-text;
        font-size: 2.1rem;
        line-height: 2.7rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.6rem;
            line-height: 1.3;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }

    &__sub-title {
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: var.$color-gray-tone7;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin: 0;
            font-size: 1.3rem;
            line-height: 1.6rem;
        }
    }

}

.jumbotron-v1 {

}