@use 'base/variables' as var;
@use 'base/mixins' as mix;

.slider-v1 {

    &__wrap {
        display: none;
        position: relative;
        width: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
        // TODO[removeBeforeRelease]
        overflow: hidden;
        // \TODO[removeBeforeRelease]
    }

    &__list-place {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__navigator-place {
        display: none;
        @include mix.breakpoint(var.$tablet-h-width) {
            display: block;
        }
    }

    &__prev {
        cursor: pointer;
        position: absolute;
        left: 3%;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__next {
        cursor: pointer;
        position: absolute;
        right: 3%;
        top: calc(50% - 2.5rem);
        width: 5rem;
        height: 5rem;
        z-index: 11;
    }

    &__dots-bar-place {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-top: 1.4rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 1rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 1.2rem;
        }
        width: 100%;
    }

    &__items {
        width: calc(100vw);
    }

    &__item {
        position: relative;
        width: calc(100vw);
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            // grid-template-rows: 48rem 3rem;
            height: 48rem;
            min-height: 48rem;
            max-height: 48rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            // grid-template-rows: 65rem 3.8rem;
            height: 65rem;
            min-height: 65rem;
            max-height: 65rem;
        }
    }

    &__bg-place {
        width: 100%;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            // grid-template-rows: 28rem 3.4rem;
            height: 28rem;
            min-height: 28rem;
            max-height: 28rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            height: 100%;
            // 2nd level
            z-index: 9;
            position: absolute;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            height: 100%;
            // 2nd level
            z-index: 9;
            position: absolute;
        }
        background-position: center bottom;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__content-place {
        width: 100%;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            // height: auto;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            height: 100%;
            // 1st level
            z-index: 10;
            position: absolute;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            height: 100%;
            // 1st level
            z-index: 10;
            position: absolute;
        }
    }

    &__content-wrap {
        // example of bad usage
        // TODO[sassBuildModules]: to-build modules!
        margin-left: auto;
        margin-right: auto;
        max-width: var.$container-width;

        @include mix.breakpointRange(var.$zero-width, (var.$desktop-width - 1)) {
            padding: 0 var.$container-padding-x-md+8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 0 var.$container-padding-x-lg+4rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding: 0 var.$container-padding-x-lg;
        }
        // \example of bad usage
        
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            height: auto;
            align-items: center;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            padding-left: 10rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            padding-left: 13.3rem;
        }
    }

    &__title {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            text-align: center;
            font-size: 2.2rem;
            margin-top: 2.5rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            font-size: 4rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            font-size: 4.8rem;
        }
        font-weight: 700;
        font-family: "Affect";
        line-height: 110%;
        color: var.$color-black-tone4;
    }

    &__subtitle {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-top: 1.2rem;
            font-size: 1.5rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 2rem;
            font-size: 1.6rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 2rem;
            font-size: 2rem;
        }
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.5rem;
        color: var.$color-black-tone4;
    }

    // TODO[dependСss]
    // depend: button-link-v1
    &__content-wrap .button-link-v1 {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-top: 2rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 3.4rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 4rem;
        }
    }
    // \depend: button-link-v1

    // depend: slick overwrite
    &__dots-bar-place .slick-dots {
        max-width: inherit;
        margin: 0;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            // TODO[fixme]: strange centering (check on mobile)
            margin-left: 1.2rem;
            // TODO[\fixme]: strange centering (check on mobile)
        }
    }

    &__dots-bar-place .slick-dots li:nth-child(1) {
        margin-left: 0;
    }
    &__dots-bar-place .slick-dots li {
        margin: 0;
        line-height: 1;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            margin-left: 1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-left: 1.2rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-left: 1.2rem;
        }
    }
    &__dots-bar-place .slick-dots li button {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: 1rem;
            height: 1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            width: 1.4rem;
            height: 1.4rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            width: 2rem;
            height: 2rem;
        }
    }
    // \depend: slick overwrite
    // \TODO[dependСss]
}