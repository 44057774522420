@use 'base/variables' as var;
@use 'base/mixins' as mix;

.news-detail-header-v1 {

    background-color: var.$color-third;
    border-radius: 0.4rem;
    padding: 0;
    display: flex;
    align-items: flex-end;
    margin-bottom: 5rem;

    &__info {
        line-height: 2.4rem;
    }
    &__container {
        padding: 2rem 1.5rem 3rem;

        @include mix.breakpoint(var.$tablet-width) {
            align-self: stretch;
            padding: 4rem 4rem 4rem 3rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-right: auto;
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 6rem;
        }
    }

    &__title {
        margin: 0 0 1.5rem;
        font-family: Affect, Arial, sans-serif;
        font-size: 2rem;
        line-height: 112%;
        letter-spacing: .015em;
        font-weight: 700;
        max-width: 26rem;


        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
            font-size: 3.2rem;
            max-width: none;
        }

        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4.4rem;
            max-width: 49.5rem;
        }
    }

    &__detail {

    }

    &__picture {
        @include mix.breakpoint(var.$tablet-width) {
            width: 32rem;
            max-width: none;
        }


        @include mix.breakpoint(var.$desktop-sm-width) {
            width: 50rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 54rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            width: 57rem;
        }

        img {
            display: block;
            width: 100%;
            max-width: 45rem;
            margin-left: auto;
            border-radius: 4px 4px 4px 4px;
        }
    }



}

// TODO[dependСss]
.news-detail-header-v1 {
    .rating-star-v1 {
        margin: 0 1rem 0 1rem;
        &__icon {
           height: 1.3rem;
        }
    }
    .link-v1 {
        color: var.$color-text;
        text-decoration: underline;
    }
}