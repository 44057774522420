@use 'base/variables' as var;
@use 'base/mixins' as mix;

.range {
    user-select: none;

    padding: 1.5rem;
    &__inner {
        position: relative;
    }
    &__scale {
        height: 3px;
        background-color: #EBF0F5;
        border-radius: 3px;
        position: relative;
        margin-bottom: 5px;
        overflow: hidden;
    }

    &__fill {
        background-color: var.$color-main;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #6563A2 0%, #7B75C9 17.38%, #7B75C9 50%, #7B75C9 83.56%, #6261A9 100%);
    }

    &__fill-left {
        position: absolute;
        right: 100%;
        height: 100%;
        width: 100%;
        background: #EBF0F5;
    }

    &__fill-right {
        position: absolute;
        left: 100%;
        height: 100%;
        width: 100%;
        background: #EBF0F5;
    }

    &__control {
        position: absolute;
        top: -0.6rem;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;

        background-color: var.$color-main;
        border: 1px solid rgba(60, 60, 112, 0.35);
        border-radius: 2px;
        // transition: position .3s;
    }

    &__value-list {
        display: flex;
        justify-content: space-around;
        margin-bottom: 1.5rem;
    }

    &__value {
        color: var.$color-text;
        width: 7rem;
        text-align: center;
        // font-size: 1.3rem;
        // line-height: 1.6rem;
    }
}
