@use 'base/variables' as var;
@use 'base/mixins' as mix;

.info-card-v3 {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
        width: 100%;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            height: 25rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            height: 27rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            height: 33.8rem;
        }
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;
        position: relative;
    }

    &__title {
        color: var.$color-black-tone4;
        line-height: 1.2;
        font-weight: 500;
        font-size: 1.8rem;
        text-decoration: none;
        text-align: center;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.6rem;
            margin-top: 1.6rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 1.8rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 2rem;
        }
    }
    
    &__desc {
        color: var.$color-main-tone7;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.4;
        text-align: center;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.4rem;
            margin-top: 1rem;
        }
        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-top: 1rem;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            margin-top: 1.2rem;
        }
    }

    &__top-bar {
        display: block;
        position: absolute;
        top: 2rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            top: 1.6rem;
        }
        left: 0;
    }

    &__bottom-bar {
        display: block;
        position: absolute;
        bottom: 2rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            bottom: 1.6rem;
        }
        left: 0;
    }

    &__bar-tag {
        float: left;
        border-radius: 0.3rem;
        margin-left: 2rem;
        &_primary {
            font-size: 1.2rem;
            padding: 1rem 1rem;
            background-color: var.$color-black-tone4;
            color: var.$color-white;
            font-weight: 400;
            line-height: 1rem;
            margin-bottom: 1rem;
        }
    }

    &__mobile-more {
        
    }

}