@use 'base/variables' as var;
@use 'base/mixins' as mix;

.social-list-v2 {

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 3rem);
    column-gap: 1rem;
    margin-top: 2rem;

    @include mix.breakpointRange(var.$zero-width, var.$mobile-xl-width) {
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
        row-gap: 1rem;
        justify-content: center;
    }

    @include mix.breakpoint(var.$tablet-width) {
        margin-top: 8rem;
        column-gap: 2rem;
    }

    &__link {
        text-decoration: none;
        display: grid;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    // &__list {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     color: #fff;
    //     @include mix.breakpoint(var.$tablet-width) {
    //         width: 28rem;
    //         margin: 0 auto;
    //     }
    //     @include mix.breakpoint(var.$desktop-width) {
    //         margin-top: 1rem;
    //     }
    // }
    //
    // &__link {
    //     color: #fff;
    //     text-decoration: none;
    //     font-size: 1.4rem;
    //     line-height: 1.8rem;
    //     font-weight: 500;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     @include mix.breakpoint(var.$tablet-width) {
    //         font-size: 1.3rem;
    //         line-height: 1.6rem;
    //     }
    //
    //     svg {
    //         max-width: 2.8rem;
    //         max-height: 2.8rem;
    //         color: #fff;
    //     }
    // }
    //
    // &__separator {
    //     transform: translateY(-3px);
    // }




}
