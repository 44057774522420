@use 'base/variables' as var;
@use 'base/mixins' as mix;

//local var
$slide-height: 22.4rem;

.product-card-v2 > * {
    flex-grow: 0;
    flex-shrink: 0;
}

.product-card-v2 {

    &__footer-wrap {
        order: 3;
        padding: 0rem 2rem 2rem 2rem;
        margin-top: auto;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.2rem 1rem 1rem 1rem;
        }
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {

        /* Ограничиваем максимальную высоту */
        max-height: 100%;

        /* Высота должна подстраиваться по контенту */
        height: auto;

        &__rating_mobile {
            display: flex;
        }
        &__rating {
            display: none;
        }
        &__real-modifier-switcher {
            width: 50%;
        }
        .old-price-v1, .sale-price-v1 {
            width: 100%;
        }
        .product-card-v2__price {
            flex-direction: column;
            gap: 0.8rem;
        }
        &__footer-wrap {
            margin-top: inherit;
        }
    }
    @include mix.breakpoint(var.$tablet-width) {
        &__rating_mobile {
            display: none;
        }
        &__rating {
            display: flex;
        }
    }

    &__price-from {
        color: var.$color-gray-tone11;
        font-family: var.$font-text;
        font-size: 1.2rem;
        // padding-left: 1.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
    }

    &:hover {
        border-radius: 6px;
        background: var.$color-white;
        box-shadow: 0px 0px 16px 4px rgba(60, 60, 112, 0.16);
    }

    // width: 33.8rem;
    // min-width: 33.8rem;
    // max-width: 33.8rem;
    border-radius: 0.6rem;
    /*display: grid;
    grid-template-columns: 100%;
*/

    display: flex;
    flex-direction: column;

    /** TODO */
    overflow: hidden;
    /** TODO */

    &__body-wrap {
        order: 2;
        padding: 1.2rem 2rem 0rem 2rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.2rem 1rem 0rem 1rem;
        }
    }

    &__gallery {
        order: 1;
        position: relative;
        // max-width: calc(100% - 4rem);
        // width: calc(100% - 4rem);
        min-height: 22.4rem;
        max-height: 22.4rem;
    }

    &__gallery &__icon-wrap {
        position: absolute;
        top: 1.75rem;
        right: 1.7rem;
        display: flex;
        z-index: 5;
        flex-direction: row;
    }

    &__badge-rating-wrap {
        display: grid;
        grid-template-columns: auto min-content;
        margin-top: 1.5rem;
    }

    &__badge-rating-wrap &__rating {
        // align-self: end;
        justify-self: end;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.2rem;
    }

    .character-list-v1 {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
    }

    .character-list-v1 .character-element-v1 {
        display: grid;
        grid-template-columns: 40% calc(60% - 0.3rem);
        column-gap: 0.3rem;
        align-items: center;
    }

    &__price-real-modifier-switcher-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.4rem;
    }

    &__title {
        /* Ограничиваем максимальную высоту */
        /* знаю костыльное решение  но уже слишком много времени потратил чтобы рещить задачу */
        /* надо будет либо действиетльно на гриды переверстывать., либо js накидывать */
        min-height: 4.33rem;
    }
}


/** DEPENDS CODE */
.product-card-v2 {

    .popular-info-v1 {

        font-size: 1rem;

        &__link {
            font-weight: 400;
            font-size: 1rem;
        }

        &__info {
            color: rgba(var.$color-text, 0.30);
            font-weight: 400;
            font-size: 1rem;
        }
    }

    .rating-star-v2 {
        margin-top: 0.1rem;
        justify-content: end;
        margin-bottom: 0;

        svg {
            margin-bottom: 0.2rem;
            width: 1.1rem;
            height: 1rem;
        }

        .badge-v1__wrap {
            padding-right: 0;
        }
    }

    .gallery-v3 {
        max-width: 100%;
        width: 100%;
        height: $slide-height;
        max-height: $slide-height;
        min-height: $slide-height;
    }
    
    .gallery-v3__page-controller {
        overflow: hidden;
        z-index: 3;
    }

    .gallery-v3__current-image {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        display: grid;
        height: $slide-height;
        align-items: center;
        overflow: hidden;
    }

    .gallery-v3__slide_wrapper {
        display: grid !important;
        justify-content: center;
        align-items: center;
        height: $slide-height;

        img {
            max-width: 100%;
            object-fit: contain;
            object-position: center;
            display: block;
            margin: 0 auto;
        }
    }

    .action-group-v1 {
        grid-template-columns: auto;
    }

    .action-group-v1 .button-v1 {
        width: 100%;
        padding: 1.5rem 0;
        font-weight: 600;
        font-size: 1.2rem;
        max-height: 4.1rem;
        color: #FFF;
        font-family: var.$font-text;
        font-style: normal;
        line-height: 120%;
    }

    .action-group-v1 .button-v1_filling-type-contained.button-v1_filling-color-type-main:hover {
        background: var.$color-text;
        color: var.$color-white !important;
    }

    .link-v1 {
        font-size: 1.8rem;
        color: #3C3C70;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 21.6px */
        margin-top: 0.8rem;
        display: block;
    }

    .character-list-v1 {
        margin-top: 0.8rem;
        margin-bottom: 1.7rem;
    }

    .character-list-v1 .character-element-v1 .character-element-v1 {

        &__label-row {
            font-weight: 400;
            font-size: 1.2rem;
        }

        &__value-row {
            font-weight: 400;
            font-size: 1.2rem;
            margin-top: 0;
            color: var.$color-gray;
        }
    }

    .p-v1 {
        font-weight: 500;
        //margin-bottom: 2.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        align-self: self-start;
        font-size: 1.4rem;
        line-height: 1;
        color: var.$color-main;
        padding-left: 2.4rem;
        position: relative;
        min-height: 2.3rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.2rem;
            min-height: 2.4rem;
            padding-left: 1.2rem;
            align-items: center;
        }

    }
    .p-v1::before {
        position: absolute;
        content: "";
        display: block;
        flex-shrink: 0;
        width: 1.6rem;
        height: 1.6rem;
        background: var.$color-second;
        border-radius: 50%;
        left: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            width: .8rem;
            height: .8rem;
        }
    }

    &__real-modifier-switcher {
        // justify-self: end;
        // width: 100%;
        // min-width: 100%;
        max-width: 50%;
        min-width: 50%;
    }

    &__price {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
    }


    &__price-real-modifier-switcher-block .p-v1 {
        font-weight: 400;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }

    &__icon-wrap .link-with-icon-v1 {
        gap: 0;
        margin-right: 1.1rem;
    }

    &__icon-wrap .link-with-icon-v1:last-child {
        margin-right: 0;
    }

    &__badge-rating-wrap .badge-list-v1 {

        // display: flex;
        // column-gap: 1rem;

    }

    &__badge-rating-wrap .badge-v1 {

        margin-right: 0.8rem;
        margin-bottom: 1rem;
        height: 2.4rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            height: 2.1rem;
            margin-right: 0.0rem;
        }
        &__label {
            font-size: 1.2rem;
            line-height: normal;
            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                font-size: 0.9rem;
            }
        }



        &__wrap {

            margin: 0;
            display: flex;
            flex-direction: row;

            > * {
                display: flex;
                align-items: center;
            }

        }

    }

    &__badge-rating-wrap .badge-v1:last-child {
        margin-right: 0rem;
    }

    &__badge-rating-wrap .badge-v1__wrap {
        padding: 0 0.5rem;
    }

    &__badge-rating-wrap .product-card-v2 {

        &__rating .popular-info-v1 .popular-info-v1 {

            &__link {

                .link-v1 {
                    font-weight: 400;
                    font-size: 1rem;
                }
            }

        }


    }

    &__badge-rating-wrap .badge-v1_content-type-icon .badge-v1__wrap {
        padding: 0 1rem;
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 0 0.5rem;
            .badge-v1__icon {
                margin-right: 0;
            }
        }
    }

    &__badge-rating-wrap .badge-v1_content-type-label-and-icon .badge-v1__icon {
        margin-right: 0.5rem;
    }

    &__badge-rating-wrap .badge-v1_content-type-label-and-icon .badge-v1__label {
        font-size: 0.7rem;
    }

    &__price .sale-price-v1 {
        font-weight: 600;
        font-size: 2.1rem;
    }

    &__price .old-price-v1__value {
        color: var.$color-main;
        text-align: right;
    }

    &__price .old-price-v1 * {
        font-weight: 400;
        font-size: 1.4rem;
    }

    &__rating_mobile {
        .rating-star-v2 .badge-v1.badge-v1_content-type-label .badge-v1__label {
            font-size: 0.9rem;
            color: var.$color-main;
        }

        .rating-star-v2 svg {
            fill: var.$color-main;
            display: inline-block;
            margin: 0.5rem 0.4rem 0 0;
            width: 0.8rem;
            height: 0.7rem;
        }

        span {
            display: inline-block;
            margin: 0 0.2rem;
        }

    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        .link-v1 {
            font-size: 1.2rem;
        }
        .character-list-v1 {
            margin-bottom: 0.8rem;
        }
        .character-list-v1 .character-element-v1 {
            grid-template-columns: 50% calc(50% - 0.3rem);
            display: none;

            &:first-child {
                display: grid;
            }
        }
        .character-list-v1 .character-element-v1 .character-element-v1__label-row {
            font-size: 1.1rem;
        }
        &__real-modifier-switcher {
            max-width: 50%;
        }
        .old-price-v1 {
            display: flex;
            justify-content: end;
        }
        .old-price-v1 * {
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 1.08rem;
        }
        .sale-price-v1 {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.08rem;
        }
        .action-group-v1 .button-v1 {
            max-height: 3.2rem;
            padding: 1.1rem 0;
        }
        .select-v2 .select2-container .select2-selection--single .select2-selection__rendered {
            font-size: 1rem;
        }
    }

    .select-v2 span.select2.select2-container {
        width: 100%;
    }
}

/** \DEPENDS CODE */
