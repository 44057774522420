@use 'base/variables' as var;
@use 'base/mixins' as mix;

.best {
    position: relative;
    z-index: 1;
    /*@include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 9rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 13.2rem;
    }
    @include mix.breakpoint(var.$desktop-lg-width) {
        margin-bottom: 14rem;
    }*/

    &._associated {
        z-index: 15;
    }

    &__top-wrap {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6.5rem;
        }
    }

    &__title {
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 48%;
            margin-bottom: 0;
        }
    }

    &__category-list {
        display: flex;
        // max-width: 100%;
        margin-bottom: 2rem;
        margin-right: -0.6rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            max-width: 48%;
            margin-right: -1.5rem;
            // margin-bottom: 0;
            line-height: 4rem;
            margin-bottom: -1rem;
        }



        @media (max-width: var.$tablet-width - 1) {
          margin-right: -1.5rem;
          max-width: 100%;
          overflow-x: scroll;
          padding-left: 1.5rem;

          .best__category-link {
            padding: 1.1rem;
          }
          .best__category-item:last-child {
            padding-right: 1.5rem;
            margin-right: 0 !important;
          }
        }

    }

    &__slider-top-container {
      @media (max-width: var.$tablet-width - 1) {
        padding: 0 !important;

        .best__title {
          padding: 0 1.5rem;
        }
      }
    }

    &__category-item {
        margin-right: 0.6rem;
        flex-grow: 1;
        transition: all .3s;
        &._active {
            .best__category-link {
                background-color: var.$color-text;
            }

            .best__category-link {
                color: var.$color-white;
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            flex-grow: 0;
            margin-right: 1.5rem;
        }
    }
    &__category-link {
        width: 100%;
        display: block;
        background-color: #F5F7FA;
        border-radius: 4px;
        color: var.$color-text;
        font-size: 1.4rem;
        line-height: 1.2;
        text-align: center;
        text-decoration: none;
        // padding-top: 0.9rem;
        // padding-bottom: 0.9rem;
        padding: 1.1rem 0.6rem;
        transition: all .3s;
        @include mix.breakpoint(var.$tablet-width) {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            font-size: 1.6rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            &:hover {
                background-color: var.$color-main;
                color: var.$color-white;
            }

        }
    }

    &__list {
        margin-top: -1.5rem;
        display: flex;
        flex-direction: column;
        @include mix.breakpoint(var.$tablet-width) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-right: -2rem;
            margin-top: -2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            position: relative;
            margin-right: 0;
            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 0;
                height: calc(100% + 300px); // для селекта
                height: 100%;
                z-index: 10;
                background-color: #ffffff;
                width: calc((100vw - 100%) / 2);
            }
            &::after {
                right: 100%;
            }
            &::before {
                left: 100%;
            }

        }
    }

    &__item {
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        @include mix.breakpoint(var.$tablet-width) {
            width: 35.4rem;
            // margin-left: 2rem;
            margin-right: 2rem;
            // width: calc(100% / 2 - 2rem);
            // margin-right: 2rem;
            // margin-top: 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 30.5rem;
            margin: 0 1rem;
        }
    }

    .slick-list {
        @include mix.breakpoint(var.$desktop-width) {
            margin: 0 -1rem;
        }
    }

    &__slider-container {
        position: relative;
    }

    &__slider-nav {
        border: none;
        background-color: transparent;
        padding: 0;
        width: 5rem;
        height: 5rem;
        // display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 20;
        outline: none;
        display: none;
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
        }
    }

    &__slider-nav._prev {
        left: -6rem;
    }
    &__slider-nav._next {
        right: -6rem;
    }

    &__nav-icon {
        width: 0.8rem;
        height: 2.2rem;
        stroke: var.$color-main;
    }
}

.best {
    @media(max-width: var.$tablet-width - 1) {
        position: relative;
        z-index: 5;
        .card {
            width: 29rem;
            margin-right: 0.75rem;
            margin-left: 0.75rem;
            outline: none;
            margin-top: 0;
            @include mix.breakpoint(var.$mobile-width) {
                width: 31rem;
            }
        }

        .best__list {
            width: calc(100vw);
            margin-left: -1.5rem;
            margin-top: 0;
            min-width: 0;
            min-height: 0;
            display: flex;
        }

        .slick-track {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }
}
