@use 'base/variables' as var;
@use 'base/mixins' as mix;

.gallery-v1 {

    @include mix.breakpoint(var.$tablet-h-width) {
        display: grid;
        grid-template-columns: 9.3rem auto;
        grid-template-rows: min-content;
        gap: 3.3rem;
    }


    &__track-wrap {
        @include mix.breakpointRange(var.$zero-width, var.$tablet-h-width) {
            display: none;
        }
    }

    &__track {
        display: grid !important;
        grid-auto-flow: row;
        row-gap: 1.5rem;
        height: 38rem;
    }

    &__track-element {
        width: 9.1rem !important;
        height: 6.2rem !important;
        margin-bottom: 1.5rem;
        display: grid !important;
        align-items: center;
        justify-items: center;

        img {
            border-radius: 0.6rem;
            max-width: 8.7rem;
            max-height: 5.6rem;
        }
    }

    &__track-element_active {
        border: 0.1rem solid;
        border-radius: 0.6rem;
        border-color: var.$color-text;
    }

    &__current-image {
        overflow: hidden;
    }


}

/** DEPENDS CODE */
.gallery-v1 {
    .nav-v1 {
        height: 5.4rem;
    }

    &__track {
        .slick-track {
            // display: grid !important;
            // grid-auto-flow: row;
            height: 38rem !important;
            width: 9.3rem !important;
        }

        .slick-slide {
            float: none !important;
            cursor: pointer;
        }

        .slick-slide:hover &__track-element {
            border: 0.1rem solid;
            border-radius: 0.6rem;
            border-color: var.$color-text;
        }
    }

    &__current-image {
        .slick-slide img {
            margin: 0 auto;
        }
    }

    &__popup {
        .mfp-close {
            right: 2rem;
            font-size: 2rem;
        }

        .mfp-counter {
            right: 2rem;
        }
    }
}


/** \DEPENDS CODE */
