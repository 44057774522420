@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popular-info-v1 {


    &__link, &__info {
        white-space: nowrap;
    }

    &__info {
        padding-left: 0.5rem;
    }

}