@use 'base/variables' as var;
@use 'base/mixins' as mix;

.breadcrumbs {
    padding-top: 2rem;
    margin-bottom: 4rem;
    @include mix.breakpoint(var.$desktop-width) {
        padding-top: 3rem;
        margin-bottom: 3.5rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        padding-top: 5.5rem;
    }

    &__item {
        display: inline;
        &:not(:last-child)::after {
            content: '';
            position: relative;
            display: inline-block;
            // margin-right: 2px;
            transform: translateY(-100%);
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: #9D9D9D;
            margin: 0 4px;
        }
        &:last-child .breadcrumbs__link {
            color: #747474;
            font-weight: 500;
        }
    }

    &__link, a {
        font-size: 1.4rem;
        line-height: 1.5;
        // padding-right: 2px;
        text-decoration: none;
        color: #9D9D9D;
    }
}
