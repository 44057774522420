@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-v2 {

    padding: 3rem 3.5rem;
    background: #7B75C9;
    color: #fff;
    position: relative;
    z-index: 0;
    overflow: hidden;

    // очень плохая логика но переношу как есть из старого проекта, потому-что времени нет.
    &_krovaty {
        &::before {
            position: absolute;
            content: '';
            display: block;
            background: #3C3C70;
            width: 97rem;
            height: 89rem;
            border-radius: 50%;
            top: -9rem;
            left: 3rem;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                width: 32rem;
                height: 32rem;
                top: -2rem;
                left: auto;
                right: -8rem;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
                width: 74rem;
                height: 74rem;
                top: -7rem;
                left: auto;
                right: -30rem;
            }

            @include mix.breakpoint(var.$tablet-h-width) {
                width: 97rem;
                height: 89rem;
                top: -9rem;
                left: 3rem;
            }
        }
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            padding: 1.6rem 1.2rem;
    }

    &__title-place {
        position: relative;
        z-index: 1;
    }

    &__background {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
        text-align: right;
        height: 100%;

        /* знаю хрень полная переношу из старого проекта как есть, нет времени разносить */
        &_krovaty {
            height: auto;
            width: 90rem;
            margin-right: -11rem;

            & > img {
                height: 100%;
                display: block;
                margin-bottom: -7rem;

                @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                    margin-bottom: 0;
                    width: 36rem !important;
                }
            }

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                width: auto;
                margin-right: 0rem;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
                width: 70rem;
            }

            @include mix.breakpoint(var.$desktop-notebook-width) {
                width: 90rem;
            }
        }
    }

    &__btn {
        background-color: var.$color-white;
        color: var.$color-text;
        border: 1px solid var.$color-white;
        font-size: 3.6rem;
        padding: 3.2rem;
        line-height: 2rem;
        border-radius: 0.8rem;
        font-weight: 500;
        font-family: var.$font-text;
        cursor: pointer;
        transition: all .3s;
    }

    &__btn:hover {
        background: var.$color-main-tone9;
        color: var.$color-white;
        border-color: var.$color-white;
    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {

        &__title-place {
            width: 24rem;
        }

        &__btn {
            font-size: 1.4rem;
            padding: 1.6rem;
            width: 16rem;
        }
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        &__title-place {
            width: 53rem;
        }
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        &__title-place {
            width: 36.6rem;
        }

        &__btn {
            width: 100%;
            font-size: 2.4rem;
            padding: 1.4rem;
        }
    }

    &__subtitle {
        text-align: left;
        color: #fff;

        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            font-size: 1.2rem;
            margin-bottom: 2.8rem;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$tablet-h-width - 1)) {
            margin-bottom: 4.6rem;
        }

        @include mix.breakpointRange(var.$tablet-h-width, (var.$desktop-width - 1)) {
            margin-bottom: 4.6rem;
        }

        @include mix.breakpointRange(var.$desktop-width, (var.$desktop-notebook-width - 1)) {
            font-size: 1.4rem;
            margin-bottom: 3.6rem;
            width: 36rem;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            font-size: 1.6rem;
            margin-bottom: 8rem;
        }
    }

    &__btn {
            border-radius: 8px;
            z-index: 0;

            @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
                width: 16rem;
                font-size: 1.4rem;
                padding: 1.6rem;
            }

            @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
                width: 31rem;
                font-size: 2.4rem;
                padding: 1.4rem;
            }

            @include mix.breakpoint(var.$desktop-notebook-width) {
                width: 41rem;
                font-size: 3.6rem;
                padding: 3.2rem;
            }
        }
}

/** DEPENDS CODE */
.questionnaire-v2 {

    .title-v2 {
        color: var.$color-white;
        @include mix.breakpointRange(var.$zero-width, (var.$mobile-l-width - 1)) {
            font-size: 1.8rem;
            width: 20rem;
            margin-bottom: 1.2rem;
            text-align: left;
        }

        @include mix.breakpointRange(var.$mobile-l-width, (var.$tablet-width - 1)) {
            font-size: 2.4rem;
            margin-bottom: 1.2rem;
            text-align: left;
        }

        @include mix.breakpointRange(var.$tablet-width, (var.$desktop-width - 1)) {
            font-size: 2.4rem !important;
            margin-bottom: 1.2rem;
            text-align: left;
        }

        @include mix.breakpointRange(var.$desktop-width, (var.$desktop-notebook-width - 1)) {
            font-size: 3.6rem;
            margin-bottom: 1.2rem;
            text-align: left;
        }

        @include mix.breakpoint(var.$desktop-notebook-width) {
            font-size: 3.6rem;
            margin-bottom: 1.6rem;
        }
    }

    .picture-v1__bg {
        height: 100%;
    }
}

/** \DEPENDS CODE */
