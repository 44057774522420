@use 'base/variables' as var;
@use 'base/mixins' as mix;

.favorite {
    margin-bottom: 6rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5.5rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        margin-bottom: 11rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        margin-bottom: 14rem;
    }
    .best__item {
        margin-left: 0;
    }

    &__list {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -1rem;
            margin-top: -2rem;
        }
    }

    &__item {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        @include mix.breakpoint(var.$tablet-width) {
            width: calc(100% / 2 - 2rem);
            margin: 0 1rem;
            margin-top: 2rem;
        }
        @include mix.breakpoint(var.$desktop-sm-width) {
            width: calc(100% / 3 - 2rem);
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 2rem);
        }

        @media (max-width: var.$tablet-width - 1) {
          margin-left: auto;
          margin-right: auto;
        }
    }
}
