@use 'base/variables' as var;
@use 'base/mixins' as mix;

.text-page-warranty-v1 {


}

/** DEPENDS CODE */
.text-page-warranty-v1 {
    .title-v1, .title-v2 {
        margin-top: 0rem;
    }

    @include mix.breakpoint(var.$tablet-width) {
        .title-v1, .title-v2 {
            margin-bottom: 5rem;
        }

    }

    @include mix.breakpoint(var.$tablet-width) {
        .title-v1, .title-v2 {
            font-size: 3.2rem;
            margin-bottom: 3rem;
        }

    }


    @include mix.breakpoint(var.$desktop-width) {
        .title-v1, .title-v2 {
            font-size: 4rem;
            margin-bottom: 6rem;
        }

        .breadcrumb-v1 {
            padding-top: 3rem;
            margin-bottom: 3.5rem;
        }
    }

    @include mix.breakpoint(var.$desktop-max-width) {
        .breadcrumb-v1 {
            padding-top: 5.5rem;
        }
    }

    .brand-list {
        @include mix.breakpoint(var.$desktop-width) {
            .title-v2 {
                font-size: 4rem;
                margin-bottom: 6rem;
            }
        }
    }
}