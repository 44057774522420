@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tab {
    margin-bottom: 7rem;
    width: 100%;
    @include mix.breakpoint(var.$desktop-width) {
        // margin-bottom: 0;
        width: 92rem;
        width: calc(100% - 6.5rem - 29.4rem);
        margin-bottom: 13.4rem;
    }
    @include mix.breakpoint(var.$desktop-max-width) {
        width: 95.5rem;
        margin-bottom: 7rem;
    }
    &__list-wrap {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        overflow-x: scroll;
        @include mix.breakpoint(var.$tablet-width) {
            width: auto;
            overflow: hidden;
            margin-left: 0;
        }
    }

    &__list {
        position: relative;
        display: flex;
        margin-right: -1.5rem;
        padding-bottom: 1.4rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-left: 0;
            margin-right: -3rem;
            padding-bottom: 2.1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            // margin-right: -8rem;
            margin-right: 0;
            justify-content: space-between;
        }
    }

    &__item {
        margin-right: 1.5rem;
        &:first-child {
            padding-left: 1.5rem;
        }

        &:last-child {
            padding-right: 1.5rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 3rem;
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-right: 0;
            // margin-right: 8rem;
        }
    }

    &__link {
        position: relative;
        text-decoration: none;
        color: #383838;
        font-size: 1.8rem;
        line-height: 2.3rem;
        &:hover {
            color:var.$color-text;
            &::after {
                content: '';
                position: absolute;
                top:100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var.$color-text;
                margin-top: 14px;
                z-index: 2;
            }
        }
        &._active {
            font-weight: 600;
            color: var.$color-text;
            &::after {
                content: '';
                position: absolute;
                top:100%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: var.$color-text;
                margin-top: 14px;
                z-index: 2;
            }
        }
        @include mix.breakpoint(var.$tablet-width) {
            &._active {
                &::after {
                    margin-top: 2.2rem;
                }
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 2rem;
            line-height: 2.5rem;
        }
    }

    &__content-list {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: -2px;
            left: -1.5rem;
            width: 1.5rem;
            height: 2px;
            background-color: #fff;
            z-index: 5;
        }
        &::after {
            content: '';
            position: absolute;
            top: -2px;
            right: -1.5rem;
            width: 1.5rem;
            height: 2px;
            background-color: #fff;
            z-index: 5;
        }
        padding-top: 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            &::after,
            &::before {
                display: none;
            }
            border-top: 2px solid #EBF0F5;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            padding-top: 5.5rem;
        }
    }

    &__content-item {
        display: none;

        &._active {
            display: block;
        }

        &._more {
            .wysiwyg__hidden {
                display: block;
            }
            .link__icon {
                transform: rotateX(180deg);
            }
        }

        &._review {
            .review {
                &__text {
                    @include mix.breakpoint(var.$tablet-width) {
                        line-clamp: 3;
                        -webkit-line-clamp: 3;
                    }
                    @include mix.breakpoint(var.$desktop-width) {
                        line-clamp: 5;
                        -webkit-line-clamp: 5;
                    }

                }
            }
        }

        .wysiwyg {
            font-size: 1.6rem !important;
            line-height: 2.4rem !important;
          }

        @include mix.breakpoint(var.$tablet-width) {
          .wysiwyg {
              font-size: 2rem !important;
              line-height: 3rem !important;
          }
        }

        @include mix.breakpoint(var.$desktop-width) {
            .wysiwyg {
                padding-right: 2.5rem;
            }
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            .wysiwyg {
                padding-right: 0;
            }
        }
    }

    &__mobile-border {
        position: absolute;
        top: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #EBF0F5;
    }

   /* &__more-link {
        display: inline-flex;
        align-items: baseline;
        justify-content: center;
        color: var.$color-main;
        margin-top: 1.5rem;
        .link__icon {
            transition: all .3s;
            width: 1rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-top: 3rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-top: 3.5rem;
        }
        @include mix.breakpoint(var.$desktop-max-width) {
            margin-top: 6rem;
        }
    }*/

    &__characteristic-list  {
        // display: flex;
        // flex-wrap: wrap;
        // margin-right: -1rem;
        // margin-top: -4rem;
        @include mix.breakpoint(var.$desktop-laptop-width) {
            // justify-content: space-between;
            // margin-right: -3rem;
        }
    }

    &__characteristic-item {
        margin-bottom: 1rem;
        align-items: baseline;
        // justify-content: space-between;
        // width: calc(100% / 2 - 1rem);
        // margin-right: 1rem;
        // margin-top: 4rem;
        @include mix.breakpoint(var.$mobile-lg-width) {
            display: flex;
            // width: calc(100% / 3 - 7.5rem);
            // margin-right: 5.5rem;
        }
        @include mix.breakpoint(var.$desktop-laptop-width) {
            // width: auto;
            // margin-right: 3rem;
        }

        @include mix.breakpoint(var.$tablet-width) {
            flex-direction: column;
        }
        @include mix.breakpoint(var.$tablet-h-width) {
            flex-direction: row;
        }


        &-wrapper {
          display: inline;
          // word-wrap: break-word;
          white-space: nowrap;

          .info-tooltip {
            margin-left: 0.5rem !important;
          }

          .tab__characteristic-value {
            // word-break: break-word;
          }
        }
    }

    &__characteristic-name {
        margin: 0;
        width: 20.5rem;
        margin-right: 2.5rem;
        // margin-bottom: 1rem;
        font-size: 1.6rem;
        line-height: 1.5;
        // color: var.$color-main;
        font-weight: 800;
        flex-shrink: 0;
        // display: flex;
        // align-items: center;

        @include mix.breakpoint(var.$mobile-lg-width) {
            font-weight:500;
        }

        @include mix.breakpoint(var.$tablet-width) {
            // margin-bottom: 1.5rem;
            width: 30rem;
            font-size: 2rem;
            //line-height: 2.3;
        }
    }

    &__characteristic-wrap {

        flex-grow: 1;
        // display: flex;
        // align-items: center;

        .info-tooltip {
            margin-left: 1rem;
            width: 1.5rem;
            height: 1.5rem;
            vertical-align: middle;
        }

        svg {
            color: var.$color-main;
        }
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 39.7rem;
        }

    }

    &__characteristic-value {
        margin: 0;
        font-size: 1.6rem;
        line-height: 1.5;
        text-decoration: none;
        display: inline;

        color: #383838;
        // + .info-tooltip {
            // margin-right: 1rem;
        // }

        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.8rem;
        }

    }
    a.tab__characteristic-value {
        border-bottom: 1px solid transparent;
        transition: border-color .3s;
        color: var.$color-main;
        &:hover {
          border-bottom: 1px solid currentColor;
        }
    }

    &__certificate-list {
        // @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            flex-wrap: wrap;
            // margin-top: -2rem;
            // margin-right: -2rem;
            align-items: flex-start;
        // }
    }

    &__certificate-item {
        margin-bottom: 1rem;
        width: calc(100% / 2 - 2rem);
        margin-right: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
        // @include mix.breakpoint(var.$tablet-width) {
        //     width: calc(100% / 3 - 2rem);
        //     margin: 0;
        //     margin-right: 2rem;
        //     margin-top: 2rem;
        // }
        @include mix.breakpoint(var.$desktop-width) {
            width: calc(100% / 4 - 2rem);
        }
    }

    &__certificate-img {
        width: 100%;
        display: block;
        max-width: 47.5rem;
        margin: 0 auto;
    }


    &__content-item._review {

        .review {
            &__list {
                display: block;
                @include mix.breakpoint(var.$tablet-width) {
                    margin-bottom: 4rem;
                }
            }

            &__item {
                width: 100%;
            }



            &__item {
                margin-bottom: 3rem;
                @include mix.breakpoint(var.$tablet-width) {
                    margin: 0;
                    margin-bottom: 3rem;
                }
                @include mix.breakpoint(var.$desktop-width) {
                    margin-bottom: 0;
                }
            }

            &__link {
                @include mix.breakpoint(var.$tablet-width) {
                    padding: 3rem;
                }
            }

            &__control-list {
                margin-left: 0;
            }

            &__button-wrap {
                @include mix.breakpoint(var.$tablet-width) {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }
            }

            &__control-item:first-of-type {
                @include mix.breakpoint(var.$tablet-width) {
                    margin-right: 1.5rem;
                }
            }
        }

        .slick-slide {
            margin-right: 3rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin-right: 0;
                margin: 0 1rem;
            }
            @include mix.breakpoint(var.$desktop-width) {
                // margin: 0 1rem;
            }
        }
        .slick-track {
            margin-left: 0.75rem;
            @include mix.breakpoint(var.$tablet-width) {
                margin: 0;
            }

        }

        .slick-list {
            @include mix.breakpoint(var.$tablet-width) {
                margin: 0 -1rem;
            }
        }

        ._slider-out-container {
            @include mix.breakpoint(var.$tablet-width) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    &__content-item._answers {
        .answers {
            &__bottom {
                @include mix.breakpoint(var.$tablet-width) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

            &__title {
                padding-right: 7rem;
            }


            &__btn {
                width: 100%;
                margin: 0;
                @include mix.breakpoint(var.$tablet-width) {
                    margin-left: auto;
                    width: 17.2rem;
                }
            }
        }


        .pagination {
            margin-bottom: 4rem;
            @include mix.breakpoint(var.$tablet-width) {
                width: 52.3rem;
            }
            @include mix.breakpoint(var.$desktop-laptop-width) {
                width: 66.1rem;
            }
        }

        .pagination__item {
            @include mix.breakpoint(var.$tablet-width) {
                &:nth-child(4),
                &:nth-last-child(3) {
                    display: none;
                }
            }
            @include mix.breakpoint(var.$desktop-laptop-width) {
                &:nth-child(4),
                &:nth-last-child(3) {
                    display: block;
                }
            }
        }
    }

}
