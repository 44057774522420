@use 'base/variables' as var;

.cities-search-dropdown-v2 {
    .js-city-select:hover  {
        //mvp
        color: var.$color-text !important;
    }
}

//deps
.cities-search-dropdown-v2 {

}