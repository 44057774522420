@use 'base/variables' as var;
@use 'base/mixins' as mix;

.questionnaire-entity-v1 {
    display: none;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    background: #fff;

    &__final {
        display: none;
    }

    &__stage {
        &.questionnaire-entity-v1__stage-active {
            display: block;
        }
    }

    &__wrapper {
        position: relative;
        margin-bottom: 3rem;
        color: var.$color-text;
        width: 100%;
    }

    &__form {
        position: relative;
        width: 100%;
        margin-top: 6rem;
    }

    &__nav {
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &__prev, &__close {
        position: absolute;
        cursor: pointer;
    }

    @include mix.breakpoint(var.$tablet-width) {
        &__form {
            margin-top: 6rem;
        }

    }

    @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
        & {
            max-width: calc(100% - 3.2rem);
            margin: 5rem 1.6rem;
        }
        &__form {
            margin-top: 5rem;
        }
        &__close {
            right: 1rem;
            top: -4rem;
        }
        &__prev {
            left: 1rem;
            top: -4rem;
        }
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        & {
            max-width: 76.9rem;
            margin: 5rem auto;
        }

        &__prev {
            left: 2.4rem;
        }

         &__close {
            right: 2.4rem;
        }
    }

    @include mix.breakpoint(var.$desktop-notebook-width) {
        & {
            max-width: 144rem;
            margin: 1.6rem auto;
        }

        &__prev {
            left: 5rem;
        }

        &__close {
            right: 5rem;
        }
    }


}

//deps
.mfp-content {
    .questionnaire-entity-v1 {
        display: flex;
    }

    @include mix.breakpointRange(var.$tablet-width, (var.$desktop-notebook-width - 1)) {
        .questionnaire-entity-v1 {
            display: block;
        }
    }
}
