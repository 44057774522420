@use 'base/variables' as var;
@use 'base/mixins' as mix;

.range-v1 {

    &__wrap {

    }

    &__active-range {
        width: 100%;
        height: 0.1rem;
        background-color: var.$color-main;
    }

    &__range {

    }

    // TODO[dependCss]: jquery-ui.slider
    &__wrap &__handle.ui-slider-handle {
        border: none;
        background: var.$color-main;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 1.6rem;
        top: -0.8rem;
        margin-left: -0.8rem;
    }

    &__wrap &__handle.ui-slider-handle:hover {
        background: rgba(var.$color-main, 0.8);
    }

    &__wrap.ui-widget-content.ui-slider-horizontal {
        border: none;
        background: none;
        color: none;
    }
    // \TODO[dependCss]: jquery-ui.slider
    
}