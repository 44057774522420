@use 'base/variables' as var;
@use 'base/mixins' as mix;

.delivery-form {
    margin-bottom: 3rem;
    @include mix.breakpoint(var.$tablet-width) {
        margin-bottom: 5rem;
    }

    &__inner {
        background: #ebf0f5;
        border-radius: 4px;
        padding: 2rem 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 3rem;
            padding-bottom: 4rem;
        }
    }

    &__desc {
        display: none;

        @include mix.breakpoint(var.$tablet-width) {
            display: block;
            max-width: 41rem;
            margin: 0;
            margin-bottom: 2.5rem;
            font-weight: 500;
            font-size: 2rem;
            line-height: 2.5rem;

        }
    }

    &__form {
        @include mix.breakpoint(var.$tablet-width) {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    &__icon-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border: 0;
        padding: 0;
        background-color: transparent;
        outline: none;
        pointer-events: none;
        // &._active {
        //     pointer-events: initial;
        // }
    }

    &__icon {
        width: 1.5rem;
        height: 0.5rem;
        stroke: var.$color-main;
        transition: all 0.3s;
    }

    // усиление селектора
    // .delivery-form__inner {
    .form__input-item {
        margin-bottom: 2rem;
        position: relative;

        @include mix.breakpoint(var.$tablet-width) {

            margin-bottom: 0;
            margin-right: 2rem;

            &._producer {
                width: 27.5rem;
            }

            &._city {
                width: 19.6rem;
            }
        }
    }

    .form__input {
        // padding-right: 4.5rem;
        margin-bottom: 0;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.5rem;
            line-height: 1.9rem;
            padding-bottom: 1.3rem;
        }

        ._text {
          outline: none;
          border: none;
        }
    }
    // }


    &__submit-btn {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.9rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 11.3rem;
            padding-top: 1.2rem;
            padding-bottom: 1.3rem;
        }
    }

    .select__option-content {
        text-align: left;
    }
}
