@use 'base/variables' as var;
@use 'base/mixins' as mix;

.popular {
    margin-bottom: 5rem;
    @include mix.breakpoint(var.$tablet-width) {
        //margin-bottom: 9rem;
    }
    @include mix.breakpoint(var.$desktop-width) {
        //margin-bottom: 10rem;
        .title {
            margin-bottom: 6.8rem;
        }
    }
    @include mix.breakpoint(var.$desktop-lg-width) {
        //margin-bottom: 9.7rem;
    }
    &__list-wrap {
        // position: relative;
        margin-right: -1.5rem;
        max-width: 100%;
        overflow: scroll;
        padding-left: 1.5rem;
        @include mix.breakpoint(var.$tablet-width) {
            padding: 0 2rem;
            margin-left: auto;
            margin-right: auto;
            max-width: var.$container-width;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 0 8rem;

        }
    }

    &__list {
        border-right: 1.5rem solid transparent;
        display: flex;
        @include mix.breakpoint(var.$tablet-width) {
            flex-wrap: wrap;
            margin-right: -1.5rem;
            margin-top: -1.5rem;
            border: 0;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: -2.5rem;
            margin-top: -2.5rem;
        }
    }

    &__item {
        flex-shrink: 0;
        margin-right: 1rem;
        &:last-child {
            padding-right: 1.5rem;
        }
        @include mix.breakpoint(var.$tablet-width) {
            margin-right: 1.5rem;
            margin-top: 1.5rem;
            &:last-child {
                padding-right: 0;
            }
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-right: 2.4rem;
            margin-top: 2.5rem;
        }
    }

    &__link {
        display: flex;
        align-items: baseline;
        padding: 1.5rem;
        padding-top: 1.4rem;
        text-decoration: none;
        color: var.$color-text;
        background: #FFFFFF;
        border: 1px solid #EBF0F5;
        border-radius: 4px;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: 500;

        &._all {
            border: 0;
            display: none;
        }

        @media(hover:hover) {
            &:hover {
                background-color: var.$color-text;
                color: var.$color-white;
                border-color: var.$color-text;
            }
        }

        &.active {
          background-color: var.$color-text;
          color: var.$color-white;
          border-color: var.$color-text;
        }

        @include mix.breakpoint(var.$tablet-width) {
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 1.6rem 2.5rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            transition: all .3s;
            padding-right: 2.4rem;
            padding-left: 2.4rem;
            &._all {
                display: flex;
                margin-left: auto;
            }

        }
    }

    &__icon {
        width: 5px;
        height: 9px;
        margin-left: 1rem;
        transform: translateY(1px);
        stroke: currentColor;
        @include mix.breakpoint(var.$tablet-width) {
            height: 10px;
        }
    }

    &__top {
        @include mix.breakpoint(var.$desktop-width) {
            display: flex;
            align-items: center;
            margin-bottom: 4rem;
        }
    }

    &__text {
        display: none;
        @include mix.breakpoint(var.$desktop-width) {
            display: block;
            margin: 0;
            margin-top: -3.5rem;
            max-width: 51.2rem;

            font-size: 1.6rem;
            line-height: 1.5;
        }
    }


}
