@use 'base/variables' as var;
@use 'base/mixins' as mix;

.tooltip {
    &__container {
        padding: 1.2rem;
        max-width: 33.9rem;
        font-family: "EuclidCircularA", Arial, sans-serif;
        color: var.$color-text;
        font-size: 1.2rem;
        line-height: 1.5rem;

        &._big {
            padding: 1.5rem 2rem;
        }

        ul {
            // margin: revert;
            padding-left: 1.6rem;
            list-style: revert;
        }
    }

    // &__text {
    //     margin: 0;
    // }

}

// tooltipster

.tooltipster-box {
    background: #f5f7fa !important;
    /*border: 0 !important;*/
    border: 1px solid var.$color-main !important;
}

.tooltipster-arrow-border {
    border-top-color: #f5f7fa !important;
    border-bottom-color: #f5f7fa !important;
}

.tooltipster-arrow-background {
    border-top-color: #7b75c9 !important;
    border-bottom-color: #7b75c9 !important;

    position: relative;

    &::before {
      content: '';
      width: 10px;
      border: 8px solid transparent;
      border-top-color: #f5f7fa !important;
      position: absolute;
      top: -9px;
      left: -8px;
      z-index: 200;
    }

}

.tooltipster-top .tooltipster-arrow-background {
  top: 0px !important;
}

.tooltipster-bottom .tooltipster-arrow-background {
  top: 0px !important;

  &::before {
    top: -7px;
    border-top-color: transparent !important;
    border-bottom-color: #f5f7fa !important;
  }
}

.tooltipster-content {
    padding: 0 !important;
    color: var.$color-text !important;
}

.tooltipster-white {
    .tooltipster-box {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        background: #fff!important;
    }
    .tooltipster-arrow-border {
        border-top-color: #fff!important;
    }

    .tooltipster-arrow-background {
        border-top-color: #fff!important;
    }
}
