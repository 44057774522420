@use 'base/variables' as var;
@use 'base/mixins' as mix;

.review-modal-v1 {
    &__star-item {

    }
    &__hide {
        display: none;
    }

  /*  &__rate-field, &__youtube-field, &__text-field, &__fio-field {
        border: 1px solid var.$color-white;
    }*/

     &__error {
        border: 1px solid var.$color-danger;
    }
}
//Dependence
.review-modal-v1 {

}