@use 'base/variables' as var;
@use 'base/mixins' as mix;

.article-page-v1 {


}

/** DEPENDS CODE */

.article-page-v1 {

    .select-dropdown-v1 {
        margin-bottom: 3rem;
    }

    .breadcrumb-v1 {
        padding-top: 3rem;
        padding-bottom: 1.6rem;
    }

    .tag-list-v14 {
        margin-bottom: 4.5rem;
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 6rem;
        }

        @include mix.breakpoint(var.$desktop-width) {
            padding: 0 8rem;

        }
        @include mix.breakpoint(var.$tablet-width) {
            padding: 0 0rem;
            margin-left: auto;
            margin-right: auto;
            max-width: var.$container-width;
        }
    }

}

/** \DEPENDS CODE */