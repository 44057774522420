@use 'base/variables' as var;
@use 'base/mixins' as mix;

.label-v1 {
    display: inline-block;
    border-radius: 0.4rem;
    padding: 0.2rem 0.8rem;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.4rem;
    background: var.$color-second;
    font-family: var.$font-header;
}