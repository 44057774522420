@use 'base/variables' as var;
@use 'base/mixins' as mix;

.breadcrumb-v1 {
    
    line-height: 2.24rem;

    &__element {
        font-family: "EuclidCircularA";
        font-weight: 400;
        font-size: 1.6rem;
        display: inline-block;
        padding-right: 1rem;
    }

    &__element:last-child {
        padding-right: 1rem;
        font-weight: 500;
    }

    &__element:last-child::after {
        display: none;
    }
    
    &__element:hover::before {
        color: var.$color-gray-tone6;
    }

    &__element::after {
        padding-left: 1rem;
        content: "•";
        color: var.$color-gray-tone4;
    }

}

// depends code
.breadcrumb-v1 {
     .breadcrumb-item-v1:last-child .breadcrumb-v1__element::after {
         display: none;
     }
}
// \depends code