@use 'base/variables' as var;
@use 'base/mixins' as mix;

.entice-card-v1 {

    width: 21.5rem;
    min-width: 21.5rem;;
    max-width: 21.5rem;;
    border: 0.1rem solid rgba(var.$color-text, 0.3);
    border-radius: 0.6rem;
    padding: 4rem 2rem 3rem 2rem;
    background: var.$color-third;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content min-content auto;
    row-gap: 2.1rem;
}


/** DEPENDS CODE */
.entice-card-v1 {

    &__summary-wrap {
        display: grid;
        grid-template-columns: min-content min-content;
        justify-content: space-between;
        white-space: nowrap;

    }

    &__summary-wrap .entice-card-v1__label {
        font-weight: 400;
        font-size: 1.4rem; 
    }

    &__summary-wrap .entice-card-v1__value {
        font-weight: 600;
        font-size: 2.1rem; 
    }

    &__сlient-wrap {
        display: grid;
        grid-template-columns: min-content min-content;
        justify-content: space-between;
        white-space: nowrap;
    }

    &__сlient-wrap .entice-card-v1__label {
        font-weight: 400;
        font-size: 1.4rem; 
    }

    &__сlient-wrap .entice-card-v1__value {
        font-weight: 600;
        font-size: 2.1rem; 
    }

    &__saving-money-wrap {
        display: grid;
        grid-template-columns: min-content min-content;
        justify-content: space-between;
        white-space: nowrap;
    }

    &__saving-money-wrap .entice-card-v1__label {
        font-weight: 400;
        font-size: 1.4rem; 
    }

    &__saving-money-wrap .entice-card-v1__value {
        font-weight: 600;
        font-size: 2.1rem; 
    }

    &__desc {
        font-weight: 400;
        font-size: 1rem; 
    }

    .button-v1 {
        padding: 0.75rem 2.9rem !important;
        font-weight: 600;
        font-size: 0.8rem;
    }

}

/** \DEPENDS CODE */
