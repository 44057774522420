@use 'base/variables' as var;
@use 'base/mixins' as mix;

.title-with-right-content-v1 {

    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 3.5rem;

    &__arrow {
        stroke: var.$color-black-tone4;
    }
    
    // TODO[dependСss]
    // depend: button-link-with-arrow-right-v1
    & .button-link-with-arrow-right-v1 {
        @include mix.breakpointRange(var.$zero-width, (var.$tablet-width - 1)) {
            display: none;
        }
    }
    // \depend: button-link-with-arrow-right-v1
    // \TODO[dependСss]
    
}