@use 'base/variables' as var;
@use 'base/mixins' as mix;

.modal-window-order-call-v1 {
        width: 70rem !important;
        @include mix.breakpoint(var.$desktop-width) {
            width: 80rem !important;
        }
        .container{
            padding: 0 !important;
        }
        .call{
            margin: 0 !important;
        }
        .call__inner{
            background-size: 16rem 17.5rem !important;
            @include mix.breakpoint(var.$desktop-width) {
                background-position: bottom -1px right 2rem !important;
            }
        }
        .call__title{
            font-size: 3rem !important;
            @include mix.breakpoint(var.$desktop-width) {
                font-size: 4rem !important;
            }

        }

    .container {
        padding: 0;
    }

    .call {
        margin-bottom: 1rem !important;
    }

    .social__list {
        background: #f5f7fa;
        padding: 2rem;
        border-radius: 2px;
        margin-bottom: 8rem;

        svg {
            max-width: 3.8rem;
            max-height: 3.8rem;
            color: var.$color-text;
        }

        @include mix.breakpoint(var.$tablet-width) {
            width: 100% !important;
        }
    }

    .msg-btn {
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
        font-size: 1.4rem;

        .when-disable {
            display: none;
        }

        &._disable {
            background-color: #f5f7fa !important;
            color: #a5a5a5 !important;

            .when-no-disable {
                display: none;
            }

            .when-disable {
                display: block;
            }
        }

    }

    .popup-contacts__list {
        width: auto !important;
        margin-left: 0 !important;
        flex-wrap: wrap;

        .contacts__item {
            width: calc(50% - 0.5rem);
            margin: 0 0 1.5rem 0;

            &:nth-child(1) {
                margin-right: 0.5rem;
            }

            &:nth-child(2) {
                margin-left: 0.5rem;
            }
        }

        @include mix.breakpoint(var.$tablet-width) {
            justify-content: space-between;
            background: none;
            border: none;
            padding: 0;
            margin-top: 2rem;
            .contacts__link {
                padding: 1rem 2rem 1rem 1.5rem;
            }
        }


    }
}

//deps
.modal-window-order-call-v1 {

}