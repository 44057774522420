@use 'base/variables' as var;
@use 'base/mixins' as mix;

.header-with-cta-v1 {
    
    &__title {
        margin: 0;
        font-size: 2rem;
        line-height: 120%;
        letter-spacing: 0.015em;
        font-family: "Affect";
        font-weight: 700;
        @include mix.breakpoint(var.$tablet-width) {
            font-size: 3.2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            font-size: 4.1rem;
            line-height: 112%;
        }
    }

    &__header-block &__title {
        max-width: 16.5rem;
        color: #ffffff;
        @include mix.breakpoint(var.$tablet-width) {
            max-width: 17.5rem;
        }
    }

    &__header-block {
        margin-bottom: 1.5rem;
        padding-top: 2rem;
        padding-bottom: 2.8rem;
        padding-left: 1.5rem;
        background-image: url("/img/maskot/sad-purple.svg");
        background-repeat: no-repeat;
        background-size: 8.6rem 7.6rem;
        background-position: bottom right 4rem;
        @include mix.breakpoint(var.$tablet-width) {
            width: 43.2rem;
            flex-grow: 1;
            margin: 0;
            margin-right: 2rem;
            padding: 3rem 2rem;
            background-size: 14.4rem 12.8rem;
            background-position: bottom right 5.1rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            padding: 5rem 6rem;
            background-size: 20rem 17.7rem;
            background-position: bottom right 11rem;
        }

        @include mix.breakpoint(var.$desktop-max-width) {
            background-position: bottom right 20rem;
        }
    }

    &__cta-block {
        padding: 2rem 1.5rem;
        background-color: #F5F7FA;
        @include mix.breakpoint(var.$tablet-width) {
            width: 27.5rem;
            flex-grow: 1;
            max-width: 40rem;
            flex-shrink: 0;
            padding: 3rem 2rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            width: 41.3rem;
            padding: 4rem 5rem;
            max-width: none;
            flex-grow: 0;
        }
    }

    &__cta-block &__title {
        margin-bottom: 2rem;
        @include mix.breakpoint(var.$tablet-width) {
            margin-bottom: 0.8rem;
        }
        @include mix.breakpoint(var.$desktop-width) {
            margin-bottom: 2rem;
        }
    }

    .more {
        &__link {
            font-size: 1.4rem;
            line-height: 1.8rem;
            font-weight: 500;
            font-family: "EuclidCircularA", Arial, sans-serif;
            @include mix.breakpoint(var.$tablet-width) {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }

        &__link-icon {
            height: 1rem;
            margin-left: 2px;
        }
    }

}

/** DEPENDS CODE */
.header-with-cta-v1 {

    .icon-v1 {

    }

    &__cta-block .link-with-icon-v1__label {
        border-bottom: 1px solid transparent;
        transition: border-color .3s;
        @media(hover:hover) {
            &:hover {
                border-bottom-color: currentColor;
            }
        }
    }

}
/** DEPENDS CODE */